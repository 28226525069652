import React, { useEffect, useState } from "react";
import "./styles.css";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import AdvogadoCardHome from "../../molecules/AdvogadoCardHome";
import { HomeService } from "../../../services/HomeServices/HomeService";
import { Col, Row } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";

export default function ParentComponent() {
  const api = new HomeService();
  const [advogados, setAdvogados] = useState([]);
  const [itemAtual, setItemAtual] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.carrosselAdvogados();

        if (Array.isArray(response.data)) {
          setAdvogados(response.data);
        } else {
          console.error("erro api:", response);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);


  const hideFirstItem = currentIndex !== 0;

  function AdvogadosCarousel({ advogados }) {
    const slides = Array.from({ length: Math.min(9, advogados.length) }).map(
      (_, index) =>
        advogados
          .slice(index * 3, index * 3 + 3)
          .map((advogado, innerIndex) => (
            <AdvogadoCardHome key={innerIndex} advogado={advogado} />
          ))
    );

    function SlidesCarousel(){
      if(slides.length <= 3){
        const slides1 = slides.slice(0, 1).map((slide, index) => (
          <Carousel.Item key={index}>
          <Row className=" my-4 d-flex justify-content-center  ">
          
            <Col lg={8} className="d-flex gap-5">
            {slide}
            </Col>
          
          </Row>
        </Carousel.Item>
        ));

        return slides1;

      } else if (slides.length > 3 && slides.length <= 6){
        const slides1 = slides.slice(0, 2).map((slide, index) => (
          <Carousel.Item key={index}>
          <Row className=" my-4 d-flex justify-content-center  ">
          
            <Col lg={8} className="d-flex gap-5">
            {slide}
            </Col>
          
          </Row>
        </Carousel.Item>
        ));

        return slides1;

      }else if (slides.length > 6){
        const slides1 = slides.slice(0, 3).map((slide, index) => (
          <Carousel.Item key={index}>
            <Row className=" my-4 d-flex justify-content-center  ">
            
              <Col lg={8} className="d-flex gap-5">
              {slide}
              </Col>
            
            </Row>
          </Carousel.Item>
        ));

        return slides1;
      }
    }    

    return (
      <Container>
        <Subtitle text="Quem está no Faça Jus" />
        <Carousel
          data-bs-theme="dark"
          activeIndex={currentIndex}
          onSelect={(index) => setCurrentIndex(index)}
          className="d-flex px-2 my-5 justify-content-center"
        >
          {SlidesCarousel()}
        </Carousel>
      </Container>
    );
  }
  return <AdvogadosCarousel advogados={advogados} />;
}
