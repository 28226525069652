import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import Header from '../../molecules/Header';

import AdvConsultasAgendadas from '../../organisms/AdvConsultasAgendadas';
import './styles.css';
import { AdvAgendamento } from '../../organisms/Agendamento';
import Footer from '../../molecules/Footer';

export const Agendamento = ({advId}) => {
  return (
    <body className='mt-5 pt-5'>
      <Container fluid className='fluid-bg'>
        <Header />
      </Container>
      <Container className='mt-5 pt-5'>
        <AdvAgendamento
        advId={advId}
        />
      </Container>
      <Container fluid className='fluid-bg '>
        <Footer/>
      </Container>
    </body>
  );
}

