import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.css";
import Logo from "../../atoms/Logo";
import { Link } from "react-router-dom";
import { CadastroClienteForms } from "../../organisms/CadastroClienteForms";
import imagem from "../../../img/fotologincli.png";
import { Image } from "react-bootstrap";
import ParagraphSmall from "../../atoms/ParagraphSmall";

export const CadastroCliente = () => {
  return (
    <Container fluid>
      <Row className="row-login">
        <Col className=" m-0 p-0 ">
          <Image className="imagem-div-login-4" src={imagem} />
        </Col>
 
        <Col lg={6} className="text-center mt-4 ">
          <Col className="">
            <Link to="/">
              <Logo type="imagem3" />
            </Link>
            <CadastroClienteForms />
          </Col>
          {/* <Col>
            <Col className=" pt-2">
              <Col className="d-flex justify-content-center text-center">
                <ParagraphSmall text="Ao se cadastrar você concorda com:" />
              </Col>
              <Col className="d-flex flex-column ps-2">
                <Link to="/TermosDeUso" target="blank">
                  <ParagraphSmall text="Termos de Uso" />
                </Link>
                <Link to="/PoliticaDePrivacidade" target="blank">
                  <ParagraphSmall text="Política de Privacidade" />
                </Link>
              </Col>
            </Col>
          </Col> */}
        </Col>
      </Row>
    </Container>
  );
};
