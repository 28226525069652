import React from 'react';
import './styles.css';

import Subtitle from '../../atoms/Subtitle';
import Paragraph from '../../atoms/Paragraph';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Icons from '../../atoms/Icons';



function AdvSelectArea(props) {
  return (
         <Container className=''>
            <Row   className=' align-items-center mt-5 '>    
              
              <Col  className="">
              <Subtitle  text={props.text}/>
              </Col>
              
            </Row>
            <Row className='align-items-center   '  >    
              
              <Col >            
                <Paragraph text={props.text2}/>
              </Col>
            </Row>
        </Container>
       
   );
}

export default AdvSelectArea;




  
