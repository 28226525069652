import React from "react";
import './styles.css';
import { useParams } from "react-router";
import { CadastroAdvogado } from "../../components/templates/CadastroAdvogado";
import { CadastroCliente } from "../../components/templates/CadastroCliente";
import { NotFound } from "../NotFound";

export const Cadastro = () => {

    const params = useParams();
    const {typeUser} = params;

    const renderTemp = () => {
      switch (typeUser.toLowerCase()) {
        case "adv":
          return <CadastroAdvogado />;
        case "cli":
          return <CadastroCliente />;
        default:
          return <NotFound />;
      }
    };

    return (
         renderTemp()
    );

}