import React from "react";
import './styles.css';
import { useParams } from "react-router";
import { LoginAdvogado } from "../../components/templates/LoginAdvogado";
import { LoginCliente } from "../../components/templates/LoginCliente";
import { NotFound } from "../NotFound";
import { TermosDeUso } from "../../components/templates/TermosDeUso";



export const Login = () => {

  

   const params = useParams();
   const {typeUser} = params;

   



    const renderTemp = () => {
      switch (typeUser.toLowerCase()) {
        case "adv":
          return <LoginAdvogado />;
        case "cli":
          return <LoginCliente   avaliacao={'cli'}/>;
        case "avaliacao":
          return <LoginCliente avaliacao={'avaliacao'} />;
        default:
          return <NotFound />;
      }
    };

    return (
         renderTemp()
    );

}