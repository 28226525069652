import React, { useState } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import { ReactComponent as IconePerfil } from "../../../../src/img/Group 279.svg";
import { AvatarPerfil } from "../AvatarPerfil";

import { Navbar, Nav, NavDropdown, Button, Offcanvas } from "react-bootstrap";
import Subtitle from "../Subtitle";

const NavItemAdm = () => {
  const nomeUsuario = sessionStorage.getItem("nome") || ""; 
  const partesDoNome = nomeUsuario.split(' '); 
  const primeiroNome = partesDoNome[0]; 
  
  const primeiraLetra = nomeUsuario.charAt(0);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  return (
    <>
      {/* Navbar for large screens (desktop) */}
      <Navbar expand="lg" className="d-none d-lg-flex p-0 m-0">
        <Nav className="list align-items-center p-0 m-0">
          {/* <Nav.Link href="/" className="no-toggle-icon">Início</Nav.Link>
            <Nav.Link href="/ConsultasCli" className="no-toggle-icon">Consultas</Nav.Link>
            <Nav.Link href="/FinanceiroCli" className="no-toggle-icon">Financeiro</Nav.Link>
            <Nav.Link href="/ForumCli" className="no-toggle-icon">Fórum</Nav.Link>
            <Nav.Link href="/faleconosco" className="no-toggle-icon">Fale conosco</Nav.Link> */}
          <Subtitle text={`Olá, ${primeiroNome}!`} />

          <NavDropdown
            className="m-0 p-0"
            title={
              <AvatarPerfil
                type="B"
                base64={sessionStorage.getItem("fotoPerfil")}
                width="4rem"
                height="4rem"
                padding="0.1rem"
                margin="1px"
              />
            }
          >
            {/* <NavDropdown.Item href="/PerfilClienteTemp">Meu Perfil</NavDropdown.Item> */}
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => sessionStorage.clear()} href="/">
              Sair
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar>

      {/* Offcanvas for small screens (mobile) */}
      <div className="d-lg-none ">
        <Button variant="outline-dark " onClick={toggleOffcanvas} className="">
          Menu
        </Button>
        <Offcanvas
          className="bg-warning"
          show={showOffcanvas}
          onHide={() => setShowOffcanvas(false)}
          placement="end"
        >
          <Offcanvas.Header className="mx-5 my-2 text-center" closeButton>
            <Offcanvas.Title className="display-5  ">FaçaJus</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="bg-warning ">
            <Nav className="list align-items-center p-0 m-0">
              {/* <Nav.Link href="/" className="no-toggle-icon">Início</Nav.Link>
            <Nav.Link href="/ConsultasCli" className="no-toggle-icon">Consultas</Nav.Link>
            <Nav.Link href="/FinanceiroCli" className="no-toggle-icon">Financeiro</Nav.Link>
            <Nav.Link href="/ForumCli" className="no-toggle-icon">Forum</Nav.Link>
            <Nav.Link href="/faleconosco" className="no-toggle-icon">Fale conosco</Nav.Link> */}

              <NavDropdown
                className="m-0 p-0 text-center"
                title={
                  <AvatarPerfil
                    type="B"
                    base64={sessionStorage.getItem("fotoPerfil")}
                    width="4rem"
                    height="4rem"
                    padding="0.1rem"
                  />
                }
              >
                {/* <NavDropdown.Item href="/PerfilClienteTemp">Meu Perfil</NavDropdown.Item> */}
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={() => sessionStorage.clear()}
                  href="/"
                >
                  Sair
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default NavItemAdm;
