
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import AppRouter from '../src/routes/AppRouter';
import React, { useState, useRef, useEffect } from "react";

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function App() {

  return (
  
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY}>
 
      <AppRouter />
 
    </GoogleReCaptchaProvider>
     
);
    
      
  
}

export default App;
