import React, { useEffect, useState } from "react";
import { Calendar, Badge } from "rsuite";
import "./styles.css";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import { Col, Container, Row } from "react-bootstrap";
import { AgendaService } from "../../../services/AdvogadoServices/AgendaService";

const Calendario = ({ selectedDate }) => {
  const [horariosLivres, setHorariosLivres] = useState([]);
  const [consultasAgendadas, setConsultasAgendadas] = useState([]);
  const [clickedDate, setClickedDate] = useState(null);

  const api = new AgendaService();
  const id = sessionStorage.getItem("id");

  useEffect(() => {
    api
      .listarAgenda(id)
      .then((data) => {
        setHorariosLivres(data.data.horariosLivres);
        setConsultasAgendadas(data.data.consultasAgendadas);
      })
      .catch((error) => {
        console.error("Error fetching agenda data:", error);
      });
  }, []);

  function renderCell(date) {
    const isDisponivel =
      horariosLivres &&
      horariosLivres.some(
        (d) =>
          new Date(d).toISOString().slice(0, 10) ===
          date.toISOString().slice(0, 10)
      );
  
    const hasConsulta =
      consultasAgendadas &&
      consultasAgendadas.some(
        (d) =>
          new Date(d).toISOString().slice(0, 10) ===
          date.toISOString().slice(0, 10)
      );
  
    if (isDisponivel) {
      return (
        <Badge
          content="livre"
          className="badge-size badge-color calendar-fake-date-badge"
          title="Clique para ver detalhes"
        />
      );
    } else if (hasConsulta) {
      return (
        <Badge
          content="cons."
          color="yellow"
          className="badge-size calendar-additional-date-badge"
          title="Clique para ver detalhes"
        />
      );
    }
  
    return null;
  }

  function handleSelect(date) {
    setClickedDate(date);
    selectedDate(date);
  }

  useEffect(() => {
  }, [clickedDate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return date.toLocaleDateString("pt-BR", options);
  };

  return (
    <Container className="">
      <Row className="bg-calendar calendar-size rounded shadow">
        <Calendar
          bordered={true}
          compact={true}
          isoWeek={false}
          renderCell={renderCell}
          locale={{
            sunday: "Dom",
            monday: "Seg",
            tuesday: "Ter",
            wednesday: "Qua",
            thursday: "Qui",
            friday: "Sex",
            saturday: "Sáb",
            ok: "OK",
            today: "Hoje",
            // yesterday: "Ontem",
            // last7Days: "Últimos 7 Dias",
          }}
          onSelect={handleSelect}
        />
      </Row>
      <Row className="d-flex flex-row align-items-center justify-content-center">
        <Col lg={2}>
          <ParagraphSmall text="Legenda:" />
        </Col>
        <Col lg={9} className="mx-2 d-flex">
          <Col className="">
            <Badge
              content="Horários livres em sua agenda."
              // color="green"
              className="badge-color me-2 badge-size calendar-fake-date-badge"
            />
            <Badge
              content="Consultas agendadas."
              color="yellow"
              className="badge-size calendar-additional-date-badge"
            />
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default Calendario;
