import React from "react";
import { useEffect, useState } from "react";
import { Breadcrumb, Col, Container,Spinner, Row, Table, FloatingLabel } from "react-bootstrap";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import Paragraph from "../../atoms/Paragraph";
import { Form, FormControl } from "react-bootstrap";
import InputMask from "react-input-mask";
import Icons from "../../atoms/Icons";
import Btn from "../../atoms/Button";
import { RelatorioConsignadoService } from "../../../services/AdmFinanceiroServices/RelatorioConsignadoService";
import functions from "../../utils/functions";
import { Toasts } from "../../utils/Toasts";
import Subtitle from "../../atoms/Subtitle";
import { AdmStatusCanceladosConsigModal } from "../../molecules/AdmStatusCanceladosConsigModal";
import DropdownBtnParcelas from "../../atoms/DropdownBtnParcelas";

export const AdmRelatorioConsignadoCancelados = () => {
  const api = new RelatorioConsignadoService();
  const id = sessionStorage.getItem("id");

  const [ano, setAno] = useState();
  const [mes, setMes] = useState();
  const [response, setResponse] = useState(null);

  const [loading, setLoading] = useState(false);
  const [arrayIdParcelas, setArrayIdParcelas] = useState([]);

  const [show, setShow] = useState(false);

  const [status, setStatus] = useState(null);
  const opcoes = ["Todos", "Cancelado", "Em aberto"];
  
  const handleOpenModal = () => {
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 1800);

      const response = await api.listarCancelados(ano, mes);
      if (response.status) {
        setResponse(response);
      }
    } catch (error) {
      console.error("Erro na chamada da API:", error);
    }
  };

  const exportToCSV = () => {
    const csvData =
      response && response.data
        ? response.data.map((item) => ({
            Status: item.statusParcela,
            "Valor do Repasse": item.valorEstorno,
            Banco: item.bancoCliente,
            Agencia: item.agenciaCliente,
            Conta: item.contaCorrenteCliente,
            "Nome do Destinatário": item.nomeCliente,
            "CPF do Destinatário": item.servidorCpf,
            "ID da Consulta": item.idConsulta,
            "Nome Cliente": item.nomeCliente,
            Matrícula: item.servidorMatricula,
            "CPF do Cliente": item.servidorCpf,
            "Data da Consulta": functions.formatarDataParaString(
              item.dataConsulta
            ),
            "Valor da Consulta": item.valorConsulta,
            "Quantidades de Parcelas": item.totalParcelas,
            "Valor da Parcela": item.valorParcela,
          }))
        : [];

    const csvContent = [
      Object.keys(csvData[0]).join(","),
      ...csvData.map((item) => Object.values(item).join(",")),
    ].join("\n");

    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent)
    );
    link.setAttribute("download", "extrato.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleAmarzenaId = (id_terceiros, isChecked) => {
    if (isChecked === true) {
      setArrayIdParcelas((prevArray) => [...prevArray, id_terceiros]);
    } else {
      setArrayIdParcelas((prevArray) =>
        prevArray.filter((id) => id !== id_terceiros)
      );
    }
  };

  return (
    <Container>
      <Row className="d-flex justify-content-between">
        <Subtitle text="Relatório de Cancelamentos (Consignado):" />
        <Col lg={8} className="d-flex  align-items-center">
          <Col className="d-flex gap-2 m-0 p-0">
            <InputMask
              className="form-control"
              mask="99"
              onChange={(e) => setMes(e.target.value)}
              placeholder="Mês"
            ></InputMask>
        
            <InputMask
              className="form-control"
              mask="9999"
              onChange={(e) => setAno(e.target.value)}
              placeholder="Ano"
            ></InputMask>

            <Col className="cursor">
              {loading ? (
                <Spinner
                  style={{ width: "1.4rem", height: "1.4rem" }}
                  animation="border"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <Icons type="buscar" onClick={fetchData} />
              )}
            </Col>
          </Col>
        
          <Col lg={7}>
            <Form.Group controlId="parcelas">
              <FloatingLabel className="d-flex align-items-center ms-4">
                <Col lg={12} className="d-flex align-items-center gap-2" >
                  <ParagraphSmall text="Filtrar por status:" />
                  <Col lg={7}> 
                    <DropdownBtnParcelas
                      text="Selecione os status"
                      defaultValue={{
                        label: "Todos",
                        value: "Todos",
                      }}
                      buttonType="primary"
                      options={opcoes.map((opcao) => ({
                        label: opcao,
                        value: opcao,
                      }))}
                      onPress={(data) => setStatus(data)}
                      />
                  </Col>  
                </Col>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Col>

        <Col lg={4} className="d-flex gap-2 ">
          <Col className="d-flex text-end align-items-center">
            <Col lg={10} className="">
              <Paragraph text="EXPORTAR DADOS" />
            </Col>
            <Col lg={2} className="cursor" onClick={exportToCSV}>
              <Icons type="exportar" />
            </Col>
          </Col>
        </Col>
      </Row>

      <Row className="d-flex flex-column">
        <Col
          className="overflow-auto d-flex  "
          // style={{ maxWidth: "50%"  }}
        >
          <Table striped hover className="">
            <thead>
              <tr>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Selecionar" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Status" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Valor Repasse" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Banco" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Agência" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Conta para transferência" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Nome destinatário" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="CPF destinatário" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="ID da consulta" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Nome Cliente" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Matrícula" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="CPF Cliente" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Data Consulta" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Valor consulta" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Quantidade de Parcelas" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Valor da Parcela" />
                </th>
              </tr>
            </thead>
            <tbody>
              {response && response.data ? (
                response.data.map((item, index) => (
                  <tr key={index} className="align-items-center">
                    {status == null || status.value == "Todos" || item.statusParcela == status.value  ? (
                      <>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <div className="d-flex justify-content-start m-0 p-0">
                            {item.statusParcela == "Cancelado" ? (
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                onClick={(e) =>
                                  handleAmarzenaId(
                                    item.idReembolso,
                                    e.target.checked
                                  )}
                              />
                            ) : (
                              " "
                            )}
                          </div>
                        </td>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <ParagraphSmall text={item.statusParcela} />
                        </td>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <ParagraphSmall text={`R$ ${item.valorEstorno}`} />
                        </td>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <ParagraphSmall text={item.bancoCliente} />
                        </td>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <ParagraphSmall text={item.agenciaCliente} />
                        </td>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <ParagraphSmall text={item.contaCorrenteCliente} />
                        </td>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <ParagraphSmall text={item.nomeCliente} />
                        </td>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <ParagraphSmall text={item.servidorCpf} />
                        </td>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <ParagraphSmall text={item.idConsulta} />
                        </td>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <ParagraphSmall text={item.nomeCliente} />
                        </td>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <ParagraphSmall text={item.servidorMatricula} />
                        </td>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <ParagraphSmall text={item.servidorCpf} />
                        </td>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <ParagraphSmall text={functions.formatarDataParaString(item.dataConsulta)}/>
                        </td>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <ParagraphSmall text={item.valorConsulta} />
                        </td>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <ParagraphSmall text={item.totalParcelas} />
                        </td>
                        <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                          <ParagraphSmall text={item.valorParcela} />
                        </td>
                      </>
                    ) : (
                      " "
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="19" style={{ textAlign: "center" }}>
                    Filtre o mês e o ano que deseja consultar os dados.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <Col className="mt-2 - d-flex flex-column">
          <Col className="justify-content-end d-flex ">
            <ParagraphSmall text="Selecione as transações que deseja alterar o status e confirme no botão abaixo." />
          </Col>
          <Col className="justify-content-end d-flex ">
            <Btn
              type="secondary-sm"
              text="Marcar como PAGO"
              onTouchable={handleOpenModal}
              />
            </Col>
          </Col>
          <AdmStatusCanceladosConsigModal
            show={show}
            onHide={handleCloseModal}
            arrayDeParcelas={arrayIdParcelas}
            cliId={id}
          />
      </Row>
    </Container>
  );
};

export default AdmRelatorioConsignadoCancelados;
