import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from '../../molecules/Header';
import Footer from '../../molecules/Footer';
import VantagensLpAdv from '../../organisms/VantagensLpAdv';

export const LandingPageAdvTemp = () => {
  return (
    <body className=''>

        <Container fluid  className='fluid-bg '> 
            <Header/>
        </Container>

        <Container>
        <VantagensLpAdv />
        </Container>

        <Container fluid className='fluid-bg '>
      
            <Footer />
        </Container>
        

    </body>
  );
}



