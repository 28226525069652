import React, { useEffect, useState } from 'react';
import './styles.css';
import Title from '../../atoms/Title';
import Subtitle from '../../atoms/Subtitle';
import Container from 'react-bootstrap/Container';
import SearchFieldsHome from '../../molecules/SearchFieldsHome';

const SearchHome = () => {
     
    return (
      <div className="">
        <Container className="my-5">
          <Title text="Encontre um advogado" />
          <Subtitle text="Diversos especialistas para orientar no seu caso" />
        </Container>
        <Container className="">
          <SearchFieldsHome />
        </Container>
      </div>
    );
  
  };

export default SearchHome;
