import React from "react";
import Container from 'react-bootstrap/Container';

import './styles.css';

import imagem from "../../../img/banner-perfil-cliente.png"
import { Image } from "react-bootstrap";



export default function ImgBannerPerfil() {

  return (
    <Container fluid className="banner-perfil-img p-0">
      <Image  src={imagem} />        
    </Container>     
      
  );
}
  


