import React, { useEffect, useRef, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import './styles.css';
import InputArquivo from "../../molecules/InputArquivo";
import Subtitle from "../../atoms/Subtitle";
import Btn from "../../atoms/Button";
import { PerfilService } from "../../../services/ClienteServices/PerfilService";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import { FotoPerfilCadastroModal } from "../../molecules/FotoPerfilCadastroModal";

export default function CabecalhoPerfilCliente() {
  const [nomeUsuario, setNomeUsuario] = useState(sessionStorage.getItem("nome") || "");
  const [show, setShow] = useState(false);
  //const [foto, setFoto] = useState();
  const firstRun = useRef(true);
  const [base64ImagemBackend, setBase64ImagemBackend] = useState('');
  const api = new PerfilService();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const handleSubmit = async (novaFoto) => {
    try {
      await api.adicionarFoto(sessionStorage.getItem("id"), novaFoto)
      .then((data) => {
        if(data.status == true) {
          sessionStorage.setItem('fotoPerfil',novaFoto)
        }
      })
      ;
      setShow(false);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Container className="mb-5">
      <Row className="align-items-center">
        <Col lg={2} className="avatar-container">
          <AvatarPerfil
            type="A"
            base64={sessionStorage.getItem("fotoPerfil")}
            width="13rem"
            height="13rem"
            padding="0.4rem"
          />
        </Col>

        <Col lg={8} className='py-2 mt-2 ms-5'>
        <Subtitle text={'Olá, ' + sessionStorage.getItem("nome")} />
          <Btn type={"secondary-sm"} isSubmit={false} text="Trocar foto" onTouchable={handleShow} />

          <FotoPerfilCadastroModal
            show={show}
            handleClose={handleClose}
            setShow={setShow}
            handleFotoPerfil={handleSubmit}
          />

          {/* <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title className="px-5">
                <Subtitle text="Envie sua foto" />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="ms-5 pe-5">
              <InputArquivo
                validFileExtensions={['.jpg', '.png', '.jpeg']}
                getFile={(e) => setFoto(e)}
                name={'Arquivo'}
                id={'foto'}
              />
            </Modal.Body>
            <Modal.Footer>
                <Col className="d-lg-flex justify-content-between">
                  <Btn type={"terciary"} isSubmit={false} text="Cancelar" onTouchable={handleClose} />
                  <Btn type={"secondary"} isSubmit={false} text="Enviar" onTouchable={handleSubmit} />
                </Col>
            </Modal.Footer>
          </Modal> */}
        </Col>
      </Row>
    </Container>
  );
}
