import { Container, Row, Col, Modal } from "react-bootstrap";
import Paragraph from "../../atoms/Paragraph";
import Btn from "../../atoms/Button";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Subtitle from "../../atoms/Subtitle";
import CardConfirmarConsultaAdv from "../../molecules/CardConfirmarConsultaAdv";
import { LoginClienteForms } from "../LoginClienteForms";
import { Toasts } from "../../utils/Toasts";
import { HomeService } from "../../../services/HomeServices/HomeService";
import { LoginClienteFormsAgendamento } from "../LoginClienteFormsAgendamento";
import "./styles.css";

export const AdvPreAgendamento = ({ advId, nextStep }) => {
  let api = new HomeService();
  const navigate = useNavigate();
  const [dados, setDados] = useState(null);
  const [controlRender, setControlRender] = useState(false);
  const [disponivel, setDisponivel] = useState(true);

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const req = async () => {
    await api
      .getInfosPreAgendamento(advId)
      .then((data) => {
        if (data.status === true) {
          setDados(data.data);
          if (data?.data?.valor_consulta === 0)
            proxStep(2);
          
          sessionStorage.setItem("advId", advId);
          setOptionsToast(optionsToast);
          setDados(data.data);
          setControlRender(true);
        } else {
          setDisponivel(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    req();
  }, []);

  const handleBackPage = () => {
    navigate(-1);
  };

  const handleRedirect = () => {
    navigate("/cadastro/cli");
  };

  const handleShow = async () => {
    let value = sessionStorage.getItem("logado") ?? false;
    if (value) {
      setShow(false);
      nextStep();
    } else {
      setShow(true);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const isUserLoggedIn = sessionStorage.getItem("logado") ?? false;


  function proxStep(data) {
    nextStep(data);
  }

  const renderPreAgendamento = () => {
    return (
      <Container>
        <Row className="d-block">
          <Col>
            <Col lg={12} className="pt-2">
              {controlRender ? (
                <CardConfirmarConsultaAdv data={dados} />
              ) : (
                <></>
              )}
            </Col>
          </Col>
          <Col className="text-center">
            <hr className="border-warning border-3 mb-5 mt-5" />
            <Paragraph fontSize="18px"
              text= { !disponivel? 'Horário não mais disponível' : isUserLoggedIn ?
                "Prossiga para realizar o pagamento da consulta."
                : "Para agendar consultas, você precisa estar logado no Faça Jus. Caso ainda não tenha, aproveite e realize o seu cadastro."
              }
              />
          </Col>
          <Col className="pg-step-pre-buttons d-lg-flex d-block gap-5 mt-5">
            <Btn
              type={"terciary"}
              isSumbit={false}
              text="Voltar"
              onTouchable={handleBackPage}
            />
            {disponivel && <Btn
              type={"secondary"}
              isSumbit={false}
              text="Avançar"
              onTouchable={handleShow}
            />}
          </Col>
        </Row>
        <Row>
          <Modal size="md" show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Container className="d-flex row  justify-content-center mb-5">
              <LoginClienteFormsAgendamento
                advId={advId}
                nextStep={(data) => proxStep(data)}
              />
              <Col lg ={8} md={6} className="d-flex  justify-content-center row  ">
                <Btn
                  type={"terciary"}
                  isSumbit={false}
                  text="Cadastre-se"
                  onTouchable={handleRedirect}
                />
              </Col>
            </Container>
          </Modal>
        </Row>
      </Container>
    );
  };
  return (
    <Container>
      <Row>
        <Col>
          {renderPreAgendamento()}
          <Toasts
            code={optionsToast.code}
            message={optionsToast.message}
            visible={optionsToast.visible}
            setDefaultOptions={() => fecharToast()}
          />
        </Col>
      </Row>
    </Container>
  );
};
