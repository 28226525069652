import api from "../Api";
 
export class UsuarioService {

  async getAll() {
    return await api
      .get("/adm/gerencial/usuario/listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async postCadastrar(
    nome,
    email,
    telefone,
    cpf,
    senha,
    confirma_senha,
    per_id
  ) {   

    return await api.post("/adm/gerencial/usuario/cadastrar", {
      "cpf": cpf,
      "nome": nome,
      "email": email,
      "celular": telefone,
      "senha": senha,
      "confirma_senha": confirma_senha,
      "per_id" :     per_id

    }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
    .then((res) =>  res.data);
 
  }

  async postListar(
  //  nome,
  //  cpf,
  //  telefone,
  //  email,
  //  id,
  //  data,
  //  perfil
  ) {
    return await api.get("/adm/gerencial/usuario/listar", {
    
			// "nome" : nome,
			// "cpf" : cpf, 
			// "telefone" : telefone,
			// "email" : email,
			// "id" : id,
			// "data" : data,
			// "perfil" : perfil
		
    }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((res) =>  res.data);
  }

  async getUsuarioPorId(
      params,
      nome,
      cpf,
      telefone,
      email,
      id,
      data,
      perfil
      ) {
        
      try {
      const { id: userId } = params;
  
      const response = await api.get(`/adm/gerencial/usuario/dados/${userId}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        data: {
          nome,
          cpf,
          telefone,
          email,
          id,
          data,
          perfil,
        },
      });
  
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // "id": "5",
  // "cpf": "77619014063",
  // "nome": "luis teste  dfdsdfsdsfdsfdfsdfsdf",
  // "celular": "5256348999", 
  // "email": "mayrus.michel9@usedigi.com.br",
  // "senha": "111111mM@",
  // "confirma_senha": "111111mM@"

  async postAlteraDadosUsuarios(
    id,
    cpf,
    nome,
    telefone,
    email,
    senha,
    confirma_senha,
    per_id
    ) {
    return await api.post("adm/gerencial/usuario/alterar", {
			"id" : id,
      "cpf" : cpf, 
      "nome" : nome,
			"telefone" : telefone,
			"email" : email,
			"senha" : senha,
      "confirma_senha" : confirma_senha,
      "per_id" : per_id
    }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  res.data);
  }

  
  
 

 
};
