import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles.css";
import Subtitle from "../../atoms/Subtitle";
import { Form, Col, Container, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Btn from "../../atoms/Button";
import { Toasts } from "../../utils/Toasts";
import DropdownBtn from "../../atoms/DropdownBtn";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import AgendaAvisos from "../../molecules/AgendaAvisos";
import { AdvAgendaInputs } from "../../molecules/AdvAgendaInputs";
import { AgendaService } from "../../../services/AdvogadoServices/AgendaService";

import { getDay } from "date-fns";

export const AdvMinhaAgenda = () => {
  const id = sessionStorage.getItem("id");
  const api = new AgendaService();

  const [aceitoTermos, setAceitoTermos] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [intervals, setIntervals] = useState([]);
  const [showAdditionalInputs, setShowAdditionalInputs] = useState(false);
  const [openedAdditionalInputs, setOpenedAdditionalInputs] = useState([]);
  const [duracaoConsultaSelecionada, setDuracaoConsultaSelecionada] =
    useState();
  const [recorrenciaAgendaSelecionada, setRecorrenciaAgendaSelecionada] =
    useState();

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);
  const [horarios, setHorarios] = useState([])

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const duracaoConsulta = [30, 60, 90, 120, 150, 180, 210];
  const idDesc = duracaoConsulta.map((item, index) => ({
    id: index + 1,
    desc: `${item} minutos`,
  }));

  const handleDurationSelection = (selectedOption) => {
    setDuracaoConsultaSelecionada(selectedOption);
  };

  const recorrenciaAgenda = [
    "1 semana",
    "2 semanas",
    "1 mês",
    "3 meses",
    "6 meses",
  ];
  const idRecorrencia = recorrenciaAgenda.map((item, index) => ({
    id: index + 1,
    desc: `${item}`,
  }));

  const handleRecorrenciaAgenda = (selectedOption) => {
    setRecorrenciaAgendaSelecionada(selectedOption);
  };

  const handleDayClick = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay !== day)
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }

    let arr = [];
    let idx = horarios.findIndex((horario) => horario.day === day);
    
    if (idx >= 0) {
      setHorarios(horarios.filter((horario) => horario.day !== day));
    }
    else {
      arr = [...horarios];
      arr.push({day, startTime: "", endTime: ""})
      arr.sort(sortSelectedDays);
      setHorarios(arr);
    }
    
    
  };

  const setDayHorarios = (horario, idx) => {
    let arr =  [...horarios];
    if (arr[idx].isAdditional) horario.isAdditional = true;
    arr.splice(idx, 1, horario);
    setHorarios(arr);
  }

  const sortSelectedDays = (a, b) => {
    const daysOrder = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SÁB"];
    return daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
  };

  const addMoreTimes = (horarios) => {
    setIntervals([...intervals, { horarios }]);
  };

  const closeAllAdditionalIntervals = (day) => {
    setShowAdditionalInputs(false);
    setHorarios(horarios.filter((horario, i) => horario.day != day || !horario.isAdditional));
    setOpenedAdditionalInputs((prevOpenedInputs) =>
      prevOpenedInputs.filter((openedDay) => openedDay !== day)
    );
  };

  const openAdditionalInputs = (day) => {
    setShowAdditionalInputs(true);

    let arr = [...horarios, { day, isAdditional: true, startTime: "", endTime: "" }];
    arr.sort(sortSelectedDays);
    setHorarios(arr);

    if (!openedAdditionalInputs.includes(day)) {
      setOpenedAdditionalInputs((prevOpenedInputs) => [
        ...prevOpenedInputs,
        day,
      ]);
    }
  };

  function convercaoDuracao(recorrencia) {
    if (recorrencia) {
      const regexSemana = /(\d+)\s+semana(s)?/;
      const regexMes = /(\d+)\s+m(?:ê|e)s(?:es)?/u;

      if (regexSemana.test(recorrencia)) {
        const match = recorrencia.match(regexSemana);
        const semanas = parseInt(match[1], 10);
        return semanas * 7;
      } else if (regexMes.test(recorrencia)) {
        const match = recorrencia.match(regexMes);
        const meses = parseInt(match[1], 10);
        return meses * 30;
      } else {
        return "Formato invalido";
      }
    } else {
      return;
    }
  }

  const dayMapping = {
    DOM: "sunday",
    SEG: "monday",
    TER: "tuesday",
    QUA: "wednesday",
    QUI: "thursday",
    SEX: "friday",
    SÁB: "saturday",
  };

  const obterDuracao = () => {
    if (duracaoConsultaSelecionada) {
      return parseInt(duracaoConsultaSelecionada.desc);
    } else {
      return 0;
    }
  };

  const obterRecorrencia = () => {
    if (recorrenciaAgendaSelecionada) {
      return convercaoDuracao(recorrenciaAgendaSelecionada.desc);
    } else {
      return 0;
    }
  };

  const obterHorariosAtendimento = () => {
    return horarios.map((horario) => {
      const dayAbreviacao = horario.day;
      const dayEngles = dayMapping[dayAbreviacao];

      if (dayEngles) {
        return {
          day: dayEngles,
          startTime: horario.startTime,
          endTime: horario.endTime,
        };
      } else {
        return horario;
      }
    });
  };

  const enviarAgenda = async () => {
    try {
      const duracao = obterDuracao();
      const recorrencia = obterRecorrencia();
      const horarioAtendimento = obterHorariosAtendimento();
      const response = await api.criarAgenda(
        id,
        duracao,
        horarioAtendimento,
        recorrencia,
        aceitoTermos
      );
      if(response.status){
      let optionsToast = {
        code: response.mensagem.codigo,
        message: response.mensagem.mensagem,
        visible: true,
      };
      setOptionsToast(optionsToast);
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } else {
      let optionsToast = {
        code: response.mensagem.codigo,
        message: response.mensagem.mensagem,
        visible: true,
      };
      setOptionsToast(optionsToast);
    }
    } catch (error) {
      console.log(error)
  }};

  // const combinedDays = [...sortedSelectedDays, ...openedAdditionalInputs].sort(
  //   (a, b) => a - b
  // );

  const daysOrder = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SÁB"];

  // const sortedCombinedDays = combinedDays.sort((a, b) => {
  //   return daysOrder.indexOf(a) - daysOrder.indexOf(b);
  // });

  const dropdownRef = useRef(null);

  const limparInformacoes = () => {
    setAceitoTermos(false);
    setSelectedDays([]);
    setIntervals([]);
    setShowAdditionalInputs(false);
    setOpenedAdditionalInputs([]);
    setDuracaoConsultaSelecionada();
    setRecorrenciaAgendaSelecionada();

    // Voltar o dropdown para o valor padrão
    if (dropdownRef.current) {
      dropdownRef.current.reset();
    }
  };

  return (
    <Container className="">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Row className="d-flex flex-column justify-content-center mb-4">
        <Col className="d-flex flex-column justify-content-center">
          <Col>
            <Subtitle text="Duração do Atendimento" />
          </Col>
          <Col lg={4} className="mb-3">
            <Form.Group>
              <DropdownBtn
                text="Selecione a duração da Consulta"
                type={"primary"}
                options={idDesc}
                onPress={(selectedOption) =>
                  handleDurationSelection(selectedOption)
                }
              />
            </Form.Group>
          </Col>
        </Col>

        <Col className="d-flex mt-3 flex-column justify-content-center">
          <Col>
            <Subtitle text="Horários de Atendimento" />
          </Col>
          <Col lg={7} className="mb-3">
            <ParagraphSmall text="Configure os horários de atendimento, informe a hora inicial e a hora final. O sistema irá criar as opções de agendamento entre esses dois horários." />
          </Col>

          <ButtonToolbar aria-label="Toolbar with button groups">
            <ButtonGroup
              size="sm"
              className="me-2 gap-1"
              aria-label="First group"
            >
              {["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SÁB"].map(
                (day, index) => (
                  <Button
                    key={index}
                    style={{
                      backgroundColor: selectedDays.includes(day)
                        ? "#476BA9"
                        : "transparent",
                      borderColor: "#476BA9",
                      color: selectedDays.includes(day) ? "#FFFFFF" : "#476BA9",
                    }}
                    onClick={() => {
                      handleDayClick(day);
                      // closeAllAdditionalIntervals(day);
                    }}
                  >
                    {day}
                  </Button>
                )
              )}
            </ButtonGroup>
          </ButtonToolbar>

          <Row>
            <Col lg={12} className="d-flex flex-wrap ">
              {horarios.map((horario, index) => (
                <Col
                  lg={5}
                  key={index}
                  className={`d-flex  my-2 ms-3 shadow rounded border-start border-secondary border-3`}
                >
                  <AdvAgendaInputs
                    day = {horario.day}
                    start = {horario.startTime}
                    end = {horario.endTime}
                    enviarAgenda = {(horarios) => setDayHorarios(horarios, index)}
                    isAdditionalInterval = {horario.isAdditional}
                  />
                  <Col lg={1} className="pt-2">
                    {openedAdditionalInputs.includes(horario.day) && horario.isAdditional ? (
                      <Btn
                        type="primary-X"
                        text="-"
                        onTouchable={() =>
                          closeAllAdditionalIntervals(horario.day)
                        }
                      />
                    ) : (
                      <Btn
                        type="primary-X"
                        text="+"
                        onTouchable={() => openAdditionalInputs(horario.day)}
                      />
                    )}
                  </Col>
                </Col>
              ))}
            </Col>
          </Row>

          <Col className="mt-5">
            <Subtitle text="Recorrência da Agenda" />
            <Col lg={4} className="mb-3">
              <Form.Group>
                <DropdownBtn
                  text="Selecione a recorrência de sua Agenda"
                  type={"primary"}
                  options={idRecorrencia}
                  onPress={(selectedOption) =>
                    handleRecorrenciaAgenda(selectedOption)
                  }
                />
              </Form.Group>
            </Col>
          </Col>
        </Col>

        <Col className="mb-4 mt-1">
          <AgendaAvisos />

          <Col className="d-flex justify-content-end">
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Estou ciente dos termos da agenda."
              checked={aceitoTermos}
              onChange={(e) => setAceitoTermos(e.target.checked)}
            />
          </Col>
        </Col>

        <Col>
          <Col className="d-flex flex-row gap-2 justify-content-end">
            <Btn
              type="terciary-sm"
              text="Limpar"
              onTouchable={limparInformacoes}
            />
            <Btn
              type="secondary-sm"
              text="Salvar agenda"
              onTouchable={enviarAgenda}
            />
          </Col>
        </Col>
      </Row>
    </Container>
  );
};
