import React, { useCallback, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../molecules/Header";
import Footer from "../../molecules/Footer";
import Subtitle from '../../atoms/Subtitle';  
import CabecalhoPerfilAdv from "../../molecules/CabecalhoPerfilAdv";
import ImgBannerPerfil from "../../atoms/ImgBannerPerfil";
import { PerfilAdvogadoForms } from "../../organisms/PerfilAdvogadoForms/index";
import { PerfilAdvSenha } from "../../organisms/PerfilAdvSenha ";
import { AdvPlano } from "../../organisms/AdvPlano";
import { ClassificacaoAdv } from "../../organisms/ClassificacaoAdv";
import { ComentarioAvaliacao } from "../../organisms/ComentarioAvaliacao";
import { PerfilService } from "../../../services/AdvogadoServices/PerfilService";

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3'; 



export const PerfilAdvTemp = () => {

  const api = new PerfilService();
  const id = sessionStorage.getItem('id');
  const [dadosApi, setDadosApi] = useState(null);
  const [controlRender, setControlRender] = useState(false);

  
  const [tokenRecaptcha, setTokenRecaptcha] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();



  useEffect(() => {
    if(dadosApi != null) {
      setControlRender(true)
    }
  }, [dadosApi]);



  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    setTokenRecaptcha(await executeRecaptcha('submit'));

  }, [executeRecaptcha]);

 
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);


  useEffect(() => {
    handleReCaptchaVerify();
    api.postPerfilAdv(id, tokenRecaptcha)
    .then((data) => {
      setDadosApi(data);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);



  return (
    <body className="">
      <Container fluid className="p-0 m-0 ">
        <Header />
      </Container>

      <Container fluid className="p-0 m-0">
        <div className="banner-container">
          <ImgBannerPerfil className="img-fluid"/>
        </div>
      </Container>


      <Container>
        { controlRender ? <CabecalhoPerfilAdv data={dadosApi}/> : <></>}                 
      </Container>


      <Container className='mb-5 pb-5'>
         <Subtitle text="Dados pessoais:"/>
         { controlRender ? <PerfilAdvogadoForms data={dadosApi}/> : <></>}                 
       </Container>


       <Container className='mb-5 pb-5'>
         <Subtitle text="Senha:" />
         { controlRender ? <PerfilAdvSenha data={dadosApi}/> : <></>}   
       </Container>

       {/* <Container className='mb-5 pb-5'>
        <Subtitle text="Minha Assinatura" />
        { controlRender ? <AdvPlano data={dadosApi}/> : <></>}   
       </Container> */}


       {/* <Container className='mb-5 pb-5'> */}
        {/* <Subtitle text="Minha Classificação"/>
        { controlRender ? <ClassificacaoAdv data={dadosApi}/> : <></>}   
       </Container> */}

      {/* 
        Sessao que ira ser adicionada quanto tiver sistema de avaliacao do advogado
       <Container className='mb-5 pb-5'>
        <Subtitle text="Comentários"/>
        { controlRender ? <ComentarioAvaliacao data={dadosApi}/> : <></>}   
       </Container>
    */}
        
      <Container fluid className="p-0 m-0 ">
        <Footer />
      </Container>
    </body>
  );
};
