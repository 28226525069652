import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Header from '../../molecules/Header';
import Footer from '../../molecules/Footer';
import { PerguntarForumCli } from '../../organisms/PerguntarForumCli';

export const Forum = () => {
  return (
    <body className='mt-5 pt-5'>

        <Container fluid  className='fluid-bg '> 
            <Header/>
        </Container>

        <Container className='my-5 '>
            <PerguntarForumCli/>
        </Container>

        <Container fluid className='fluid-bg '>
                <Footer />
        </Container>
        

    </body>
  );
}

