import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../molecules/Header";
import { PerguntasFreqAdv } from '../../molecules/PerguntasFreqAdv';

import "./styles.css";
import Footer from "../../molecules/Footer";
import Title from "../../atoms/Title";

export const PerguntasFrequentes = () => {
  return (
    <body className="mt-5 pt-5">
      <Container fluid className="fluid-bg">
        <Header />
      </Container>

      <Container className="mt-5 pt-5 ">
        <Col  className="pf-mx-5 pf-px-5 ">
        <Title text="Perguntas Frequentes" />
        </Col>
        <Col className="pf-mx-5 pf-px-5 pf-my-5 ">
        <PerguntasFreqAdv />

        </Col>
      </Container>

      <Container fluid className="fluid-bg ">
        <Footer />
      </Container>
    </body>
  );
};
