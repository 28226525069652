import React from "react";

import Accordion from "react-bootstrap/Accordion";

export function PerguntasFreqAdv() {
  return (
    <Accordion defaultActiveKey={["0"]} alwaysOpen className="px-md-5 ">
      <Accordion.Item eventKey="0" className="acordeon-resposta ">
        <Accordion.Header>
          01. Quais são os diferentes tipos de advocacia?
        </Accordion.Header>
        <Accordion.Body>
          Existem várias áreas de prática dentro da advocacia, como direito
          civil, direito penal, direito empresarial, direito de família, direito
          trabalhista, direito ambiental, entre outros. O Faça Jus conta com uma
          grande rede de advogados, sempre buscando abranger todas as áreas para
          atender a sua necessidade.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" className="acordeon-resposta">
        <Accordion.Header>02. Quando devo procurar um advogado?</Accordion.Header>
        <Accordion.Body>
          Você deve procurar um advogado sempre que estiver enfrentando uma
          situação legal que envolva direitos, responsabilidades ou questões
          jurídicas. Isso pode incluir assuntos como divórcio, acusações
          criminais, problemas contratuais, entre outros.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" className="acordeon-resposta ">
        <Accordion.Header>
          03. Como escolher o advogado certo para o meu caso?
        </Accordion.Header>
        <Accordion.Body>
          Ao escolher um advogado, considere sua especialização na área
          relevante, experiência, reputação, localização e custos. Uma consulta
          inicial pode ajudá-lo a avaliar se o advogado é adequado para o seu
          caso.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" className="acordeon-resposta ">
        <Accordion.Header>04. O que é uma consulta inicial?</Accordion.Header>
        <Accordion.Body>
          Uma consulta inicial é uma reunião inicial com o advogado para
          discutir os detalhes do seu caso, avaliar suas opções legais e
          determinar se o advogado é a escolha certa para representá-lo. No Faça
          Jus você pode agendar com mais de um profissional, para ter mais
          opiniões sobre o seu caso.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4" className="acordeon-resposta ">
        <Accordion.Header>
         05. O advogado oferece garantia de vitória?
        </Accordion.Header>
        <Accordion.Body>
          Não, nenhum advogado pode garantir resultados específicos em um caso,
          pois o resultado final depende de vários fatores, incluindo leis,
          evidências e decisões judiciais. O Faça Jus oferece a comunicação com
          advogados verificados pela plataforma, para assim seguir com o seu
          caso da melhor forma.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5" className="acordeon-resposta ">
        <Accordion.Header>
          06. O que devo trazer para a minha primeira reunião com o advogado?
        </Accordion.Header>
        <Accordion.Body>
          É útil trazer quaisquer documentos relevantes, como contratos,
          correspondências, registros ou evidências relacionadas ao seu caso.
          Isso ajudará o advogado a entender melhor a situação. Ao realizar uma
          consulta com um profissional, você terá acesso ao chat, onde poderá
          tirar suas dúvidas diretamente com o advogado escolhido.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6" className="acordeon-resposta">
        <Accordion.Header>
         07. Quanto custa uma consulta com um advogado?
        </Accordion.Header>
        <Accordion.Body>
          As consultas variam dependendo da experiência do advogado, da
          especialidade e da localização. No Faça Jus você encontra uma
          variedade de profissionais e preços de acordo com a sua necessidade. É
          importante lembrar que outros custos referente ao processo do caso,
          não são cobrados na plataforma.
        </Accordion.Body>
      </Accordion.Item>
      {/* <Accordion.Item eventKey="7" className="acordeon-resposta ">
        <Accordion.Header>08. Como posso realizar o pagamento?</Accordion.Header>
        <Accordion.Body>
          Caso você seja servidor, o pagamento pode ser feito via consignado,
          onde será descontado da sua margem disponível, podendo ser parcelado
          em até X vezes ou a vista via transferência bancária para o advogado.
          (VERIFICAR ESSA INFORMAÇÃO)
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8" className="acordeon-resposta ">
        <Accordion.Header>09. Quais são os planos disponíveis?</Accordion.Header>
        <Accordion.Body>
          O Faça Jus disponibiliza dois planos para o advogado. Plano I com a
          possibilidade de agendamento on-line, fórum de perguntas e respostas
          com os clientes e pagamento parcelado via Paga Para Mim. Já no Plano
          II você poderá contar com chat com clientes, ranking de visibilidade
          com base nas avaliações dos clientes, sistema de videoconferência para
          consultas além dos benefícios do Plano I.
        </Accordion.Body>
      </Accordion.Item> */}
      <Accordion.Item eventKey="9" className="acordeon-resposta ">
        <Accordion.Header>
          08. Como é feita a verificação do perfil?
        </Accordion.Header>
        <Accordion.Body>
          A verificação do perfil de um advogado envolve uma série de etapas
          para garantir que a pessoa está qualificada e autorizada a exercer a
          advocacia. A equipe Faça Jus verifica se os profissionais cumpriram
          todas as seguites etapas: Formação Acadêmica e Jurídica: O
          profissional deve ter concluído um curso de Direito reconhecido em uma
          instituição de ensino credenciada. Isso geralmente envolve a obtenção
          de um diploma de bacharel em Direito. Inscrição na Ordem dos
          Advogados: O advogado deve estar registrado na Ordem dos Advogados.
          Isso envolve fornecer documentação que comprove a conclusão
          bem-sucedida dos requisitos educacionais e de exame.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
