import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Breadcrumb, Col, Container, Row, Form, Modal } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import Btn from "../../atoms/Button";
import InputMask from "react-input-mask";
import "./styles.css";

import Button from "react-bootstrap/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import functions from "../../utils/functions";
import { FloatingLabel } from "react-bootstrap";
import GuiaPassoAPassoForm from "../../molecules/GuiaPassoAPassoForm";
import { useNavigate } from "react-router-dom";

import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { Toasts } from "../../utils/Toasts";
import { UsuarioService } from "../../../services/AdmUsuarioServices/UsuarioService";
import Paragraph from "../../atoms/Paragraph";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import { PerfilService } from "../../../services/AdmUsuarioServices/PerfilService";
import CheckboxTree from "react-checkbox-tree";

export const AdmGerencialPerfisDados = ({ id }) => {
  const api = new PerfilService();
  const navigate = useNavigate();

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("eyeOff");
  const [showModal, setShowModal] = useState(false);

  const handleToggle = () => {
    setType(type === "password" ? "text" : "password");
    setIcon(icon === "eyeOff" ? "eye" : "eyeOff");
  };

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  // Função para abrir o modal
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleNavigateBack = () => {
    Navigate(-1);
  };

  // Função para excluir o advogado (substitua por sua lógica)
  const handleDeleteAdvogado = () => {
    // Implemente a lógica para excluir o advogado aqui
    // Feche o modal após a exclusão
    setShowModal(false);
  };

  const initialValues = {
    per_nome: "",
    per_descricao: "",
  };

  const schema = yup.object().shape({
    per_nome: yup
      .string()
      .required("O nome é obrigatório")
      .matches(/^[a-zA-ZÀ-ÿ\s]*$/, "Por favor, digite apenas letras e acentos")
      .max(80, "Máximo de caracteres excedido")
      .min(3, "Mínimo de caracteres não detectado"),
    per_descricao: yup
      .string()
      .required("Descrição é obrigatória")
      .max(80, "Máximo de caracteres excedido")
      .min(3, "Mínimo de caracteres não detectado"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      // Handle form submission here
      console.log(values, checkedNodes);
      enviaDadosPerfil(values);
    },
  });

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  async function enviaDadosPerfil(values) {
    try {
      const response = await api.postEditar(
        id,
        values.per_nome,
        values.per_descricao,
        checkedNodes.gerencialPerfis ?? ""
      );
      if (response.status) {
        let optionsToast = {
          code: response.mensagem.codigo,
          message: response.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);

        setTimeout(() => {
          navigate("/adm/gerencial/perfil");
        }, 3100); // Atraso
      }
    } catch (error) {
      console.log("Erro ao buscar dados da API:", error);
    }
  }

  const [checkedNodes, setCheckedNodes] = useState({
    gerencialPerfis: [],
    // gerencialUsuarios: []
  });

  const handleCheckboxChange = (newChecked, nodeId, treeId) => {
    setCheckedNodes((prevCheckedNodes) => ({
      ...prevCheckedNodes,

      [treeId]: newChecked,
    }));
  };

  const nodes = [
    {
      value: "gerencialPerfis",

      label: "Gerencial - Perfis",

      children: [
        {
          value: "gerencial-perfis-acessar",

          label: "Acessar",
        },

        {
          value: "gerencial-perfis-cadastrar",

          label: "Cadastrar",
        },

        {
          value: "gerencial-perfis-editar",

          label: "Editar",
        },
      ],
    },

    {
      value: "gerencialUsuarios",

      label: "Gerencial - Usuários",

      children: [
        {
          value: "gerencial-usuarios-acessar",

          label: "Acessar",
        },

        {
          value: "gerencial-usuarios-cadastrar",

          label: "Cadastrar",
        },

        {
          value: "gerencial-usuarios-editar",

          label: "Editar",
        },
      ],
    },

    {
      value: "plataformaAdvogado",

      label: "Plataforma - Advogado",

      children: [
        {
          value: "plataforma-advogado-acessar",

          label: "Acessar",
        },
       
        {
          value: "plataforma-advogado-editar",

          label: "Editar",
        },
      ],
    },
    {
      value: "plataformaCliente",

      label: "Plataforma - Cliente",

      children: [
        {
          value: "plataforma-clientes-acessar",

          label: "Acessar",
        },

        {
          value: "plataforma-cliente-editar",

          label: "Editar",
        },
            
      ],
    },
    {
      value: "financeiroConsignado",

      label: "Financeiro - Consignado",

      children: [
        {
          value: "financeiro-consignado-acessar",

          label: "Acessar",
        },

        {
          value: "financeiro-consignado-editar",

          label: "Editar",
        },
      ],
    },

    {
      value: "financeiroParticular",

      label: "Financeiro - Particular",


      children: [
        {
          value: "financeiro-particular-acessar",

          label: "Acessar",
        },

        {
          value: "financeiro-particular-editar",

          label: "Editar",
        },
      ],
    },
    {
      value: "dashboard",

      label: "Dashboard ",

      children: [
        {
          value: "dashboard-acessar",

          label: "Acessar",
        },

        {
          value: "dashboard-editar",

          label: "Editar",
        },
      ],
    },
  ];

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await api.getPerfilPorId({ id });
        const userData = response.data;
        formik.setValues(userData);
        // setCheckedNodes({gerencialPerfis: [response.data.per_acessos]})
        setCheckedNodes({
          gerencialPerfis: response.data.per_acessos.split(","),
        });
      } catch (error) {
        console.error("Erro ao buscar dados do usuário:", error);
      }
    }
    fetchUserData();
  }, [id]);

  useEffect(() => {
    console.log(checkedNodes.gerencialPerfis);
  }, [checkedNodes]);

  return (
    <Container className="px-4">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Row className="pe-3">
        <Col>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="per_nome" className="d-lg-flex gap-3">
              <Col lg={6} md={6} sm={12}>
                <FloatingLabel controlId="per_nome" label="Nome">
                  <Form.Control
                    type="text"
                    name="per_nome"
                    value={formik.values.per_nome}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.per_nome && formik.errors.per_nome
                    }
                    placeholder="Nome"
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.per_nome}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>

              <Col lg={6} md={6} sm={12}>
                <FloatingLabel controlId="per_descricao" label="Descrição">
                  <Form.Control
                    type="per_descricao"
                    name="per_descricao"
                    value={formik.values.per_descricao}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.per_descricao &&
                      formik.errors.per_descricao
                    }
                    placeholder="E-mail"
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.per_descricao}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col lg={6} md={6} sm={12}></Col>
            </Form.Group>

            <Col className="ms-5 gap-5 d-flex">
              <CheckboxTree
                nodes={nodes}
                checked={checkedNodes.gerencialPerfis}
                expanded={[
                  "gerencialPerfis",
                  "gerencialUsuarios",
                  "plataformaAdvogado",
                  "plataformaCliente",
                  "financeiroConsignado",
                  "financeiroParticular",
                  "dashboard"

                ]}
                onCheck={(newChecked) =>
                  handleCheckboxChange(
                    newChecked,
                    "gerencialPerfis",
                    "gerencialPerfis"
                  )
                }
              />{" "}
            </Col>

            <Col className="d-flex gap-3">
              <Btn
                type="terciary-sm"
                text="Voltar"
                onTouchable={() => navigate(-1)}
              />

              <Btn type="secondary-sm" text="Salvar" isSumbit="true" />
            </Col>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
