import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import Paragraph from "../../atoms/Paragraph";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import { useNavigate } from 'react-router-dom';

const AdvogadoCardHome = ({ advogado }) => {
  const navigate = useNavigate();

  // const handleClick = () => {
  //   navigate(`/ResultadoSearch/${advogado.clienteId}`);
  //   alert ("/ResultadoSearch/${advogado.clienteId}");
  // };



  return (
    <Container >
      <Row lg={12} className="">
        <Col className="d-lg-flex align-items-center justify-content-start gap-2  ">
        
            <AvatarPerfil
              base64={advogado.foto}
              type="B"
              width="4rem"
              display="none"
              height="4rem"
            />
          </Col>
 
          <Col lg={12} className="">
            <Col className=" text-center text-lg-start">
              
                <Paragraph text={advogado.advogadoNome} />
                <Col className="d-flex flex-column align-items-center align-items-lg-start">
                  <ParagraphSmall text={"OAB " + advogado.OAB} />
                </Col>
             
                <Rating
                  readonly={true}
                  initialValue={advogado.estrelas}
                  size={22}
                />
             
            </Col>
          </Col>
        
      </Row>
    </Container>
  );
};


export default AdvogadoCardHome;
