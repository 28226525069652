import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col } from "react-bootstrap";
import Header from "../../../molecules/Header";
import NavitemSidebarAdm from "../../../atoms/NavitemSidebarAdm";
import "./styles.css";
import AdmHeader from "../../../molecules/AdmHeader";
import Paragraph from "../../../atoms/Paragraph";
import AdmRelatorioConsignado from "../../../organisms/AdmRelatorioConsignado";
import AdmRelatorioConsignadoCancelados  from "../../../organisms/AdmRelatorioConsignadoCancelados";

export const FinanceiroConsignadoTemp = () => {
  const [sideNavExpanded, setSideNavExpanded] = useState(true);

  const toggleSideNav = () => {
    setSideNavExpanded(!sideNavExpanded);
  };

  return (
    <body className="m-0 p-0">
      <Container fluid className="fluid-bg">
        <AdmHeader />
      </Container>

      <Container fluid className="d-flex  row mt-5">
        <Col
          className={`menu-position ${sideNavExpanded ? "" : "menu-fechado"}`}
        >
          <NavitemSidebarAdm
            menuAberto={sideNavExpanded}
            toggleMenu={toggleSideNav}
          />
        </Col>

        <Col lg={8} className="flex-grow-1 ms-2 ">
          <Col className="shadow-lg rounded p-2 mb-4">
            <AdmRelatorioConsignado />
          </Col>
          <Col className="shadow-lg rounded p-2 mb-4">
            <AdmRelatorioConsignadoCancelados />
          </Col>
        </Col>
      </Container>
    </body>
  );
};
