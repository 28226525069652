import React, { useState } from "react";
import "./styles.css";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import Btn from "../../atoms/Button";
import Paragraph from "../../atoms/Paragraph";
import { ConsultaService } from "../../../services/AdvogadoServices/ConsultasService";
import { Toasts } from "../../utils/Toasts";


const CancelarConsultaAdvModal = ({ showModal, handleCloseModal, idAgenda }) => {

  const api = new ConsultaService();
  const id = sessionStorage.getItem("id");

  const [loading, setLoading] = useState(null);
  
  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

    const cancelarConsultaAdv = async () => {
      setLoading(true);
      api.cancelarConsultaAdv(id, idAgenda)
      .then(response => {
        let optionsToast = {
          code: response.mensagem.codigo,
          message: response.mensagem.mensagem,
          visible: true,
        };
        if (response.status) {
          setLoading(false);
          setOptionsToast(optionsToast);
          setTimeout(() => {
            window.location.reload();
          }, 2500);
        } else {
          setLoading(false);
          setOptionsToast(optionsToast);
        }
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
         let optionsToast = {
          code: '102',
          message: 'Não é possível cancelar esta consulta',
          visible: true,
        }
        setOptionsToast(optionsToast);
      });
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Modal.Header closeButton>
        <Modal.Title>Cancelamento de Consulta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Paragraph
          text={`Tem certeza de que deseja cancelar a consulta? Esta ação não pode ser revertida.`}
        />
      </Modal.Body>
      <Modal.Footer>
        <Btn type="terciary-sm" text="Voltar" onTouchable={handleCloseModal} />
        <Btn type="danger-sm-load" text="Sim, cancelar!" onTouchable={cancelarConsultaAdv} isLoading={loading}/>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelarConsultaAdvModal;
