import React from 'react';
import defaultPhoto from '../../../../src/img/Group 279.svg';
import './styles.css';

export const AvatarPerfil = ({ base64, width, height, padding, display, margin = '0', type }) => {
  const photoSource = base64 ? `data:image/jpg;base64,${base64}` : defaultPhoto;

  // const name = sessionStorage.getItem("nome");
  // let firstCharacter = '';

  // if (name) {
  //   firstCharacter = name.charAt(0).toUpperCase();
  // }

  let avatarClass = 'blue-circle p-0 m-0';

  switch (type) {
    case 'A':
      avatarClass = 'avatar-size-g';
      break;
    case 'B':
      avatarClass = 'avatar-size-p';
      break;
    
    case 'C':
      avatarClass = 'avatar-size-clear';
      break;

    default:
      avatarClass = 'blue-circle p-0 m-0';
  }

  return (
    <div>
    <img
      className=''
      src={photoSource}
      alt='Foto de perfil'
      style={{
        backgroundColor: '#fff',
        padding: padding,
        margin: margin,
        width: width,
        height: height,
        objectFit: 'cover',
        borderRadius: '50%',
      }}
      onError={(e) => {
        e.target.src = defaultPhoto;
      }}
    />
    {/* {firstCharacter && (
      <div className={avatarClass} 
      style={{ 
        display: display }}> {firstCharacter}
      </div>
    )} */}
  </div>
  );
};