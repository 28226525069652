import React, {  useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col } from "react-bootstrap";
import NavitemSidebarAdm from "../../../atoms/NavitemSidebarAdm";
import "./styles.css";
import AdmHeader from "../../../molecules/AdmHeader";
import AdmRelatorioParticular from "../../../organisms/AdmRelatorioParticular";
import AdmRelatorioParticularCancelados from "../../../organisms/AdmRelatorioParticularCancelados";

export const FinanceiroParticularTemp = () => {
  const [sideNavExpanded, setSideNavExpanded] = useState(true);

  const toggleSideNav = () => {
    setSideNavExpanded(!sideNavExpanded);
  };

  return (
    <body className="m-0 p-0">
      <Container fluid className="fluid-bg">
        <AdmHeader />
      </Container>

      <Container fluid className="d-flex   row mt-5">
        <Col
          className={`menu-position ${sideNavExpanded ? "" : "menu-fechado"}`}
        >
          <NavitemSidebarAdm
            menuAberto={sideNavExpanded}
            toggleMenu={toggleSideNav}
          />
        </Col>

        <Col lg={8} className="flex-grow-1 ms-2 ">
          <Col className="shadow-lg rounded p-2 mb-4">
            <AdmRelatorioParticular />
          </Col>
          <Col className="shadow-lg rounded p-2 mb-4">
          <AdmRelatorioParticularCancelados />
          </Col>
        </Col>
      </Container>
    </body>
  );
};
