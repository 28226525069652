import functions from "../../components/utils/functions";
import api from "../Api";
 
export class AvaliacaoService {
  
    async avaliacaoConsultaListar(
        cliId
      ){
        return await api.post("/advogado/avaliacao-adv-listar", {
          'cliente_id' : cliId
        }, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          }
        })
        .then((res) => res.data)
        .catch((error) => {
          if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
            functions.modalToken();
          } 
        });
      }

      async enviaEmailFeedback(
        nomeAdvogado,
        nomeCliente,
        mensagem,
        email
      ){
        return await api.post("/advogado/email-feedback-avaliacao", {
          'nomeAdvogado' : nomeAdvogado,
          'nomeCliente' : nomeCliente,
          'mensagem' : mensagem,
          "email" : email
        }, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          }
        })
        .then((res) => res.data)
        .catch((error) => {
          if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
            functions.modalToken();
          } 
        });
      }


    async solicitarExclusaoAvaliacao(
      consultaId,
      cac_exclusao_coment
    ){
      return await api.post("/advogado/solicitar-exclusao-avaliacao", {
        'consultaId' : consultaId,
        'cac_exclusao_coment' : cac_exclusao_coment
      }, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        }
      })
      .then((res) => res.data)
      .catch((error) => {
        if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
          functions.modalToken();
        } 
      });
    }

    async listarMediaEstrelas(id){
      return await api.post("/advogado/listar-media-estrelas", {
        'id' : id,
        }, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        }
      })
      .then((res) => res.data)
      .catch((error) => {
        if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
          functions.modalToken();
        } 
      });
    }
 
};
