import React from "react";
import { useEffect, useState } from "react";
import { Breadcrumb, Col, Container, FloatingLabel, Row, Spinner, Table} from "react-bootstrap";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import Paragraph from "../../atoms/Paragraph";
import { Form, FormControl } from "react-bootstrap";
import InputMask from "react-input-mask";
import Icons from "../../atoms/Icons";
import Btn from "../../atoms/Button";
import { RelatorioConsignadoService } from "../../../services/AdmFinanceiroServices/RelatorioConsignadoService";
import functions from "../../utils/functions";
import { Toasts } from "../../utils/Toasts";
import Subtitle from "../../atoms/Subtitle";
import { AdmStatusConsignadoModal } from "../../molecules/AdmStatusConsignadoModal";
import DropdownBtnParcelas from "../../atoms/DropdownBtnParcelas";

export const AdmRelatorioParticular = () => {
  const api = new RelatorioConsignadoService();
  const idUsuario = sessionStorage.getItem("idUsuario");

  const [ano, setAno] = useState();
  const [mes, setMes] = useState();
  const [response, setResponse] = useState(null);

  const [arrayIdParcelas, setArrayIdParcelas] = useState([]);
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);

  const [status, setStatus] = useState(null);
  const opcoes = [
    "Todos",
    "Em aberto",
    "Pago",
    "Não pago",
    "Pagamento parcial",
  ];

  const handleOpenModal = () => {
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await api.listar(ano, mes);
      if (response.status) {
        setResponse(response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Erro na chamada da API:", error);
    }
  };

  useEffect(() => {
    const fetchFirstData = async () => {
      try {
        setLoading(true);
        let today = new Date();
        let mes = `${(today.getMonth() + 1).toString().padStart(2, '0')}`;
        let ano = `${today.getFullYear()}`;

        setMes(mes);
        setAno(ano);
        const response = await api.listar(ano, mes);

        setResponse(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Erro ao obter taxas:", error);
      }
    };
  
    fetchFirstData();
  }, []);

  const handleAmarzenaId = (id_terceiros, isChecked) => {
    if (isChecked === true) {
      setArrayIdParcelas((prevArray) => [...prevArray, id_terceiros]);
    } else {
      setArrayIdParcelas((prevArray) =>
        prevArray.filter((id) => id !== id_terceiros)
      );
    }
  };

  const exportToCSV = () => {
    const csvData =
      response && response.data
        ? response.data.map((item) => ({
            consultaID: item.idConsulta,
            status: item.statusParcela,
            valorRepasse: item.valorRepasse,
            banco: item.banco,
            agencia: item.agencia,
            conta: item.contaCorrente,
            nomeAdvogado: item.nomeAdvogado,
            cpfAdvogado: item.CPFadvogado,
            nomeCliente: item.nomeCliente,
            cpfCliente: item.servidorCpf,
            dataConsulta: item.dataConsulta,
            valorConsulta: item.valorConsulta,
            quantidadeParcelas: item.totalParcelas,
            valorParcela: item.valorParcela,
            taxaFacajus: item.taxaFacajus,
            taxaTerceiro: item.taxaConsignado,
          }))
        : [];

    const csvContent = [
      [
        "Consulta ID",
        "Status",
        "Valor do Repasse",
        "Banco",
        "Agencia",
        "Conta",
        "Nome do Advogado",
        "Cpf do Advogado",
        "Nome Cliente",
        "Cpf do Cliente",
        "Data da Consulta",
        "Valor da Consulta",
        "Quantidades de Parcelas",
        "Valor da Parcela",
        "Taxa do FaçaJus",
        // "Taxa de Terceiro",
      ],
      ...csvData.map((item) =>
        [
          item.consultaID,
          item.status,
          item.valorRepasse,
          item.banco,
          item.agencia,
          item.conta,
          item.nomeAdvogado,
          item.cpfAdvogado,
          item.nomeCliente,
          item.cpfCliente,
          functions.formatarDataParaString(item.dataConsulta),
          item.valorConsulta,
          item.quantidadeParcelas,
          item.valorParcela,
          item.taxaFacajus,
          // item.taxaTerceiro,
        ].join(",")
      ),
    ].join("\n");

    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent)
    );
    link.setAttribute("download", "extrato.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      <Row className="">
        <Col
          lg={12}
          className="m-0 p-0 justify-content-start d-flex text-start"
        >
          <Breadcrumb className="p-0 m-0">
            <Breadcrumb.Item>Financeiro</Breadcrumb.Item>
            <Breadcrumb.Item active>Consignado</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row className="d-flex justify-content-between">
        <Subtitle text="Relatório de Repasse (Consignado):" />
        <Col lg={8} className="d-flex  align-items-center">
          <Col className="d-flex gap-2 m-0 p-0">
            <InputMask
              className="form-control"
              mask="99"
              value={mes}
              onChange={(e) => setMes(e.target.value)}
              placeholder="Mês"
            ></InputMask>
        
            <InputMask
              className="form-control"
              mask="9999"
              value={ano}
              onChange={(e) => setAno(e.target.value)}
              placeholder="Ano"
            ></InputMask>

            <Col className="cursor">
              {loading ? (
                <Spinner
                  style={{ width: "1.4rem", height: "1.4rem" }}
                  animation="border"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <Icons type="buscar" onClick={fetchData} />
              )}
            </Col>
          </Col>
        
          <Col lg={7}>
            <Form.Group controlId="parcelas">
              <FloatingLabel className="d-flex align-items-center ms-4">
                <Col lg={12} className="d-flex align-items-center gap-2" >
                  <ParagraphSmall text="Filtrar por status:" />
                  <Col lg={7}> 
                    <DropdownBtnParcelas
                      text="Selecione os status"
                      defaultValue={{
                        label: "Todos",
                        value: "Todos",
                      }}
                      buttonType="primary"
                      options={opcoes.map((opcao) => ({
                        label: opcao,
                        value: opcao,
                      }))}
                      onPress={(data) => setStatus(data)}
                      />
                  </Col>  
                </Col>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Col>

        <Col lg={4} className="d-flex gap-2 ">
          <Col className="d-flex text-end align-items-center">
            <Col lg={10} className="">
              <Paragraph text="EXPORTAR DADOS" />
            </Col>
            <Col lg={2} className="cursor" onClick={exportToCSV}>
              <Icons type="exportar" />
            </Col>
          </Col>
        </Col>
      </Row>

      <Row className="d-flex flex-column">
        <Col
          className="overflow-auto d-flex  "
          // style={{ maxWidth: "50%"  }}
        >
          <Table striped hover className="">
            <thead>
              <tr>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="#" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Selecionar" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Status" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Valor Repasse" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Banco" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Agência" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Conta para transferência" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Nome destinatário" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="CPF destinatário" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="ID da consulta" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Nome Cliente" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Matrícula" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="CPF Cliente" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Data Consulta" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Valor consulta" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Quantidade de Parcelas" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Valor da Parcela" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Taxa Façajus" />
                </th>
                {/* <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Taxa Consignado" />
                </th> */}
              </tr>
            </thead>
            <tbody>
              {response && response.data ? (
                response.data.map((item, index) => (
                  <tr key={index} className="align-items-center">
                    {status == null || status.value == "Todos" || item.statusParcela == status.value ? (
                      <>
                        <td style={{textAlign: "start", verticalAlign: "middle"}}>
                          {index + 1}
                        </td>
                        <td style={{textAlign: "start", verticalAlign: "middle"}}>
                          <div className="d-flex justify-content-start">
                            {item.statusParcela == "Em aberto" ? (
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                onClick={(e) =>
                                  handleAmarzenaId(
                                    item.idTerceiros,
                                    e.target.checked
                                  )
                                }
                              />
                            ) : (
                              " "
                            )}
                          </div>
                        </td>
                        <td style={{textAlign: "start", verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.statusParcela} />
                        </td>
                        <td style={{textAlign: "start", verticalAlign: "middle"}}>
                          <ParagraphSmall text={`R$ ${item.valorRepasse}`} />
                        </td>
                        <td style={{textAlign: "start", verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.banco} />
                        </td>
                        <td style={{textAlign: "start", verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.agencia} />
                        </td>
                        <td style={{textAlign: "start", verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.contaCorrente} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.nomeAdvogado} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.CPFadvogado} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.idConsulta} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.nomeCliente} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.servidorMatricula} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.servidorCpf} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={functions.formatarDataParaString(item.dataConsulta)}/>
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={`R$ ${item.valorConsulta}`} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle"}}>
                          <ParagraphSmall text={item.totalParcelas} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle", }}>
                          <ParagraphSmall text={`R$ ${item.valorParcela}`} />
                        </td>
                        <td style={{textAlign: "start",   verticalAlign: "middle", }}>
                          <ParagraphSmall
                            text={`10% - R$ ${item.taxaFacajus}`}
                          />
                        </td>
                        {/* <td style={{textAlign: "start",   verticalAlign: "middle", }}>
                          <ParagraphSmall text={`4% - R$ ${item.taxaConsignado}`}/>
                        </td> */}
                      </>
                    ) : (
                      " "
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="19" style={{ textAlign: "center" }}>
                    Filtre o mês e o ano que deseja consultar os dados.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <Col className="mt-2 - d-flex flex-column">
          <Col className="justify-content-end d-flex ">
            <ParagraphSmall text="Selecione as transações que deseja alterar o status e confirme no botão abaixo." />
          </Col>
          <Col className="justify-content-end d-flex ">
            <Btn
              type="secondary-sm"
              text="Marcar como PAGO"
              onTouchable={handleOpenModal}
            />
          </Col>
        </Col>
        <AdmStatusConsignadoModal
          show={show}
          onHide={handleCloseModal}
          arrayDeParcelas={arrayIdParcelas}
          cliId={idUsuario}
        />
      </Row>
    </Container>
  );
};

export default AdmRelatorioParticular;
