import React, { useState, useEffect, useRef } from 'react';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import InputMask from 'react-input-mask';
import * as formik from 'formik';
import * as yup from 'yup';
import functions from '../../utils/functions';
import { Container, FloatingLabel, Form, Col, Toast, Row } from "react-bootstrap";
import Btn from '../../atoms/Button';
import Paragraph from '../../atoms/Paragraph';
import Switch from '../../molecules/SwitchTwoOptions';
import { faleConoscoService } from '../../../services/FaleConoscoServices/FaleConoscoServices';
import { useNavigate } from 'react-router-dom';
import { Toasts } from '../../utils/Toasts';


const FaleConoscoForms = () => {

    const { Formik } = formik;
    let Api = new faleConoscoService();
    const [envio, setEnvio] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [validated, setValidated] = useState(false);
    const [optionContact, setOptionContact] = useState();
    const navigate = useNavigate();

    let defaultOptionsToast = {
        code: '',
        message: '',
        visible: false
    }

    const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);


    function fecharToast() {
        let defaultOptionsToast = {
            code: '',
            message: '',
            visible: false
        }
        setOptionsToast(defaultOptionsToast)
    }

    const schema1 = yup.object().shape({
        nome: yup.string().required('O nome é obrigatório')
        .matches(/^[a-zA-ZÀ-ÿ\s]*$/, 'Por favor, digite apenas letras com acentos')
        .matches(/^[a-zA-ZÀ-ÿ\s]*$/, 'Por favor, digite apenas letras com acentos')
        .max(80, 'Máximo de caracteres excedido'),
        email: yup.string().required('Email é obrigatório').email('Formato de e-mail inválido.'),
        mensagem: yup.string().required('Digite sua pergunta'),
        telefone: yup
        .string()
        .test(
          "telefone",
          "O Telefone deve conter exatamente 11 dígitos",
          (value) => {
            if (value) {
              return /^\(\d{2}\) \d{5}-\d{4}$/.test(value);
            }
            return false;
          }
        ),
    });

    const handleSubmit = (values) => {
        setFormValues((prevFormValues) => ({ ...prevFormValues, ...values }));
        setEnvio(!envio);
        Api.postFaleConosco(
            values.nome,
            values.telefone,
            values.email,
            values.mensagem,
            values.opcaoContato,
        ).then(
            (data) => {
                if (data.status === true) {
                    let optionsToast = {
                        code: data.mensagem.codigo,
                        message: data.mensagem.mensagem,
                        visible: true
                    };
                    setOptionsToast(optionsToast);
                }
            }).catch(
                (error) =>
                    console.log(error)
            )
        setValidated(true);
    };

    const renderForm = () => {
        return (
            <Formik
                key={envio}
                validationSchema={schema1}

                onSubmit={(values) => {
                    // Remover mascara antes de enviar info ao back
                    const { telefone, ...rest } = values;
                    const telefoneSemMascara = functions.removeMascara(telefone)

                    const opcaoContato = optionContact === 'Whatsapp/SMS' ? '1' : optionContact === 'E-mail' ? '2' : null;

                    const valuesSemMascara = {
                        ...rest,
                        telefone: telefoneSemMascara,
                        opcaoContato: opcaoContato
                    };
                    handleSubmit(valuesSemMascara)
                }}
                initialValues={{
                    nome: '',
                    telefone: '',
                    email: '',
                    mensagem: '',
                }}>

                {({ handleSubmit, handleChange, handleReset, handleBlur, values, touched, errors }) => (

                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="nome">
                            <FloatingLabel label={"Nome Completo"} className="mb-3">
                                <Form.Control
                                    className={`margim-campos-formulario ${touched.nome && errors.nome ? "is-invalid" : ""}`}
                                    type="text"
                                    name="nome"
                                    maxLength={100}
                                    value={values.nome}
                                    onChange={handleChange}
                                    isValid={touched.nome && !errors.nome}
                                    placeholder="Nome"
                                />
                                {touched.nome && errors.nome && (
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {errors.nome}
                                    </Form.Control.Feedback>
                                )}
                            </FloatingLabel>
                            <Form.Control.Feedback type="invalid" tooltip>
                                {errors.nome}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="telefone">
                            <FloatingLabel label={"Telefone"} className="mb-3">
                                <InputMask
                                    mask="(99) 99999-9999"
                                    value={values.telefone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}>
                                    {(inputProps) => (
                                        <Form.Control
                                            className={`margim-campos-formulario ${touched.telefone && errors.telefone ? "is-invalid" : ""}`}
                                            type="text"
                                            name="telefone"                                         
                                            value={values.telefone}
                                            onChange={handleChange}
                                            isValid={touched.telefone && !errors.telefone}
                                            placeholder="Telefone"
                                            {...inputProps}
                                        />
                                    )}
                                </InputMask>
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.telefone}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group controlId="email">
                            <FloatingLabel label={"Email"} className="mb-3">
                                <Form.Control
                                    className={`margim-campos-formulario ${touched.email && errors.email ? "is-invalid" : ""}`}
                                    type="email"
                                    name="email"
                                    maxLength={80}
                                    value={values.email}
                                    onChange={handleChange}
                                    isValid={touched.email && !errors.email}
                                    placeholder="Email"
                                />
                                {touched.email && errors.email && (
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {errors.email}
                                    </Form.Control.Feedback>
                                )}
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group controlId="mensagem">
                            <FloatingLabel label={"Mensagem"} className="mb-3">
                                <Form.Control
                                    className={`mb-3 ${touched.mensagem && errors.mensagem ? "is-invalid" : ""}`}
                                    as="textarea"
                                    type="text"
                                    name="mensagem"
                                    value={values.mensagem}
                                    maxLength={350}
                                    onChange={handleChange}
                                    isValid={touched.mensagem && !errors.mensagem}
                                    placeholder="Mensagem"
                                    style={{ height: '16rem' }}
                                />
                                {touched.mensagem && errors.mensagem && (
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {errors.mensagem}
                                    </Form.Control.Feedback>
                                )}
                            </FloatingLabel>
                            <Form.Control.Feedback type="invalid" tooltip>
                                {errors.mensagem}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div className='paragrafo-fale-conosco'>
                            <Paragraph text="selecione como deseja o retorno:" />

                            <Switch
                                firstOption={'Whatsapp/SMS'}
                                secondOption={'E-mail'}
                                onSelected={(data) => setOptionContact(data)}
                            />
                        </div>

                        <div className='d-grid'>
                            <Btn type={"secondary"} isSumbit={true} text="Enviar" />
                        </div>

                    </Form>
                )}
            </Formik>
        );
    };

    return (
        <Container className='form-forum'>
            <Toasts
                code={optionsToast.code}
                message={optionsToast.message}
                visible={optionsToast.visible}
                setDefaultOptions={() => fecharToast()}
            />
            <Row>
                <Col>
                    {renderForm()}
                </Col>
            </Row>

        </Container>
    );
};


export default FaleConoscoForms;
