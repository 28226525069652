import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.css";
import { ImgModal } from "../../atoms/ImgModal";
import Subtitle from "../../atoms/Subtitle";
import Paragraph from "../../atoms/Paragraph";

export default function ConteudoCarrosselLpTwo() {
  return (
    <Container className="mb-5">
      <Row className="my-5">
        <Col className="d-lg-flex align-items-center">
          <Col lg={5} sm={12} className="d-flex  py-3" >
            <ImgModal type="imgLpThree" 
             
            />
          </Col>
          <Col className=" border-start border-warning border-5 rounded shadow">
            <Col className="d-flex p-3  flex-column   ">
              <Col>
                <Subtitle text="Filtre a sua busca:" />
              </Col>
              <Col>
              
                <Paragraph
                  text="O Faça Jus disponibiliza a pesquisa com base 
                em critérios como localização, área de atuação e informações 
                do profissional, como consultas realizadas na plataforma e avaliações 
                dos clientes. Isso ajudará a refinar sua busca e encontrar advogados 
                que melhor atendam as suas necessidades."
                />
              </Col>
            </Col>
            <Col className="d-flex p-3 flex-column   ">
              <Col>
                <Subtitle text="Credenciais Verificadas:" />
              </Col>
              <Col>
                <Paragraph
                  text="Não se preocupe! O Faça Jus verifica as credenciais
                 do advogado, como sua licença para exercer a advocacia e
                  qualquer associação profissional à qual possa pertencer."
                />
              </Col>
            </Col>
            <Col className="d-flex p-3 flex-column   ">
              <Col>
                <Subtitle text="Analise os perfis dos advogados:" />
              </Col>
              <Col>
                <Paragraph
                  text="Ao encontrar advogados que pareçam interessantes, 
                clique em seus perfis para obter mais informações. 
                Verifique detalhes como sua experiência, áreas de especialização,
                 informações de contato, avaliações de clientes anteriores e o 
                 valor da consulta."
                />
              </Col>
            </Col>
          </Col>
        </Col>
      </Row>

      <Row className="my-5">
        <Col className="d-lg-flex align-items-center">
          <Col lg={5} sm={12} className="d-flex  py-3">
            <ImgModal type="imgLpTwo" />
          </Col>
          <Col className=" border-start border-warning border-5 rounded shadow">
            <Col className="d-flex p-3 flex-column   ">
              <Col>
                <Subtitle text="Agende a teleconsulta:" />
              </Col>
              <Col>
                <Paragraph
                  text="Verifique se a disponibilidade dos profissionais atende 
                  a sua agenda. São vários horários disponíveis para você agendar."
                />
              </Col>
            </Col>
            <Col className="d-flex p-3 flex-column   ">
              <Col>
                <Subtitle text="Faça o seu cadastro:" />
              </Col>
              <Col>
                <Paragraph
                  text="Realize o cadastro no Faça Jus para continuar 
                  com o agendamento da teleconsulta."
                />
              </Col>
            </Col>
            <Col className="d-flex p-3 flex-column   ">
              <Col>
                <Subtitle text="Efetue o pagamento e realize a teleconsulta:" />
              </Col>
              <Col>
                <Paragraph
                  text="Escolha a forma de pagamento que melhor atende a sua necessidade, 
                  você receberá as informarções de confirmação por e-mail, e 
                  em seguida é só aguardar até a data escolhida. No Faça Jus,
                   você pode tirar suas dúvidas com o profissional escolhido 
                   por meio de chat na plataforma antes da teleconsulta."
                />
              </Col>
            </Col>
          </Col>
        </Col>
      </Row>

      <Row className="my-5">
        <Col className="d-lg-flex align-items-center">
          <Col lg={5} sm={12} className="d-flex  py-3">
            <ImgModal type="imgLpOne" />
          </Col>
          <Col className=" border-start border-warning border-5 rounded shadow d-flex align-items-center">
            <Col className="d-flex p-3 flex-column   ">
              <Col>
                <Subtitle text="Contribua com comentários:" />
              </Col>
              <Col>
                <Paragraph
                  text="Você pode avaliar o advogado sobre a teleconsulta realizada,
                  isso garante o comprometimento dos profissionais que
                  utilizam a plataforma."
                />
              </Col>
            </Col>           
           
          </Col>
        </Col>
      </Row>
    </Container>
  );
}
