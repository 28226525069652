import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Btn from "../../atoms/Button";
import AvatarEditor from "react-avatar-editor";
import InputArquivo from "../InputArquivo";
import { Col } from "react-bootstrap";

export const FotoPerfilCadastroModal = ({ show, handleClose, setShow, handleFotoPerfil }) => {

  const [foto, setFoto] = useState(null);
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(1);
  const [editorKey, setEditorKey] = useState(0); // Chave para forçar a atualização do editor
  const editorRef = useRef(null);

  const handleSave = async () => {
    const canvas = editorRef.current.getImageScaledToCanvas();
    const dataURL = canvas.toDataURL();
    const imageData = dataURL.substring(dataURL.indexOf(",") + 1);
    return imageData;
  };

  const handleSubmit = async () => {
    const imageData = await handleSave();
    sessionStorage.setItem("fotoPerfil", imageData); 
    handleFotoPerfil(imageData);
    setShow(false);
  };
  

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFoto(file);
      setEditorKey(editorKey + 1); // Atualiza a chave do editor para forçar a atualização
    }
  };

  const handleImageChange = () => {
    if (editorRef.current) {
      const imageData = editorRef.current.getImageScaledToCanvas().toDataURL();
      // handleFotoPerfil(imageData); // Atualiza a variável de sessão com a nova imagem
    }
  };

  useEffect(() => {
    if (foto) {
      // Reset o editor quando uma nova foto é selecionada
      setEditorKey(editorKey + 1); // Atualiza a chave do editor para forçar a atualização
    }
  }, [foto]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title className="px-5">Envie sua foto</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 m-0 d-flex flex-column align-items-center  ">
        <Col className="">
          <InputArquivo
            validFileExtensions={[".jpg", ".png", ".jpeg"]}
            getFile={(e) => setFoto(e)}
            name={"Arquivo"}
            id={"foto"}
            onChange={handleFileChange}
          />
        </Col>
        {foto && (
          <Col className="d-flex justify-content-center p-0 m-0">
            <AvatarEditor
              key={editorKey} // Chave para forçar a atualização do editor
              ref={editorRef}
              image={foto}
              width={320}
              height={320}
              border={50}
              borderRadius={250}
              color={[255, 255, 255, 0.7]}
              scale={scale}
              onImageChange={handleImageChange}
            />
          </Col>
        )}
     
      </Modal.Body>
      <Modal.Footer>
        <Col className="d-lg-flex  justify-content-between ">
          <Btn
            type={"terciary"}
            isSubmit={false}
            text="Cancelar"
            onTouchable={handleClose}
          />
          <Btn
            type={"secondary"}
            isSubmit={false}
            text="Enviar"
            onTouchable={handleSubmit}
          />
        </Col>
      </Modal.Footer>
    </Modal>
  );
};
