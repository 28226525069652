import api from "../Api";

export class AdvogadoService {
  async getAll() {
    return await api
      .get("/", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async postListar(nome, telefone, id, data, validado, dataCadastro, advogadoRecusado) {
    return await api
      .post(
        "/adm/site/advogado/listar",
        {
          nome: nome,
          telefone: telefone,
          id: id,
          data: data,
          validado: validado,
          dataCadastro: dataCadastro,
          advogadoRecusado: advogadoRecusado
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getAdvogadoPorId(params, nome, cpf, telefone, email, id, data, perfil, dataCadastro) {
    try {
      const { id: userId } = params;
      const response = await api.get(`/adm/site/advogado/dados/${userId}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        data: {
          nome,
          cpf,
          telefone,
          email,
          id,
          data,
          perfil,
          dataCadastro
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }


  async postDocumentosAdvogado(
    doc
    ) {                    
    return await api.post("adm/site/advogado/dados/doc", {
			"doc" : doc,
        }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  res.data);
  }

  async postValidarAdovogado(
    id
    ) {                    
    return await api.post("adm/site/advogado/dados/validar", {
			"id" : id,
        }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  res.data);
  }

  async postRecusarAdovogado(
    id,
    status
    ) {                    
    return await api.post("adm/site/advogado/dados/recusar", {
			"id" : id,
      "status" : status
        }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  res.data);
  }

  async postValidarCodigoAdovogado(cli_id)
  {
    return await api.post("adm/site/advogado/dados/validar-codigo", {"cli_id" : cli_id}, 
    {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  res.data);
  }

  async toogleDispAdovogado(cli_id)
  {
    return await api.post("adm/site/advogado/dados/mudar-disponibilidade", {"cli_id" : cli_id}, 
    {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  res.data);
  }


  
}
