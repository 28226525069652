import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Breadcrumb, Row } from "react-bootstrap";
import "./styles.css";
import Header from "../../../molecules/Header";
import NavitemSidebarAdm from "../../../atoms/NavitemSidebarAdm";
import Subtitle from "../../../atoms/Subtitle";
import { AdmLoginForms } from "../../../organisms/AdmLoginForms";

export const LoginTemp = () => {
  return (
    <body className=" ">
      <Container fluid className="bg-login-adv d-flex justify-content-center align-items-center fluid-bg">
        <Col lg={3} className="card card-sm shadow-lg p-3 m-0">
          <AdmLoginForms />
        </Col>
      </Container>
    </body>
  );
};
