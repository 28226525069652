import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Breadcrumb, Row } from "react-bootstrap";
import "./styles.css";
import Header from "../../../molecules/Header";
import NavitemSidebarAdm from "../../../atoms/NavitemSidebarAdm";
import AdmGerencialUsuariosCadastrar from "../../../organisms/AdmGerencialUsuariosCadastrar";
import Subtitle from "../../../atoms/Subtitle";
import AdmGerencialPerfisCadastrar from "../../../organisms/AdmGerencialPerfisCadastrar";
import AdmHeader from "../../../molecules/AdmHeader";

export const GerencialPerfisCadastrarTemp = () => {
  const [sideNavExpanded, setSideNavExpanded] = useState(true);

  const toggleSideNav = () => {
    setSideNavExpanded(!sideNavExpanded);
  };

  return (
    <body className="m-0 p-0">
      <Container fluid className="fluid-bg">
        <AdmHeader />
      </Container>

      <Container fluid className="d-flex  mt-5">
        <Col
          className={`menu-position ${sideNavExpanded ? "" : "menu-fechado"}`}
        >
          <NavitemSidebarAdm
            menuAberto={sideNavExpanded}
            toggleMenu={toggleSideNav}
          />
        </Col>

        <Col className="container-tabela flex-grow-2 shadow-lg">
          <Row className="p-3">
            <Col lg={3} className="m-0 p-0  text-start">
              <Breadcrumb className="">
                <Breadcrumb.Item>Gerencial</Breadcrumb.Item>
                <Breadcrumb.Item>Perfis</Breadcrumb.Item>

                <Breadcrumb.Item href="/cadastrar" active>
                  Cadastrar
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>

          <Row>
            <Col className=" align-items-center ps-5 ">
              <Col className="">
                <Subtitle text="Cadastrar perfil" />
              </Col>
            </Col>
          </Row>
          <Row >
            <AdmGerencialPerfisCadastrar />
          </Row>
        </Col>
      </Container>

      <Container></Container>
    </body>
  );
};
