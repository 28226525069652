import React, { useState, useEffect, useMemo, useCallback } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../molecules/Header";
import ImgBannerPerfil from "../../atoms/ImgBannerPerfil";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import Subtitle from "../../atoms/Subtitle";
import { NotificacaoAdv } from "../../organisms/NotificacaoAdv";
import Btn from "../../atoms/Button";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import { LembreteAdvModal } from "../../molecules/LembreteAdvModal";
import { Footer } from "rsuite";

export const NotificacoesAdvogados = () => {

  const nomeUsuario = sessionStorage.getItem("nome") || "";


  return (
    <body className='mt-5 pt-5'>

      <Container fluid className='p-0 m-0 '>
        <Header />
      </Container>

      <Container fluid className="p-0 m-0 banner-container">
        <ImgBannerPerfil className="img-fluid " />
      </Container>

      <Container>
        <Row className='gap-3'>
          <Col lg={2} className="avatar-container">
            <AvatarPerfil
              type="A"
              base64={sessionStorage.getItem("fotoPerfil")}
              width="13rem"
              height="13rem"
              padding="0.4rem"
            />
          </Col>

          <Col lg={8} className='py-2 mt-2 ms-2'>
            <Subtitle text={'Olá, ' + nomeUsuario} />
          </Col>
        </Row>
      </Container>


      <Container className='my-5 mt-3 '>
        <Row className="justify-content-center">
          <Col lg={9} className="">
            <Col className="ms-3">
              <Subtitle text="Notificações" />
              <ParagraphSmall text="A página de notificações mantém você conectado as ultimas novidades e informações importantes." />
            </Col>
            <Col>
              <NotificacaoAdv />
            </Col>
          </Col>
        </Row>


      </Container>

      <Container fluid className="p-0 m-0 ">
        <Footer />
      </Container>
    </body>
  );
}