import React from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import { Link } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import { PerguntarForumAdv } from "../PerguntarForumAdv";
import { ResponderForumAdv } from "../ResponderForumAdv";

const ForumAdvogado = () => {
  return (
    <Container>
      <Row>
        <PerguntarForumAdv />
      </Row>
      <Row>
        <ResponderForumAdv />
      </Row>
    </Container>
  );
};

export default ForumAdvogado;
