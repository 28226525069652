import React from "react";
import Col from "react-bootstrap/Col";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Paragraph from "../../atoms/Paragraph";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import Icons from "../../atoms/Icons";
import { Navigate, useNavigate } from "react-router-dom";
import Btn from "../../atoms/Button";

export const Notificacao = (props) => {
  const tipo = sessionStorage.getItem("tipo");
  const { text, text2, text3 } = props;
  const navigate = useNavigate();

  const handleNavigation = () => {
    switch (text) {
      case "Agendamento":
      case "Reagendamento Cliente":
        navigate(tipo === "C" ? "/ConsultasCli" : "/ConsultasAdv");
        break;
      case "Chat Cliente":
        navigate("/ConsultasCli");
        break;
      case "Chat Advogado":
        navigate("/ForumAdv");
        break;
      // Add more cases as needed
      default:
        // Handle the default case or do nothing
        break;
    }
  }

  const renderIcon = () => {
    switch (text) {
      case "Agendamento":
      case "Reagendamento Cliente":
        return <Icons type="notAgenda" />;
      case "Chat Cliente":
        return <Icons type="notChat" />;
      case "Chat Advogado":
        return <Icons type="notChat" />;
      // Add more cases as needed
      default:
        // Handle the default case or return a default icon
        return <Icons type="notLembrete" />;
    }
  };

  const renderButton = () => {
    switch (text) {
      case "Agendamento":
      case "Reagendamento Cliente":
      case "Chat Advogado":
        return <Btn type="terciary-sm" text="ver mais" onTouchable={handleNavigation} />;
      case "Chat Cliente":
        return <Btn type="terciary-sm" text="ver mais" onTouchable={handleNavigation} />;
      // Add more cases as needed
      default:
        // Handle the default case or return null for no button
        return null;
    }
  };

  return (
    <Col className="d-flex flex-row gap-4 align-items-center shadow">
      <Col lg={1} className="px-3 py-3 d-flex flex-row align-items-center ms-2">
        {renderIcon()}
      </Col>
      <Col lg={8} className="d-flex flex-column gap-1">
        <Col>
          <Paragraph text={text} />
          <ParagraphSmall text={text3} />
        </Col>
        <Col>
          <ParagraphSmall text={text2} />
        </Col>
      </Col>
      <Col className="me-2">{renderButton()}</Col>
    </Col>
  );
};
