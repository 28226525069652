import { useState, useEffect } from "react";
import "./styles.css";
import {
  Button,
  Carousel,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import Btn from "../../atoms/Button";
import Paragraph from "../../atoms/Paragraph";
import { Navigate, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { format, parseISO, zonedTimeToUtc } from "date-fns-tz";
import AgendaSelectBtn from "../../atoms/AgendaSelectBtn";
import ImgBannerPerfil from "../../atoms/ImgBannerPerfil";
import CabecalhoPerfilCliente from "../../organisms/CabecalhoPerfilCliente";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import { ComentarioAvaliacao } from "../../organisms/ComentarioAvaliacao";
import { ConsultasService } from "../../../services/ClienteServices/ConsultasService";
import { ptBR } from "date-fns/locale";
import { Toasts } from "../../utils/Toasts";
import HorarioSelectBtn from "../../atoms/HorarioSelectBtn";
import DropdownBtn from "../../atoms/DropdownBtn";

export const ReagendarConsultaModal = ({
  show,
  onHide,
  nome,
  idConsulta,
  agenda,
}) => {
  const api = new ConsultasService();
  const [selectedDate, setSelectedDate] = useState();
  const [horarios, setHorarios] = useState();

  const fusoHorario = "Etc/UTC";
  const { parseISO, format } = require("date-fns");
  const { utcToZonedTime } = require("date-fns-tz");

  const agendaAdv = agenda;
  const idConsultaAntiga = idConsulta;
  const [comentario, setComentario] = useState();
  const [idConsulNova, seIdConsulNova] = useState();
  const [dropdownDatas, setDropdownDatas] = useState([]);
  const [dropdownHorarios, setDropdownHorarios] = useState([]);

  
  const formatDate = (dateString) => {
    return format(
      utcToZonedTime(parseISO(dateString), fusoHorario),
      "dd/MM/yyyy",
      { locale: ptBR }
    );
  };

  useEffect(() => {
    if (agendaAdv && agendaAdv.agenda && agendaAdv.agenda.length > 0) {
      const novasOpcoes = agendaAdv.agenda.map((consulta) => ({
        id: consulta.id,
        desc: formatDate(consulta),
      }));
      setDropdownDatas(novasOpcoes);
    }
  }, [agendaAdv]);

  useEffect(() => {
    if (horarios && horarios.length > 0) {
      const novasOpcoes = horarios.map((horario) => ({
        id: horario.adv_id,
        desc: formatarHora(horario.adv_hora),
      }));
      setDropdownHorarios(novasOpcoes);
    }
  }, [horarios]);

  const handleDateClick = async (selectedDate) => {
    try {
      setSelectedDate(selectedDate);
      const response = await api.listarHorarioReagendamento(
        agendaAdv.id_advogado,
        selectedDate
      );
      setHorarios(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleGravaConsulta = async (adv_id) => {
    seIdConsulNova(adv_id);
  };

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const handleConfirm = async () => {
    try {
      api
        .reagendarConsulta(idConsulNova, idConsultaAntiga, comentario)
        .then((data) => {
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);
          setTimeout(() => {
            setTimeout(() => {
              window.location.reload();
            });
          }, 2500);
        })
        .catch((error) => {
          let optionsToast = {
            code: "102",
            message: "Preencha todos os campos",
            visible: true,
          };
          setOptionsToast(optionsToast);
        });
    } catch (error) {
      // Se houver erro de validação, você pode lidar com isso aqui
      alert(error.message);
    }
  };

  function formatarHora(hora) {
    if (hora && hora.length === 4) {
      const hh = hora.slice(0, 2);
      const mm = hora.slice(2, 4);
      return `${hh}:${mm}`;
    }
    return hora;
  }

  // useEffect(() => {
  //   console.log(idConsulNova)
  // }, [idConsulNova]);

  return (
    <Modal
      className="d-flex flex-column align-items-start"
      show={show}
      onHide={onHide}
      size="lg"
    >
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Modal.Header closeButton>
        <Modal.Title className="">
          <Subtitle text="Reagendar Consulta" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="d-flex flex-column align-items-center">
          <Row className=" d-flex  justify-content-center">
            <Col>
              <AvatarPerfil
                type="B"
                base64={
                  agendaAdv && agendaAdv.foto_advogado
                    ? agendaAdv.foto_advogado
                    : ""
                }
                width="7rem"
                height="7rem"
                padding="0.1rem"
              />
              <Subtitle text={nome} />
            </Col>
          </Row>
          {/* <Row className="mt-4">
            <Col>
              <Paragraph text="Selecione uma nova data:" />
            </Col>
          </Row> */}
          {/* <Row className=" my-0 py-0">
            <Carousel
              data-bs-theme="dark"
              indicators={false}
              fade={false}
              slide={false}
            >
              {agendaAdv && agendaAdv.agenda && agendaAdv.agenda.length > 0 ? (
                agendaAdv.agenda.map((consulta, consultaIndex) => (
                  <Carousel.Item key={`carousel-item-${consultaIndex}`}>
                    <Container
                      key={`container-${consultaIndex}`}
                      className="modal-reagendar d-flex"
                    >
                      <Col
                        key={`col-${consultaIndex}`}
                        className="justify-content-center d-flex"
                      >
                        <AgendaSelectBtn
                          type={"dia-data"}
                          text={formatDate(consulta)}
                          onTouchable={() => handleDateClick(consulta)}
                        />
                      </Col>
                    </Container>
                  </Carousel.Item>
                ))
              ) : (
                <Carousel.Item key="no-consults">
                  <Col className="d-flex px-5 row my-5 justify-content-center">
                    <Col lg={12} className="d-flex gap-5">
                      <Paragraph text="Não há consultas disponíveis" />
                    </Col>
                  </Col>
                </Carousel.Item>
              )}
            </Carousel>
          </Row> */}

          <Row className="d-flex flex-column">
            {dropdownDatas.length > 0 ? (
              <DropdownBtn
                type={"primary1"}
                text="Selecione a data"
                options={dropdownDatas}
                onPress={(selectedDate) => handleDateClick(selectedDate)}
              />
            ) : (
              <Paragraph text="Não há datas disponíveis." />
            )}

            {horarios && horarios.length > 0 ? (
              <DropdownBtn
                type={"primary1"}
                text="Selecione a hora"
                options={dropdownHorarios}
                onPress={(selectedHorario) => {
                  seIdConsulNova(selectedHorario.id);
                }}
              />
            ) : (
              ""
            )}
          </Row>

          {/* {horarios ? (
            <Row className="mt-4">
              <Col>
                <Paragraph text="Selecione o horário:" />
              </Col>
            </Row>
          ) : null}
          <Row className="d-flex flex-column justify-content-center">
            <Col className="d-flex flex-row gap-3">
              {horarios
                ? horarios.map((horario, index) => (
                    <Col key={index}>
                      <HorarioSelectBtn
                        type={"horario"}
                        text={formatarHora(horario.adv_hora)}
                        onTouchable={() => seIdConsulNova(horario.adv_id)}
                      />
                    </Col>
                  ))
                : ""}
            </Col>
          </Row> */}

          <Row className="d-flex flex-column mt-4 w-100">
            <Col className="text-center">
              <Subtitle text="Motivo do reagendamento" />

              <Form className="">
                <Form.Group>
                  <Col>
                    <Form.Control
                      style={{ height: "6rem" }}
                      as="textarea"
                      maxLength={300}
                      type="text"
                      name="resposta"
                      isSumbit={true}
                      value={comentario}
                      onChange={(e) => setComentario(e.target.value)}
                      placeholder="Nesse campo você pode descrever o motivo do reagendamento da consulta (Máximo de 300 caracteres)"
                    />
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row className=" modal-reagendar">
            <Col className="d-flex  flex-column justify-content-center flex-lg-row gap-3">
              <Col>
                <Btn
                  type={"terciary"}
                  isSumbit={false}
                  text="Voltar"
                  onTouchable={onHide}
                />
              </Col>
              <Col>
                <Btn
                  type="secondary"
                  isSumbit={true}
                  text="Confirmar"
                  onClick={handleConfirm}
                />
              </Col>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
