import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './styles.css';
import ImgBanner from '../../atoms/ImgBanner';
import TitleBanner from '../../atoms/TitleBanner';
import SubtitleBanner from '../../atoms/SubtitleBanner';

const BannerDisplay = () => {
  return (
    <Container  className="my-5 p-0">
      <Row className="row m-0 p-0">
        <Col lg={6} className="banner-col">
          <Col className="banner-image-container">
            <ImgBanner type="banner1" key="banner1" />
            <Col className="banner-text">
              <TitleBanner text="Chat com usuários e advogados" />
              <SubtitleBanner text="Tire dúvidas dos usuários, debata com outros advogados sobre determinados casos" />
            </Col>
          </Col>
        </Col>
        <Col lg={6} className="banner-col">
          <Col className="banner-image-container">
            <ImgBanner type="banner2" key="banner2"  />
            <Col className="banner-text">
              <TitleBanner text="Potenciais clientes" />
              <SubtitleBanner text="Contamos com uma base de clientes, tendo casos de diferentes áreas do direito" />
            </Col>
          </Col>
        </Col>
      </Row>
      <Row className="row m-0 p-0">
        <Col lg={6} className="banner-col">
          <Col className="banner-image-container">
            <ImgBanner type="banner3" key="banner3"  />
            <Col className="banner-text">
              <TitleBanner className="h4" text="Ranking de destaque" />
              <SubtitleBanner text="Tenha destaque na busca por profissionais, respondendo as dúvidas dos usuários e com as teleconsultas" />
            </Col>
          </Col>
        </Col>
        <Col lg={6} className="banner-col">
          <Col className="banner-image-container">
            <ImgBanner type="banner4" key="banner4"  />
            <Col className="banner-text">
              <TitleBanner text="Consultas por videoconferência" />
              <SubtitleBanner text="Marque consultas no ambiente que estiver" />
            </Col>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default BannerDisplay;
