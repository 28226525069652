import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import Header from "../../molecules/Header";
import Footer from "../../molecules/Footer";
import ImgBannerPerfil from "../../atoms/ImgBannerPerfil";
import Subtitle from "../../atoms/Subtitle";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import "./styles.css";
import { Rating } from "react-simple-star-rating";
import { MeioPagamentoAdv } from "../../organisms/MeioPagamentoAdv";
import { MeioRecebimentoAdv } from "../../organisms/MeioRecebimentoAdv";
import ExtratoFinanceiroAdv from "../../molecules/ExtratoFinanceiroAdv";
import { PerfilService } from "../../../services/AdvogadoServices/PerfilService";
import { AvaliacaoService } from "../../../services/AdvogadoServices/AvaliacaoService";
import Paragraph from "../../atoms/Paragraph";

const nomeUsuario = sessionStorage.getItem("nome") || "";

export const FinanceiroAdv = () => {
  const api = new AvaliacaoService
  const id = sessionStorage.getItem("id")

  const [estrelas, setEstrelas] = useState()

  useEffect(() => {
    api.listarMediaEstrelas(id)
    .then((data) => {
      setEstrelas(data.data);
    })
    .catch((error) => {
      console.log(error);
    });
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <body className="mt-5 pt-5">
      <Container fluid className="fluid-bg ">
        <Header />
      </Container>

      <Container fluid className="p-0 m-0 banner-container">
        <ImgBannerPerfil className="img-fluid " />
      </Container>

      <Container>
        <Row className="gap-5">
          <Col lg={2} className="avatar-container">
            <AvatarPerfil
              type="A"
              base64={sessionStorage.getItem("fotoPerfil")}
              width="13rem"
              height="13rem"
              padding="0.4rem"
            />
          </Col>

          <Col lg={8} className="py-2 mt-2 ms-2">
            <Subtitle text={"Olá, " + nomeUsuario} />
            <Rating size={25} readonly={true} initialValue={estrelas}></Rating>
          </Col>
        </Row>
      </Container>

      <Container className="my-5 mt-5 ">
        <Row>
          <Col>
            <MeioRecebimentoAdv />
            {/* <Tabs
              defaultActiveKey="Recebimento"
              id="justify-tab-example"
              className="custom-tabs gap-2 mb-5 ms-5 me-5 ps-5 pe-5"
              justify
            >
              
              <Tab eventKey="Recebimento" title="Recebimento">
                <MeioRecebimentoAdv />
              </Tab>
              <Tab eventKey="Pagamento" title="Assinatura">
                <Col>
                  <MeioPagamentoAdv />
                </Col>
                <Col className="mt-5">
                  <ExtratoFinanceiroAdv />
                </Col>
              </Tab> 
             </Tabs>  */}
          </Col>
        </Row>
      </Container>

      <Container fluid className="fluid-bg ">
        <Footer />
      </Container>
    </body>
  );
};
