import React, { useState } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, FloatingLabel, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { ForumService } from "../../../services/AdvogadoServices/ForumService";
import Btn from "../../atoms/Button";
import { Toasts } from "../../utils/Toasts";
import Paragraph from "../../atoms/Paragraph";

export const PerguntarForumAdv = () => {
  const api = new ForumService();
  const id = sessionStorage.getItem("id");

  const [optionsToast, setOptionsToast] = useState({
    code: '',
    message: '',
    visible: false
  });

  function fecharToast() {
    setOptionsToast(defaultOptionsToast);
  }

  const defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const schema = yup.object().shape({
    pergunta: yup.string().required("Digite sua pergunta"),
  });

  const handleSubmit = async (values) => {
    try {

      const data = await api.enviaPerguntaForumAdvogado(
        id,
        values.pergunta,
      );


      const optionsToast = {
        code: data.mensagem.codigo,
        message: data.mensagem.mensagem,
        visible: true,
      };
      setOptionsToast(optionsToast);
    } catch (error) {


    }
  };


  const renderForm = () => {
    return (
      <Formik
        validationSchema={schema}
        initialValues={{
          pergunta: "",
        }}

        onSubmit={(values) => {
          handleSubmit(values);

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }}


      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Col className="mb-5">
              <Paragraph text="Os advogados que contribuírem com respostas no nosso fórum serão
          recompensados com destaque na lista de busca do site. Isso significa que, quanto mais
          você ajudar a esclarecer dúvidas legais, mais visível você estará para os usuários
          que procuram assistência jurídica!"/>
            </Col>
            <Form.Group controlId="pergunta">
              <FloatingLabel label={"Escreva aqui sua dúvida"} className="mb-3">
                <Form.Control
                  className={`margim-campos-formulario ${touched.pergunta && errors.pergunta ? "is-invalid" : ""
                    }`}
                  type="text"
                  name="pergunta"
                  value={values.pergunta}
                  onChange={handleChange}
                  isValid={touched.pergunta && !errors.pergunta}
                  placeholder="pergunta"
                  as="textarea"
                  style={{ height: "150px" }}
                />
                {touched.pergunta && errors.pergunta && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.pergunta}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Form.Group>
            <Btn type="secondary-sm" text="Enviar" isSumbit={true} />
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      {renderForm()}
    </>
  );
};