import React, { useEffect, useState } from "react";
import "./styles.css";
import Button from "react-bootstrap/Button";
import Subtitle from "../../atoms/Subtitle";
import * as formik from "formik";
import * as yup from "yup";
import { LoginService } from "../../../services/AdmUsuarioServices/LoginService";
import InputMask from "react-input-mask";
import functions from "../../utils/functions";
import Btn from "../../atoms/Button";
import { Link } from "react-router-dom";
import {
  Col,
  Collapse,
  Container,
  FloatingLabel,
  FormLabel,
  Row,
  InputGroup,
  Form,
  Modal,
  ModalTitle,
} from "react-bootstrap";
import Title from "../../atoms/Title";

import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import Nav from "react-bootstrap/Nav";
import { useSessionStorage } from "../../../storage/UseSessionStorage";
import { Toasts } from "../../utils/Toasts";

import { useNavigate } from "react-router-dom";
import Logo from "../../atoms/Logo";
import { UserService } from "../../../services/UserServices/UserService";

export const AdmLoginForms = () => {
  const { Formik } = formik;
  let Api = new LoginService();
  let ApiEmail = new UserService();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({});
  const [validated, setValidated] = useState(false);
  const [showFormForgotPassword, setShowFormForgotPassword] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const schema1 = yup.object().shape({
    cpf: yup
      .string()
      .test("valid-cpf", "CPF inválido", function (value) {
        return functions.validarCPF(value);
      })
      .required("CPF é obrigatório"),
    senha: yup.string("Senha incorreta").required("Senha é obrigatório"),
  });

  const handleSubmit = async (values) => {
    try {
      const { cpf, ...rest } = values;
      const cpfSemMascara = functions.removeMascara(cpf);
      const valuesSemMascara = {
        ...rest,
        cpf: cpfSemMascara,
      };
      const data = await Api.postLogin(valuesSemMascara.cpf, valuesSemMascara.senha);
      if (data.status === true) {
        sessionStorage.setItem("nome", data.data.name);
        sessionStorage.setItem("token", data.data.tokenId.dados);
        sessionStorage.setItem("idUsuario", data.data.id);

        navigate("/adm/dashboard");
        window.location.reload()

        console.log(data.status);
      } else {
        let optionsToast = {
          code: data.mensagem.codigo,
          message: data.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const openFormForgotPassword = () => {
    setShowFormForgotPassword(true);
  };

  const fecharModal = () => {
    setShowFormForgotPassword(false);
  };

  const handleSubmitEmail = async (values) => {
    try {
      const response = await ApiEmail.postEnviarEmail(values.email);
      let optionsToast = {
        code: response.mensagem.codigo,
        message: response.mensagem.mensagem,
        visible: true,
      };
      setOptionsToast(optionsToast);
    } catch (error) {
      console.log(error);
    }
  };

  const schema2 = yup.object().shape({
    email: yup
      .string()
      .required("Email é obrigatório")
      .email("Formato de e-mail inválido"),
  });

  return (
    <Container>
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Row>
        <Modal
          size="md"
          show={showFormForgotPassword}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton onClick={() => fecharModal}></Modal.Header>
          <Container className=" text-center">
            <Row>
              <Col className="d-flex row gap-5 my-3">
                <Logo type="imagem3" />
                <Subtitle text="Digite email cadastrado:" />
              </Col>
            </Row>
            <Row>
              <Col className="px-5">
                <Formik
                  key={"email"}
                  validationSchema={schema2}
                  onSubmit={(values) => {
                    handleSubmitEmail(values);
                  }}
                  initialValues={{
                    email: "",
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group controlId="email">
                        <FloatingLabel label={"Email"}>
                          <Form.Control
                            className={`input-register-login-facj ${
                              touched.email && errors.email ? "is-invalid" : ""
                            }`}
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                            placeholder="E-mail"
                          />
                          {touched.email && errors.email && (
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.email}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Form.Group>
                      <div className="d-grid">
                        <Btn type={"secondary"} isSubmit={true} text="Enviar" />
                      </div>
                      <div className="layout-footer"></div>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </Modal>
        <Col>
          <Col lg={12} className="align-logo-adm ">
            <Logo type="imagem3" />
          </Col>
          <Col className="mt-4 text-center border-bottom border-3 border-warning">
            <Subtitle text={"Administrativo"} />
          </Col>
          <Formik
            validationSchema={schema1}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            initialValues={{
              cpf: "",
              senha: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="cpf">
                  <FloatingLabel label={"CPF"}>
                    <InputMask
                      mask="999.999.999-99"
                      value={values.cpf}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.cpf && errors.cpf ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="cpf"
                          value={values.cpf}
                          onChange={handleChange}
                          isValid={touched.cpf && !errors.cpf}
                          placeholder="CPF"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.cpf && errors.cpf && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.cpf}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>
                <Form.Group controlId="senha">
                  <FloatingLabel label={"Senha"}>
                    <Form.Control
                      className={`input-register-login-facj ${
                        touched.senha && errors.senha ? "is-invalid" : ""
                      }`}
                      type={type}
                      name="senha"
                      value={values.senha}
                      onChange={handleChange}
                      isValid={touched.senha && !errors.senha}
                      placeholder="Senha"
                    />
                    {touched.senha && errors.senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.senha}
                      </Form.Control.Feedback>
                    )}
                    <span className="icon-password-facj" onClick={handleToggle}>
                      <Icon icon={icon} size={25} />
                    </span>
                  </FloatingLabel>
                </Form.Group>
                <Col className="d-grid">
                  <Btn type={"secondary"} text="Entrar" isSumbit={true} />
                </Col>
                {/* <Col className="text-center">
                  <Nav.Link onClick={openFormForgotPassword}>
                    Esqueceu sua senha?
                  </Nav.Link>
                </Col> */}
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};
