import React, { useState } from "react";
import "./styles.css";
import InputMask from "react-input-mask";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Modal,
  Row,
} from "react-bootstrap";
import { Formik, Field } from "formik";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import Btn from "../../atoms/Button";
import Paragraph from "../../atoms/Paragraph";
import { Toasts } from "../../utils/Toasts";
import { ConsultasService } from "../../../services/ClienteServices/ConsultasService";
import Subtitle from "../../atoms/Subtitle";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import { PerfilService } from "../../../services/ClienteServices/PerfilService";
import functions from "../../utils/functions";

const CancelarConsultaCliModal = ({
  showModal,
  handleCloseModal,
  idConsulta,
}) => {
  const api = new ConsultasService();
  const apiConta = new PerfilService();

  const id = sessionStorage.getItem("id");
  const [step, setStep] = useState(1);

  const handleStep = (values) => {
    setStep((prevStep) => prevStep + 1);
  };

  const backStep = (event) => {
    setStep((prevStep) => prevStep - 1);
  };

  const schemaDadosBancarios = yup.object().shape({
    codigoBanco: yup.string().required("Código do banco é obrigatório"),
    agencia: yup.string().required("Agência é obrigatória"),
    contaCorrente: yup.string().required("Conta Corrente é obrigatória"),
    nome: yup.string().required("Nome é obrigatório"),
    cpf: yup.string()
      .test("valid-cpf", "CPF inválido", function (value) {
        return functions.validarCPF(value);
      })
      .required("CPF é obrigatório"),
    cep: yup.string().required("CEP é obrigatório"),
    numero: yup.string().required("Número da residência é obrigatório."),
    complemento: yup.string().required("Complemento é obrigatório"),
  });

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const handleSubmit = (values, id, idConsulta) => {
    apiConta
      .cadastrarContaBanco(
        id,
        values.agencia,
        values.cep,
        values.codigoBanco,
        values.complemento,
        values.cpf,
        values.contaCorrente,
        values.nome,
        values.numero
      )
      .then((data) => {
        if (data.status === true) {
          cancelarConsultaCli(id, idConsulta);
        }
        else {
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  const cancelarConsultaCli = () => {
    api
      .cancelarConsultaCli(id, idConsulta)
      .then((response) => {
        if (response.status == true) {
          let optionsToast = {
            code: response.mensagem.codigo,
            message: response.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);

          setTimeout(() => {
            window.location.reload();
          }, 2500);
        } else {
          let optionsToast = {
            code: response.mensagem.codigo,
            message: response.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);

          setTimeout(() => {
            window.location.reload();
          }, 2500);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <Container>
            <Toasts
              code={optionsToast.code}
              message={optionsToast.message}
              visible={optionsToast.visible}
              setDefaultOptions={() => fecharToast()}
            />
            <Row>
              <Col className="text-center">
                <Subtitle text="Cancelamento de Consulta" />
              </Col>
            </Row>
            <Col className="text-center">
              <Paragraph
                text={`Tem certeza de que deseja cancelar a consulta? 
              Esta ação não pode ser revertida. O reembolso total será processado,
              desde que o cancelamento seja feito com pelo menos 24 horas de antecedência.`}
              />
            </Col>
            <Col className="d-flex justify-content-center pt-3 gap-2">
              <Btn
                type="terciary-sm"
                text="Voltar"
                onTouchable={handleCloseModal}
              />
              <Btn
                type="danger-sm"
                text="Sim, cancelar!"
                onTouchable={handleStep}
              />
            </Col>
          </Container>
        );
      case 2:
        return (
          <Formik
            initialValues={{
              codigoBanco: "",
              agencia: "",
              contaCorrente: "",
              cep: "",
              numero: "",
              complemento: "",
              nome: "",
              cpf: "",
            }}
            onSubmit={(values) => {
              const cpfSemMascara = functions.removeMascara(values.cpf);
              const cepSemMascara = functions.removeMascara(values.cep);

              const valuesSemMascara = {
                ...values,
                cpf: cpfSemMascara,
                cep: cepSemMascara,
              };
              handleSubmit(valuesSemMascara, id);
            }}
            validationSchema={schemaDadosBancarios}
          >
            {({
              handleSubmit,
              handleChange,
              handleReset,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form
                onSubmit={handleSubmit}
                className="p-2 bg-radio rounded justify-content-center"
              >
                <Col className="text-center pt-1">
                  <Subtitle text="Adicionar meio de recebimento" />
                </Col>

                <Form.Group>
                  <Col lg={12} className=" ">
                    <Form.Group className="d-flex row">
                      <Col lg={6} className="">
                        <FloatingLabel label="Código do banco" className="mb-3">
                          <Form.Control
                            type="text"
                            name="codigoBanco"
                            className={`form-control ${
                              touched.codigoBanco && errors.codigoBanco
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Banco"
                            value={values.codigoBanco}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                /^[0-9]*$/.test(inputValue) &&
                                inputValue.length <= 8
                              ) {
                                handleChange(e);
                              }
                            }}
                          />
                          {touched.codigoBanco && errors.codigoBanco && (
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.codigoBanco}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>
                      <Col lg={6} className="">
                        <FloatingLabel label="Agência" className="mb-3">
                          <Form.Control
                            type="text"
                            name="agencia"
                            className={`form-control ${
                              touched.agencia && errors.agencia
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Agência"
                            value={values.agencia}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                /^[0-9]*$/.test(inputValue) &&
                                inputValue.length <= 5
                              ) {
                                handleChange(e);
                              }
                            }}
                          />
                          {touched.agencia && errors.agencia && (
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.agencia}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>
                      <Col lg={12}>
                        <FloatingLabel label="Conta Corrente" className="mb-3">
                          <Form.Control
                            type="text"
                            name="contaCorrente"
                            className={`form-control ${
                              touched.contaCorrente && errors.contaCorrente
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Conta"
                            value={values.contaCorrente}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                /^[0-9]*$/.test(inputValue) &&
                                inputValue.length <= 15
                              ) {
                                handleChange(e);
                              }
                            }}
                          />
                          {touched.contaCorrente && errors.contaCorrente && (
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.contaCorrente}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>
                      <Col lg={12}>
                        <FloatingLabel label="Nome" className="mb-3">
                          <Form.Control
                            type="text"
                            name="nome"
                            className={`form-control ${
                              touched.nome && errors.nome ? "is-invalid" : ""
                            }`}
                            placeholder="Nome"
                            value={values.nome}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                /^[a-zA-ZÀ-ÿ\s']*$/u.test(inputValue) &&
                                inputValue.length <= 50
                              ) {
                                handleChange(e);
                              }
                            }}
                          />
                          {touched.nome && errors.nome && (
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.nome}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>

                      <Col lg={6} className="">
                        <FloatingLabel label={"CEP"}>
                          <InputMask
                            mask="99999-999"
                            value={values.cep}
                            onChange={(e) => {
                              handleChange(e); // Atualize o valor do campo CEP no estado do Formik
                            }}
                            onBlur={handleBlur}
                          >
                            {(inputProps) => (
                              <Form.Control
                                className={`form-control ${
                                  touched.cep && errors.cep ? "is-invalid" : ""
                                }`}
                                type="text"
                                name="cep"
                                value={values.cep}
                                // isValid={touched.cep && !errors.cep}
                                placeholder="CEP"
                                {...inputProps}
                              />
                            )}
                          </InputMask>
                          {touched.cep && errors.cep && (
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.cep}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>

                      <Col lg={6} className="">
                        <FloatingLabel label="Numero" className="mb-3">
                          <Form.Control
                            type="text"
                            name="numero"
                            className={`form-control ${
                              touched.numero && errors.numero
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Agência"
                            value={values.numero}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              // Use uma expressão regular para permitir apenas números e até 15 caracteres
                              if (
                                /^[0-9]*$/.test(inputValue) &&
                                inputValue.length <= 8
                              ) {
                                handleChange(e);
                              }
                            }}
                          />
                          {touched.numero && errors.numero && (
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.numero}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>

                      <Col lg={12}>
                        <FloatingLabel label="Complemento" className="mb-3">
                          <Form.Control
                            type="text"
                            name="complemento"
                            className={`form-control ${
                              touched.complemento && errors.complemento
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Complemento"
                            value={values.complemento}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (inputValue.length <= 300) {
                                handleChange(e);
                              }
                            }}
                          />
                          {touched.complemento && errors.complemento && (
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.complemento}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>

                      <Col
                        lg={12}
                        className="m-0 d-lg-flex gap-5 align-items-center justify-content-center"
                      ></Col>
                      <Col>
                        <Form.Group controlId="cpf">
                          <FloatingLabel label="CPF" className="mb-3">
                            <InputMask
                              mask="999.999.999-99"
                              type="text"
                              className={`form-control ${
                                touched.cpf && errors.cpf ? "is-invalid" : ""
                              }`}
                              name="cpf"
                              value={values.cpf}
                              onChange={handleChange}
                            />
                            {touched.cpf && errors.cpf && (
                              <Form.Control.Feedback type="invalid" tooltip>
                                {errors.cpf}
                              </Form.Control.Feedback>
                            )}
                          </FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Form.Group>
                  </Col>
                </Form.Group>
                <Col className=" text-center">
                  <Paragraph text="Atenção! Certifique-se de que os dados informados estejam corretos." />
                </Col>
                <Col className="d-flex justify-content-center gap-2">
                  <Btn
                    type="terciary-sm"
                    text="Voltar"
                    onTouchable={backStep}
                  />
                  <Btn type="danger-sm" text="Confirmar" isSumbit={true} />
                </Col>
                <Toasts
                  code={optionsToast.code}
                  message={optionsToast.message}
                  visible={optionsToast.visible}
                  setDefaultOptions={fecharToast}
                />
              </Form>
            )}
          </Formik>
        );
    }
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>{renderForm()}</Modal.Body>
    </Modal>
  );
};

export default CancelarConsultaCliModal;
