import React from "react";
import "./styles.css";

import myImage from "../../../../src/img/banner-lp-adv-01.jpg";
import myImage2 from "../../../../src/img/banner-lp-adv-02.jpg";
import myImage3 from "../../../../src/img/banner-lp-adv-03.jpg";
import myImage4 from "../../../../src/img/banner-lp-adv-04.jpg";
import myImage5 from "../../../../src/img/banner_consultas_usemais.png";
import myImage6 from "../../../../src/img/banner_consultas_assistamais.png";

import mobile1 from "../../../../src/img/banner-mobile-01.png";
import mobile2 from "../../../../src/img/banner-mobile-02.png";

import bannerLpOne from "../../../../src/img/bannerLpOne.png";
import bannerLpTwo from "../../../../src/img/bannerLpTwo.png";

import alerta from "../../../../src/img/imagem-alerta.png";
import confirmacao from "../../../../src/img/confirmacao.png";

import header from "../../../../src/img/header-email-facajus.png";
import footer from "../../../../src/img/footer-email-facajus.png";

import headerforum from "../../../../src/img/header-forum.png";
import headerconsulta from "../../../../src/img/header-consulta.png";
import headersenha from "../../../../src/img/header-senha-nova.png";
import calendario from "../../../../src/img/calendario.png";

import email_1 from "../../../../src/img/facajus-notificacoes-por-email-cabecalho.jpg";
import email_2 from "../../../../src/img/facajus-notificacoes-por-email-rodape.jpg";


import { hasFormSubmit } from "@testing-library/user-event/dist/utils";

const ImgBanner = ({ type }) => {
  let imagem = "";

  switch (type) {
    case "banner1":
      imagem = myImage;
      break;
    case "banner2":
      imagem = myImage2;
      break;
    case "banner3":
      imagem = myImage3;
      break;
    case "banner4":
      imagem = myImage4;
      break;
    case "banner5":
      imagem = myImage5;
      break;
    case "banner6":
      imagem = myImage6;
      break;

    case "mobile1":
      imagem = mobile1;
      break;

    case "mobile2":
      imagem = mobile2;
      break;

    case "bannerLpOne":
      imagem = bannerLpOne;
      break;

    case "bannerLpTwo":
      imagem = bannerLpTwo;
      break;

    case "alerta":
      imagem = alerta;
      break;

    case "confirmacao":
      imagem = confirmacao;
      break;

    case "header":
      imagem = header;
      break;

    case "calendario":
      imagem = calendario;
      break;

    case "footer":
      imagem = footer;
      break;

    case "headerforum":
      imagem = headerforum;
      break;

    case "headerconsulta":
      imagem = headerconsulta;
      break;

    case "headersenha":
      imagem = headersenha;
      break;

    case "email_1":
      imagem = email_1;
      break;

      case "email_2":
        imagem = email_2;
        break;
  }

  return <img src={imagem} alt="Imagem Banner" className="img-banner" />;
};

export default ImgBanner;
