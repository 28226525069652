import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Subtitle from "../../atoms/Subtitle";
import { Field, Formik, useFormikm } from "formik";

import * as formik from "formik";
import * as yup from "yup";
import { CadastroService } from "../../../services/ClienteServices/CadastroService";
import InputMask from "react-input-mask";
import functions from "../../utils/functions";
import { FloatingLabel, Overlay, Tooltip } from "react-bootstrap";
import GuiaPassoAPassoForm from "../../molecules/GuiaPassoAPassoForm";
import Btn from "../../atoms/Button";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";

import {ic_check_circle} from 'react-icons-kit/md/ic_check_circle';
import {ic_cancel} from 'react-icons-kit/md/ic_cancel';

import { Toasts } from "../../utils/Toasts";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import CountdownTimer from "../../molecules/CountdownTimer ";
import TermosDeUsoModal from "../../molecules/TermosDeUsoModal";
import PoliticaPrivacidadeModal from "../../molecules/PoliticaPrivacidadeModal";
import ProgressBar from "react-bootstrap/ProgressBar";
import Paragraph from "../../atoms/Paragraph";

export const CadastroClienteForms = () => {
  //const { Formik } = formik;
  let cli_id = sessionStorage.getItem("id");
  let api = new CadastroService();

  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");

  const codigoCompleto = code1 + code2 + code3 + code4;

  const handleSair = () => {
    {
      sessionStorage.clear();
      window.location.href = "/";
    }
  };

  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [percent3, setPercent3] = useState(80);

  const [loading, setLoading] = useState();
  const [step, setStep] = useState();
  const [formValues, setFormValues] = useState({});

  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();

  const [defaultCpf, setDefaultCpf] = useState("");
  const [defaultNome, setDefaultNome] = useState("");
  const [defaultEmail, setDefaultEmail] = useState("");
  const [defaultTelefone, setDefaultTelefone] = useState("");
  // const [defaultConfirmaTelefone, setDefaultConfirmaTelefone] = useState("");
  const [defaultConfirmaEmail, setDefaultConfirmaEmail] = useState("");
  const [user, setUser] = useState(null);

  const [aceitoTermos, setAceitoTermos] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalPoliticaShow, setModalPoliticaShow] = useState(false);

  const inputRefs = {
    code1: useRef(),
    code2: useRef(),
    code3: useRef(),
    code4: useRef(),
  }

  const [showDialogSenha, setShowDialogSenha] = useState(false);
  const [showDialogConfirmaSenha, setShowDialogConfirmaSenha] = useState(false);

  const [validation, setValidation] = useState({
    hasMinimumLength: false,
    hasNumber: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasSpecialCharacter: false,
    doesntHasProhibitedCharacter: false
  });

  const [validationConfirmaSenha, setValidationConfirmaSenha] = useState({
    hasMinimumLength: false,
    hasNumber: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasSpecialCharacter: false,
    doesntHasProhibitedCharacter: false
  });


  const handlePasswordChange = (e) => {

    const newPassword = e.target.value;

    if (e.target.name == 'senha') {
      setValidation(validatePassword(newPassword));
    } else {
      setValidationConfirmaSenha(validatePassword(newPassword))
    }

    // setPassword(newPassword);
  };

  // Função para verificar o comprimento mínimo da senha
  const hasMinimumLength = (password) => {
    return password.length >= 8;
  };

  // Função para verificar se há pelo menos um número na senha
  const hasNumber = (password) => {
    return /[0-9]/.test(password);
  };

  // Função para verificar se há pelo menos uma letra maiúscula na senha
  const hasUpperCase = (password) => {
    return /[A-Z]/.test(password);
  };

  // Função para verificar se há pelo menos uma letra minúscula na senha
  const hasLowerCase = (password) => {
    return /[a-z]/.test(password);
  };

  // Função para verificar se há pelo menos um caractere especial na senha
  const hasSpecialCharacter = (password) => {
    return /[!_@#$&*\.]/.test(password);
  };

  // Função para verificar se não há algum caracter especial que viola as práticas de segurança
  const doesntHasProhibitedCharacter = (password) => {
    return /[/\\'`\-\"%(),]/.test(password);
  };

  // Função de validação completa que retorna um objeto com o estado de cada critério
  const validatePassword = (password) => {
    return {
      hasMinimumLength: hasMinimumLength(password),
      hasNumber: hasNumber(password),
      hasUpperCase: hasUpperCase(password),
      hasLowerCase: hasLowerCase(password),
      hasSpecialCharacter: hasSpecialCharacter(password),
      doesntHasProhibitedCharacter: doesntHasProhibitedCharacter(password)
    };
  };

  const handleTermosClick = () => {
    setModalShow(true);
  };

  const handlePoliticaClick = () => {
    setModalPoliticaShow(true);
  };

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  function validaEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  function isAllSameDigits(phoneNumber) {
    const cleanedNumber = phoneNumber.replace(/[^0-9]+/g, '');
    
    // Cria uma expressão regular que verifica se todos os dígitos são iguais
    const regex = /^(\d)\1*$/;
    
    // Testa o número limpo contra a expressão regular
    return regex.test(cleanedNumber);
  }

  const schema1 = yup.object().shape({
    nome: yup
      .string()
      .matches(/^[a-zA-ZÀ-ÿ\s]*$/, "Por favor, digite apenas letras e acentos")
      .max(80, "Máximo de caracteres excedido")
      .min(3, "Mínimo de caracteres não detectado")
      .test("sobrenome", "Por favor, digite seu sobrenome", function (value) {
        if (!value) return false;
        const partes = value.trim().split(" ");
        if (partes.length < 2) return false; // Menos de duas partes (nome e sobrenome)
        return /[a-zA-Z]/.test(partes[1]); // Verifica se o segundo elemento (sobrenome) contém letras
        })
      .required("O nome é obrigatório"),

    cpf: yup
      .string()
      .test("valid-cpf", "CPF inválido", function (value) {
        return functions.validarCPF(value);
      })
      .required("CPF é obrigatório"),

    email: yup
      .string()
      .required("Email é obrigatório")
      .email("Formato de e-mail inválido")
      .test("formato-email", "Formato de e-mail inválido", (value) => {
        return validaEmail(value);
      }),

    confirma_email: yup
      .string()
      .required("Confirmação do email é obrigatório")
      .email("Formato de e-mail inválido")
      .test("formato-confirmacao-email", "Formato de e-mail inválido", (value) => {
        return validaEmail(value);
      })
      .oneOf([yup.ref("email")], "Os emails devem ser iguais."),

    telefone: yup
      .string()
      .test(
        "telefone",
        "O Telefone deve conter exatamente 11 dígitos",
        (value) => {
          if (value) {
            const Regex = /^\(\d{2}\) \d{5}-\d{4}$/;
            return Regex.test(value);
          }
          return false;
        }
      )
      .test(
        "telefone-invalido",
        "Informe um telefone válido",
        (value) => {
          if (value) {
            const Regex = /^\(\d{2}\) \d{5}-\d{4}$/;
            const teste = Regex.test(value);
            if (teste) {
              if (isAllSameDigits(value)) {
                return false;
              } else {
                return true;
              }
            }
            return false;
          }
          return false;
        }
      ),

    // confirma_telefone: yup
    //   .string()
    //   .oneOf([yup.ref("telefone")], "Os celulares devem ser iguais.")
    //   .required("A confirmação do celular é obrigatória."),
  });

  const schema2 = yup.object().shape({
    senha: yup
      .string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$&*\._])[a-zA-Z\d!@#$&*\._]{8,}$/,
        "Verifique se sua senha cumpre os requisitos mínimos."
      )
      .test(
        'A senha não pode conter caracteres proibidos.',
        value => !/[/\\'`\-\"%(),]/.test(value)
      )
      .required("A senha é obrigatória."),
    confirma_senha: yup
      .string()
      .oneOf([yup.ref("senha")], "As senhas devem ser iguais.")
      .test(
        'A senha não pode conter caracteres proibidos.',
        value => !/[/\\'`\-\"%(),]/.test(value)
      )
      .required("A confirmação de senha é obrigatória."),
  });

  useEffect(() => {
    const etapa_1 = sessionStorage.getItem("etapa_1");
    const etapa_2 = sessionStorage.getItem("etapa_2");
    const etapa_3 = sessionStorage.getItem("etapa_3");
    let defaultStep = 1;

    if (etapa_1 === "true") {
      defaultStep = 2;
    }
    if (etapa_2 === "true") {
      defaultStep = 3;
    }
    if (etapa_1 === "true" && etapa_2 === "true" && etapa_3 === "true") {
      defaultStep = 3;
    }
    setStep(defaultStep);
  }, []);

  const handleSubmit = async (values) => {
    if (step === 1) {
      setIsLoading1(true);
      
      if (values.email != values.confirma_email) {
        let optionsToast = {
          code: "102",
          message: "Os emails devem ser iguais.",
          visible: true,
        };
        setOptionsToast(optionsToast);
        setIsLoading1(false);
        // nextStep();
        return;
      }

      try {

        const response = await api.validarClienteExistente(
          values.nome,
          values.email,
          values.telefone,
          values.cpf
        );

        let optionsToast = {
          code: response.mensagem.codigo,
          message: response.mensagem.mensagem,
          visible: true
        };
        
        if (response.status === true) {
          setIsLoading1(false);
        } else {
          setOptionsToast(optionsToast);
          setIsLoading1(false);

          if (response.data?.clienteCadastrado) {
            optionsToast.onAction = () => window.location.href = "/Login/Cli";
            setOptionsToast(optionsToast);
          } else {
            setOptionsToast(optionsToast);
          }
          return false;
        }
      
      } catch (error) {
        setIsLoading1(false);
        console.log(error);
        return false;
      };
      
    }

    if (step === 2) {
      setIsLoading2(true);

      if (aceitoTermos === null || aceitoTermos === false) {
        let optionsToast = {
          code: "102",
          message: "Por favor, aceite os termos de uso",
          visible: true,
        };
        setOptionsToast(optionsToast);
        setIsLoading2(false);
        // nextStep();
        return;
      } else {

        const validaCadastro = await cadastroCliente(values)

        if (!validaCadastro) {
          return false
        }


        setIsLoading2(false);
        // nextStep();
        // return;
      }
    }

    if (step === 3) {
      setIsLoading3(true);

      if (!codigoCompleto) {
        let optionsToast = {
          code: "102",
          message: "Por favor, digite o código que enviamos.",
          visible: true,
        };
        setOptionsToast(optionsToast);
        setIsLoading3(false);
        return;
      }

      try {

        const responseValidacao = await api
          .postValidaCodigoVerificador(user.cli_id, codigoCompleto)

        let optionsToast = {
          code: responseValidacao.mensagem.codigo,
          message: responseValidacao.mensagem.mensagem,
          visible: true,
        };

        setOptionsToast(optionsToast);

        if (responseValidacao.status === true) {
          setIsLoading3(false);
          setPercent3(100);
          setTimeout(() => {
            navigate("/Login/cli");
          }, 1800); // Atraso
        } else {
          setIsLoading3(false);
          return false;
        }

      } catch (error) {
        console.log(error);
        setIsLoading3(false);
        return false;
      }
      
      setIsLoading3(false);
      return;
    }
    setFormValues((prevFormValues) => ({ ...prevFormValues, ...values }));
    setTimeout(() => {
      setStep((prevStep) => prevStep + 1);
    }, 1000);
    setValidated(true);
  };

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const backStep = (event) => {
    setStep((prevStep) => prevStep - 1);
  };

  function resetValidations() {
    setValidation({
      hasMinimumLength: false,
      hasNumber: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasSpecialCharacter: false
    })
  }

  // const handleLoading = (clicked) => {
  //   if (clicked) {
  //     setLoading(true);
  //   }
  // };

  useEffect(() => {
    if (step === 0) {
      navigate("/");
    }

    if (step === 1) {
      resetValidations()
    }

    // if (step === 3) {
    //   cadastroCliente()
    // }

    // if (step === 4) {
    //   cadastroCliente() 
    // }
    // if (step === 3) {
    //   setIsLoading3(true);
    //   api
    //     .postCadastrar(
    //       formValues.nome,
    //       formValues.email,
    //       formValues.telefone,
    //       formValues.cpf,
    //       formValues.senha,
    //       formValues.confirma_senha
    //     )
    //     .then((data) => {
    //       if (data.status === true) {
    //         let optionsToast = {
    //           code: data.mensagem.codigo,
    //           message: data.mensagem.mensagem,
    //           visible: true,
    //         };

    //         setOptionsToast(optionsToast);

    //         setTimeout(() => {
    //           navigate("/Login/cli");
    //         }, 1800); // Atraso
    //       } else {
    //         return false;
    //       }
    //     })
    //     .catch((error) => {
    //       setIsLoading3(false);
    //       console.log(error);
    //     });
    // }
  }, [step]);

  async function cadastroCliente (valuesCadastro) {
    setIsLoading2(true);

    try {

      const response = await api.postCadastrar(
          formValues.nome,
          formValues.email,
          formValues.telefone,
          formValues.cpf,
          valuesCadastro.senha,
          valuesCadastro.confirma_senha
      );

      console.log(response.data)
      
      if (response.status === true) {
        let optionsToast = {
          code: response.mensagem.codigo,
          message: response.mensagem.mensagem,
          visible: true,
        };

        setOptionsToast(optionsToast);
        setUser(response.data)
        return true

      } else {
        setIsLoading2(false);
        return false;
      }
    
    
    } catch (error) {
      setIsLoading2(false);
      console.log(error);
      return false
    };
  }

  async function enviaCodigoValidacao(cli_id) {
    const response = await api.postEnviaCodigoValidacao(cli_id);

    if (response.status === true) {
      setIsLoading2(false);
    }
  }

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const [type2, setType2] = useState("password");
  const [icon2, setIcon2] = useState(eyeOff);

  const handleToggle2 = () => {
    if (type2 === "password") {
      setIcon2(eye);
      setType2("text");
    } else {
      setIcon2(eyeOff);
      setType2("password");
    }
  };

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <Formik
            key={step}
            validationSchema={schema1}
            onSubmit={(values) => {
              setDefaultNome(values.nome);
              setDefaultEmail(values.email);
              setDefaultTelefone(values.telefone);
              // setDefaultConfirmaTelefone(values.confirma_telefone);
              setDefaultConfirmaEmail(values.confirma_email);
              setDefaultCpf(values.cpf);

              // const { telefone, cpf, confirma_telefone, ...rest } = values;
              const { telefone, cpf, ...rest } = values;
              const telefoneSemMascara = functions.removeMascara(telefone);
              // const confirmaTelefoneSemMascara = functions.removeMascara(confirma_telefone);
              const cpfSemMascara = functions.removeMascara(cpf);
              const validarCPF = functions.validarCPF(cpfSemMascara);
              const valuesSemMascara = {
                ...rest,
                telefone: telefoneSemMascara,
                // confirma_telefone: confirmaTelefoneSemMascara,
                cpf: cpfSemMascara,
              };
              handleSubmit(valuesSemMascara);
            }}
            initialValues={{
              nome: defaultNome,
              email: defaultEmail,
              telefone: defaultTelefone,
              // confirma_telefone: defaultConfirmaTelefone,
              confirma_email: defaultConfirmaEmail,
              cpf: defaultCpf,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleReset,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="nome" className="mt-3">
                  <FloatingLabel label={"Nome e sobrenome"}>
                    <InputMask
                      value={values.nome}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          maxLength={80}
                          className={`input-register-login-facj ${
                            touched.nome && errors.nome ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="nome"
                          value={values.nome}
                          onChange={handleChange}
                          isValid={touched.nome && !errors.nome}
                          placeholder={"Nome e sobrenome"}
                          {...inputProps}
                          // onFocus={() => setShowDialogSenha(true)}
                          // onBlur={() => setShowDialogSenha(false)}
                        />
                      )}
                    </InputMask>
                    
                    {touched.nome && errors.nome && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.nome}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.nome}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="cpf">
                  <FloatingLabel label={"CPF"}>
                    <InputMask
                      mask="999.999.999-99"
                      value={values.cpf}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.cpf && errors.cpf ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="cpf"
                          value={values.cpf}
                          onChange={handleChange}
                          isValid={touched.cpf && !errors.cpf}
                          placeholder="CPF"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.cpf && errors.cpf && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.cpf}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-3" controlId="email">
                  <FloatingLabel label={"Email"}>
                    <InputMask
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.email && errors.email ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email}
                          placeholder="E-mail"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    
                    {touched.email && errors.email && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-3" controlId="confirma_email">
                  <FloatingLabel label={"Confirme seu email"}>
                    <InputMask
                      value={values.confirma_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.confirma_email && errors.confirma_email ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="confirma_email"
                          value={values.confirma_email}
                          onChange={handleChange}
                          isValid={touched.confirma_email && !errors.confirma_email}
                          placeholder="E-mail"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    
                    {touched.confirma_email && errors.confirma_email && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.confirma_email}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>

                {/* <Form.Group controlId="confirma_email">
                  <FloatingLabel label={"Confirme seu email"}>
                    <InputMask
                      mask="(99) 99999-9999"
                      value={values.confirma_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.confirma_email &&
                            errors.confirma_email
                              ? "is-invalid"
                              : ""
                          }`}
                          type="text"
                          name="confirma_email"
                          value={values.confirma_email}
                          onChange={handleChange}
                          isValid={
                            touched.confirma_email &&
                            !errors.confirma_email
                          }
                          placeholder="Confirme seu celular"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.confirma_email && errors.confirma_email && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.confirma_email}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  
                </Form.Group> */}

                <Form.Group controlId="telefone">
                  <FloatingLabel label={"Celular"}>
                    <InputMask
                      mask="(99) 99999-9999"
                      value={values.telefone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.telefone && errors.telefone
                              ? "is-invalid"
                              : ""
                          }`}
                          type="text"
                          name="telefone"
                          value={values.telefone}
                          onChange={handleChange}
                          isValid={touched.telefone && !errors.telefone}
                          placeholder="Telefone"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.telefone && errors.telefone && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.telefone}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  <ParagraphSmall
                    textSize="13px"
                    text="Nos próximos passos enviaremos um SMS com um código
                    para confirmar seu número de celular."
                  />
                </Form.Group>

                

                {/* <div className="navbar-register-facj">
                    <GuiaPassoAPassoForm
                        totalSteps={2}
                        stateStep={step}
                    />
                </div> */}

                <Col className="mt-3 mb-3">
                  <ProgressBar
                    now={20}
                    label={`${20}%`}
                    animated={isLoading1}
                    className=""
                  />
                </Col>

                <Col className="d-lg-flex">
                  <Col className="d-flex justify-content-start">
                    <Btn
                      type={"terciary"}
                      isSumbit={false}
                      text="Voltar"
                      onTouchable={() => backStep()}
                    />
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Btn type={"secondary"} text="Próximo" isSumbit={true} />
                  </Col>
                </Col>
              </Form>
            )}
          </Formik>
        );

      case 2:      
        return (
          <Formik
            key={step}
            validationSchema={schema2}
            onSubmit={(values) => handleSubmit(values)}
            initialValues={{
              senha: "",
              confirma_senha: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleReset,
              onFocus,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="senha">
                  <FloatingLabel label={"Senha"}>
                    <Form.Control
                      className={`input-register-login-facj ${
                        touched.senha && errors.senha ? "is-invalid" : ""
                      }`}
                      type={type}
                      name="senha"
                      value={values.senha}
                      onChange={(event) => {
                        handlePasswordChange(event)
                        handleChange(event)
                      }}
                      onFocus={() => setShowDialogSenha(true)}
                      onBlur={() => setShowDialogSenha(false)}
                      isValid={touched.senha && !errors.senha}
                      placeholder="Senha"
                    />
                    {touched.senha && errors.senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.senha}
                      </Form.Control.Feedback>
                    )}
                    <span className="icon-password-facj" onClick={handleToggle}>
                      <Icon icon={icon} size={25} />
                    </span>

                    {showDialogSenha && (
                      <Form.Control.Feedback tooltip className="d-block bg-white p-4 border border-1 bg-border-line shadow-sm mt-3" style={{ zIndex: 9999 }}>
                        <h3 className="fw-semibold fs-6 text-black">
                          A senha deve incluir pelo menos:
                        </h3>
                        <div className="d-flex gap-2">
                          <Icon 
                            icon={validation.hasMinimumLength ? ic_check_circle : ic_cancel} 
                            style={{ color: validation.hasMinimumLength ? '#00b400' : '#f00', margin: 0 }} 
                          />
                          <p 
                            className="fw-medium" 
                            style={{ color: validation.hasMinimumLength ? '#00b400' : '#f00' }}
                          >
                            8 caracteres
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon 
                            icon={validation.hasNumber ? ic_check_circle : ic_cancel} 
                            style={{ color: validation.hasNumber ? '#00b400' : '#f00', margin: 0 }} 
                          />
                          <p 
                            className="fw-medium" 
                            style={{ color: validation.hasNumber ? '#00b400' : '#f00' }}
                          >
                            1 número
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon 
                            icon={validation.hasUpperCase ? ic_check_circle : ic_cancel} 
                            style={{ color: validation.hasUpperCase ? '#00b400' : '#f00', margin: 0 }} 
                          />
                          <p 
                            className="fw-medium" 
                            style={{ color: validation.hasUpperCase ? '#00b400' : '#f00' }}
                          >
                            1 letra MAIÚSCULA
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon 
                            icon={validation.hasLowerCase ? ic_check_circle : ic_cancel} 
                            style={{ color: validation.hasLowerCase ? '#00b400' : '#f00', margin: 0 }} 
                          />
                          <p 
                            className="fw-medium" 
                            style={{ color: validation.hasLowerCase ? '#00b400' : '#f00' }}
                          >
                            1 letra minúscula
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon 
                            icon={validation.hasSpecialCharacter ? ic_check_circle : ic_cancel} 
                            style={{ color: validation.hasSpecialCharacter ? '#00b400' : '#f00', margin: 0 }} 
                          />
                          <p 
                            className="fw-medium" 
                            style={{ color: validation.hasSpecialCharacter ? '#00b400' : '#f00' }}
                          >
                            1 caractere especial (! @ # $ & * . _)
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon 
                            icon={!validation.doesntHasProhibitedCharacter ? ic_check_circle : ic_cancel} 
                            style={{ color: !validation.doesntHasProhibitedCharacter ? '#00b400' : '#f00', margin: 0 }} 
                          />
                          <p 
                            className="fw-medium" 
                            style={{ color: !validation.doesntHasProhibitedCharacter ? '#00b400' : '#f00' }}
                          >
                            Não contém caracteres proibidos
                          </p>
                        </div>
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>

                <Form.Group className="mb-3" controlId="confirma_senha">
                  <FloatingLabel label={"Confirme sua senha"}>
                    <Form.Control
                      className={`input-register-login-facj ${
                        touched.confirma_senha && errors.confirma_senha
                          ? "is-invalid"
                          : ""
                      }`}
                      type={type2}
                      name="confirma_senha"
                      value={values.confirma_senha}
                      //onChange={handleChange}
                      onChange={(event) => {
                        handlePasswordChange(event)
                        handleChange(event)
                      }}
                      onFocus={() => setShowDialogConfirmaSenha(true)}
                      onBlur={() => setShowDialogConfirmaSenha(false)}
                      isValid={touched.confirma_senha && !errors.confirma_senha}
                      placeholder="Confirme sua senha"
                    />
                    {touched.confirma_senha && errors.confirma_senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.confirma_senha}
                      </Form.Control.Feedback>
                    )}
                    <span
                      className="icon-password-facj"
                      onClick={handleToggle2}
                    >
                      <Icon icon={icon2} size={25} />
                    </span>

                    {showDialogConfirmaSenha && (
                      <Form.Control.Feedback tooltip className="d-block bg-white p-4 border border-1 bg-border-line shadow-sm mt-3" style={{ zIndex: 9999 }}>
                        <h3 className="fw-semibold fs-6 text-black">
                          A senha deve incluir pelo menos:
                        </h3>
                        <div className="d-flex gap-2">
                          <Icon 
                            icon={validationConfirmaSenha.hasMinimumLength ? ic_check_circle : ic_cancel} 
                            style={{ color: validationConfirmaSenha.hasMinimumLength ? '#00b400' : '#f00', margin: 0 }} 
                          />
                          <p 
                            className="fw-medium" 
                            style={{ color: validationConfirmaSenha.hasMinimumLength ? '#00b400' : '#f00' }}
                          >
                            8 caracteres
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon 
                            icon={validationConfirmaSenha.hasNumber ? ic_check_circle : ic_cancel} 
                            style={{ color: validationConfirmaSenha.hasNumber ? '#00b400' : '#f00', margin: 0 }} 
                          />
                          <p 
                            className="fw-medium" 
                            style={{ color: validationConfirmaSenha.hasNumber ? '#00b400' : '#f00' }}
                          >
                            1 número
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon 
                            icon={validationConfirmaSenha.hasUpperCase ? ic_check_circle : ic_cancel} 
                            style={{ color: validationConfirmaSenha.hasUpperCase ? '#00b400' : '#f00', margin: 0 }} 
                          />
                          <p 
                            className="fw-medium" 
                            style={{ color: validationConfirmaSenha.hasUpperCase ? '#00b400' : '#f00' }}
                          >
                            1 letra MAIÚSCULA
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon 
                            icon={validationConfirmaSenha.hasLowerCase ? ic_check_circle : ic_cancel} 
                            style={{ color: validationConfirmaSenha.hasLowerCase ? '#00b400' : '#f00', margin: 0 }} 
                          />
                          <p 
                            className="fw-medium" 
                            style={{ color: validationConfirmaSenha.hasLowerCase ? '#00b400' : '#f00' }}
                          >
                            1 letra minúscula
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon 
                            icon={validationConfirmaSenha.hasSpecialCharacter ? ic_check_circle : ic_cancel} 
                            style={{ color: validationConfirmaSenha.hasSpecialCharacter ? '#00b400' : '#f00', margin: 0 }} 
                          />
                          <p 
                            className="fw-medium" 
                            style={{ color: validationConfirmaSenha.hasSpecialCharacter ? '#00b400' : '#f00' }}
                          >
                            1 caractere especial (! @ # $ & * . _)
                          </p>
                        </div>
                        <div className="d-flex gap-2">
                          <Icon 
                            icon={!validationConfirmaSenha.doesntHasProhibitedCharacter ? ic_check_circle : ic_cancel} 
                            style={{ color: !validationConfirmaSenha.doesntHasProhibitedCharacter ? '#00b400' : '#f00', margin: 0 }} 
                          />
                          <p 
                            className="fw-medium" 
                            style={{ color: !validationConfirmaSenha.doesntHasProhibitedCharacter ? '#00b400' : '#f00' }}
                          >
                            Não contém caracteres proibidos
                          </p>
                        </div>
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Form.Group>
                <ParagraphSmall
                  textSize="13px"
                  text="A senha deve conter pelo menos 8 caracteres, um número, uma letra maiúscula, uma letra
                  minúscula e um caractere especial"
                />

                <Row>
                  <Col className="d-flex mt-2 align-items-center">
                    <Form.Check
                      type="switch"
                      id="custom-switch2"
                      checked={aceitoTermos}
                      onChange={(e) => setAceitoTermos(e.target.checked)}
                    />
                    <Col>
                      <span>Li e concordo com os </span>
                      <span
                        className="link-color"
                        style={{ cursor: "pointer" }}
                        onClick={handleTermosClick}
                      >
                        <strong>Termos de uso</strong>
                      </span>
                      <span> e a </span>
                      <span
                        className="link-color"
                        style={{ cursor: "pointer" }}
                        onClick={handlePoliticaClick}
                      >
                        <strong>Política de Privacidade.</strong>
                      </span>
                    </Col>
                  </Col>
                </Row>

                {modalShow && (
                  <TermosDeUsoModal
                    size="lg"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                )}
                {modalPoliticaShow && (
                  <PoliticaPrivacidadeModal
                    size="lg"
                    show={modalPoliticaShow}
                    onHide={() => setModalPoliticaShow(false)}
                  />
                )}

                <Col className="">
                  <ProgressBar
                    now={40}
                    label={`${40}%`}
                    animated={isLoading2}
                    className=""
                  />
                </Col>

                <Col className="d-lg-flex">
                  <Col className="d-flex justify-content-start">
                    <Btn
                      type={"terciary"}
                      text="Voltar"
                      onTouchable={backStep}
                    />
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Btn
                      type={"secondary-load"}
                      text="Cadastrar"
                      isSumbit={true}
                      isLoading={loading}
                    />
                  </Col>
                </Col>
              </Form>
            )}
          </Formik>
        );

      case 3:
        return (
          <Container className="alinhamento-step ">
            <Col className="d-flex justify-content-center">
              <Col className="text-center mb-3">
                <Subtitle text="Por fim, precisamos validar sua conta!" />
                <Col className="d-flex justify-content-center">
                  <ParagraphSmall
                    text="Por favor, digite abaixo o código de verificação 
            enviado ao seu celular via SMS e/ou E-mail cadastrado."
                  />
                </Col>
              </Col>
            </Col>

            <Row className="d-flex flex-column">
              <Col>
                <Formik
                  initialValues={{
                    code1: "",
                    code2: "",
                    code3: "",
                    code4: "",
                  }}
                  onSubmit={() => handleSubmit(codigoCompleto)}
                >
                  {({ values, handleChange }) => (
                    <Form className="" noValidate>
                      <Col className="d-flex gap-3 ">
                        <Field
                          key={1}
                          innerRef={el => inputRefs.code1 = el}
                          ref={inputRefs.code1}
                          type="text"
                          name="code1"
                          value={values.code1}
                          onChange={(e) => {
                            handleChange(e);
                            setCode1(e.target.value);
                            // inputRefs.code2.focus()
                            // inputRefs.code2.current.focus()
                          }}
                          onKeyUp={(e) => {
                            console.log(e)
                            inputRefs.code2.focus()
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                        <Field
                          key={2}
                          innerRef={el => inputRefs.code2 = el}
                          ref={inputRefs.code2}
                          type="text"
                          name="code2"
                          value={values.code2}
                          onChange={(e) => {
                            handleChange(e);
                            setCode2(e.target.value);
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                        <Field
                          key={3}
                          innerRef={el => inputRefs.code3 = el}
                          ref={inputRefs.code3}
                          type="text"
                          name="code3"
                          value={values.code3}
                          onChange={(e) => {
                            handleChange(e);
                            setCode3(e.target.value);
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                        <Field
                          key={4}
                          innerRef={el => inputRefs.code4 = el}
                          ref={inputRefs.code4}
                          type="text"
                          name="code4"
                          value={values.code4}
                          onChange={(e) => {
                            handleChange(e);
                            setCode4(e.target.value);
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Col>
              <Col className="d-flex justify-content-center">
                <Col className="d-flex flex-column gap-2 align-items-center justify-content-center">
                  <Col>
                    <CountdownTimer initialTime={240} cliId={user.cli_id} />
                  </Col>
                </Col>
              </Col>
            </Row>

            <Row className="d-flex flex-column gap-3 mb-3">
              <Col>
                <ProgressBar now={percent3} animated={isLoading3} striped={isLoading3 && percent3 == 100} label={`${percent3}%`} />
              </Col>
              <Col>
                <ParagraphSmall
                  textAlign="center"
                  textSize="13px"
                  text="Em caso de dúvidas entre em contato com o 
               <strong><a  target=blank href='https://api.whatsapp.com/send?phone=554199934379&text=Ol%C3%A1,%20vi%20seu%20contato%20no%20site%20e%20gostaria%20de%20saber%20mais.'>Atendimento</a>.</strong>"
                />
              </Col>
              <Col className="d-lg-flex gap-2 justify-content-between">
                <Btn
                  type={"terciary"}
                  isSumbit={false}
                  text="Sair"
                  onTouchable={handleSair}
                />
                {isLoading3 ? (
                  <Button disabled className="botao-sec-disable">
                    <Spinner size="sm" className="align-itens-center"></Spinner>
                  </Button>
                ) : (
                  <Button
                    type={"button"}
                    className={"botao-secundario"}
                    onClick={handleSubmit}
                  >
                    Próximo
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        );
      default:
        return null;
    }
  };

  return (
    <Container className="container-register-facj">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => {
          fecharToast()
          if (optionsToast?.onAction) {
            optionsToast?.onAction()
          }
        }}
      />
      <Row>
        <Col>
          <Subtitle text={"Crie sua conta"} />
          {renderForm()}
        </Col>
      </Row>
    </Container>
  );
};
