import React, { useState, useEffect } from "react";
import { Col, Container, Pagination, Row, Table } from "react-bootstrap"; // Import Pagination from react-bootstrap
import { Rating } from "react-simple-star-rating";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import Paragraph from "../../atoms/Paragraph";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import { UsuarioService } from "../../../services/AdmUsuarioServices/UsuarioService";
import Icons from "../../atoms/Icons";
import "./styles.css";
import { Link, useNavigate } from "react-router-dom";
import { PerfilService } from "../../../services/AdmUsuarioServices/PerfilService";
import { usePermissaoAdm } from "../../../hooks/usePermissaoAdm";

const AdmGerenciaListaPerfis = ({ permissoes }) => {
  const [userData, setUserData] = useState(null);
  const api = new PerfilService();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await api.getAll();
        setUserData(response);
      } catch (error) {
        console.log("Erro ao buscar dados da API:", error);
      }
    }
    fetchUserData();
  }, []);

  // Define the number of items per page
  const itemsPerPage = 5;

  // Calculate the total number of pages based on the data
  const totalPages = userData
    ? Math.ceil(userData.data.length / itemsPerPage)
    : 1;

  const handleEditar = (userId, permissoes) => {
    const url = `/adm/gerencial/perfil/editar/${userId}`;
    navigate(url);
  };

  const podeEditar = usePermissaoAdm(
    "gerencial-usuarios-cadastrar",
    permissoes
  );

  // Function to render table rows for the current page
  const renderTableRows = () => {
    if (!userData) return null;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return userData.data.slice(startIndex, endIndex).map((user) => (
      <tr key={user.per_id} className="align-itens-center table-container">
        <td style={{ width: "50%" }}>
          <Paragraph text={user.per_nome} />
        </td>
        <td style={{ width: "20%" }}>
          <Paragraph text={user.per_descricao} />
        </td>
        <td>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => (podeEditar ? handleEditar(user.per_id) : null)}
          >
            <Icons type={podeEditar ? "editar" : "pix"} />
          </Col>
        </td>
      </tr>
    ));
  };

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Table responsive hover className="">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Descrição</th>
                <th>Editar</th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </Table>
        </Col>
        <Col
          lg={12}
          className="d-flex align-items-center justify-content-center"
        >
          <Pagination size="sm">
            {/* Render pagination items based on the total number of pages */}
            {Array.from({ length: totalPages }).map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
};

export default AdmGerenciaListaPerfis;
