import React from 'react';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Title= (props) => {
    return (
        <h2>{props.text}</h2>
      
      );
}

export default Title;



