import React, { useCallback, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../molecules/Header";
import Footer from "../../molecules/Footer";
import Subtitle from "../../atoms/Subtitle";
import CabecalhoPerfilAdv from "../../molecules/CabecalhoPerfilAdv";
import ImgBannerPerfil from "../../atoms/ImgBannerPerfil";
import { PerfilAdvogadoForms } from "../../organisms/PerfilAdvogadoForms/index";
import { PerfilAdvSenha } from "../../organisms/PerfilAdvSenha ";
import { AdvPlano } from "../../organisms/AdvPlano";
import { ClassificacaoAdv } from "../../organisms/ClassificacaoAdv";
import { ComentarioAvaliacao } from "../../organisms/ComentarioAvaliacao";
import { PerfilService } from "../../../services/AdvogadoServices/PerfilService";

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { Rating } from "react-simple-star-rating";
import { Col, Form, Row } from "react-bootstrap";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import Paragraph from "../../atoms/Paragraph";
import Btn from "../../atoms/Button";
import { AvaliacaoService } from "../../../services/AdvogadoServices/AvaliacaoService";

export const AvaliacoesAdvTemp = () => {
  const api = new PerfilService();
  const Api = new AvaliacaoService();

  const id = sessionStorage.getItem("id");
  const [dadosApi, setDadosApi] = useState(null);
  const [controlRender, setControlRender] = useState(false);
  const [userData, setUserData] = useState(null);

  const [visibleCount, setVisibleCount] = useState(3);

  const handleVerMais = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  const handleVerMenos = () => {
    setVisibleCount(3);
  };

  // const [tokenRecaptcha, setTokenRecaptcha] = useState("");
  // const { executeRecaptcha } = useGoogleReCaptcha();

  const nomeUsuario = sessionStorage.getItem("nome") || "";
  const cliId = sessionStorage.getItem("id");

  // console.log("userdata", userData);

  useEffect(() => {
    if (dadosApi != null) {
      setControlRender(true);
    }
  }, [dadosApi]);

  // const handleReCaptchaVerify = useCallback(async () => {
  //   if (!executeRecaptcha) {
  //     console.log("Recaptcha não lido ainda.");
  //     return;
  //   }

  //   setTokenRecaptcha(await executeRecaptcha("submit"));
  // }, [executeRecaptcha]);

  // useEffect(() => {
  //   handleReCaptchaVerify();
  // }, [handleReCaptchaVerify]);

  // useEffect(() => {
  //   handleReCaptchaVerify();
  //   api
  //     .postPerfilAdv(id, tokenRecaptcha)
  //     .then((data) => {
  //       setDadosApi(data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await Api.avaliacaoConsultaListar(cliId);
        setUserData(response.data);
      } catch (error) {
        console.log("Erro ao buscar dados da API:", error);
      }
    }
    fetchUserData();
  }, [cliId]);

  return (
    <body className="">
      <Container fluid className="p-0 m-0 ">
        <Header />
      </Container>

      <Container fluid className="p-0 m-0">
        <div className="banner-container">
          <ImgBannerPerfil className="img-fluid" />
        </div>
      </Container>

      <Container>
        <Row className="gap-5">
          <Col lg={2} className="avatar-container">
            <AvatarPerfil
              type="A"
              base64={sessionStorage.getItem("fotoPerfil")}
              width="13rem"
              height="13rem"
              padding="0.4rem"
            />
          </Col>

          <Col lg={8} className="py-2 mt-2 ms-2">
            <Subtitle text={"Olá, " + nomeUsuario} />
            <Rating size={25} readonly={true}></Rating>
          </Col>
        </Row>
      </Container>
      {/* data={dadosApi}  */}

      <Container className=" my-4 pb-5">
        <Row className="d-flex flex-column align-items-center">
          <Col lg={9}>
            <Subtitle text="Minhas Avaliações" />
            <Col className="w-75 ps-2">
              <Paragraph
                text="Aqui você pode acompanhar suas avaliações e 
              dar feedback para seus clientes, assim como, solicitar exclusão 
              de comentários e avaliações."
              />
            </Col>
          </Col>
        </Row>

        <Row className="d-flex flex-column align-items-center">
          {userData &&
            userData.slice(0, visibleCount).map((data, index) => (
              <Col key={index} lg={9}>
                <ClassificacaoAdv userData={data} />
              </Col>
            ))}
        </Row>
        <Row className="d-flex justify-content-center ">
          {visibleCount === 3 ? (
            <Col lg={2} className="justify-content-center">
              <Btn
                onTouchable={handleVerMais}
                type="secondary-sm"
                text="Ver Mais"
              />
            </Col>
          ) : (
            <Col lg={2} className="justify-content-center">
              <Btn
                onTouchable={handleVerMenos}
                type="secondary-sm"
                text="Ver Menos"
              />
            </Col>
          )}
        </Row>
      </Container>

      <Container fluid className="p-0 m-0 ">
        <Footer />
      </Container>
    </body>
  );
};
