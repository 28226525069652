import React, { useEffect, useRef, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ImgBanner from "../../atoms/ImgBanner";

import './styles.css';
import Title from "../../atoms/Title";
import Paragraph from "../../atoms/Paragraph";


export default function CabecalhoCarrosselLpOne() {
 
  return (
    <Container className="mb-5">
      <Row className="">
        <Col className="d-flex flex-column align-items-center justify-content-center">
          <Col className="shadow">
            <ImgBanner type="bannerLpTwo" key="banner2" />
          </Col>
          <Col lg={10} className="shadow" >
            <Col className="d-flex justify-content-center text-center mt-5">
              <Title text="Vantagens utilizando o seu consignado" />
            </Col>
            <Col className="mt-2 ">
              <Paragraph text="O pagamento descontado em folha para consultas 
              jurídicas é um serviço pelo qual o servidor pode acessar serviços 
              de consultoria jurídica e assistência legal, com o custo desses 
              serviços sendo deduzido diretamente de seu salário ou remuneração regular." />
            </Col>
          </Col>
        </Col>
      </Row>
    </Container>
    
  );
}



