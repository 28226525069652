import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Row, Breadcrumb } from "react-bootstrap";
import "./styles.css";
import Header from "../../../molecules/Header";
import AdmGerencialUsuarios from "../../../organisms/AdmGerencialUsuarios";
import NavitemSidebarAdm from "../../../atoms/NavitemSidebarAdm";
import AdmGerencialPerfis from "../../../organisms/AdmGerencialPerfis";
import AdmHeader from "../../../molecules/AdmHeader";

export const GerencialPerfisTemp = ({ permissoes }) => {
  const [sideNavExpanded, setSideNavExpanded] = useState(true);

  const toggleSideNav = () => {
    setSideNavExpanded(!sideNavExpanded);
  };

  return (
    <body className="m-0 p-0">
      <Container fluid className="fluid-bg">
        <AdmHeader />
      </Container>

      <Container fluid className="d-flex  mt-5">
        <Col
          className={`menu-position ${sideNavExpanded ? "" : "menu-fechado"}`}
        >
          <NavitemSidebarAdm
            menuAberto={sideNavExpanded}
            toggleMenu={toggleSideNav}
          />
        </Col>

        <Col className="container-tabela flex-grow-2 shadow-lg">
          <Row className="p-3">
            <Col lg={3} className="m-0 p-0  text-start">
              <Breadcrumb className="">
                <Breadcrumb.Item>Gerencial</Breadcrumb.Item>
                             <Breadcrumb.Item href="/gerencial" active>
                  Perfis
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>

          <Row>
            <AdmGerencialPerfis permissoes={permissoes} />
          </Row>
        </Col>
      </Container>

      <Container></Container>
    </body>
  );
};
