import React, { useState } from "react";
import "./styles.css";
import Carousel from "react-bootstrap/Carousel";
import myImage1 from "../../../../src/img/banner-home-preciso-de-um-advogado-02.07.png";
import myImage2 from "../../../../src/img/banner-home-opcao-pagamento01-02.07.png";
import myImage3 from "../../../../src/img/banner-home-opcao-pagamento02-02.07.png";
import myImage4 from "../../../../src/img/banner-home-saiba-mais-02.07.png";
import myImage5 from "../../../../src/img/banner-home-forum-02.07.png";
import myImage6 from "../../../../src/img/banner-home-localizacoes-convenios-02.07.png";
import TitleBanner from "../../atoms/TitleBanner";
import SubtitleBanner from "../../atoms/SubtitleBanner";
import Image from "react-bootstrap/Image";
import Btn from "../../atoms/Button";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function ControlledCarousel() {
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const lpcarouselOne = () => {
    navigate(`/CarrosselLpOne`);
  };
  
  const lpcarouselTwo = () => {
    navigate(`/CarrosselLpTwo`);
  };

  const lpCadastroCliente = () => {
    window.location.href = "/Cadastro/Cli";
  };

  const lpForum = () => {
    window.location.href = "/Forum";
  };


  const scrollToPosition = () => {
    window.scrollTo({
      top: 700,
      behavior: 'smooth',
    });
  }

  const carousel = [
    {
      image: myImage1,
      text: <span>Seu caminho para a justiça começa com os <span style={{color:'#EEB20A'}}>nossos advogados</span></span>,
      buttonText: 'Preciso de um advogado',
      buttonHandler: scrollToPosition
    },
    {
      image: myImage2,
      text: 'Parcele em até 12x no cartão de crédito:',
      subText: <span style={{color:'#EEB20A'}}>Encontre um advogado, agende sua consulta e resolva seus problemas jurídicos com praticidade e segurança.</span>,
    },
    {
      image: myImage3,
      text: <span>Vantagens exclusivas para o <span style={{color:'#EEB20A'}}>Servidor Público</span></span>,
      subText: <span style={{color:'#EEB20A'}}>Parcele através do crédito consignado sem burocracia.</span>,
      buttonText: 'Preciso de um advogado',
      buttonHandler: lpcarouselOne
    },
    {
      image: myImage4,
      text: 'Conheça as facilidades do Faça Jus',
      subText: 'Encontre o advogado ideal em poucos cliques',
      buttonText: 'Saiba mais',
      buttonHandler: lpcarouselTwo
    },
    {
      image: myImage5,
      text: <span>Descomplicando o seu <span style={{color:'#EEB20A'}}>acesso a advogados.</span></span>,
      subText: <span style={{color:'#EEB20A'}}>Participe do nosso fórum gratuito.</span>,
      buttonText: 'Preciso de um advogado',
      buttonHandler: lpForum
    },
    {
      image: myImage6,
      text: 'Atenção servidores de Goiás e Sorocaba!',
      subText: <span>Seu atendimento pode ser pago através do crédito consignado! <br/><br/><span style={{color:'#EEB20A'}}>Realize uma consulta ainda hoje e o desconto será na próxima folha de pagamento.</span></span>,

    }
  ]
  

  return (
    <div className="container-carousel-father">
      <div className="container-carousel">
        <Container fluid className="d-flex align-items-center">
          <Row>
            <Col className="d-flex align-items-center">
              <Carousel activeIndex={index} onSelect={handleSelect}>
                {
                  carousel.map((item, idx)  => {
                    return <Carousel.Item>
                      <Col className="carousel-col-image">
                        <Image
                          src={item.image}
                          alt="First slide"
                          className="carousel-image"
                        />
                      </Col>
                        <Carousel.Caption className={"carousel-image texto-banner"}>
                          <Col>
                            <TitleBanner
                              text={item.text}
                            />
                            <SubtitleBanner
                            text={item.subText}
                            />
                            {item.buttonText ? (
                              <Btn
                                className="mt-3 btn-carousel"
                                type={"primary"}
                                text={item.buttonText}
                                isSumbit={false}
                                onTouchable={item.buttonHandler}
                              />) : (<></>)}
                          </Col>
                        </Carousel.Caption>
                    </Carousel.Item>
                  })
                }
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ControlledCarousel;


