import React, { useState } from "react";
import { useParams } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Breadcrumb, Row } from "react-bootstrap";
import "./styles.css";
import Header from "../../../molecules/Header";
import NavitemSidebarAdm from "../../../atoms/NavitemSidebarAdm";
import Subtitle from "../../../atoms/Subtitle";
import { useEffect } from "react";
import { UsuarioService } from "../../../../services/AdmUsuarioServices/UsuarioService";
import AdmHeader from "../../../molecules/AdmHeader";
import { AdmGerencialPerfisDados } from "../../../organisms/AdmGerencialPerfisDados";

export const  GerencialPerfisEditarTemp = () => {
  const [sideNavExpanded, setSideNavExpanded] = useState(true);

  const toggleSideNav = () => {
    setSideNavExpanded(!sideNavExpanded);
  };

  const { id } = useParams(); // Acessa o ID da URL
  console.log("ID recebido:", id); 
  const api = new UsuarioService();
  





  return (
    <body className="m-0 p-0">
      <Container fluid className="fluid-bg">
      <AdmHeader />
      
      </Container>

      <Container fluid className="d-flex gap-2 row mt-5">
        <Col
          className={`menu-position  ${sideNavExpanded ? "" : "menu-fechado"}`}
        >
          <NavitemSidebarAdm
            menuAberto={sideNavExpanded}
            toggleMenu={toggleSideNav}
          />
        </Col>

        <Col className="container-tabela flex-grow-1 shadow-lg">
          <Row className="p-3">
            <Col lg={3} className="m-0 p-0  text-start">
              <Breadcrumb className="">
                <Breadcrumb.Item>Gerencial</Breadcrumb.Item>
                <Breadcrumb.Item href="/gerencial/usuarios">
                  Perfis
                </Breadcrumb.Item>
                <Breadcrumb.Item href="#" active>
                  Editar
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row>
            <Col className=" align-items-center ps-5 ">
              <Col className="">
                <Subtitle text="Editar perfil" />
              </Col>
            </Col>
          </Row>
          <Row>
          <AdmGerencialPerfisDados id={id} />

          </Row>
        
        </Col>
      </Container>

      <Container></Container>
    </body>
  );
};