import functions from "../../components/utils/functions";
import api from "../Api";


export class PerfilService {

async postPerfilAdv(
    id,
    recaptcha_token
  ) {
    return await api.post("/advogado/dados-pessoais-adv-perfil", {
      "id": id,
      "tokenRecaptcha" : recaptcha_token
    }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((res) =>  res.data);
}

  async postAlteraDadosPessoaisAdvogado(
  id,
  nome,
  celular,
  email,
  resumo,
  tokenRecaptcha
  ) {
  return await api.post("/advogado/altera-dados-pessoais-adv", {
    "id": id,
    "nome": nome,
    "celular": celular,
    "email": email,
    "resumo": resumo,
    "tokenRecaptcha" : tokenRecaptcha
  }, {
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    },
  }).then((res) =>  res.data)
    .catch((error) => {
      if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
        functions.modalToken();
      } 
    });
}


  async postAlteraDadosConsulta(
  id,
  valor,
  tempo,
  tokenRecaptcha
  ) { return await api.post("/advogado/altera-dados-consultas", {
  "id": id,
  "valor" : valor,
  "tempo" : tempo,
  "tokenRecaptcha" : tokenRecaptcha
  }, {
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("token"),
  },
  }).then((res) =>  res.data)
    .catch((error) => {
      if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
        functions.modalToken();
      } 
    });
}


  async postAlterarSenha(
    id,
    senha,
    nova_senha,
    confirma_senha,
    tokenRecaptcha
  ) { return await api.post("/advogado/alterar-senha", {
    "id": id,
    "senha": senha,
    "nova_senha": nova_senha,
    "confirma_senha" : confirma_senha,
    "tokenRecaptcha" : tokenRecaptcha
    }, {
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("token"),
    },
    }).then((res) => res.data)
      .catch((error) => {
        if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
          functions.modalToken();
        } 
    });
  }

  async adicionarFoto(id, foto) {
    let formData = new FormData();
    formData.append('id', id);
    formData.append('foto', foto);
    
    return await api.post("/fotoUsuario/adicionar-foto", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
       },
     })
     .then((res) => res.data)
     .catch((error) => {
      if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
        functions.modalToken();
      } 
     });
  }

  async adicionarServicoCorrespondente( 
    id,
    servico,
    valorServico,
    tokenRecaptcha
  )  
    { return await api.post("/advogado/adicionar-servico-correspondente", {
      "id": id,
      "servico" : servico,
      "valorServico" : valorServico,
      "tokenRecaptcha" : tokenRecaptcha
  }, {
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("token"),
  },
  }).then((res) =>  res.data)
    .catch((error) => {
      if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
        functions.modalToken();
      } 
  });
}

async inativaServicoAdvogado( 
  idServico,
  tokenRecaptcha
)  
  { return await api.post("/advogado/inativa-servico-adv", {
    "idServico" : idServico,
    "tokenRecaptcha" : tokenRecaptcha
}, {
headers: {
  "Content-type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("token"),
},
}).then((res) =>  res.data)
  .catch((error) => {
    if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
      functions.modalToken();
    } 
  });
}
};