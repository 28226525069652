import React, { useState } from "react";
import Btn from "../../atoms/Button";
import { FloatingLabel, Form, Container, Row, Col } from "react-bootstrap";

function InputArquivo({ validFileExtensions, required, name, getFile, id }) {
  const [fileAnexada, setFileAnexada] = useState(null);
  const [valid, setValid] = useState(true);
  const [validErro, setValidErro] = useState(null);

  function isArchiveFile(file, validFileExtensions) {
    let filename = file.name;
    const fileExtension = filename.toLowerCase().slice(filename.lastIndexOf("."));
    return validFileExtensions.includes(fileExtension);
  }

  function handleFileInputChange(event) {
    const file = event.target.files[0];
    if (!file) return;

    if (validFileExtensions.includes(".pdf")) {
      if (file.type !== "application/pdf") {
        setFileAnexada(null);
        setValid(false);
        setValidErro("Apenas arquivos PDF permitidos");
        return;
      }
    } else {
      setFileAnexada(file);
      setValid(true);
      setValidErro(null);
      getFile(file);
      }
      if (!isArchiveFile(file, validFileExtensions)) {
        setFileAnexada(null);
        setValid(false);
        setValidErro("Apenas formatos JPG/PNG/JPEG");
        return;
    }

    setFileAnexada(file);
    setValid(true);
    setValidErro(null);

    // Comprimir a imagem apenas se o tamanho exceder 1,8 MB
    if (file.size / (1024 * 1024) > 1.8) {
      compressImage(file)
        .then((compressedBlob) => {
          getFile(compressedBlob);
        })
        .catch((error) => {
          setValid(false);
          setValidErro("Erro ao processar a imagem");
          console.error("Erro ao comprimir imagem:", error);
        });
    } else {
      getFile(file);
    }
  }

  function handleSubmit() {
    document.getElementById(id)?.click();
  }

  function compressImage(file) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const maxWidth = 1000;
      const maxHeight = 800;

      image.onload = () => {
        let width = image.width;
        let height = image.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        context.drawImage(image, 0, 0, width, height);

        canvas.toBlob((blob) => {
          // Salvando o arquivo com a extensão correta
          const fileName = `${file.name.replace(/\.[^/.]+$/, "")}.jpeg`;
          resolve(new File([blob], fileName, { type: "image/jpeg" }));
        }, "image/jpeg", 0.8); // Qualidade da compressão: 90%
      };

      image.onerror = (error) => {
        reject(error);
      };

      image.src = URL.createObjectURL(file);
    });
  }

  return (
    <Container className="m-0 p-0">
      <form
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        <Row className="d-flex flex-column align-items-center ">
          <Col  className="">
            <FloatingLabel label={name} className="input-anexar">
              <Form.Control
                className={`bg-white  ${
                  valid ? "" : "is-invalid"
                }`}
                name={name}
                value={fileAnexada ? fileAnexada.name : ""}
                onChange={"handleChange"}
                placeholder={name}
                disabled={true}
              />
              {!valid && (
                <Form.Control.Feedback type="invalid" tooltip>
                  {validErro}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
            <div style={{ display: "none" }}>
              <input
                type="file"
                onChange={handleFileInputChange}
                id={id}
                accept="image/*"
                multiple={false}
              />
            </div>
         
          </Col>
          <Col className="d-flex justify-content-end">
            <Btn type={"secondary-sm"} isSumbit={true} text="Anexar" />
          </Col>
          
        </Row>
      </form>
    </Container>
  );
}

export default InputArquivo;
