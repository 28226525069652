import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Container, Row, Form } from "react-bootstrap";
import Btn from "../../atoms/Button";
import "./styles.css";

import Button from "react-bootstrap/Button";
import { useFormik } from "formik"; // Importe useFormik aqui
import * as yup from "yup";
import functions from "../../utils/functions";
import { FloatingLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


import { Toasts } from "../../utils/Toasts";
import CheckboxTree from "react-checkbox-tree";

// import "react-checkbox-tree/lib/react-checkbox-tree.css";


import Icons from "../../atoms/Icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PerfilService } from "../../../services/AdmUsuarioServices/PerfilService";

export const AdmGerencialPerfisCadastrar = () => {
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("eyeOff");

  const api = new PerfilService();

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };
  const navigate = useNavigate();

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const handleToggle = () => {
    setType(type === "password" ? "text" : "password");
    setIcon(icon === "eyeOff" ? "eye" : "eyeOff");
  };

  const initialValues = {
    nome: "",
    descricao: "",
  };

  const schema = yup.object().shape({
    nome: yup
      .string()
      .required("O nome é obrigatório")
      .matches(/^[a-zA-ZÀ-ÿ\s]*$/, "Por favor, digite apenas letras e acentos")
      .max(80, "Máximo de caracteres excedido")
      .min(3, "Mínimo de caracteres não detectado"),
    descricao: yup
      .string()
      .required("Descrição é obrigatória")
      .max(80, "Máximo de caracteres excedido")
      .min(3, "Mínimo de caracteres não detectado"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      // Handle form submission here
      enviaDadosPerfil(values);
    },
  });

  async function enviaDadosPerfil(values) {
    try {
      const response = await api.postCadastrar(
        values.nome,
        values.descricao,
        checkedNodes.gerencialPerfis ?? ""
      );
      if (response.status) {
        let optionsToast = {
          code: response.mensagem.codigo,
          message: response.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);

        setTimeout(() => {
          navigate("/adm/gerencial/perfil");
        }, 3100); // Atraso
      }
    } catch (error) {
      console.log("Erro ao buscar dados da API:", error);
    }
  }

  const [checkedNodes, setCheckedNodes] = useState({
    gerencialPerfis: [],
    // gerencialUsuarios: []
  });

  const handleCheckboxChange = (newChecked, nodeId, treeId) => {
    setCheckedNodes((prevCheckedNodes) => ({
      ...prevCheckedNodes,

      [treeId]: newChecked,
    }));
  };

  const nodes = [
    {
      value: "gerencialPerfis",

      label: "Gerencial - Perfis",

      children: [
        {
          value: "gerencial-perfis-acessar",

          label: "Acessar",
        },

        {
          value: "gerencial-perfis-cadastrar",

          label: "Cadastrar",
        },

        {
          value: "gerencial-perfis-editar",

          label: "Editar",
        },
      ],
    },

    {
      value: "gerencialUsuarios",

      label: "Gerencial - Usuários",

      children: [
        {
          value: "gerencial-usuarios-acessar",

          label: "Acessar",
        },

        {
          value: "gerencial-usuarios-cadastrar",

          label: "Cadastrar",
        },

        {
          value: "gerencial-usuarios-editar",

          label: "Editar",
        },
      ],
    },

    {
      value: "plataformaCliente",

      label: "Plataforma - Cliente",

      children: [
        {
          value: "plataforma-clientes-acessar",

          label: "Acessar",
        },

        // {
        //   value: "plataforma-advogado-editar",

        //   label: "Editar",
        // },
      ],
    },

    {
      value: "plataformaAdvogado",

      label: "Plataforma - Advogado",

      children: [
        {
          value: "plataforma-advogado-acessar",

          label: "Acessar",
        },

        {
          value: "plataforma-advogado-editar",

          label: "Editar",
        },
      ],
    },

    {
      value: "financeiroConsignado",

      label: "Financeiro - Consignado",

      children: [
        {
          value: "financeiro-consignado-acessar",

          label: "Acessar",
        },

        {
          value: "financeiro-consignado-editar",

          label: "Editar",
        },
      ],
    },

    {
      value: "financeiroParticular",

      label: "Financeiro - Particular",


      children: [
        {
          value: "financeiro-particular-acessar",

          label: "Acessar",
        },

        {
          value: "financeiro-particular-editar",

          label: "Editar",
        },
      ],
    },

    {
      value: "dashboard",

      label: "Dashboard ",

      children: [
        {
          value: "dashboard-acessar",

          label: "Acessar",
        },

        {
          value: "dashboard-editar",

          label: "Editar",
        },
      ],
    },
  ];

  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <Container className="px-4">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />

      <Row className="pe-3">
        <Col>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="nome" className="d-lg-flex gap-3">
              <Col lg={6} md={6} sm={12}>
                <FloatingLabel controlId="nome" label="Nome">
                  <Form.Control
                    type="text"
                    name="nome"
                    value={formik.values.nome}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.nome && formik.errors.nome}
                    placeholder="Nome"
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.nome}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>

              <Col lg={6} md={6} sm={12}>
                <FloatingLabel controlId="descricao" label="Descrição">
                  <Form.Control
                    type="descricao"
                    name="descricao"
                    value={formik.values.descricao}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.descricao && formik.errors.descricao
                    }
                    placeholder="E-mail"
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.descricao}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
              <Col lg={6} md={6} sm={12}></Col>
            </Form.Group>

            <Col lg={4} className="d-flex gap-5 spacing-5 ms-5">
              <CheckboxTree
                nodes={nodes}
                checked={checkedNodes.gerencialPerfis}
                expanded={[
                  "gerencialPerfis",
                  "gerencialUsuarios",
                  "plataformaAdvogado",
                  "plataformaCliente",
                  "financeiroConsignado",
                  "financeiroParticular",
                  "dashboard"
                ]}
                onCheck={(newChecked) =>
                  handleCheckboxChange(
                    newChecked,
                    "gerencialPerfis",
                    "gerencialPerfis"
                  )
                }
              />{" "}
            </Col>

            <Col className="d-flex gap-3">
              <Btn
                type="terciary-sm"
                text="Voltar"
                onTouchable={handleNavigateBack}
              />

              <Btn type="secondary-sm" text="Cadastrar" isSumbit="true" />
            </Col>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default AdmGerencialPerfisCadastrar;
