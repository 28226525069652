import functions from "../../components/utils/functions";
import api from "../Api";

export class FinanceiroService {
  async getAll() {
    return await api
      .get("/", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getById(id) {
    return await api
      .post(
        "/contratos-id",
        { cnt_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  // async extratoConsultaCliente(cac_add_id) {
  //   try {
  //     const response = await api.post("/cliente/extrato-consulta-cli", {
  //       "cac_add_id": cac_add_id
  //     }, {
  //       headers: {
  //         "Content-type": "application/json",
  //         Authorization: "Bearer " + sessionStorage.getItem("token"),
  //       },
  //     });

  //     return response.data;
  //   } catch (error) {
  //     console.error(error);
  //     throw error;
  //   }
  // }

  async extratoConsultaCliente(id, tokenRecaptcha) {
    try {
      const response = await api.post(
        "/cliente/extrato-consulta-cli",
        {
          cacCliId: id,
          tokenRecaptcha: tokenRecaptcha,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.msg === "Token Expirado3."
      ) {
        functions.modalToken();
      } else {
        throw error;
      }
    }
  }

  async consultaServidor() {
    try {
      const response = await api.post(
        "/cliente/consulta-servidor",
        {
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async consultaMargem(matricula, convenio) {
    try {
      const response = await api.post(
        "/cliente/consulta-margem",
        {
          matricula: matricula,
          convenio: convenio,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async realizarPagamentoConsignado(
    id,
    add_id,
    adv_id,
    nome,
    convenio,
    matricula,
    senha,
    qtdParcela,
    comentario
  ) {
    try {
      const response = await api.post(
        "/cliente/realizar-pagamento-consignado",
        {
          cli_id: id,
          add_id: add_id,
          adv_id: adv_id,
          nome: nome,
          convenio: convenio,
          matricula: matricula,
          senha: senha,
          qtdeparcelas: qtdParcela,
          comentario: comentario,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.msg === "Token Expirado3."
      ) {
        functions.modalToken();
      } else {
        throw error;
      }
    }
  }

  async realizarPagamentoParticular(
    id,
    adv_id,
    add_id,
    parcelas,
    cartaoNome,
    cartaoNumero,
    dataExpiracao,
    cartaoCcv,
    // telefone,
    // cpf,
    // cep,
    // enderecoNumero,
    comentario,
    valorConsulta
  ) {
    try {
      const response = await api.post(
        "/cliente/realizar-pagamento-particular",
        {
          id: id,
          adv_id: adv_id,
          add_id: add_id,
          parcelas: parcelas,
          cartaoNome: cartaoNome,
          cartaoNumero: cartaoNumero,
          dataExpiracao: dataExpiracao,
          cartaoCcv: cartaoCcv,
          // telefone: telefone,
          // cpf: cpf,
          // cep: cep,
          // enderecoNumero: enderecoNumero,
          comentario: comentario,
          valor: valorConsulta,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.msg === "Token Expirado3."
      ) {
        functions.modalToken();
      } else {
        throw error;
      }
    }
  }

  async realizarPagamentoPlano(
    id,
    adv_id,
    add_id,
    comentario,
  ) {
    try {
      const response = await api.post(
        "/cliente/realizar-pagamento-plano",
        {
          id: id,
          adv_id: adv_id,
          add_id: add_id,
          comentario: comentario
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.msg === "Token Expirado3."
      ) {
        functions.modalToken();
      } else {
        throw error;
      }
    }
  }

  async exibirMeioRecebimento(id) {
    try {
      const response = await api.post(
        "/cliente/exibir-meio-recebimento-agendamento",
        {
          add_id: id,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.msg === "Token Expirado3."
      ) {
        functions.modalToken();
      } else {
        throw error;
      }
    }
  }

  async taxasCredito() {
    return await api
      .get("/cliente/taxas-particular", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }
}
