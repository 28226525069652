import React, { useRef, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

const SubDropdown = ({ options, selectedItem, selectedSubItem, onPress }) => {
  const subDropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = (subOption) => {
    onPress(selectedItem, subOption);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        subDropdownRef.current &&
        !subDropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="sub-dropdown"
      ref={subDropdownRef}
      style={{
        position: "absolute",
        left: "100%",
        top: "0",
        zIndex: 1,
      }}
    >
      <ul className="dropdown-menu dropdown-submenu show">
        {options && options.length > 0 ? (
          options.map((subOption, subIndex) => (
            <li
              key={subIndex}
              className={`dropdown-item item-combo ${
                selectedSubItem && selectedSubItem.desc === subOption.desc
                  ? "active"
                  : ""
              }`}
              onClick={() => handleItemClick(subOption)}
            >
              {subOption.desc}
            </li>
          ))
        ) : (
          <li className="dropdown-item">Nenhuma subopção</li>
        )}
      </ul>
    </div>
  );
};

export default SubDropdown;
