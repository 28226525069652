import functions from "../../components/utils/functions";
import api from "../Api";

export class NotificacaoService {

    async lembreteAdvogado(
        id,
        titulo,
        horario,
        dia,
        descricao,
        recaptcha_token
    ) {
        return await api.post("/advogado/lembrete-advogado", {
            "not_cli_id": id,
            "titulo": titulo,
            "horario": horario,
            "dia": dia,
            "descricao": descricao,
            "tokenRecaptcha": recaptcha_token
        }, {
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        })
            .then((res) => res.data);
    }

    async listarNotificacoes(
        id
    ) {
        return await api.post("/advogado/listar-notificacoes-adv", {
            "not_cli_id": id,
        }, {
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        })
            .then((res) => res.data);
    }

    async listarNotificacoesCli(
        id
    ) {
        return await api.post("/cliente/listar-notificacoes-cli", {
            "not_cli_id": id,
        }, {
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        })
            .then((res) => res.data);
    }


    async mensagemLida(id) {
        return await api.post("/advogado/notificacao-lida", {
            "idsNotificacoes": id
        }, {
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        }).then((res) => res.data)
            .catch((error) => {
                if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
                    functions.modalToken();
                }
            });
    }

    async mensagemLidaCli(id) {
        return await api.post("/cliente/notificacao-lida-cli", {
            "idsNotificacoes": id
        }, {
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        }).then((res) => res.data)
            .catch((error) => {
                if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
                    functions.modalToken();
                }
            });
    }

    async contarNotificacoesCli(
        id
    ) {
        return await api.post("/cliente/contar-notificacoes-cli", {
            "not_cli_id": id,
        }, {
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        })
            .then((res) => res.data);
    }

    async contarNotificacoesAdv(
        id
    ) {
        return await api.post("/advogado/contar-notificacoes-adv", {
            "not_cli_id": id,
        }, {
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        })
            .then((res) => res.data);
    }

}