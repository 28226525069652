import React, { useState, useEffect, useMemo, useCallback } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "../../molecules/Header";
import ControlledCarousel from "../../organisms/ControlledCarousel";
import HowItWorks from "../../organisms/HowItWorks";
import AdvsCarousel from "../../organisms/AdvsCarousel";
import AdvsCarouselMobile from "../../organisms/AdvsCarouselMobile";
import Footer from "../../molecules/Footer";
import SearchHome from "../../organisms/SearchHome";
import { Modal, ModalHeader } from "react-bootstrap";
import { RestCadastroAdvogadoForms } from "../../organisms/RestCadastroAdvogadoForms";
import Logo from "../../atoms/Logo";
import { VerRespostaForumCli } from "../../molecules/VerRespostaForumCli";
import { VerPerguntaForumCli } from "../../molecules/VerPerguntaForumCli";
import Subtitle from "../../atoms/Subtitle";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Btn from "../../atoms/Button";
import { faleConoscoService } from "../../../services/FaleConoscoServices/FaleConoscoServices";
import ControlledCarouselMobile from "../../organisms/ControlledCarouselMobile";
import { VideoConferenciaService } from "../../../services/VideoConferenciaServices/VideoConferenciaService";
import { CadastroService } from "../../../services/AdvogadoServices/CadastroService";

const api = new faleConoscoService();

const apiAdv = new CadastroService();

export const HomeAdvTemp = () => {

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/Forum");
  };

  const [showModal, setShowModal] = useState(false);
  const [perguntas, setPerguntas] = useState([]);

  // const api2 = useMemo(() => new VideoConferenciaService(), []);
  const api2 =  new VideoConferenciaService();
  const [usersComet, setUsersComet] = useState([]);

  function removeAccents(text) {
    const accents = "ÀÁÂÃÄÅàáâãäåÈÉÊËèéêëÌÍÎÏìíîïÒÓÔÕÖØòóôõöøÙÚÛÜùúûüÝýÿ";

    const accentsOut = "AAAAAAaaaaaaEEEEeeeeIIIIiiiiOOOOOOooooooUUUUuuuuYYy";

    return text
      .split("")
      .map((letter, index) => {
        const accentIndex = accents.indexOf(letter);
        return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
      })
      .join("");
  }
  const emailLogado = sessionStorage.getItem("email");
  const telLogado = sessionStorage.getItem("tel");
  const cli_id = sessionStorage.getItem("id");
  const nomeLogado_1 = sessionStorage.getItem("nome");
  const nomeLogado_2 = nomeLogado_1.split(" ");
  const nomeLogado_3 = nomeLogado_2[0];
  const nomeLogado = (removeAccents(nomeLogado_3) + "_" + cli_id).toLowerCase();

  const createUser = async (username, email, tel) => {
    try {
      const user = await api2.createUser(username, email, tel);
      if (user.status) {

        // Atualizar o valor da sessionStorage para a lista dos usuarios do CometChat
        
        api2.getUsers().then((data) =>{
          if (data.data){
            let usersComet = JSON.stringify(data.data);
            sessionStorage.setItem("usersComet", usersComet)
          } else {
            console.log("erro ao carregar usurio do cometchat")
          }
        }).catch((error) => {
          console.error("Error ao carregar os dados", error);
        });
        
        return true;
      } else {
        console.log("usuario já esta criado");
        return true;
      }
    } catch (error) {
      console.log("erro ao criar usuário", error);
      return false;
    }
  };

  const users = async () => {
    try {
      let response = sessionStorage.getItem("usersComet")
      setUsersComet(JSON.parse(response));
    } catch (error) {
      console.error("problema de recuperar usuarios", error);
    }
  };

  useEffect(() => {
    users();
  }, []);

  const verifyUser = (username) => {
    return usersComet.some((user) => user.name === username);
  };

  const fetchData = useCallback(async () => {
    try {
      if (!verifyUser(nomeLogado)) {
        const created = createUser(nomeLogado, emailLogado, telLogado);

        if (created) {
          console.log("usuário criado com sucesso");
        } else {
          console.log("erro ao criar usuário, não é possível fazer login ");
          return;
        }
      }
    } catch (error) {
      console.error("erro ao criar usuario com excessão", error);
    }
  }, [nomeLogado, emailLogado, telLogado]);

  useEffect(() => {
    fetchData();
  }, [usersComet]);

  useEffect(() => {
    async function fetchPerguntas() {
      try {
        const response = await api.verPerguntaForum();

        if (Array.isArray(response.data)) {
          setPerguntas(response.data);
        } else {
          console.error("API response is not an array:", response);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchPerguntas();
  }, []);

  useEffect(() => {
    const etapa_1 = sessionStorage.getItem("etapa_1");
    const etapa_2 = sessionStorage.getItem("etapa_2");
    const etapa_3 = sessionStorage.getItem("etapa_3");
    const etapa_4 = sessionStorage.getItem("etapa_4");
    const etapa_5 = sessionStorage.getItem("etapa_5");  

    if (
      etapa_1 === "true" &&
      etapa_2 === "true" &&
      etapa_3 === "true" &&
      etapa_4 === "true" &&
      etapa_5 === "true"
      ) {
        
        setShowModal(false);
      } else {
        setShowModal(true);
      }
    }, []);
    


  return (
    <div className="mt-5 pt-4">
      <Container>
        <Modal
          size="lg"
          show={showModal}
          backdrop="static"
          keyboard={false}
          className="tamanho-modal"
        >
          <Container className="text-center">
            <Row>
              <Col className="my-3">
                <Logo type="imagem3" />
              </Col>
            </Row>
          </Container>

          <RestCadastroAdvogadoForms  
          callBackCadastroRealizado={(data) => setShowModal(data)}
          />
        </Modal>
      
      </Container>

      <Container fluid className="fluid-bg ">
        <Header />
      </Container>

      <Container fluid className="fluid-bg">
        <Col className="d-none d-md-block">
          <ControlledCarousel />
        </Col>
      </Container>

      <Container fluid className="fluid-bg">
        <Col className="d-flex m-0 p-0 d-md-none">
          <ControlledCarouselMobile />
        </Col>
      </Container>

      <Container className="my-5 py-5">
        <HowItWorks />
      </Container>

      <Container className="my-5">
        <Row className="py-5">
          <Col className="d-lg-flex justify-content-center">
            <Col className="">
              <Subtitle text="Tire suas dúvidas com especialistas" />
            </Col>

            <Col className="">
              <Btn
                type="secondary"
                text="Envie sua pergunta"
                isSubmit={false}
                onTouchable={handleNavigate}
              />
            </Col>
          </Col>
        </Row>
        <Row>
          <Col>
            <VerPerguntaForumCli perguntas={perguntas} exibirBotoes={false} />
          </Col>
        </Row>
      </Container>

      <Container className=" my-5">
        <Col className="d-none d-md-block">
          <AdvsCarousel />
        </Col>
      </Container>

      <Container className=" my-5">
        <Col className="d-flex m-0 p-0 d-md-none">
          <AdvsCarouselMobile />
        </Col>
      </Container>

      <Container fluid className="fluid-bg ">
        <Footer />
      </Container>
    </div>
  );
};
