import React from "react";
import { Col, Container, Modal } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import PoliticaDePrivacidadeText from "../../atoms/PoliticaDePrivacidadeText";

const PoliticaPrivacidadeModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Container>
        <Modal.Header closeButton>
          <Col className="text-center">
            <Subtitle text="Política de Privacidade" />
          </Col>
        </Modal.Header>
        <Modal.Body className="mb-5 modal-scroll">
          <PoliticaDePrivacidadeText />
        </Modal.Body>
      </Container>
    </Modal>
  );
};

export default PoliticaPrivacidadeModal;
