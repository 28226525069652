import React, { useState } from "react";
import { Badge, Col, Container, Modal, Row } from "react-bootstrap";
import Btn from "../../atoms/Button";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import Subtitle from "../../atoms/Subtitle";
import Paragraph from "../../atoms/Paragraph";
import { Rating } from "react-simple-star-rating";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import "./styles.css";
import functions from "../../utils/functions";

export const AdvOpinioesModal = ({ show, onHide, data, opinioesAdv }) => {
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container className="d-flex flex-column ">
          <Col className="d-flex ms-4 mt-0 ">
            <Col sm={12} lg={2} className="">
              <AvatarPerfil
                base64={data.foto}
                type="B"
                width="7rem"
                display="none"
                height="7rem"
                padding="0.4rem"
              />
            </Col>
            <Col sm={12} lg={5} className="ps-lg-4">
              <Col sm={12} className="p-0 text-center text-lg-start">
                <Badge className="me-2 p-2 badge-bg mb-3">
                  {data.adv_cidade + "/" + data.adv_estado}
                </Badge>
                <Subtitle text={data.cliente_nome} />
                <Paragraph text={"OAB " + data.oab_numero} />
                <Col lg={6} className="d-flex align-items-center gap-2">
                  <Paragraph text={data.numero_estrelas} />
                  <Rating
                    readonly={true}
                    initialValue={data.numero_estrelas}
                    size={25}
                  />
                </Col>
              </Col>
            </Col>
          </Col>
          <Col className="d-flex flex-column justify-content-top align-items-center opinioes-scroll">
            {opinioesAdv && opinioesAdv.length > 0 ? (
              opinioesAdv.map((comentario, index) => (
                <Container>
                  <Col lg={12} className="border rounded px-2 pt-2">
                    <Col lg={12} className="d-flex  gap-3">
                      <Col lg={1}>
                        <AvatarPerfil
                          base64={comentario.fotoCliente} //TODO
                          type="C"
                          width="4rem"
                          display="none"
                          height="4rem"
                          padding="0.4rem"
                        />
                      </Col>
                      <Col lg={10}>
                        {!comentario.clienteAnonimo ? (
                          <Subtitle text={comentario.nomeCliente} />
                        ) : (
                          <Subtitle text="Anônimo" />
                        )}
                        <ParagraphSmall
                          text={functions.formatarDataParaString(
                            comentario.dataComentario
                          )}
                        />
                      </Col>
                    </Col>
                    <Col key={index} className="px-3 d-flex">
                      <Col>
                        <Paragraph
                          className="d-flex flex-column"
                          text={`${comentario.comentarioCliente}`}
                        />
                      </Col>
                    </Col>
                  </Col>
                </Container>
              ))
            ) : (
              <Paragraph text="Nenhuma opinião disponível." />
            )}
          </Col>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
