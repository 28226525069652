import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col } from "react-bootstrap";
import "./styles.css";
import NavitemSidebarAdm from "../../../atoms/NavitemSidebarAdm";
import AdmSiteClientes from "../../../organisms/AdmSiteClientes";
import AdmHeader from "../../../molecules/AdmHeader";

export const SiteClientesTemp = () => {
  const [sideNavExpanded, setSideNavExpanded] = useState(true);

  const toggleSideNav = () => {
    setSideNavExpanded(!sideNavExpanded);
  };

  return (
    <body className="m-0 p-0">
      <Container fluid className="fluid-bg">
        <AdmHeader />
      </Container>
      <Container fluid className="d-flex  mt-5">
        <Col
          className={`menu-position ${sideNavExpanded ? "" : "menu-fechado"}`}
        >
          <NavitemSidebarAdm
            menuAberto={sideNavExpanded}
            toggleMenu={toggleSideNav}
          />
        </Col>

        <Col className=" w-75 shadow-lg">
          <AdmSiteClientes />
        </Col>
      </Container>

      <Container></Container>
    </body>
  );
};
