import React, { useState, useEffect } from "react";
import "./styles.css";
import { Col, Modal, Form, Row, Container } from "react-bootstrap";
import Paragraph from "../../atoms/Paragraph";
import Btn from "../../atoms/Button";
import Subtitle from "../../atoms/Subtitle";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import { Rating } from "react-simple-star-rating";
import { Toasts } from "../../utils/Toasts";
import { ptBR } from "date-fns/locale";
import { AvaliacaoService } from "../../../services/ClienteServices/AvaliacaoService";


export const AvaliacaoCliModal = ({ advogadoDados, show, onHide }) => {

  const api = new AvaliacaoService();

  const [comentario, setComentario] = useState("");
  const [anonimo, setAnonimo] = useState(false);
  const [notas, setNotas] = useState([]);
  const [tipoAvaliacoes, setTipoAvaliacoes] = useState([]);
  const [rating, setRating] = useState(0);

  const [userData, setUserData] = useState();

  const fusoHorario = "Etc/UTC";
  const { parseISO, format } = require("date-fns");
  const { utcToZonedTime } = require("date-fns-tz");

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const formatDateTime = (dateString, timeString) => {
    if (!dateString || !timeString) {
      return;
    }
    const formataData = format(
      utcToZonedTime(parseISO(dateString), fusoHorario),
      "dd/MM/yyyy",
      { locale: ptBR }
    );

    const hours = timeString.slice(0, 2);
    const minutes = timeString.slice(2, 4);

    return `${formataData} - ${hours}:${minutes}`;
  };

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await api.listarTiposAvaliacao();
        setUserData(response);
        const tipoAvaliacaoIds = response.data.map((avaliacao) => avaliacao.tipoAvaliacaoId);
        setTipoAvaliacoes(tipoAvaliacaoIds);

        // Inicialize notas com um array de zeros com o mesmo comprimento que tipoAvaliacaoIds
        setNotas(Array(tipoAvaliacaoIds.length).fill(0));
      } catch (error) {
        console.log("Erro ao buscar dados da API:", error);
      }
    }

    fetchUserData();
  }, []);


  const handleRatingChange = (value, index) => {
    // Crie uma cópia do array de notas para evitar mutação direta do estado
    const newNotas = [...notas];
    newNotas[index] = value;
    setNotas(newNotas);
  };

  
  const handleEnviarAvaliacao = async () => {
    // Verifique se há algum campo de nota não preenchido
    if (notas.some((nota) => nota === 0)) {
      let optionsToast = {
        code: "102",
        message: "Por favor, preencha todas as avaliações do advogado.",
        visible: true,
      };
      setOptionsToast(optionsToast);
      return; 
    }
  
    if (comentario.length > 300) {
      let optionsToast = {
        code: "102",
        message: "O comentário não pode exceder 300 caracteres.",
        visible: true,
      };
      setOptionsToast(optionsToast);
      return; 
    }
  
    try {
      const response = await api.avaliacaoConsultaAdv(
        advogadoDados.consulta_id,
        notas,
        tipoAvaliacoes,
        comentario || null,
        anonimo
      );  
      let optionsToast = {
        code: response.mensagem.codigo,
        message: response.mensagem.mensagem,
        visible: true,
      };
      setOptionsToast(optionsToast);  
      setTimeout(() => {
        window.location.reload();

      }, 3100);
    } catch (error) {
      let optionsToast = {
        code: "102",
        message: "Não foi possível enviar sua avaliação, tente novamente.",
        visible: true,
      };
      setOptionsToast(optionsToast);
    }
  };


  return (
    <Modal show={show} onHide={onHide} size="medium">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
            <Container>
              <Row className="d-flex flex-row align-items-center">
                <Col lg={3}>
                  <AvatarPerfil
                    type="B"
                    base64={advogadoDados.foto_advogado}
                    width="6rem"
                    height="6rem"
                    padding="0.1rem"
                    margin="1px"
                  />
                </Col>
                <Col className="d-flex flex-column text-start">
                  <Col className="">
                    <Subtitle text={advogadoDados.nome_advogado} />
                    {formatDateTime(advogadoDados.data, advogadoDados.hora)}
                  </Col>
                </Col>
              </Row>
              <Row className="d-flex flex-column">
                <Col className="justify-content-center">
                  <Subtitle text="Avalie o advogado conforme os requisitos:" />
                </Col>

                <Col>
                  <Row className="d-flex flex-column">
                    {userData &&
                      userData.data &&
                      userData.data.map((avaliacao, index) => (
                        <Col key={avaliacao.tipoAvaliacaoId} className="d-flex gap-2">
                          <Rating
                            size={25}
                            initialValue={0}
                            defaultValue={notas[index]}
                            allowFraction={true}
                            onClick={(value) => handleRatingChange(value, index)}
                          />
                          <Col className="justify-content-start">
                            <Paragraph text={avaliacao.descricaoAvaliacao} />
                          </Col>
                        </Col>
                      ))}
                  </Row>
                </Col>

                <Col className="mt-3">
                  <Subtitle text="Comentário (Opcional)" />
                  <Col className="text-center">
                    <Form className="">
                      <Form.Group>
                        <Col className="text-center">
                          <Form.Control
                            as="textarea"
                            style={{ height: "12rem" }}
                            type="text"
                            name="comentario"
                            id="comentario"
                            value={comentario}
                            onChange={(e) => setComentario(e.target.value)}
                            placeholder="Neste campo você pode fornecer comentários adicionais
                      para justificar suas avaliações e oferecer sugestões/feedbacks."
                          />
                        </Col>
                      </Form.Group>
                    </Form>
                    <Row className="d-flex">
                      <Col className="text-start">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Desejo permanecer anônimo."
                          checked={anonimo}
                          onChange={(e) => setAnonimo(e.target.checked)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </Row>

              <Row className="">
                <Col className="d-flex justify-content-between">
                  <Btn type="terciary-sm" text="Fechar" onTouchable={onHide} />
                  <Btn
                    type="secondary-sm"
                    text="Enviar"
                    onTouchable={handleEnviarAvaliacao}
                  />
                </Col>
              </Row>
            </Container>
      </Modal.Body>
    </Modal>
  );
};
