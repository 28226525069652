import { Container, Row, Col, Form, FloatingLabel } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import { useEffect, useState } from "react";
import Paragraph from "../../atoms/Paragraph";
import Btn from "../../atoms/Button";
import { FinanceiroService } from "../../../services/ClienteServices/FinanceiroService";
import { Toasts } from "../../utils/Toasts";
import { Formik } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import functions from "../../utils/functions";
import DropdownBtnParcelas from "../../atoms/DropdownBtnParcelas";
import { SairAgendamentoModal } from "../SairAgendamentoModal";
import { useNavigate } from "react-router-dom";

export const PagamentoPlano = ({ adv_id, add_id, valorConsulta, changeStep }) => {
  const navigate = useNavigate();
  const id = sessionStorage.getItem("id");
  const api = new FinanceiroService();

  const [parcela, setParcela] = useState(1);
  const [qtdParcela, setQtdParcela] = useState([]);

  const [comentario, setComentario] = useState("");
  const [isLoading, setIsLoading] = useState();

  const [showModal, setShowModal] = useState(false);
  const [taxas, setTaxas] = useState(null);

  const [valorConsultaComTaxa, setValorConsultaComTaxa] = useState(0);
  const [taxaDeParcelamento, setTaxaDeParcelamento] = useState(0);
  const [valorParcela, setValorParcela] = useState(0);

  useEffect(() => {
    const fetchTaxas = async () => {
      try {
        const response = await api.taxasCredito();
        if (response && response.data && response.data.response) {
          setTaxas(response.data.response);
        } else {
          console.error("Resposta inválida da API de taxas:", response);
        }
      } catch (error) {
        console.error("Erro ao obter taxas:", error);
      }
    };

    fetchTaxas();
  }, []);

  const handleParcelaSelect = (parcela) => {
    if (taxas) {
      let valorConsultaComTaxa = 0;
      let novaTaxaDeParcelamento = 0;
      let novoValorParcela = 0;

      if (parcela === 1) {
        novaTaxaDeParcelamento = parseFloat(taxas.taxaParcelamento_1x);

        valorConsultaComTaxa =
          (parseFloat(valorConsulta) * parseFloat(taxas.taxaParcelamento_1x)) /
            100 +
          parseFloat(valorConsulta) +
          parseFloat(taxas.taxaOperacao);
      } else if (parcela >= 2 && parcela <= 6) {
        novaTaxaDeParcelamento = parseFloat(taxas.taxaParcelamento_2a6x);

        valorConsultaComTaxa =
          (parseFloat(valorConsulta) *
            parseFloat(taxas.taxaParcelamento_2a6x)) /
            100 +
          parseFloat(valorConsulta) +
          parseFloat(taxas.taxaOperacao);
      } else if (parcela >= 7 && parcela <= 12) {
        novaTaxaDeParcelamento = parseFloat(taxas.taxaParcelamento_7a12x);

        valorConsultaComTaxa =
          (parseFloat(valorConsulta) *
            parseFloat(taxas.taxaParcelamento_7a12x)) /
            100 +
          parseFloat(valorConsulta) +
          parseFloat(taxas.taxaOperacao);
      }

      novoValorParcela = valorConsultaComTaxa / parcela;
      valorConsultaComTaxa = parseFloat(valorConsultaComTaxa.toFixed(2));
      novoValorParcela = parseFloat(novoValorParcela.toFixed(2));

      setValorConsultaComTaxa(valorConsultaComTaxa);
      setTaxaDeParcelamento(novaTaxaDeParcelamento);
      setValorParcela(novoValorParcela);
    } else {
      console.error("Taxas não disponíveis no momento da seleção da parcela.");
    }
  };

  const handleQtdClick = (data) => {
    setParcela(data.value);
  };

  const generateQtdParcelaOptions = () => {
    const options = [];
    for (let i = 1; i <= 12; i++) {
      options.push({ label: `${i} parcela${i !== 1 ? "s" : ""}`, value: i });
    }
    setQtdParcela(options);
  };

  const enviarDados = () => {
    if (
      !comentario
    ) {
      let optionsToast = {
        code: "102",
        message: "Explicação sobre o caso é obrigatória!",
        visible: true,
      };
      setOptionsToast(optionsToast);
      return;
    }

    setIsLoading(true);
    api
      .realizarPagamentoPlano(
        id,
        adv_id,
        add_id,
        comentario
      )
      .then((data) => {
        if (data.status === true) {
          setIsLoading(false);
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);
          changeStep();
        } else {
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    generateQtdParcelaOptions();
  }, []);

  const handleBackPage = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const schema1 = Yup.object().shape({
    // telefone: Yup.string().required("O campo telefone é obrigatório"),
    // cpf: Yup.string()
    //   .test("valid-cpf", "CPF inválido", functions.validarCPF)
    //   .required("CPF é obrigatório"),
    // cep: Yup.string().required("O campo CEP é obrigatório"),
    // enderecoNumero: Yup.string().required("O campo número é obrigatório"),
    // cartaoNumero: Yup.string()
    //   .min(15, "Número do cartão inválido")
    //   // .max(16)
    //   .test(
    //     "valid-cartao-numero",
    //     "Número do cartão inválido",
    //     validaNumeroCartao
    //   )
    //   .required("O campo número do cartão é obrigatório"),
    // cartaoNome: Yup.string().required("O campo nome do cartão é obrigatório"),
    // dataExpiracao: Yup.string()
    //   .matches(/^\d{2}\/\d{2}$/, "Data de expiração inválida")
    //   .test(
    //     "valid-cartao-validade",
    //     "Data de expiração inválida",
    //     validaDataValidade
    //   )
    //   .required("O campo data de expiração é obrigatório"),
    // cartaoCcv: Yup.string()
    //   .min(3, "CVV inválido")
    //   .max(4)
    //   .required("O campo CVV do cartão é obrigatório"),
  });

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  function validaNumeroCartao(numero = "") {
    var soma = 0;

    //numero = numero.replace(/\D/g, "");

    var digitos = numero.split("").reverse();
    for (var i = 0; i < digitos.length; i++) {
      var digito = parseInt(digitos[i]);
      if (i % 2 !== 0) {
        digito *= 2;
        if (digito > 9) {
          digito -= 9;
        }
      }
      soma += digito;
    }
    return soma % 10 === 0;
  }

  function validaDataValidade(dataValidade = "") {
    var partes = dataValidade.split("/");
    var mes = parseInt(partes[0], 10);
    var ano = parseInt(partes[1], 10) + 2000; // Adiciona 2000 para converter o ano de dois dígitos em quatro dígitos

    var dataAtual = new Date();
    var mesAtual = dataAtual.getMonth() + 1; // Os meses são indexados a partir de 0 em JavaScript
    var anoAtual = dataAtual.getFullYear();

    // O cartão é válido se o ano for maior que o ano atual, ou se o ano for igual ao ano atual e o mês for maior ou igual ao mês atual
    return ano > anoAtual || (ano == anoAtual && mes >= mesAtual);
  }

  return (
    <Formik
      key={1}
      validationSchema={schema1}
      onSubmit={() => {


        // console.log(values)
        enviarDados();
      }}
      initialValues={{
        id: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleReset,
        handleBlur,
        values,
        touched,
        errors,
      }) => (
        <Container className="d-flex ">
          <Form noValidate onSubmit={handleSubmit} className="">
            {/* <Row className="d-felx align-items-center">
              <Col className="d-flex flex-column">
                <Col>
                  <Col
                    lg={6}
                    className="d-flex flex-column pb-2 bg-aviso-box rounded gap-2"
                  >
                    <Col className="d-flex ps-3 pe-3 mt-3 justify-content-between">
                      <Paragraph text="Consulta Via Plano Recorrente" />
                      {/* <Paragraph text={`R$ 1`} /> 
                    </Col>
                    <Col className="d-flex bg-warning p-3 rounded justify-content-between">
                      <Paragraph text="Valor da consulta" />
                      <Paragraph
                        text={`R$ ${
                          valorParcela ?? "-"
                        } `}
                      />
                    </Col>
                  </Col>
                </Col>
              </Col>
            </Row> */}

            <Row className="d-flex flex-column">
              <Col className="my-4">
                <hr className="border-warning border-3 mb-5 mt-5" />
                <Subtitle text="Explique sobre seu caso" />
                <Paragraph
                  text="Para melhor atendimento, precisamos entender o seu caso. 
                  Pedimos que descreva o seu caso, assim o seu advogado(a) terá mais informações para a sua consulta. "
                />
              </Col>
              <Col
                style={{ width: "100%", height: "200px", overflowY: "scroll" }}
              >
                <Form className="">
                  <Form.Group>
                    <Col className="text-center">
                      <Form.Control
                        as="textarea"
                        maxLength={1000}
                        style={{ height: "12rem" }}
                        type="text"
                        name="resposta"
                        value={comentario}
                        onChange={(e) => setComentario(e.target.value)}
                        placeholder="Digite aqui."
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </Col>

              <Col className="d-lg-flex column-gap-5 mt-5">
                <Btn
                  type={"terciary"}
                  isSumbit={false}
                  text="Cancelar"
                  onTouchable={handleBackPage}
                />
                <Btn
                  type={"secondary-load"}
                  isLoading={isLoading}
                  isSumbit={true}
                  text="Concluir Agendamento"
                />
              </Col>
            </Row>

            <Toasts
              code={optionsToast.code}
              message={optionsToast.message}
              visible={optionsToast.visible}
              setDefaultOptions={fecharToast}
            />
          </Form>
          <SairAgendamentoModal show={showModal} onHide={handleCloseModal} />
        </Container>
      )}
    </Formik>
  );
};
