import React, { useState, useEffect } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { HomeService } from "../../../services/HomeServices/HomeService";
import functions from "../../utils/functions";

const DropdownBtnParcelas = ({ text, options, onPress, defaultValue }) => {
  const [dados, setDados] = useState([]);
  const [selectedItem, setSelectedItem] = useState(defaultValue ?? null);

  useEffect((props) => {
    const API = new HomeService();
    API.getAll().then((data) => {
      if (data.status === true) {
        setDados(data);
      }
    });
  }, []);

  const handleItemClick = (option) => {
    setSelectedItem(option);
    onPress(option);
  };

  return (
    <div className="dropdown">
      <button
        className="drop-primario dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {selectedItem ? selectedItem.label : text}
      </button>

      <ul className="dropdown-menu custom-dropdown-menu">
        {options.map((option, index) => (
          <li
            className="item-combo "
            key={index}
            onClick={() => handleItemClick(option)}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownBtnParcelas;
