import React, { useEffect, useState } from "react";
import "./styles.css";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import AdvogadoCardHome from "../../molecules/AdvogadoCardHome";
import { HomeService } from "../../../services/HomeServices/HomeService";
import { Col } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";

export default function ParentComponent() {
  const api = new HomeService();
  const [advogados, setAdvogados] = useState([]);
  const [itemAtual, setItemAtual] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.carrosselAdvogados();

        if (Array.isArray(response.data)) {
          setAdvogados(response.data);
        } else {
          console.error("erro api:", response);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const hideFirstItem = currentIndex !== 0;

  function AdvogadosCarousel({ advogados }) {
    const slides = Array.from({ length: Math.min(6, advogados.length) }).map(
      (_, index) =>
        advogados
          .slice(index *2, index * 2 + 2)
          .map((advogado, innerIndex) => (
            <AdvogadoCardHome key={innerIndex} advogado={advogado}/>
          ))
    );

  function SlidesCarousel(){
    if(slides.length <= 2 ){

      const slides1 = slides.slice(0,1).map((slide, index) => (
        <Carousel.Item key={index}>
          <Col className="d-flex px-5 my-5 row justify-content-center">
          
            <Col lg={12} className="d-flex flex-column gap-5">
            {slide}
            </Col>
          
          </Col>
        </Carousel.Item>
      ));

      return slides1

    }else if(slides.length > 2 && slides.length <= 4){

      const slides1 = slides.slice(0,2).map((slide, index) => (
        <Carousel.Item key={index}>
          <Col className="d-flex px-5 my-5 row justify-content-center">
          
            <Col lg={12} className="d-flex flex-column gap-5">
            {slide}
            </Col>
          
          </Col>
        </Carousel.Item>
      ));

      return slides1;

    }else if(slides.length > 4){

      const slides1 = slides.slice(0,3).map((slide, index) => (
        <Carousel.Item key={index}>
          <Col className="d-flex justify-content-center">
            
            <Col lg={12} className="d-flex flex-column gap-5">
            {slide}
            </Col>
          
          </Col>
        </Carousel.Item>
      ));

      return slides1;
    }

  }

    return (
      <Container>
        <Subtitle text="Quem está no Faça Jus" />
        <Carousel
          data-bs-theme="dark"
          activeIndex={currentIndex}
          onSelect={(index) => setCurrentIndex(index)}
          className="d-flex px-5 my-5 justify-content-center"
        >
          {SlidesCarousel()}
        </Carousel>
      </Container>
    );
  }
  return <AdvogadosCarousel advogados={advogados} />;
}