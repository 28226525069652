import React, { useState } from "react";
import { CometChat } from "@cometchat-pro/chat";
import { CometChatMessages } from "../../../cometchat-chat-uikit-react/CometChatWorkspace/src/components/Messages";

export const ConferenciaCliente = () =>{

    const [chatWithGroup, setChatWithGroup] = useState('');
  
   
/** 
*  chat ou Video?
*/
   
    const renderTemp = () => {
        
            return
            <div>
                Cliente
            </div>
        
    };
        
    return (
        renderTemp()
    );
}
   