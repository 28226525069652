import React from "react";
import { useEffect, useState } from "react";
import { Breadcrumb, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import Paragraph from "../../atoms/Paragraph";
import { Form, FormControl } from "react-bootstrap";
import InputMask from "react-input-mask";
import Icons from "../../atoms/Icons";
import Btn from "../../atoms/Button";
import { RelatorioParticularService } from "../../../services/AdmFinanceiroServices/RelatorioParticularService";

export const AdmRelatorioParticularCancelados = () => {
  const api = new RelatorioParticularService();

  const [primeiroPeriodo, setPrimeiroPeriodo] = useState();
  const [segundoPeriodo, setSegundoPeriodo] = useState();
  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 1800);

      const response = await api.listarCancelamento(primeiroPeriodo, segundoPeriodo);
      setData(response);
    } catch (error) {
      console.error("Erro na chamada da API:", error);
    }
  };

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const exportToCSV = () => {
    console.log(" data aqui--->", data);
    const csvData = Object.values(data.data).map((item, index) => ({
      status: item.status,
      id_consulta:  item.id_consulta,
      valor_reembolsado: item.valor_reembolsado,
      nome_cliente: item.nome_cliente,
      cpf_cliente: item.cpf_cliente,
      nome_advogado: item.nome_advogado,
      cpf_advogado: item.cpf_advogado,
      valor_consulta: item.valor_consulta,
      qtd_parcelas: item.qtd_parcelas,     
    }));

    const csvContent = [
      [
        "Status",
        "ID Consulta",
        "Valor do Reembolso",
        "Nome Cliente",
        "CPF Cliente",
        "Nome Advogado",
        "Cpf do Advogado",
        "Valor Consulta",
        "Quantidade de Parcelas"
              ],
      console.log("csv data esta aqui ->", csvData),
      ...csvData.map((item) =>
        [
          item.status,
          item.id_consulta,
          item.valor_reembolsado,
          item.nome_cliente,
          item.cpf_cliente,
          item.nome_advogado,
          item.cpf_advogado,
          item.valor_consulta,
          item.qtd_parcelas         
        ].join(",")
      ),
    ].join("\n");

    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent)
    );
    link.setAttribute("download", "extrato.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      <Row className="d-flex justify-content-between">
        <Subtitle text="Relatório de Cancelamentos (Asaas):" />
        <Col lg={8} className="d-flex align-items-center gap-2">
          <Col lg={3}>
            <InputMask
              className="form-control"
              mask="99-99-9999"
              onChange={(e) => setPrimeiroPeriodo(e.target.value)}
              placeholder="Filtro por período"
            ></InputMask>
          </Col>

          <ParagraphSmall text="à" />

          <Col lg={3}>
            <InputMask
              className="form-control"
              mask="99-99-9999"
              onChange={(e) => setSegundoPeriodo(e.target.value)}
              placeholder="Filtro por período"
            ></InputMask>
          </Col>

          <Col lg={1} className="cursor">
            {loading ? (
              <Spinner
                style={{ width: "1.4rem", height: "1.4rem" }}
                animation="border"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <Icons type="buscar" onClick={fetchData} />
            )}
          </Col>
        </Col>  

          <Col lg={4} className="d-flex gap-2 ">
          <Col className="d-flex text-end align-items-center">
            <Col lg={10} className="">
              <Paragraph text="EXPORTAR DADOS" />
            </Col>
            <Col lg={2} className="cursor" onClick={exportToCSV}>
              <Icons type="exportar" />
            </Col>
          </Col>
        </Col>
      </Row>
       

      <Row className="d-flex flex-column">
        <Col
          className="overflow-auto d-flex  "
          // style={{ maxWidth: "50%"  }}
        >
          <Table striped hover className="">
            <thead>
              <tr>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="#" />
                </th>
                {/* <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Selecionar" />
                // </th> */}
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Status" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Consulta ID" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Valor Reembolso" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Nome Cliente" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="CPF Cliente" />
                </th>

                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Nome Advogado" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="CPF Advogado" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Valor Consulta" />
                </th>
                <th style={{ textAlign: "start", verticalAlign: "middle" }}>
                  <Paragraph text="Quantidade Parcelas" />
                </th>
              </tr>
            </thead>
            <tbody>
              {data && Object.values(data.data).length === 0 ? (
                <tr>
                  <td colSpan="20">Nenhum dado disponível.</td>
                </tr>
              ) : (
                data &&
                Object.values(data.data).map((item, index) => (
                  <tr key={index} className="align-items-center">
                    <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                      {index + 1}
                    </td>
                    {/* <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                      <div className="d-flex justify-content-start m-0 p-0">
                        <Form.Check type="switch" id="custom-switch" />
                      </div>
                    </td> */}
                    <td style={{ textAlign: "start", verticalAlign: "middle" , padding: "13px"}} >
                      <ParagraphSmall text={item.status} />
                    </td>
                    <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                      <ParagraphSmall text={item.id_consulta} />
                    </td>
                    <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                      <ParagraphSmall text={item.valor_reembolsado} />
                    </td>
                    <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                      <ParagraphSmall text={item.nome_cliente} />
                    </td>
                    <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                      <ParagraphSmall text={item.cpf_cliente} />
                    </td>

                    <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                      <ParagraphSmall text={item.nome_advogado} />
                    </td>
                    <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                      <ParagraphSmall text={item.cpf_advogado} />
                    </td>
                    <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                      <ParagraphSmall text={item.valor_consulta} />
                    </td>
                    <td style={{ textAlign: "start", verticalAlign: "middle" }}>
                      <ParagraphSmall text={item.qtd_parcelas} />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default AdmRelatorioParticularCancelados;
