import { useState, useEffect } from "react";
import "./styles.css";
import { Col, Container, Row } from "react-bootstrap";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import Icons from "../../atoms/Icons";
import Btn from "../../atoms/Button";
import Paragraph from "../../atoms/Paragraph";
import Subtitle from "../../atoms/Subtitle";
import { ConsultasService } from "../../../services/ClienteServices/ConsultasService";
import { CometChatSenderPollMessageBubble } from "../../../cometchat-chat-uikit-react/CometChatWorkspace/src/components/Messages";
import { ptBR } from "date-fns/locale";
import { AvaliacaoCliModal } from "../../molecules/AvaliacaoCliModal";

export const HistoricoConsultasCli = () => {
  const api = new ConsultasService();
  const cacCliId = sessionStorage.getItem("id");

  const fusoHorario = "Etc/UTC";
  const { parseISO, format } = require("date-fns");
  const { utcToZonedTime } = require("date-fns-tz");
  
  const now = new Date();
  const dataAtual = format(now, 'yyyy-MM-dd')
  const horaAtual = format(now, 'HHmm')
  
  const [consultas, setConsultas] = useState([]);
  console.log("consultas aqui ->", consultas)
  const [visibleConsultas, setVisibleConsultas] = useState(4);
  const [advogadoDados, setAdvogadoDados] = useState([]);

  useEffect(() => {
    async function fetchConsultas() {
      try {
        const response = await api.historicoConsultasCli(cacCliId);
        setConsultas(response);
      } catch (error) {
        console.error(error);
      }
    }
    fetchConsultas();
  }, [cacCliId]);

  const exportToCSV = () => {
    const csvData = consultas.map((consulta) => {
      return {
        Nome: consulta.cliente_nome,
        Data: new Date(consulta.adv_data).toLocaleDateString("pt-BR"),
        Hora: new Date(consulta.adv_hora).toLocaleTimeString("pt-BR"),
      };
    });

    const csvContent = [
      ["Nome", "Data", "Hora"],
      ...csvData.map((item) => [item.Nome, item.Data]),
    ]
      .map((row) => row.join(","))
      .join("\n");

    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent)
    );
    link.setAttribute("download", "extrato.csv");
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatDateTime = (dateString, timeString) => {
    const formataData = format(
      utcToZonedTime(parseISO(dateString), fusoHorario),
      "dd/MM/yyyy",
      { locale: ptBR }
    );

    const hours = timeString.slice(0, 2);
    const minutes = timeString.slice(2, 4);

    return `${formataData} - ${hours}:${minutes}`;
  };

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (consulta) => {
    setAdvogadoDados(consulta);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Container>
      <AvaliacaoCliModal
        show={showModal}
        onHide={handleCloseModal}
        advogadoDados={advogadoDados}
      />
      <Row className="d-flex align-items-center justify-content-center extrato-container">
        <Col>
          <Subtitle text="Histórico de consultas" />
        </Col>
        <Col className="d-flex text-end align-items-center">
          <Col lg={10} className="cursor" onClick={exportToCSV}>
            <Paragraph text="EXPORTAR DADOS" />
          </Col>
          <Col lg={2} className="cursor" onClick={exportToCSV}>
            <Icons type="exportar" />
          </Col>
        </Col>
      </Row>
      <Row className="overflow-historico">
        <Col lg={12} className="d-flex row justify-content-center">
          {consultas.map((consulta, index) => (
            <Row lg={12} key={index} className="w-75 d-flex align-items-center">
              { dataAtual > consulta.data && consulta.ativo == true || (dataAtual == consulta.data && horaAtual > consulta.hora) && consulta.ativo == true  ? (
                <>
                  <Col lg={3} className="text-center">
                    <AvatarPerfil
                      type="B"
                      base64={consulta.foto_advogado}
                      width="4rem"
                      height="4rem"
                      padding="0.1rem"
                      margin="1px"
                      display="none"
                    />
                  </Col>
                  <Col lg={4} className="">
                    <Col>{consulta.nome_advogado}</Col>
                  </Col>
                  <Col lg={3} className="">
                    {formatDateTime(consulta.data, consulta.hora)}
                  </Col>
                  <Col lg={2} className="text-center">
                    {consulta.avaliacao_existente ? (
                      // Se avaliacao_existente for true, não exibe o botão "Avaliar"
                      <span>Advogado já avaliado.</span>
                    ) : (
                      // Se avaliacao_existente for false, exibe o botão "Avaliar"
                      <Btn
                        text="Avaliar"
                        type="secondary-sm"
                        onTouchable={() => handleOpenModal(consulta)}
                      />
                    )}
                  </Col>
                  <hr />
                </>
              ) : null}
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default HistoricoConsultasCli;
