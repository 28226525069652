import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { VideoConferenciaService } from "../../services/VideoConferenciaServices/VideoConferenciaService";
import { CometChat } from "@cometchat-pro/chat";
import { useParams } from 'react-router-dom';
import { ConferenciaCliente } from '../../components/templates/ConferenciaCliente';
import { ConferenciaAdvogado } from '../../components/templates/ConferenciaAdvogado';
import { NotFound } from '../NotFound';
// import { UIKitSettingsBuilder, } from '@cometchat/uikit-shared';
// import { CometChatUIKit } from '../../cometchat-chat-uikit-react';

//==================================================================
// import ZoomVideo from '@zoom/videosdk';
// import uitoolkit from '@zoom/videosdk-ui-toolkit';
// import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css';

// var client = ZoomVideo.createClient()
// var stream

// await client.init('en-US', 'Global', { patchJsMedia: true })
// await client.join('sessionName', 'VIDEO_SDK_JWT', 'userName', 'sessionPasscode')

// stream = client.getMediaStream()
//==================================================================

export const VideoConferencia = () =>{

    // const api = new VideoConferenciaService();

    const api = useMemo(() => new VideoConferenciaService(), []);
    const params = useParams();
    const {typeUser} = params;
    
    const [appId , setAppId] = useState('');
    const [region , setRegion] = useState('');
    const [authKey , setAuthKey] = useState('');
    const [userLogged, setUserLogged] = useState(false); 

    /**
     * recuperar usuario(adv ou cli) no sistema facJ
     * criar Id com o nome dele no comet se não existe
     * Só o adv cria o grupo com  nome ???? ## consulta_horario_cli_adv_nome
     * verificar se é para abrir uma sala video ou uma sala do chat 
     * 
     */


    const fetchData = useCallback(async () => {
        try {
            const response = await api.getLinks();

            setAppId(response.app_id);
            setRegion(response.region);
            setAuthKey(response.authKey);
        } catch (error) {
            console.error('problema em recuperar os dados', error);
        }
    }, [api]);

    const initializeCometChat = useCallback(() => {
        //create the builder
        const UIKitSettings = new CometChat.AppSettingsBuilder()
        .setRegion(region)
        .subscribePresenceForAllUsers()
        .build();

        /* -------------- Verificar o Usuario ou criar o usuario para adicionar ao grupo---------------------
            ---
        */

        /* -------------- Criar Grupo  -----------------------------
        
        */

        
        // CometChatUIKit.init(UIKitSettings)
        CometChat.init(appId, UIKitSettings)
            .then(() => {
                console.log("Initialização completada com sucesso");

                if(CometChat.setSource) {
                    CometChat.setSource("ui-kit", "web", "reactjs");
                  }

                if (!userLogged) {
                    CometChat.login('francisco', authKey)
                    .then(
                        user => {
                            console.log("Login com sucesso:", { user });
                            setUserLogged(true);
                        },
                        error => {
                            console.log("Login falhado com exeção :", { error });
                        }
                    );
                }
        })
        .catch(error => {
            console.log("inicialização falhada com exeção:", { error });
        });

    }, [appId, region, authKey, userLogged]);



    useEffect(() => {
        fetchData();
       
        initializeCometChat();
      }, [appId, region, authKey, userLogged,initializeCometChat,fetchData]);
   

    const renderTemp = () => {
        
            switch (typeUser.toLowerCase()) {
                case "adv":
                  return userLogged? (<ConferenciaAdvogado 
                    salaVideo={true}
                    salaChat={false}
                    nomeGrupo={''}
                  />) : null;
                case "cli":
                  return <ConferenciaCliente />;
                default:
                  return <NotFound />;
              }
        
    };
        
    return (
        renderTemp()
    );
}

