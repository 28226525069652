import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.css";
import Logo from "../../atoms/Logo";
import { Link } from "react-router-dom";
import { LoginAdvogadoForms } from "../../organisms/LoginAdvogadoForms";
import imagem from "../../../img/banner-login.png";
import { Image } from "react-bootstrap";

export const LoginAdvogado = () => {
  return (
    <Container fluid className=" margem-zero">
      <Row className="">
        <Col className="p-0 m-0">
          <Image className=" imagem-div-login-2" src={imagem} />
        </Col>
        <Col lg={6} className="text-center mt-4">
          <Link to="/">
            <Logo type="imagem3" />
          </Link>
          <LoginAdvogadoForms />
        </Col>
      </Row>
    </Container>
  );
};
