import React from "react";
import { Form } from "react-bootstrap";
import "./styles.css";

export const removeNonNumericChars = (str) => {
  return str.replace(/\D/g, "");
};

export const formatTelefone = (phoneNumber) => {
  const cleanedNumber = removeNonNumericChars(phoneNumber);
  if (cleanedNumber.length === 11) {
    return `(${cleanedNumber.slice(0, 2)}) ${cleanedNumber.slice(
      2,
      7
    )}-${cleanedNumber.slice(7)}`;
  } else {
    return cleanedNumber;
  }
};

const BarraPesquisaNumero = ({ value, onChange }) => {
  const handleChange = (e) => {
    const rawValue = e.target.value;
    const numericValue = removeNonNumericChars(rawValue);
    const formattedValue = formatTelefone(rawValue);
    onChange({ rawValue: numericValue, formattedValue });
  };

  return (
    <Form.Control
      type="text"
      value={value.formattedValue || ""}
      onChange={handleChange}
      placeholder="Pesquisar Nº"
    />
  );
};

export default BarraPesquisaNumero;
