import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { DashboardService } from "../../../services/AdmDashboardServices/DashboardService";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Bar } from "react-chartjs-2";
import Icons from "../../atoms/Icons";
import DropdownBtn from "../../atoms/DropdownBtn";
import Subtitle from "../../atoms/Subtitle";
import ParagraphSmall from "../../atoms/ParagraphSmall";

Chart.register(CategoryScale);

const AdmGraficoFaturamento = () => {
  const api = new DashboardService();
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(null);

  const [selectedPeriod, setSelectedPeriod] = useState(1);

  const periodOptions = [
    { desc: "1 mês atrás", id: 1 },
    { desc: "3 meses atrás", id: 3 },
    { desc: "6 meses atrás", id: 6 },
    { desc: "9 meses atrás", id: 9 },
    { desc: "12 meses atrás", id: 12 },
  ];

  const handlePeriodChange = (value) => {
    setSelectedPeriod(value);
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    fetchData();
  }, [selectedPeriod]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await api.grafico(selectedPeriod); // Passando o objeto selectedPeriod inteiro
      if (response.status) {
        let data = {
          labels: response.data.periodos,
          datasets: [
            {
              label: "Faturamento Particular",
              data: response.data.faturamentoParticular,
              backgroundColor: "rgba(253, 198, 40, 0.80)",
              borderColor: "rgba(248, 187, 14, 0.86)",
              borderWidth: 1,
              borderRadius: 6,
            },
            {
              label: "Faturamento Consignado",
              data: response.data.faturamentoConsignado,
              backgroundColor: "rgba(159, 160, 165, 0.86)",
              borderColor: "rgba(114, 114, 115, 0.86)",
              borderWidth: 1,
              borderRadius: 6,
            },
            {
              label: "Total",
              data: response.data.faturamentoTotal,
              backgroundColor: "rgba(48, 89, 166, 0.55",
              borderColor: "rgba(27, 66, 139, 0.89)",
              borderWidth: 1,
              borderRadius: 6,
            },
          ],
        };
        setChartData(data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Erro na chamada da API:", error);
    }
  };

  return (
    <Container fluid>
      <Row className="">
        <Col className="d-flex align-items-center gap-2 m-0 p-0 ps-1">
          <Col>
            <Subtitle text="Gráfico faturamento total:" />
          </Col>
          <Col className="d-flex align-items-center justify-content-end gap-2">
            {loading ? (
              <Spinner
                style={{ width: "1.4rem", height: "1.4rem" }}
                animation="border"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <div></div>
            )}
            <Col>
              <DropdownBtn
                options={periodOptions}
                text={"Selecione o periodo"}
                onPress={(selectedPeriodo) =>
                  handlePeriodChange(selectedPeriodo.id)
                }
              />
            </Col>
          </Col>
        </Col>

        {chartData && <Bar data={chartData} options={options} />}
      </Row>
    </Container>
  );
};

export default AdmGraficoFaturamento;
