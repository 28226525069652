import React from 'react';
import { Form } from 'react-bootstrap';
import './styles.css';

const BarraPesquisaData = ({ value, onChange }) => {
  return (
    <Form.Control
      type="date"
      value={value}
      onChange={onChange}
      placeholder="Pesquisar por Data"
    />
  );
};

export default BarraPesquisaData;
