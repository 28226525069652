import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "../../molecules/Header";
import ControlledCarousel from "../../organisms/ControlledCarousel";
import HowItWorks from "../../organisms/HowItWorks";
import AdvsCarousel from "../../organisms/AdvsCarousel";
import AdvsCarouselMobile from "../../organisms/AdvsCarouselMobile"
import Footer from "../../molecules/Footer";
import SearchHome from "../../organisms/SearchHome";
import { Toasts } from "../../utils/Toasts";
import Subtitle from "../../atoms/Subtitle";
import Btn from "../../atoms/Button";
import { useNavigate } from "react-router-dom";
import { VerPerguntaForumCli } from "../../molecules/VerPerguntaForumCli";
import { faleConoscoService } from "../../../services/FaleConoscoServices/FaleConoscoServices";
import ControlledCarouselMobile from "../../organisms/ControlledCarouselMobile";

const api = new faleConoscoService();
console.log ("VERSÃO >>>>>>>> Fase - 15/05/24 - v.0.1");
export const HomeTemp = () => {
  const [perguntas, setPerguntas] = useState([]);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/Forum");
  };

  useEffect(() => {
    async function fetchPerguntas() {
      try {
        const response = await api.verPerguntaForum();

        if (Array.isArray(response.data)) {
          setPerguntas(response.data);
        } else {
          console.error("API response is not an array:", response);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchPerguntas();
  }, []);

  return (
    <div className="mt-5 pt-4">
      <Container fluid className="fluid-bg ">
        <Header />
      </Container>

      <Container fluid className="fluid-bg">
        <Col className="d-none d-md-block">
          <ControlledCarousel />
        </Col>
      </Container>

      <Container fluid className="fluid-bg">
        <Col className="d-flex m-0 p-0 d-md-none">
         <ControlledCarouselMobile />
        </Col>
      </Container>

      <Container>
        <SearchHome />
      </Container>

      <Container className="my-5 ">
        <HowItWorks />
      </Container>

      <Container className="my-5">
        <Row className="py-5">
          <Col className="d-lg-flex justify-content-center">
            <Col className="">
              <Subtitle text="Tire suas dúvidas com especialistas" />
            </Col>

            <Col className="">
              <Btn
                type="secondary"
                text="Envie sua pergunta"
                isSubmit={false}
                onTouchable={handleNavigate}
              />
            </Col>
          </Col>
        </Row>
        <Row>
          <Col>
            <VerPerguntaForumCli perguntas={perguntas} exibirBotoes={false} />
          </Col>
        </Row>
      </Container>

      <Container className=" my-5">
        <Col  className="d-none d-md-block">
          <AdvsCarousel />
        </Col>
      </Container>

      <Container className=" my-5">
        <Col className="d-flex m-0 p-0 d-md-none">
          <AdvsCarouselMobile/>
        </Col>
      </Container>

      
    <p>
      i
    </p>
      <Container fluid className="fluid-bg ">
        <Footer />
      </Container>
    </div>
  );
};
