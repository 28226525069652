import api from "../Api";

export class UserService {

  async validarEmail(token) {
    return await api
      .get(`/web/autentica/email-advogado/${token}`, {
        params: { token }, // Use "params" para passar os parâmetros
        headers: {
          "Content-type": "application/json",
          // Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async validarEmailCliente(token) {
    return await api
      .get(`/web/autentica/email-cliente/${token}`, {
        params: { token }, // Use "params" para passar os parâmetros
        headers: {
          "Content-type": "application/json",
          // Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }


  async postRedefinirSenha(senha,confirma_senha,token) {
    return await api
      .post(
        "/user/reseta-senha-usuario",
        {
          senha: senha,
          confirma_senha: confirma_senha,
          token: token
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async alterarSenhaUsuario(cpf,senha,confirma_senha) {
    return await api
      .post(
        "/web/usuario/altera-senha-usuario",
        {
          cpf: cpf,
          senha: senha,
          confirma_senha: confirma_senha
        },
        {
          headers: {
            "Content-type": "application/json",
            // Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async enviaCodigoRedefinirSenha(cpf) {
    return await api.post("/web/usuario/envia-codigo-redefinir-senha", { cpf },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }).then((res) => res.data)
      .catch((error) => {
        console.log(error);
      });
  }

  async postValidaCodigoVerificador(id, codigo) {
    return await api.post("/web/usuario/valida-codigo-senha", { id, codigo },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }).then((res) => res.data)
      .catch((error) => {
        console.log(error);
      });
  }


  async postEnviarEmail(email) {
    return await api
      .post(
        "/user/envia-email-reseta-senha",
        {
          email: email
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }


  async reenviarEmailValidacao(email) {
    return await api
      .post(
        "/user/reenvia-email-validacao",
        {
          email: email
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
}