import React, { useEffect, useState } from 'react';
import './styles.css'

const Switch = ({ firstOption, secondOption, onSelected }) => {

  const [selectedOption, setSelectedOption] = useState(firstOption);

  useEffect(() => {
    onSelected(selectedOption)
  }, [selectedOption])

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onSelected(option)
  };

  return (
    <div className="switch-container">
      <div
        className={`switch-option ${selectedOption === firstOption ? 'selected' : ''}`}
        onClick={() => handleOptionClick(firstOption)}
        >
        {firstOption}
      </div>
      <div
        className={`switch-option ${selectedOption === secondOption ? 'selected' : ''}`}
        onClick={() => handleOptionClick(secondOption)}
        >
        {secondOption}
      </div>
    </div>
  );
};

export default Switch;
