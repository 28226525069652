import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  ModalFooter,
  Card,
  Modal,
  Form,
  FloatingLabel,
  Badge,
} from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import Paragraph from "../../atoms/Paragraph";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import "./styles.css";
import * as yup from "yup";
import Btn from "../../atoms/Button";
import { faleConoscoService } from "../../../services/FaleConoscoServices/FaleConoscoServices";
import { VerRespostaForumCli } from "../VerRespostaForumCli";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import { Rating } from "react-simple-star-rating";
import { Field, Formik } from "formik";
import { useNavigate } from 'react-router-dom';

export const VerPerguntaForumCli = ({ perguntas, exibirBotoes, onClose }) => {
  const api = new faleConoscoService();
  const navigate = useNavigate();

  const [perguntasVisiveis, setPerguntasVisiveis] = useState(3);

  const [showRespostas, setShowRespostas] = useState(false);
  const [arrayRespostas, setArrayRespostas] = useState([]);

  const [perguntaId, setIdPergunta] = useState(null);

  const [showFormResp, setShowFormResp] = useState(false);
  // const [resposta, setResposta] = useState(null)
  const id = sessionStorage.getItem("id");

  const schema = yup.object().shape({
    resposta: yup.string().max(1000, 'A resposta deve ter no máximo 1000 caracteres').required('A resposta é obrigatória'),
  });


  // const id = sessionStorage.getItem('id');
  // const [idPergunta, setIdPergunta] = useState(null);
  const [mensagem, setMensagem] = useState(null);

  const exibirMaisPerguntas = () => {
    setPerguntasVisiveis(perguntasVisiveis + 3);
  };

  const exibirMenosPerguntas = () => {
    setPerguntasVisiveis(Math.max(perguntasVisiveis - 3, 3));
  };

  const respondePergunta = (idPergunta) => {
    setIdPergunta(idPergunta);
    setShowModal(true);
  };

  const handleSubmitResponder = async (idPergunta, resposta) => {
    await api
      .postEnviaRespostaForum(id, idPergunta, resposta)
      .then((data) => {
        if (data.status === true) {
          setShowModal(false);
        } else {
          setShowModal(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [showModal, setShowModal] = useState(false);

  const handleSubmitRespostas = async (idPergunta) => {
    setIdPergunta(idPergunta);
    setShowRespostas(true);
    await api
      .postVerRespostaForum(idPergunta)
      .then((data) => {
        if (data.status === true) {
          setArrayRespostas(data.data);
          setShowModal(false);
        } else {
          console.log(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleClick = (idCliente) => {
  //   navigate(`/ResultadoSearch/${idCliente}`);
  // };

  const perguntasReversas = [...perguntas].reverse();
  return (
    <Container>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Container className="text-center">
          <Modal.Header closeButton>
            <Col className="text-center pt-1">
              <Subtitle text="Escreva a sua resposta. " />
            </Col>
          </Modal.Header>

          <Modal.Body className>
            <Formik
              key={1}
              validationSchema={schema}
              onSubmit={(values) =>
                handleSubmitResponder(perguntaId, values.resposta)
              }
              initialValues={{
                resposta: "",
              }}
            >
              {({ handleChange, handleSubmit, touched, errors, values }) => (
                <Form className="" onSubmit={handleSubmit}>
                  <Form.Group>
                    <Col className="text-center">
                      <Form.Control
                        as="textarea"
                        maxLength={1000}
                        style={{ height: "12rem" }}
                        type="text"
                        name="resposta"
                        value={values.resposta}
                        onChange={handleChange}
                        isValid={touched.resposta && !errors.resposta}
                        placeholder="Digite aqui."
                      />

                      {touched.resposta && errors.resposta && (
                        <Form.Control.Feedback type="invalid">
                          {errors.resposta}
                        </Form.Control.Feedback>
                      )}
                      <Form.Control.Feedback type="invalid">
                        {errors.resposta}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Col className="mt-2">
                    <Btn type={"secondary"} isSumbit={true} text="Enviar" />
                  </Col>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Container>
      </Modal>
      <Row className="gap-2">
        {perguntasReversas.slice(0, perguntasVisiveis).map((pergunta) => (
          <Col lg={12} key={pergunta?.id_pergunta} className=" ">
            <Col
              lg={8}
              className="d-flex  justify-content-between align-items-center "
            >
              <Col lg={12} className="px-lg-5 py-lg-4 bg-pergunta">
                <Row className="align-items-center">
                  <Col lg={6}>
                    <ParagraphSmall text={pergunta?.dataHora} />
                  </Col>
                  <Col lg={6} className="text-end">
                    <Badge bg="secondary" className="m-1 px-4 py-2">
                      {pergunta?.areaAtuacaoTitulo}
                    </Badge>
                  </Col>
                </Row>
                <Paragraph text={pergunta?.mensagem} />
              </Col>

              <Col lg={2} className=" text-center">
                <Col>
                  <AvatarPerfil
                    type="B"
                    width="4rem"
                    display="none"
                    height="4rem"
                    padding="0.4rem"
                  />
                </Col>
                <Col className="d-flex justify-content-center">
                  <ParagraphSmall text="Usuário" />
                </Col>
              </Col>
            </Col>
            <Col className="d-flex gap-2 ">
              {exibirBotoes && (
                <>
                  <Btn
                    type={"primary-sm"}
                    text="Responder"
                    isSumbit={false}
                    // onTouchable={() => setIdPergunta(pergunta.idPergunta)}
                    onTouchable={() => respondePergunta(pergunta.idPergunta)}
                  />
                </>
              )}
              <Btn
                type={"terciary-sm"}
                text="Ver respostas"
                isSubmbit={false}
                // onTouchable={-------}
                onTouchable={() => handleSubmitRespostas(pergunta.idPergunta)}
              />
            </Col>

            {pergunta.idPergunta === perguntaId ? (
              <Col lg={12}>
                {showRespostas ? (
                  <Card className="scrollable-card-container ">
                    {arrayRespostas.length > 0 ? (
                      arrayRespostas
                        .slice()
                        .reverse()
                        .map((resposta) => (
                          <Col
                            className="d-lg-flex gap-2 align-items-center px-4 m-0"
                            key={resposta.id}
                          >
                            <Col
                              // onClick={() => handleClick(resposta.idCliente)}
                              lg={5}
                              className="d-flex align-items-center"
                            >
                              <Col lg={3} className=" ">
                                <AvatarPerfil
                                  base64={resposta.foto}
                                  type="B"
                                  width="6rem"
                                  display="none"
                                  height="6rem"
                                  padding="0.4rem"
                                />
                              </Col>
                              <Col className="p-lg-2">
                                <Col className="p-0  text-lg-start">
                                  <Subtitle text={resposta.nomeAdvogado} />
                                  <Paragraph
                                    text={"OAB " + resposta.numeroOab}
                                  />
                                  <Rating
                                    readonly={true}
                                    initialValue={resposta.numeroEstrelas}
                                    size={25}
                                  />
                                </Col>
                              </Col>
                            </Col>
                            <Col
                              lg={7}
                              sm={12}
                              className=" resposta-forum-border"
                            >
                              <Paragraph text={resposta.resposta} />
                            </Col>
                          </Col>
                        ))
                    ) : (
                      <Col
                        lg={10}
                        className="d-flex align-items-center m-2 p-4 resposta-forum-border"
                      >
                        <Paragraph text="Ainda não há respostas para esta pergunta." />
                      </Col>
                    )}
                    <Col lg={6}></Col>
                  </Card>
                ) : (
                  <></>
                )}
              </Col>
            ) : (
              <></>
            )}
          </Col>
        ))}
      </Row>
      <Row>
        <Col className="mt-5 gap-2 d-flex justify-content-start">
          {perguntasVisiveis < perguntasReversas.length && (
            <Btn
              type={"secondary-sm"}
              text="Ver mais"
              isSumbit={false}
              onTouchable={exibirMaisPerguntas}
            />
          )}
          {perguntasVisiveis > 3 && (
            <Btn
              type={"secondary-sm"}
              text="Ver menos"
              isSumbit={false}
              onTouchable={exibirMenosPerguntas}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};
