import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Navbar, Nav, NavDropdown, Button, Offcanvas } from "react-bootstrap";

const NavItem = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  return (
    <>
      {/* Navbar for large screens (desktop) */}
      <Navbar expand="lg" className="menu-home d-none d-lg-flex ">
        <Nav className="d-flex align-items-center gap-3   ">
          <Nav.Link className="px-2" href="/">
            Início
          </Nav.Link>
          <Nav.Link  className="px-2" href="/forum">Pergunte a um Advogado</Nav.Link>
          <Nav.Link className="px-2" href="/faleconosco">Fale conosco</Nav.Link>
          <NavDropdown
            title="Crie seu Perfil"
            id="basic-nav-dropdown"
            className="me-2 px-2"
          >
            <NavDropdown.Item href="/Cadastro/Cli">
              Como cliente
            </NavDropdown.Item>
            <NavDropdown.Item href="/LandingPageAdv">
              Como advogado
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Nav>
          <NavDropdown
            title="Entrar"
            id="basic-nav-dropdown "
            className="border px-2 shadow-lg border-warning rounded "
          >
            <NavDropdown.Item href="/Login/Cli">Cliente</NavDropdown.Item>
            <NavDropdown.Item href="/Login/Adv">Advogado</NavDropdown.Item>
            {/* <NavDropdown.Item href="/adm/login">Administrador</NavDropdown.Item> */}
          </NavDropdown>
        </Nav>
      </Navbar>

      {/* Offcanvas for small screens (mobile) */}
      <div className="d-lg-none ">
        <Button variant="outline-dark " onClick={toggleOffcanvas} className="">
          Menu
        </Button>
        <Offcanvas
          className="bg-warning"
          show={showOffcanvas}
          onHide={() => setShowOffcanvas(false)}
          placement="end"
        >
          <Offcanvas.Header className="ms-4 " closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="bg-warning ">
            <Nav className="d-flex align-items-start row my-5 ps-5">
              <Nav.Link href="/">Início</Nav.Link>
              <Nav.Link href="/forum">Pergunte a um Advogado</Nav.Link>
              <Nav.Link href="/faleconosco">Fale conosco</Nav.Link>
              <NavDropdown
                title="Crie seu Perfil"
                id="basic-nav-dropdown"
                className="p-0 m-0"
              >
                <NavDropdown.Item href="/Cadastro/Cli">
                  Como cliente
                </NavDropdown.Item>
                <NavDropdown.Item href="/LandingPageAdv">
                  Como advogado
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Entrar"
                id="basic-nav-dropdown"
                className="p-0 m-0"
              >
                <NavDropdown.Item href="/Login/Cli">Cliente</NavDropdown.Item>
                <NavDropdown.Item href="/Login/Adv">Advogado</NavDropdown.Item>
                {/* <NavDropdown.Item href="/adm/login">Administrador</NavDropdown.Item> */}
              </NavDropdown>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default NavItem;
