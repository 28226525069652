import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../molecules/Header";

import "./styles.css";
import Footer from "../../molecules/Footer";
import Paragraph from "../../atoms/Paragraph";
import Title from "../../atoms/Title";
import TermoDeUsoText from "../../atoms/TermoDeUsoText";

export const TermosDeUso = () => {
  return (
    <body className="mt-5 pt-5">
      <Container fluid className="fluid-bg">
        <Header />
      </Container>

      <Container className="mt-5 pt-5 ">
        <Col  className="mx-5 px-5 ">
        <Title text="Termos de Uso" />
        </Col>
        <Col className="mx-5 px-5 my-5 termo-scrol shadow">
          <TermoDeUsoText />
        </Col>
      </Container>

      <Container fluid className="fluid-bg ">
        <Footer />
      </Container>
    </body>
  );
};
