import React, { useEffect, useState } from "react";
import "./styles.css";
import { Col, Container, Modal, Row, Form } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import { TecladoSenha } from "../TecladoSenha";
import { RelatorioConsignadoService } from "../../../services/AdmFinanceiroServices/RelatorioConsignadoService";
import { Toasts } from "../../utils/Toasts";
import Btn from "../../atoms/Button";
import Paragraph from "../../atoms/Paragraph";

export const AdmStatusConsignadoModal = ({show, onHide, arrayDeParcelas, cliId}) => {
  const api = new RelatorioConsignadoService();
  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const handleMudarStatus = () => {
    api.statusConsignado(arrayDeParcelas, cliId).then((response) => {
      let optionsToast = {
        code: response.mensagem.codigo,
        message: response.mensagem.mensagem,
        visible: true,
      };
      if (response.status) {
        setOptionsToast(optionsToast);
        setTimeout(() => {
          window.location.reload();
        }, 2500);
      } else {
        setOptionsToast(optionsToast);
      }
    }).catch((error) => {
      let optionsToast = {
        code: "102",
        message: "Nenhuma parcela selecionada",
        visible: true,
      };
      setOptionsToast(optionsToast);
    })
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Container>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className=" mb-5">
          <Col className="d-flex  align-items-center m-0 p-0">
            <Paragraph text="Tem certeza de que deseja alterar o status desta parcela?" />
          </Col>
          <Col className="justify-content-center d-flex pt-1 gap-2">
            <Btn
              type="terciary-sm"
              text="Voltar"
              onTouchable={onHide}
            />
            <Btn
              type="secondary-sm"
              text="Confirmar"
              onTouchable={handleMudarStatus}
            />
          </Col>
        </Modal.Body>
      </Container>
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
    </Modal>
  );
};
