import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import Btn from "../../atoms/Button";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import { ImgModal } from "../../atoms/ImgModal";
import { UserService } from "../../../services/UserServices/UserService";
import { Toasts } from "../../utils/Toasts";

export const EmailClienteModal = ({ }) => {

  const emailValidado = sessionStorage.getItem("emailValidado");

  const emailLogado = sessionStorage.getItem("email");

  let api = new UserService();
  
  const eventoLogout = () => {
    if (emailValidado === "false") {
      sessionStorage.clear();
      window.location.href = "/";
    }
  };

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }


  const reenviarEmail = async () => {
    try {
      const response = await api.reenviarEmailValidacao(
        emailLogado
      );
      if (response.status) {
        let optionsToast = {
          code: response.mensagem.codigo,
          message: response.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <Modal.Body>
      <Container className="text-center alinhamento-step">
        <Toasts
          code={optionsToast.code}
          message={optionsToast.message}
          visible={optionsToast.visible}
          setDefaultOptions={() => fecharToast()}
        />
        <Row className="d-flex justify-content-center">
          <Col lg={12} className="mb-5">
            <Subtitle
              text={`Enviamos um e-mail para 
                ${emailLogado} 
                com um link para validar a sua conta`}
            />
          </Col>
          <Col lg={8} className="  ">
            <ImgModal type="imagem3" />
          </Col>
          <Col lg={12} className="d-grid my-5 ">
            <Btn
              type={"secondary"}
              isSumbit={false}
              text="Ok, entendi"
              onTouchable={eventoLogout}
            />
          </Col>
          <Col lg={12} className="mb-5 ">
            <ParagraphSmall
              text="Verifique sua caixa de entrada, lembramos que o e-mail
              também pode ter ido para o Spam ou Lixo Eletrônico!"
            />
            <Col lg={12} className="text-center justify-content-center">
              <Col className="d-flex flex-row align-items-center gap-2">
                <ParagraphSmall text="Caso não tenha recebido o e-mail," />
                <Col lg={2} onClick={reenviarEmail} className="cursor underline-link">
                  <ParagraphSmall text="clique aqui." />
                </Col>
              </Col>
            </Col>
            <ParagraphSmall text="Precisando de ajuda? Entre em Contato através do suporte ao usuário." />
          </Col>
        </Row>
      </Container>
    </Modal.Body>
  );
};


