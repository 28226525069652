import React from "react";
import "./styles.css";

import myImage from "../../../../src/img/confia-logo.svg";
import myImage2 from "../../../../src/img/Modal-aviao.svg";
import myImage3 from "../../../../src/img/Modal-envelope.svg";

import resultado_busca from "../../../../src/img/resultado-busca.png";

import cartao_padrao from "../../../../src/img/Cartao-padrao.png";

import imgLpOne from "../../../../src/img/img-lp-1.png";
import imgLpTwo from "../../../../src/img/img-lp-2.png";
import imgLpThree from "../../../../src/img/img-lp-3.png";


export const ImgModal = ({ type }) => {
  let imagem = "";

  switch (type) {
    case "imagem1":
      imagem = myImage;
      break;
    case "imagem2":
      imagem = myImage2;
      break;
    case "imagem3":
      imagem = myImage3;
      break;
    case "cartao-padrao":
      imagem = cartao_padrao;
      break;
    case "resultado-busca":
      imagem = resultado_busca;
      break;

    case "imgLpOne":
      imagem = imgLpOne;
      break;

    case "imgLpTwo":
      imagem = imgLpTwo;
      break;

    case "imgLpThree":
      imagem = imgLpThree;
      break;
  }

  return <img src={imagem} alt="Logo" />;
};
