import functions from "../../components/utils/functions";
import api from "../Api";

export class AvaliacaoService {

    async avaliacaoConsultaAdv(
        adv_id,
        nota_avaliacao,
        tipo_avaliacao_id,
        consulta_cli_comentario,
        consulta_comentario_anonimo,
    ) {
        return await api.post("/cliente/avaliacao-consulta-adv", {
            "adv_id": adv_id,
            "nota_avaliacao": nota_avaliacao,
            "tipo_avaliacao_id": tipo_avaliacao_id,
            "consulta_cli_comentario": consulta_cli_comentario,
            "consulta_comentario_anonimo": consulta_comentario_anonimo,
        }, {
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            }
        })
            .then((res) => res.data)
            .catch((error) => {
                if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
                    functions.modalToken();
                }
            });
    }

    async listarTiposAvaliacao() {
        return await api.get("/cliente/listar-tipo-avaliacao", 
            {
                headers: {
                    "Content-type": "application/json",
                    Authorization: "Bearer " + sessionStorage.getItem("token"),
                }
            })
            .then((res) => res.data)
            .catch((error) => {
                if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
                    functions.modalToken();
                }
            });
    }

}