import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col } from "react-bootstrap";
import "./styles.css";
import NavitemSidebarAdm from "../../../atoms/NavitemSidebarAdm";
import AdmHeader from "../../../molecules/AdmHeader";
import { AdmGerencialAdvogadoDados, AdmGerencialUsuariosDados } from "../../../organisms/AdmSiteAdvogadoDados";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { AdvogadoService } from "../../../../services/AdmAdvogadoServices/AdvogadoService";

export const SiteAdvogadosValidarTemp = () => {
  const [sideNavExpanded, setSideNavExpanded] = useState(true);

  const api = new AdvogadoService();

  const toggleSideNav = () => {
    setSideNavExpanded(!sideNavExpanded);
  };
  const { id } = useParams(); // Acessa o ID da URL
  // console.log("ID recebido:", id); 

  // useEffect(() => {
  //   async function fetchPessoa() {
  //     try {
  //       const response = await api.getAdvogadoPorId(id);
  //       // Use os detalhes da pessoa retornados pela API
  //     } catch (error) {
  //       console.error("Erro ao buscar dados da API:", error);
  //     }
  //   }
  //   fetchPessoa();
  // }, [id]);

  return (
    <body className="m-0 p-0">
      <Container fluid className="fluid-bg">
        <AdmHeader />
      </Container>

      <Container fluid className="d-flex  mt-5">
        <Col
          className={`menu-position ${sideNavExpanded ? "" : "menu-fechado"}`}
        >
          <NavitemSidebarAdm
            menuAberto={sideNavExpanded}
            toggleMenu={toggleSideNav}
          />
        </Col>

        <Col className=" w-75 shadow-lg">
          <AdmGerencialAdvogadoDados id={id}/>
        </Col>
      </Container>

      <Container></Container>
    </body>
  );
};
