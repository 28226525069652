import React, { useState } from "react";
import "./styles.css";
import Carousel from "react-bootstrap/Carousel";
import mobile1 from "../../../../src/img/banner-home-preciso-de-um-advogado-mobile-02.07.png";
import mobile2 from "../../../../src/img/banner-home-opcao-pagamento01-mobile-02.07.png";
import mobile3 from "../../../../src/img/banner-home-opcao-pagamento02-mobile-02.07.png";
import mobile4 from "../../../../src/img/banner-home-saiba-mais-mobile-02.07.png";
import mobile5 from "../../../../src/img/banner-home-forum-mobile-02.07.png";
import mobile6 from "../../../../src/img/banner-home-localizacoes-convenios-mobile-02.07.png";
import TitleBanner from "../../atoms/TitleBanner";
import SubtitleBanner from "../../atoms/SubtitleBanner";
import Image from "react-bootstrap/Image";
import Btn from "../../atoms/Button";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function ControlledCarouselMobile() {
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const lpcarouselOne = () => {
    navigate(`/CarrosselLpOne`);
  };

  const lpcarouselTwo = () => {
    navigate(`/CarrosselLpTwo`);
  };

  const lpCadastroCliente = () => {
    window.location.href = "/Cadastro/Cli";
  };

  const lpForum = () => {
    window.location.href = "/Forum";
  };

  const carouselMobile = [
    {
      image: mobile1,
      text: <span>Seu caminho para a justiça começa com os <span style={{color:'#EEB20A'}}>nossos advogados</span></span>,
      buttonText: "Saiba mais",
      buttonHandler: lpcarouselTwo,
    },
    {
      image: mobile2,
      text: 'Parcele em até 12x no cartão de crédito:',
      subText: <span style={{color:'#EEB20A'}}>Encontre um advogado, agende sua consulta e resolva seus problemas jurídicos com praticidade e segurança.</span>,
    },
    {
      image: mobile3,
      text: <span>Vantagens exclusivas para o <span style={{color:'#EEB20A'}}>Servidor Público</span></span>,
      subText: <span style={{color:'#EEB20A'}}>Parcele através do crédito consignado sem burocracia.</span>,
      buttonText: "Preciso de um advogado",
      buttonHandler: lpcarouselOne,
    },
    {
      image: mobile4,
      text: "Conheça as facilidades do FaçaJus",
      subText: "Encontre o advogado ideal em poucos cliques",
      buttonText: "Preciso de um advogado",
      buttonHandler: lpForum,
    },
    {
      image: mobile5,
      text: <span>Descomplicando o seu <span style={{color:'#EEB20A'}}>acesso a advogados.</span></span>,
      subText: <span style={{color:'#EEB20A'}}>Participe do nosso fórum gratuito.</span>,
      buttonText: "Preciso de um advogado",
      buttonHandler: lpCadastroCliente,
    },
    {
      image: mobile6,
      text: 'Atenção servidores de Goiás e Sorocaba!',
      subText: <span>Seu atendimento pode ser pago através do crédito consignado! <br/><span style={{color:'#EEB20A'}}>Realize uma consulta ainda hoje e o desconto será na próxima folha de pagamento.</span></span>,

    },
  ];

  return (
    <div className="container-carousel-father">
      <div className="container-carousel">
        <Container fluid className="d-flex align-items-center">
          <Row>
            <Col className="d-flex align-items-center">
              <Carousel activeIndex={index} onSelect={handleSelect}>
                {carouselMobile.map((item, idx) => (
                  <Carousel.Item key={idx}>
                    <div className="carousel-item-container">
                      <Image
                        src={item.image}
                        alt={`Slide ${idx + 1}`}
                        className="carousel-image"
                      />
                      <div className="carousel-caption">
                        <TitleBanner text={item.text} />
                        <SubtitleBanner text={item.subText} />
                        {item.buttonText && (
                          <Btn
                            className="mt-3 carousel-btn"
                            type="primary"
                            text={item.buttonText}
                            isSumbit={false}
                            onTouchable={item.buttonHandler}
                          />
                        )}
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ControlledCarouselMobile;
