import React, { useState, useEffect } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import Btn from "../../atoms/Button";
import { ForumService } from "../../../services/AdvogadoServices/ForumService";
import { VerPerguntaForumAdv } from "../../molecules/VerPerguntaForumAdv";

const api = new ForumService();

export const ResponderForumAdv = () => {
  const [perguntas, setPerguntas] = useState([]);
  const [filtro, setFiltro] = useState(""); // Único estado para o filtro

  async function fetchPerguntas() {
    try {
      const response = await api.verPerguntaForumAdvogado();

     
    if (Array.isArray(response.data)) {
      const respostasInvertidas = response.data.reverse();
      setPerguntas(respostasInvertidas);
      } else {
        console.error("API response is not an array:", response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchPerguntas();
  }, []);

  const handleFiltroChange = (event) => {
    setFiltro(event.target.value);
  };

  const perguntasFiltradas = perguntas.filter(
    (pergunta) =>
      pergunta.mensagem.toLowerCase().includes(filtro.toLowerCase()) ||
      pergunta.dataHora.includes(filtro) ||
      pergunta.advogadoNome.toLowerCase().includes(filtro.toLowerCase())
  );

  return (
    <Container className="">
      <Row>
        <Col  className="d-lg-flex align-items-center mt-5 ">
          <Col lg={10} className="">
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Filtre por nome, palavra ou data."
                value={filtro}
                onChange={handleFiltroChange}
              />
            </Form.Group>
          </Col>
          
        </Col>
      </Row>
      <Col>
        <VerPerguntaForumAdv
          perguntas={perguntasFiltradas}
          exibirBotoes={true}
        />
      </Col>
      <Row></Row>
    </Container>
  );
};
