import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import Subtitle from "../../atoms/Subtitle";
import Paragraph from "../../atoms/Paragraph";
import HoverTooltip from "../../atoms/HoverTooltip";
import { HomeService } from "../../../services/HomeServices/HomeService";
import "./styles.css";

const api = new HomeService();

const CardConfirmarConsultaAdv = ({ data }) => {
  return (
    <Container
      lg={12}
      className="box-adv-confirma justify-content-center align-items-center"
    >
      <Row className="rounded d-flex align-items-center">
        <Col lg={12}>
          <Col className="d-lg-flex align-items-center ms-lg-4">
            <Col sm={12} lg={2} className="text-center pe-2">
              <AvatarPerfil
                base64={data.foto}
                type="B"
                width="7rem"
                display="none"
                height="7rem"
                padding="0.4rem"
              />
            </Col>
            <Col sm={12} lg={6} className="ps-lg-4">
              <Col sm={12} className="p-0 text-center text-lg-start">
                <Subtitle text={data.cliente_nome} />
                <Paragraph text={"OAB " + data.oab_numero} />
                <Rating
                  readonly={true}
                  initialValue={data.numero_estrelas}
                  size={25}
                />
              </Col>
              <Col className="d-flex pt-2 justify-content-center justify-content-lg-start">
                <Col className="d-flex pt-2 justify-content-center justify-content-lg-start"></Col>
              </Col>
            </Col>
            <Col sm={2} lg={2} className="text-center">
              <Col>
                <Paragraph text={data.data} />
              </Col>
            </Col>
            <Col sm={2} lg={2} className="text-center">
              <div className="d-inline-flex align-items-center">
                <Paragraph
                  text={
                    "R$" +
                    (data.valor_consulta == 0
                      ? data.valor_consulta
                      : data.valor_consulta + " + taxas.")
                  }
                />
                <HoverTooltip
                  text={
                    "O valor da consulta <b>inclui a taxa</b> de consulta mais a <b>taxa de processamento</b> para operações com cartão de crédito e crédito consignado."
                  }
                  placement={"right"}
                  className="tooltip-margin-left custom-tooltip"
                />
              </div>
              <Paragraph text="Valor da consulta" />
            </Col>
          </Col>
        </Col>
        <Col lg={12} className="p-3">
          <Col className="text-start mx-lg-5">
            <Subtitle text="Resumo Profissional" />
          </Col>
          <Col className="text-start mx-lg-5">
            <Paragraph text={data.resumo} />
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default CardConfirmarConsultaAdv;
