import api from "../Api";
 
export class LoginService {
  async getAll() { 
    return await api.get("/", {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  res.data);
  }


  
  async getById(id) {
    return await api.post("/contratos-id", { cnt_id: id },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }).then((res) =>  res.data);
  }

  // async postAtualizar(tokenGoogleo) {
  //   return await api
  //     .post("/contratos-atualizar", {
  //       tokenGoogleo: tokenGoogleo,
  //     }, {
  //       headers: {
  //         "Content-type": "application/json",
  //         Authorization: "Bearer " + sessionStorage.getItem("token"),
  //       },
  //     })
  //     .then((res) =>  res.data);
  // }

  async postCadastrar(
    cpf,
    nome,
    email,
    telefone,
    senha,
    confirma_senha,
    dt_nascimento
  ) {
    return await api.post("/web/usuario/cadastrar-cliente", {
      "cpf": cpf,
      "nome": nome,
      "email": email,
      "telefone": telefone,
      "senha": senha,
      "confirma_senha": confirma_senha,
      "dt_nascimento": dt_nascimento
    }, {
      headers: {
        "Content-type": "application/json",
        // Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((res) =>  res.data);
  }

  async postLogin(
    cpf,
    senha,
  ) {
    return await api.post("/web/autentica/cliente", {
      "cpf": cpf,
      "senha": senha,
    }, {
      headers: {
        "Content-type": "application/json",
        // Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((res) =>  res.data);
  }

  async getDocsById(id) {
    return await api.post("/documentos-listar", { cnt_id: id }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  res.data);
  }

};
