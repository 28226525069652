import { Col, Container, Row } from "react-bootstrap";
import React from "react";

import "./styles.css";
import Paragraph from "../../atoms/Paragraph";
import ParagraphSmall from "../../atoms/ParagraphSmall";

const AgendaAvisos = () => {
  return (
    <Container>
      <Row className=" mt-5  border rounded p-2">
        <Col className="">

          <Paragraph text="*Prazo para cancelamento Consulta" />
          <ParagraphSmall
            text="
          Permitido realizar o cancelamento de uma consulta com até 24h de antecedência.
          "
          />
        </Col>
        
        <Col>
          <Paragraph text="*Antecedência mínima para agendamento" />
          <ParagraphSmall
            text="
          O Cliente poderá agendar uma Consulta com no mínimo 24h de antecedência."
          />
        </Col>
        <Col>
          <Paragraph text="*Antecedência máxima para agendamento" />
          <ParagraphSmall
            text="
          Sua agenda disponibilizará os próximos 30 dias de sua agenda para os clientes. "
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AgendaAvisos;
