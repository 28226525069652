// Funcoes utilizadas no sistema
import Swal from "sweetalert2";

export default {
  removeMascara: (valor) => {
    return valor.replace(/\s/g, "").replace(/[^\w]/g, "");
  },

  aplicarMascara: (telefone) => {
    // Remove caracteres não numéricos do número de telefone
    const numerosApenas = telefone.replace(/\D/g, "");

    // Aplica a máscara ao número de telefone
    const regex = /^(\d{2})(\d{5})(\d{4})$/;
    const telefoneFormatado = numerosApenas.replace(regex, "($1) $2-$3");

    return telefoneFormatado;
  },

  adicionarDescricaoEstado: (siglaEstado) => {
    const estados = {
      AC: "Acre",
      AL: "Alagoas",
      AM: "Amazonas",
      AP: "Amapá",
      BA: "Bahia",
      CE: "Ceará",
      DF: "Distrito Federal",
      ES: "Espírito Santo",
      GO: "Goiás",
      MA: "Maranhão",
      MG: "Minas Gerais",
      MS: "Mato Grosso do Sul",
      MT: "Mato Grosso",
      PA: "Pará",
      PB: "Paraíba",
      PE: "Pernambuco",
      PI: "Piauí",
      PR: "Paraná",
      RJ: "Rio de Janeiro",
      RN: "Rio Grande do Norte",
      RO: "Rondônia",
      RR: "Roraima",
      RS: "Rio Grande do Sul",
      SC: "Santa Catarina",
      SE: "Sergipe",
      SP: "São Paulo",
      TO: "Tocantins",
    };

    const descricaoEstado = estados[siglaEstado];

    if (descricaoEstado) {
      return siglaEstado + " - " + descricaoEstado;
    } else {
      return siglaEstado; // Retorna o valor original se não for uma sigla de UF válida
    }
  },

  validarCPF: (cpf) => {
    cpf = cpf.replace(/[^\d]+/g, ""); // Remove caracteres não numéricos

    // Verifica se o CPF possui 11 dígitos
    if (cpf.length !== 11) {
      return false;
    }

    // Verifica se todos os dígitos são iguais
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }

    // Calcula e verifica o dígito verificador
    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return false;
    }

    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(10, 11))) {
      return false;
    }

    return true;
  },

  formatarDataParaString: (data) => {
    // Converte a string de data em um objeto Date
    const dataObj = new Date(data);

    // Obtém o ano, mês e dia da data
    const ano = dataObj.getFullYear();
    const mes = String(dataObj.getMonth() + 1).padStart(2, "0"); // O mês é baseado em zero (0 = janeiro)
    const dia = String(dataObj.getDate()).padStart(2, "0");

    // Monta a string no formato 'YYYY-MM-DD'
    const dataFormatada = `${dia}/${mes}/${ano}`;

    return dataFormatada;
  },

  formatarDia(input) {
    const regex = /^(\d{0,2})(\d{0,2})(\d{0,4})$/;
    const match = input.match(regex);
    if (!match) return "";
    const dia = match[1] || "01";
    const mes = match[2] || "01";
    const ano = match[3] || "2022"; // Preencha o ano padrão conforme necessário
    return `${dia.padStart(2, "0")}/${mes.padStart(2, "0")}/${ano}`;
  },

  formatarHora(input) {
    const regex = /^(\d{0,2})(\d{0,2})$/;
    const match = input.match(regex);
    if (!match) return "";
    const horas = match[1] || "00";
    const minutos = match[2] || "00";
    return `${horas.padStart(2, "0")}:${minutos.padStart(2, "0")}`;
  },

  validarHorario(inputHorario) {
    // Expressão regular para validar o formato HH:MM
    const regexHorario = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

    if (regexHorario.test(inputHorario)) {
      // Separar horas e minutos
      const [horas, minutos] = inputHorario.split(":");

      // Verificar se horas e minutos estão dentro dos limites
      if (
        parseInt(horas, 10) >= 0 &&
        parseInt(horas, 10) <= 23 &&
        parseInt(minutos, 10) >= 0 &&
        parseInt(minutos, 10) <= 59
      ) {
        return true; // Horário válido
      }
    }

    return false; // Horário inválido
  },

  formatarCPF(cpf) {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  },

  formatarCNPJ(cnpj) {
    return cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      "$1.$2.$3/$4-$5"
    );
  },

  modalToken: () => {
    Swal.fire({
      title: "Sessão Expirada",
      text: "Por favor, faça login novamente. Lembramos que é para sua segurança.",
      allowOutsideClick: false,
      confirmButtonText: "OK",
      background: "#FFF",
      confirmButtonColor: "#476BA9",
      didClose: () => {
        const tipoUsuario = sessionStorage.getItem("tipo");
        const userIdAdm = sessionStorage.getItem("idUsuario");

        let redirectUrl = "";

        if (tipoUsuario === "A") {
          redirectUrl = "/Login/Adv";
        } else if (tipoUsuario === "C") {
          redirectUrl = "/Login/Cli";
        } else if (userIdAdm != null) {
          redirectUrl = "/adm";
        }
        sessionStorage.clear();
        window.location.href = redirectUrl;
      },
    });
  },
};
