import React, { useEffect, useState } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";


import { ReactComponent as IconePerfil } from "../../../../src/img/Group 279.svg";
import { AvatarPerfil } from "../AvatarPerfil";

import {
  Navbar,
  Nav,
  NavDropdown,
  Button,
  Offcanvas,
  Col,
  Alert
} from "react-bootstrap";
import Icons from "../Icons";
import Paragraph from "../Paragraph";
import { NotificacaoService } from "../../../services/HomeServices/NotificacaoService";

const NavItemCliente = () => {
  const nomeUsuario = sessionStorage.getItem("nome") || "";
  const primeiraLetra = nomeUsuario.charAt(0);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [qtdNotificacoes, setQtdnotificacoes] = useState(0);

  const api = new NotificacaoService();
  const id = sessionStorage.getItem("id");

  const [show, setShow] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.contarNotificacoesCli(id);
        setQtdnotificacoes(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  return (
    <>
      {/* Navbar for large screens (desktop) */}
      <Navbar expand="lg" className="d-none d-lg-flex p-0 m-0">
        <Nav className="list align-items-center p-0 m-0">
          <Nav.Link href="/" className="no-toggle-icon">
            Início
          </Nav.Link>
          <Nav.Link href="/ConsultasCli" className="no-toggle-icon">
            Consultas
          </Nav.Link>
          <Nav.Link href="/FinanceiroCli" className="no-toggle-icon">
            Financeiro
          </Nav.Link>
          <Nav.Link href="/ForumCli" className="no-toggle-icon">
            Pergunte a um Advogado
          </Nav.Link>
          <Nav.Link href="/faleconosco" className="no-toggle-icon">
            Fale conosco
          </Nav.Link>

          <NavDropdown
            className="m-0 p-0"
            title={
              <AvatarPerfil
                type="B"
                base64={sessionStorage.getItem("fotoPerfil")}
                width="4rem"
                height="4rem"
                padding="0.1rem"
              />
            }
          >
            <NavDropdown.Item href="/PerfilClienteTemp">
              Meu Perfil
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => sessionStorage.clear()} href="/">
              Sair
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/NotificacoesCli">
          <Col className="container">
              <Icons type="notificacao" />
              {qtdNotificacoes !== 0 && (
                <>
                  <span className="number-badge">{qtdNotificacoes}</span>
                </>
              )}
            </Col>
          </Nav.Link>
        </Nav>
      </Navbar>
      {qtdNotificacoes !== 0 && show && (
        <Col className="alert-container">
          <Alert variant="warning" onClose={() => setShow(false)} dismissible
            className="alert-flush shadow"
          >
            Atenção, você possui novas notificações!
          </Alert>
        </Col>
      )}

      {/* Offcanvas for small screens (mobile) */}
      <div className="d-lg-none ">
        <Button variant="outline-dark " onClick={toggleOffcanvas} className="">
          Menu
        </Button>
        <Offcanvas
          className="bg-warning"
          show={showOffcanvas}
          onHide={() => setShowOffcanvas(false)}
          placement="end"
        >
          <Offcanvas.Header className="mx-5 my-2 text-center" closeButton>
            <Offcanvas.Title className="display-5  ">FaçaJus</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="bg-warning ">
            <Nav className="list align-items-center p-0 m-0">
              <Nav.Link href="/" className="no-toggle-icon">
                Início
              </Nav.Link>
              <Nav.Link href="/ConsultasCli" className="no-toggle-icon">
                Consultas
              </Nav.Link>
              <Nav.Link href="/FinanceiroCli" className="no-toggle-icon">
                Financeiro
              </Nav.Link>
              <Nav.Link href="/ForumCli" className="no-toggle-icon">
                Pergunte a um Advogado
              </Nav.Link>
              <Nav.Link href="/faleconosco" className="no-toggle-icon">
                Fale conosco
              </Nav.Link>
              <NavDropdown
                className="m-0 p-0 text-center"
                title={
                  <AvatarPerfil
                    type="B"
                    base64={sessionStorage.getItem("fotoPerfil")}
                    width="4rem"
                    height="4rem"
                    padding="0.1rem"
                  />
                }
              >
                <NavDropdown.Item href="/PerfilClienteTemp">
                  Meu Perfil
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={() => sessionStorage.clear()}
                  href="/"
                >
                  Sair
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/NotificacoesCli">
              <Col className="container">
                  <Icons type="notificacao" />
                  {qtdNotificacoes !== 0 ? (
                    <span className="number-badge">{qtdNotificacoes}</span>
                  ) : null}
                </Col>
          </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default NavItemCliente;
