import React, { useEffect, useMemo, useState } from "react";
// import  CometChat2  from "@cometchat-pro/chat";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { CometChatMessages,CometChatIncomingCall} from "@cometchat/chat-uikit-react";
import { VideoConferenciaService } from "../../../services/VideoConferenciaServices/VideoConferenciaService";
// import { CometChatMessages2 } from "../../../cometchat-chat-uikit-react/CometChatWorkspace/src/components/Messages/CometChatMessages2";
// import { CometChatGroupEvents} from "@cometchat/uikit-resources";
import { ListItemStyle } from "@cometchat/uikit-elements";
import { MessageComposerConfiguration, MessageComposerStyle, MessageHeaderConfiguration, MessageHeaderStyle, MessagesStyle } from "@cometchat/uikit-shared/dist";
import { Spinner } from "react-bootstrap";
import "./styles.css";

export const ConferenciaAdvogado = ({ salaChat, salaVideo, nomeGrupo, nomeLigar, nomeLogado }) => {
  // const api2 = useMemo(() => new VideoConferenciaService(), []);
  const [chatWithUser, setChatWithUser] = useState('');
  const [usersComet, setUsersComet] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [group, setGroup] = useState('');
  const [grupos, setGrupos] = useState('');
  const [chatWithUsuario, setChatWithUsuario] = useState(null);
  const [atualiza, setAtualiza] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await api2.getUsers();
        // const response_grupo = await api2.getGroup();
        
        let response = sessionStorage.getItem("usersComet")
        let response_grupo = sessionStorage.getItem("groupsComet")

        response = JSON.parse(response);
        response_grupo = JSON.parse(response_grupo)

        // console.log(response );

        setUsersComet(response);
        setGrupos(response_grupo);
        // setUsersComet(response.data);
        // setGrupos(response_grupo.data);
        setDataLoaded(true);
  
        if (dataLoaded && nomeLigar) {
          // let limit = response.data.length;
          // let limit2 = response_grupo.data.length;

          let limit = response.length;
          let limit2 = response_grupo.length;

  
          let usersRequest = new CometChat.UsersRequestBuilder()
            .setLimit(limit)
            .build();
  
          let gruposRequest = new CometChat.GroupsRequestBuilder()
            .setLimit(limit2)
            .build();
       
       
          const gp = await gruposRequest.fetchNext();
          const fetchGrupo = gp.find((gp) => gp.getName() === nomeGrupo);
   
          const user = await usersRequest.fetchNext();
          const fetchUser = user.find((user) => user.getName() === nomeLigar);
  
  
          setChatWithUser(fetchUser);
          setGroup(fetchGrupo);
        } else {
          setChatWithUser(null);
        }
      } catch (error) {
        console.error("Erro ao recuperar o usuario", error);
      }
    };
  
    fetchData();
  }, [dataLoaded, nomeGrupo, nomeLigar]);
  const mhStyle = new MessageHeaderStyle({
    background: " #d3d3c1",
    borderRadius:  "1rem",
    
  });

  const mcStyle = new MessageComposerStyle({
   
   
   
  });

  const lStyle = new ListItemStyle({
    background: "transparent"
  });

  const mhConfig = new MessageHeaderConfiguration({
    listItemStyle: lStyle,
    messageHeaderStyle: mhStyle
  });

  const mcConfig = new MessageComposerConfiguration({
    messageComposerStyle: mcStyle
  });

  const mStyle = new MessagesStyle({
    width: "100%",
    height: "90%",
    // border: "1px solid rgb(201, 255, 191)"
  });

  console.log(chatWithUser,salaVideo)

  const renderTemp = () => {
    return chatWithUser   ? (
    
      <div style={{ width: "100vw", height: "80vh" }} >

        {salaVideo && 
              <CometChatMessages 
                group={group}
                messagesStyle={mStyle}
                messageHeaderConfiguration={mhConfig}
                messageComposerConfiguration={mcConfig} 
                
              />            
        }
        {/* {salaChat &&           
          <CometChatMessages 
            group={group}
          />
        } */}
      </div>
   
    ) : (
      <div className="loading-spinner">
        <Spinner animation="grow" role="status" variant="primary">
          
        </Spinner>
      </div>
    );
  };

  return renderTemp();
};
