import React, { useState, useEffect } from "react";
import { Col, Container, Pagination, Row, Table } from "react-bootstrap"; // Import Pagination from react-bootstrap
import { Rating } from "react-simple-star-rating";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import Paragraph from "../../atoms/Paragraph";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import { UsuarioService } from "../../../services/AdmUsuarioServices/UsuarioService";
import Icons from "../../atoms/Icons";
import "./styles.css";
import { Link, useNavigate } from "react-router-dom";
import { usePermissaoAdm } from "../../../hooks/usePermissaoAdm";

const AdmGerenciaListaUsuarios = ({ permissoes }) => {
  const [userData, setUserData] = useState(null);
  const api = new UsuarioService();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await api.getAll(
          "nome",
          "cpf",
          "telefone",
          "email",
          "id",
          "data",
          "perfil"
        );
        setUserData(response);
      } catch (error) {
        console.error("Erro ao buscar dados da API:", error);
      }
    }
    fetchUserData();
  }, []);

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  
    if (value.trim() === '') {
      setFilteredData(null);
    } else {
      const filtered = userData.data.filter((user) => {
        return user.nome.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredData(filtered);
    }
  };

  // Define the number of items per page
  const itemsPerPage = 5;

  // Calculate the total number of pages based on the data
  const totalPages = userData
    ? Math.ceil(userData.data.length / itemsPerPage)
    : 1;

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("pt-BR", options);
  }

  function formatPhoneNumber(phoneNumber) {
    const numericOnly = phoneNumber.replace(/\D/g, "");
    const formattedPhoneNumber = `(${numericOnly.slice(
      0,
      2
    )}) ${numericOnly.slice(2, 7)}-${numericOnly.slice(7, 11)}`;

    return formattedPhoneNumber;
  }

  function formatCPF(cpf) {
    const numericOnly = cpf.replace(/\D/g, "");
    const formattedCPF = `${numericOnly.slice(0, 3)}.${numericOnly.slice(
      3,
      6
    )}.${numericOnly.slice(6, 9)}-${numericOnly.slice(9, 11)}`;

    return formattedCPF;
  }

  const handleEditar = (userId) => {
    const url = `/adm/gerencial/usuarios/editar/${userId}`;
    navigate(url);
  };
  const podeEditar = usePermissaoAdm(
    "gerencial-usuarios-cadastrar",
    permissoes
  );

  // Function to render table rows for the current page
  const renderTableRows = () => {
    if (!userData) return null;
    // Sort the data array by date in descending order (most recent first)
    const sortedData = userData.data.slice().sort((a, b) => {
      return new Date(b.data) - new Date(a.data);
    });

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return sortedData.slice(startIndex, endIndex).map((user) => (
      <tr key={user.id} className="align-itens-center table-container">
        <td style={{ width: "50%" }}>
          <Paragraph text={user.nome} />
        </td>
        <td style={{ width: "20%" }}>
          <Paragraph text={formatDate(user.data)} />
        </td>
        <td style={{ width: "20%" }}>
          <Paragraph text={formatCPF(user.cpf)} />
        </td>
        <td style={{ width: "20%" }}>
          <Paragraph text={formatPhoneNumber(user.telefone)} />
        </td>
        <td style={{ width: "20%" }}>
          <Paragraph text={user.email} />
        </td>
        <td style={{ width: "20%" }}>
          {/* <Paragraph text={user.perfil} /> */}
          <Paragraph text={user.perfil}  />
        </td>
        <td>
          <Col
            style={{ cursor: "pointer" }}
            onClick={() => (podeEditar ? handleEditar(user.id) : null)}
          >
            <Icons type={podeEditar ? "editar" : "pix"} />
          </Col>
        </td>
      </tr>
    ));
  };

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Table responsive hover className="">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Data</th>
                <th>CPF</th>
                <th>Telefone</th>
                <th>E-mail</th>
                <th>Perfil</th>
                <th>Editar</th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </Table>
        </Col>
        <Col
          lg={12}
          className="d-flex align-items-center justify-content-center"
        >
          <Pagination size="sm">
            {Array.from({ length: totalPages }).map((_, index) => (
              <Pagination.Item
                key={index}
                active={index + 1 === currentPage}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
};

export default AdmGerenciaListaUsuarios;
