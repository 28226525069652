import React from "react";
import './styles.css'
import { PermissaoTemp } from "../../components/templates/PermissaoTemp";


export const Permissao = () => {

    return (
        <div>
            <PermissaoTemp/>
        </div>
    );

}