import React from 'react';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const SubtitleBanner= (props) => {
  return (
    <h3 className='subtext-banner'>{props.text}</h3>
    
    );
}

export default SubtitleBanner;



