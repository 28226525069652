import functions from "../../components/utils/functions";
import api from "../Api";
 
export class ForumService {
  async getAll() { 
    return await api.get("/", {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) =>  res.data)
  }

  async getById(id) {
    return await api.post("/contratos-id", { cnt_id: id },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }).then((res) =>  res.data);
  }
  
  async chatCliente(id, tokenRecaptcha) {
    try {
      const response = await api.post("/cliente/lista-chat-cli", {
        "cacCliId": id,
        "tokenRecaptcha": tokenRecaptcha
      },{
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });

      return response.data;
    } catch (error) {
        if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
          functions.modalToken();
        } else {
          throw error; 
        }
    }
  }
  


  async mensagemCliente(
    cac_add_id, 
    cac_cli_id) 
    
    {  try {
      const response = await api.post("/cliente/chat-mensagem-cli", {
        "cac_add_id": cac_add_id,
        "cac_cli_id": cac_cli_id
      },{
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
  
      return response.data;
    } catch (error) {
        if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
          functions.modalToken();
        } else {
          throw error; 
        }
  }
  }
 
  async salvarNovaMensagemCliente(
    cac_add_id,
    cac_cli_id,
    mensagemCliente
  ) {
    return await api.post("/cliente/salvar-mensagem-cli", {
      "cac_add_id": cac_add_id,
      "cac_cli_id": cac_cli_id,
      "mensagem": mensagemCliente,
    }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) => res.data)
      .catch((error) => {
        if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
          functions.modalToken();
        } 
      });
  }
  

 
  async extratoConsultaCli(cacCliId) {
    try {
      const response = await api.post("/cliente/extrato-consulta-cli", {
        "cacCliId": cacCliId,
      }, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      });
  
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }



  

};
