import api from "../Api";

export class PerfilService {
  async getAll() {
    return await api
      .get("/adm/gerencial/perfil/listar", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async postCadastrar(nome, descricao, acessos) {
    return await api
      .post(
        "/adm/gerencial/perfil/cadastrar",
        {
          nome,
          descricao,
          acessos,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async postEditar(id, nome, descricao, acessos) {
    return await api
      .post(
        "/adm/gerencial/perfil/editar",
        {
          id,
          nome,
          descricao,
          acessos,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getPerfilPorId(id) {
    try {
      const response = await api.get(`/adm/gerencial/perfil/dados/${id.id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        data: {
          id,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getPermissoes() {
    return await api
      .post(
        "/adm/gerencial/permissoes",
        {},
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
}
