import React from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import Btn from "../../atoms/Button";
import AdmSiteListaAdvogados from "../../molecules/AdmSiteListaAdvogados";

const AdmSiteAdvogados= () => {
 

  return (
    <Container className="p-4">
      <Row className="">
        <Col lg={2} className="m-0 p-0  text-start">
          <Breadcrumb className="">
            <Breadcrumb.Item>Gerencial</Breadcrumb.Item>
            <Breadcrumb.Item href="#" active>
              Advogados
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row >
        <Col className="d-flex align-items-center ">
          <Col className="">
            <Subtitle text="Advogados cadastrados" />
          </Col>         
        </Col>
      </Row>

      <Row className="">
        <Col>
          <AdmSiteListaAdvogados />
        </Col>
      </Row>
    </Container>
  );
};

export default AdmSiteAdvogados;
