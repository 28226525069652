import React from "react";
import './styles.css'


export const NotFound = () => {

    return (
        <div>
            <h1>Página não encontrada!</h1>
        </div>
    );

}