import React from 'react';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const white = '#fff'; 

const ParagraphSmall = (props) => {
  const { text, textColor, textSize, textAlign } = props;
  const style = {
    color: textColor === 'white' ? white : textColor || 'black',
    fontSize: textSize || '16px', 
    textAlign: textAlign || 'left', 
  };

  return (
    <p className='paragraph-small' style={style}>
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </p>
  );
};

export default ParagraphSmall;
