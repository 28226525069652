import React from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

const TermoDeUsoText = () => {
  return (
<div>
  <p>Este Termo de Uso apresenta as condições gerais aplicáveis ao uso dos serviços do “Faça Jus”, plataforma oferecida através do Portal Web “https://www.facajus.com.br”, pela USE MAIS MULTISERVIÇOS LTDA, inscrita no CNPJ/MF sob o n°. 12.751.349/0001-44, com sede na Avenida República Argentina, nº 1505, sala 1303, Bairro Água Verde, Curitiba, Paraná, doravante denominada “Use Mais”.</p>
  <p>O Faça Jus atua como um intermediário para facilitar a comunicação e a contratação de serviços jurídicos, tendo como principal objetivo fornecer um espaço online em que pessoas, doravante denominadas “Clientes”, encontrem profissionais disponíveis, doravante denominados “Advogados”, para prestar assistência jurídica às suas demandas. Clientes e Advogados serão chamados coletivamente de “Usuários”.</p>
  <p>Qualquer pessoa que pretenda, como Cliente ou Advogado, utilizar os serviços do Faça Jus, deverá aceitar este Termo de Uso e todas as demais políticas e princípios que o regem. A UTILIZAÇÃO DO WEBSITE E SEUS SERVIÇOS PRESUME A ACEITAÇÃO DESTE TERMO DE USO, ALÉM DE CONSTITUIR UM ACORDO LEGAL ENTRE A USE MAIS, OS CLIENTES E OS ADVOGADOS.</p>
  <p>O Usuário, no ato do acesso ao Website, deverá ler, certificar-se de haver entendido e aceitar todas as condições estabelecidas no Termo de Uso, assim como nos demais documentos incorporados ao mesmo por referência, antes de seu cadastro como Cliente ou Advogado no Faça Jus.</p>
  <p>Ao acessar o site do Faça Jus, o usuário concorda, por livre e espontânea vontade, em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis, sob pena de aplicação das penalidades cabíveis por tempo indeterminado. E se não aceitar este Termo, o Usuário não poderá acessar o Website e nem utilizá-lo.</p>
  <p>O acesso ou o uso contínuo do Faça Jus, depois desta publicação, constitui o consentimento do Usuário para estar sob as disposições deste Termo de Uso e suas modificações, configurando em especial a concordância de que o Faça Jus é unicamente uma plataforma de comunicações, não possuindo nenhuma responsabilidade pelos serviços prestados pelos Advogados.</p>
  <p>O Usuário pode entrar em contato com a equipe de atendimento do Faça Jus através do canal “Fale Conosco”, o qual se encontra no seguinte link: https://www.facajus.com.br – Aba “Fale Conosco” ou poderá ser atendido diretamente pelo WhatsApp + 55 (41) 99993-4379.</p>
  <p>O Usuário concorda que, para ficar ciente das informações importantes relativas à sua conta e manter a comunicação com a empresa, o telefone e endereço de e-mail cadastrados serão os canais de comunicação utilizados pelo Faça Jus ou pela Use Mais.</p>
  
  <h3>1. DO OBJETO</h3>
  <p>1.1. Os serviços objeto do presente Termo de Uso consistem em conectar Advogados com potenciais Clientes, facilitando a busca do cliente pelo profissional adequado, além de possibilitar o agendamento de consultas por videoconferência e proporcionar a orientação de especialistas para a elucidação de dúvidas jurídicas.</p>
  <p>1.2. A plataforma Faça Jus oferece soluções tecnológicas que permitem ao cliente encontrar Advogados com base em suas necessidades e preferências, com a verificação da qualificação e das credenciais do profissional antes de sua inclusão na plataforma.</p>
  <p>1.3. Estão inclusos nos serviços do Faça Jus a disponibilização de ferramentas de comunicação atualizadas para Clientes e Advogados, a manutenção da privacidade e da confidencialidade das informações compartilhadas, assim como o gerenciamento dos pagamentos dos serviços jurídicos prestados pelos Advogados aos Clientes, conforme acordado entre as partes.</p>
  <p>1.4. O Usuário cadastrado tem a sua disposição a equipe das centrais de relacionamento, por meio de canais online. Um serviço exclusivo para o Usuário, seja Cliente ou Advogado, tirar todas as suas dúvidas sobre a plataforma.</p>
  
  <h3>2. DO SERVIÇO ENTRE USUÁRIOS</h3>
  <p>2.1. O Faça Jus é um espaço informático onde os Usuários podem se conectar e contratar serviços jurídicos, os quais são celebrados entre Cliente, de um lado, e Advogado, de outro. O Faça Jus não presta serviços jurídicos. A prestação dos serviços contratados através da plataforma Faça Jus depende exclusivamente dos Advogados.</p>
  <p>2.2. Os Advogados são independentes da plataforma. Eles são responsáveis por fornecer serviços jurídicos de acordo com as leis e regulamentações aplicáveis. A plataforma Faça Jus não assume responsabilidade pelo trabalho dos profissionais.</p>
  <p>2.3. O Faça Jus não será responsável por quaisquer atos, omissões, erros ou condutas inadequadas dos Advogados ou Clientes. O Faça Jus não assume qualquer responsabilidade pela qualidade dos serviços prestados pelos Advogados, nem pela resolução de disputas entre Advogados e Clientes. Cada Cliente é responsável por selecionar e contratar um Advogado adequado às suas necessidades e por avaliar a adequação dos serviços prestados.</p>
  
  <h3>3. DO CADASTRO NO WEBSITE</h3>
  <p>3.1. Os serviços do Faça Jus estão disponíveis apenas para as pessoas que tenham capacidade legal para contratá-los, não podendo ser utilizados por menores de 18 (dezoito) anos de idade ou por pessoas que tenham sido inabilitadas pelo Faça Jus temporária ou definitivamente.</p>
  <p>3.2. Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra forma de transferência da conta. Também não se permitirá a manutenção de mais de um cadastro por uma mesma pessoa, ou ainda a criação de cadastros por pessoas cujos cadastros originais tenham sido cancelados por infrações às políticas do Faça Jus. Em todos esses casos, o Faça Jus se reserva no direito de excluir todas as contas do Usuário infrator.</p>
  <p>3.3. Somente será confirmado o cadastramento do interessado que preencher todos os campos solicitados. O futuro Usuário deverá completá-lo com informações exatas, precisas e verdadeiras, e assume o compromisso de atualizar os dados pessoais sempre que neles ocorrer alguma alteração. O Faça Jus não se responsabiliza pela correção dos dados pessoais inseridos por seus Usuários, ou por informações fornecidas de forma incorreta ou incompleta.</p>
  <p>3.4. Os Usuários garantem e respondem, em qualquer caso, pela veracidade, exatidão e autenticidade dos dados pessoais cadastrados.</p>
  <p>3.5. O Faça Jus se reserva o direito de utilizar todos os meios válidos e possíveis para identificar seus Usuários, bem como de solicitar dados adicionais e documentos pessoais válidos que estime ser pertinente, a fim de conferir os dados pessoais informados, em especial para comprovação do registro do Usuário Advogado no órgão de classe profissional.</p>
  <p>3.6. No ato do cadastro, o Usuário Advogado declara estar apto a prestar as atividades privativas da advocacia, preenchendo todos os requisitos estabelecidos no art. 8º da Lei 8.906/94, estando devidamente inscrito na Ordem dos Advogados do Brasil.</p>
  <p>3.7. O Usuário Advogado assume total responsabilidade em manter sua documentação atualizada e se compromete a comunicar o Faça Jus, de forma imediata, no caso do surgimento de qualquer restrição ou impedimento superveniente para exercer as atividades da advocacia.</p>
  <p>3.8. Caso exista a necessidade, por qualquer motivo, de conferência dos dados cadastrais de um Usuário e se constate haver dados incorretos ou inverídicos, ou ainda caso o Usuário se furte ou negue a enviar os documentos requeridos para conferência, o Faça Jus poderá cancelar, definitivamente ou não, o cadastro do Usuário, sem prejuízo de outras medidas que entender necessárias e oportunas.</p>
  <p>3.9. O Usuário acessará sua conta através do seu CPF e senha, e compromete-se a não informar a terceiros esses dados, responsabilizando-se integralmente pelo uso que deles seja feito.</p>
  <p>3.10. O Usuário compromete-se a notificar o Faça Jus imediatamente, e através de meio seguro, a respeito de qualquer acesso e/ou uso não autorizado de sua conta.</p>
  <p>3.11. O Usuário será o único responsável pelas ações e interação realizadas em sua conta, uma vez que o acesso à mesma só será possível mediante a aposição da senha, à qual é de uso pessoal e intransferível.</p>

  <h3>4. DA OPÇÃO ENCONTRE UM ADVOGADO</h3>
  <p>4.1. O Faça Jus disponibiliza a pesquisa de profissionais Advogados, com base na necessidade do Cliente, na áreas de atuação jurídica e no Estado de sua atuação. O Cliente tem acesso a uma lista de Advogados, constando o nome do profissional, o número de sua OAB, a avaliação do profissional dentro da plataforma, o valor da consulta, o número de consultas realizadas pelo profissional, os horários disponíveis para agendamento de consulta e a possibilidade de iniciar uma conversa diretamente com o profissional.</p>

  <h3>5. DA SEÇÃO PERGUNTE A UM ADVOGADO</h3>
  <p>5.1. A plataforma Faça Jus disponibiliza uma ferramenta gratuita de esclarecimento de dúvidas jurídicas com profissionais especializados.</p>
  <p>5.2. O Usuário Cliente poderá acessar a aba “Fórum” no Portal Web, selecionar a área jurídica do caso e enviar a sua dúvida, a qual será postada de forma anônima e será respondida no tempo aproximado de 48h.</p>
  <p>5.3. O Usuário Cliente reconhece e concorda que os advogados envolvidos neste contrato não têm a obrigação de responder perguntas formuladas no fórum do site. Quaisquer interações nos fóruns online são de natureza voluntária e não constituem a prestação de serviços jurídicos ou a criação de uma relação cliente-advogado.</p>

  <h3>6. DOS PLANOS DE ASSINATURA</h3>
  <h3>A) PARA OS ADVOGADOS</h3>
  <p>6.1. O Usuário Advogado, ao se cadastrar na plataforma Faça Jus, deverá pagar pelo plano de assinatura, concordando em pagar os valores especificados de acordo com o plano selecionado.</p>
  <p>6.2. O Faça Jus pode suspender a funcionalidade “agenda” do Advogado em caso de atraso no pagamento da assinatura, com o posterior cancelamento do cadastro após 30 (trinta) dias de inadimplência.</p>
  <p>6.3. O Advogado pode cancelar a assinatura a qualquer momento antes da data de renovação. O cancelamento pode ser feito diretamente na plataforma ou entrando em contato com o suporte ao cliente.</p>
  <p>6.4. O Faça Jus não oferece reembolso parcial ou total por períodos não utilizados da assinatura do Advogado.</p>
  <p>6.5. O Faça Jus reserva o direito de alterar os preços das assinaturas a qualquer momento, não sendo afetadas as assinaturas contratadas anteriormente.</p>
  <p>6.6. Inclusive e para efeitos de oferta de lançamento e expansão da comercialização do Faça Jus, estarão os advogados isentos do pagamento de mensalidade até ulterior estabelecimento por parte da Plataforma Faça Jus, quando esta isenção for suspensa, os advogados cadastrados serão notificados com 30 (trinta) dias de antecedência. Por meio da notificação, os advogados serão convidados a inserir seus dados de pagamento para a continuidade na plataforma.</p>

  <h3>B) PARA SERVIDORES PÚBLICOS</h3>
  <p>6.7. Os servidores públicos terão a opção de aderir ao plano FAÇA JUS BÁSICO e as condições de comercialização e demais condições estarão descritas no termo de adesão próprio, que será acessado no momento da contratação, diretamente nos aplicativos onde estiver disponível.</p>

  <h3>7. DOS PAGAMENTOS DAS CONSULTAS</h3>
  <p>7.1. Os Usuários advogados concordam em utilizar a plataforma de pagamento designada pelo site para que o cliente efetue o pagamento da consulta.</p>
  <p>7.2. O Faça Jus se reserva o direito de reter uma parte de cada consulta como compensação para manutenção da plataforma. O advogado concorda em pagar uma taxa fixa de 10% (dez por cento) sobre o valor total de cada transação realizada através da plataforma.</p>
  <p>7.3. Além disso, o advogado também concorda em arcar com quaisquer taxas variáveis cobradas pela empresa de meio de pagamento responsável pelo processamento das transações, as taxas serão apresentadas ao advogado logo após seu pedido de cadastramento, por meio de comunicação eletrônica (e-mail) informativa.</p>
  <p>7.4. As taxas serão deduzidas automaticamente do valor total da transação antes da transferência dos fundos para a conta corrente indicada pelo advogado.</p>
  <p>7.5. A taxa cobrada pelo Faça Jus será estabelecida pelo site e poderá variar de acordo com a natureza do pagamento e outras condições estipuladas pelo site e previamente informada aos advogados e usuários.</p>
  <p>7.6. O site se reserva o direito de alterar as taxas de consulta a qualquer momento, mediante aviso prévio aos advogados, que poderá ser realizado por meio de comunicação eletrônica.</p>
  <p>7.7. As alterações nas taxas de consulta entrarão em vigor na data especificada no aviso e serão aplicáveis a todas as consultas realizadas após essa data.</p>

  <h3>8. DOS MEIOS DE PAGAMENTO DAS CONSULTAS: DÉBITO CONSIGNADO OU CARTÃO DE CRÉDITO</h3>
  <p>8.1. O usuário/cliente da Plataforma Faça Jus poderá optar por realizar o pagamento das consultas e outros serviços que eventualmente sejam disponibilizados, de duas formas, por meio de consignação em folha de pagamento (quando disponível no convênio ao qual pertence) ou por lançamento em seu cartão de crédito.</p>
  <p>8.2. Em casos específicos em que o cliente opte pelo pagamento por meio de débito consignado para as consultas jurídicas oferecidas pelo site, o advogado concorda em aceitar tal modalidade de pagamento.</p>
  <p>8.3. O advogado reconhece que, ao aceitar o pagamento por débito consignado, os fundos recebidos podem estar sujeitos a taxas ou descontos administrativos cobrados pela instituição financeira responsável pelo processamento das transações, os quais serão deduzidos do valor a ser repassado ao advogado.</p>
  <p>8.4. O cliente concorda em autorizar o site a atuar como intermediário na transferência dos fundos provenientes do pagamento por débito consignado para os advogados que prestarem o serviço de consulta, fornecendo as informações necessárias para que o site possa efetuar tais transferências de forma adequada e oportuna.</p>
  <p>8.5. O cliente poderá optar pelo pagamento parcelado em cartão de crédito, de sua titularidade, seguindo os preços e condições de pagamento apresentados no momento da contratação.</p>
  <p>8.6. Caso o cliente opte pelo pagamento com cartão de crédito, o advogado está ciente que eventuais custos adicionais referentes ao processamento das transações poderão incidir sobre o valor repassado pelo Faça Jus à conta de sua titularidade e os custos serão esclarecidos no momento da ativação do cadastro na plataforma, por meio de envio de comunicação eletrônica ao advogado.</p>
  <p>8.7. Para a operacionalização do débito consignado, o FAÇA JUS poderá contar com parceiros de negócios, que atuam como consignatárias perante os órgãos públicos conveniados, estes parceiros serão responsáveis pelo débito e operacionalização dos descontos e repasse à Plataforma Faça Jus e consequentemente aos advogados que realizarem consultas.</p>

  <h3>9. PRAZO PARA RECEBIMENTO DAS CONSULTAS E PROCESSAMENTO DE PAGAMENTOS</h3>
  <p>9.1. Após a realização de uma consulta por parte do advogado, o site se compromete a processar e fornecer o pagamento pela consulta dentro do prazo máximo de 60 (sessenta) dias, conforme estipulado nesta cláusula.</p>
  <p>9.2. O site envidará esforços razoáveis para garantir que as consultas sejam processadas e os pagamentos sejam efetuados dentro do prazo estabelecido, sujeito a eventuais atrasos decorrentes de circunstâncias fora do controle do site.</p>
  <p>9.3. O tempo para processamento de pagamentos pode variar dependendo do método de pagamento escolhido pelo cliente (à vista/consignado) e outros fatores relacionados ao processamento financeiro.</p>
  <p>9.4. O site reserva-se o direito de atualizar ou modificar os prazos estabelecidos nesta cláusula, mediante aviso prévio aos advogados, conforme necessário.</p>

  <h3>10. CLÁUSULA DE CANCELAMENTO DE CONSULTA COM REEMBOLSO</h3>
  <p>10.1. O Usuário cliente tem o direito de cancelar uma consulta agendada, com direito a reembolso, desde que o cancelamento seja realizado com, pelo menos, 24 horas de antecedência da data e hora agendadas para a consulta.</p>
  <p>10.2. Caso o Usuário cliente cancele a consulta com menos de 24 horas de antecedência da data e hora agendadas, não será elegível para receber um reembolso do valor pago pela consulta.</p>
  <p>10.3. O Usuário cliente deve comunicar o cancelamento da consulta através dos canais de comunicação designados pelo site, seguindo as instruções fornecidas para esse fim.</p>
  <p>10.4. O cancelamento só será considerado válido após a confirmação pelo site, e o Usuário cliente será notificado sobre qualquer reembolso devido, conforme as disposições desta cláusula.</p>
  <p>10.5. O site reserva-se o direito de atualizar ou modificar esta política de cancelamento de consulta com reembolso, mediante aviso prévio aos clientes conforme necessário.</p>
  <p>10.6. Para os usuários do plano FAÇA JUS BÁSICO, as regras de cancelamento e reagendamento estarão descritas no termo de adesão próprio do produto, acessível no momento da contratação.</p>

  <h3>11. DO TRATAMENTO DOS DADOS PESSOAIS</h3>
  <p>11.1. Toda e qualquer prática de tratamento de dados pessoais realizada pelo Faça Jus, em nome da Use Mais (proprietária do website), está em conformidade com a Lei, especialmente a Lei Geral de Proteção de Dados, sendo resguardado os direitos dos titulares dos dados pessoais.</p>
  <p>11.2. Para poder desempenhar suas atividades com segurança, o Usuário deverá ler atentamente nossa Política de Privacidade antes de prosseguir com o cadastro. Nela, ele terá acesso de forma detalhada a respeito dos dados que coletamos, como são utilizados, quais seus direitos e como entrar em contato com a equipe Faça Jus e com a Use Mais para solicitar informações relativas aos seus dados pessoais.</p>
  <p>11.3. Reforçamos que todos os dados pessoais são armazenados em banco de dados próprio, proporcionando a segurança das informações coletadas.</p>
  <p>11.4. O Faça Jus utiliza os cookies para facilitar o uso e melhor adaptar o site aos Usuários. Os cookies também podem ser utilizados para ajudar a acelerar suas atividades e experiências futuras em nossa página, bem como para compilar estatísticas anônimas e agregadas que nos permitem entender como as pessoas utilizam nossa página, nos ajudando a melhorar sua estrutura.</p>
  <p>11.5. As informações coletadas poderão ser usadas em auditorias, análises estatísticas, ciência de dados, desenvolvimento e pesquisas para melhoria dos serviços prestados pelo Faça Jus.</p>

  <h3>12. DAS MODIFICAÇÕES DO TERMO DE USO</h3>
  <p>12.1. O Faça Jus poderá alterar, a qualquer tempo, este Termo de Uso, visando seu aprimoramento e melhoria dos serviços prestados.</p>
  <p>12.2. O novo Termo de Uso entrará em vigor a partir de sua publicação no Website. No prazo de 24 (vinte e quatro) horas, contadas a partir da publicação das modificações, o Usuário deverá comunicar, pelo e-mail: facajus@facajus.com.br, caso não concorde com as alterações do Termo de Uso. Nesse caso, o vínculo contratual deixará de existir, desde que não haja contas ou dívidas em aberto, em nome do Usuário.</p>
  <p>12.3. Não havendo manifestação no prazo estipulado, entender-se-á que o Usuário aceitou tacitamente o novo Termo de Uso e o contrato continuará vinculando as partes.</p>

  <h3>13. DA PRIVACIDADE DA INFORMAÇÃO</h3>
  <p>13.1. O Faça Jus atua em consonância com a sua missão institucional e tomará todas as medidas possíveis para manter a confidencialidade e a segurança descritas nesta cláusula, porém não responderá por prejuízo que possam ser derivados da violação dessas medidas por culpa exclusiva de terceiros que utilizem as redes públicas ou a internet, subvertendo os sistemas de segurança para acessar as informações e dados pessoais de Usuários.</p>

  <h3>14. DA VIOLAÇÃO NO SISTEMA OU BASE DE DADOS</h3>
  <p>14.1. Não é permitida a utilização de nenhum dispositivo, software, ou outro recurso que venha a interferir nas atividades e operações do Faça Jus, bem como nos seus bancos de dados.</p>
  <p>14.2. Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas neste Termo de Uso, tornarão o responsável passível das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais danos causados.</p>

  <h3>15. DAS SANÇÕES</h3>
  <p>15.1. Sem prejuízo de outras medidas, o Faça Jus poderá advertir, suspender ou cancelar, temporária ou definitivamente, a conta de um Usuário a qualquer tempo, e iniciar as ações legais cabíveis se:</p>
  <ul>
    <li>a) o Usuário não efetuar os pagamentos acordados;</li>
    <li>b) o Usuário não cumprir qualquer dispositivo deste Termo de Uso;</li>
    <li>c) praticar atos fraudulentos ou dolosos;</li>
    <li>d) não puder ser verificada a identidade do Usuário ou qualquer informação fornecida por ele esteja incorreta;</li>
    <li>e) se o Faça Jus entender que quaisquer atitudes do Usuário tenham causado algum dano a terceiros ou ao próprio Faça Jus ou tenham a potencialidade de assim o fazer.</li>
  </ul>

  <h3>16. DA RESPONSABILIDADES DE FUNCIONAMENTO</h3>
  <p>16.1. O Faça Jus garante a prestação dos serviços de forma ininterrupta e/ou isenta de erros. Porém, tendo em vista a impossibilidade de funcionamento integral e ininterrupto de qualquer sistema de telecomunicação ou de informática, inclusive em razão da dependência de serviços de telecomunicações prestados por terceiros, eventualmente, o sistema poderá não estar disponível por motivos técnicos ou falhas da internet, ou por qualquer outro evento fortuito ou de força maior alheio ao controle da Use Mais (proprietária do Website).</p>

  <h3>17. DA PROPRIEDADE INTELECTUAL</h3>
  <p>17.1. O uso comercial da expressão “Faça Jus” como marca, nome empresarial ou nome de domínio, bem como os conteúdos das telas relativas aos serviços do Faça Jus, assim como os programas, bancos de dados, redes e arquivos, que permitem que o Usuário acesse e use sua conta, são de propriedade da Use Mais Multiserviços Ltda e estão protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais. O uso indevido e a reprodução total ou parcial dos referidos conteúdos são proibidos, salvo a autorização expressa da Use Mais.</p>

  <h3>18. DA INDENIZAÇÃO</h3>
  <p>18.1. O Usuário indenizará o Faça Jus, a Use Mais e suas filiais, empresas controladas ou controladoras, diretores, administradores, colaboradores, representantes e empregados por qualquer demanda promovida por outros Usuários ou terceiros decorrentes de suas atividades no site ou por seu descumprimento dos Termos de Uso ou pela violação de qualquer lei ou direitos de terceiros, incluindo honorários de advogados.</p>
  <p>Não obstante a existência deste Termo de Uso, a relação entre a Use Mais, por meio do Faça Jus, e o Usuário é regida pelas leis brasileiras, em especial pelo Código de Defesa do Consumidor.</p>

  <p>Versão 004 - atualizada em 13.08.2024</p>
  <p>USE MAIS MULTISERVIÇOS LTDA</p>
  <p>CNPJ 12.751.349/0001-44</p>
</div>
  );
};

export default TermoDeUsoText;
