import React from 'react';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Paragraph from '../Paragraph';
import ParagraphSmall from '../ParagraphSmall';

const PoliticaDePrivacidadeText= () => {
    // return (
    //     <p className='paragraph-small '>
        
    //     A USEDIGI MEIOS DE PAGAMENTOS LTDA, pessoa jurídica de direito privado, inscrita no CNPJ
    //     sob n° 40.153.803/0001-09, com sede na Av. Paulista, nº 37, conjunto 41 9 VGS, Bairro Bela
    //     Vista, na capital do Estado de São Paulo, proprietária do site https://www.facajus.com.br, leva
    //     sua política a sério e zela pela segurança e proteção de dados pessoais de todos os clientes
    //     (“Usuário” ou “você”).
    //     <br></br>
    //     Uma das nossas metas é fortalecer a sua confiança em nosso trabalho. Por isso, queremos te
    //     contar como usamos e protegemos seus dados pessoais quando você acessa nossa página oficial
    //     (“site”).
    //     <br></br>
    //     1. O que você precisa saber antes de ler esse aviso?
    //     A presente Política de Privacidade fornece uma visão geral de nossas práticas de privacidade,
    //     das escolhas que você pode fazer, bem como dos direitos que você pode exercer em relação aos
    //     seus Dados Pessoais.
    //     <br></br>
    //     Nossa intenção é trazer a você informações a respeito do modo como tratamos, total ou
    //     parcialmente, de forma automatizada ou não, os seus dados pessoais. Seu objetivo é esclarecer
    //     aos interessados acerca dos tipos de dados que são coletados, dos motivos da coleta e da forma
    //     como o usuário poderá atualizar, gerenciar ou excluir estas informações.
    //     Esta Política de Privacidade foi elaborada em conformidade com Lei Federal n. 13.709, de 14 de
    //     agosto de 2018 (Lei Geral de Proteção de Dados Pessoais) e com os padrões internacionais de
    //     Compliance.
    //     <br></br>
    //     Esta Política de Privacidade poderá ser atualizada em decorrência da eventual atualização
    //     normativa, razão pela qual convida-se o usuário a consultar periodicamente esta seção.
    //     <br></br>
    //     2. Direitos dos Usuários
    //     <br></br>
    //     A UseDigi se compromete a cumprir as normas previstas na legislação, principalmente, mas não
    //     se limitando a Lei Geral de Proteção de Dados, em respeito aos princípios dispostos no artigo 6°
    //     da Lei 13.709/2018:
    //     <br></br>
    //     Princípio da finalidade: Os dados pessoais do usuário serão coletados apenas para finalidades
    //     determinadas, explícitas e legítimas, constantes nesta Política de Privacidade, não podendo ser
    //     tratado posteriormente de uma forma incompatível com essas finalidades.
    //     Princípio da adequação: Os dados pessoais solicitados aos usuários serão compatíveis com a
    //     finalidade do tratamento de dados a ser realizado pela UseDigi.
    //     <br></br>
    //     Princípio da necessidade: Os dados pessoais do usuário serão coletados de forma adequada,
    //     pertinente e limitada às necessidades do objetivo para os quais eles serão tratados.
    //     <br></br>
    //     Princípio do livre acesso: Você poderá solicitar, a qualquer momento e de forma gratuita,
    //     informações sobre como seus dados pessoais estão sendo tratados.
    //     <br></br>
    //     Princípio da qualidade dos dados: o usuário poderá ter acesso ao tratamento de dados realizado
    //     de forma confiável, garantindo a autodeterminação informativa, ou seja, as informações estarão
    //     sempre completas e atualizadas.
    //     <br></br>
    //     Princípio da transparência: os seus dados pessoais serão processados de forma transparente.
    //     <br></br>
    //     Princípio da segurança: Os dados pessoais serão tratados de forma segura, protegidos do
    //     tratamento não autorizado ou ilícito e contra a sua perda, destruição ou danificação acidental,
    //     adotando as medidas técnicas ou organizativas adequadas.
    //     <br></br>
    //     Princípio da prevenção: A UseDigi adota medidas para prevenir a ocorrência de danos em
    //     virtude do tratamento dos dados pessoais dos usuários do site.
    //     <br></br>
    //     Princípio da não discriminação: Os dados pessoais tratados nunca serão tratados/destinados
    //     para fins discriminatórios ilícitos ou abusivos.
    //     <br></br>
    //     O usuário possui os seguintes direitos, conferidos pela Lei Geral de Proteção de Dados:
    //     <br></br>
    //     Direito de confirmação e acesso: é direito do usuário de obter da UseDigi a confirmação de que
    //     os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for esse o caso,
    //     o direito de acessar os seus dados pessoais.
    //     <br></br>
    //     Direito de retificação: é o direito do usuário de obter, sem demora injustificada, a retificação
    //     dos dados pessoais inexatos que lhe digam respeito.
    //     <br></br>
    //     Direito à eliminação dos dados: é o direito do usuário de ter seus dados apagados, quando não
    //     houver determinação legal e/ou judicial que assegure a manutenção dos dados pessoais pela
    //     UseDigi.
    //     <br></br>
    //     Direito à limitação do tratamento dos dados: é o direito do usuário de limitar o tratamento de
    //     seus dados pessoais, podendo obtê-la quando contesta a exatidão dos dados, quando o
    //     tratamento for ilícito, quando a UseDigi não precisar mais dos dados para as finalidades
    //     propostas e quando estiver oposto ao tratamento dos dados em caso de tratamento de dados
    //     desnecessários.
    //     <br></br>
    //     Direito de oposição: é o direito do usuário de, a qualquer momento, se opor por motivos
    //     relacionados com a sua situação particular, ao tratamento de dados pessoais que lhe digam
    //     respeito, podendo se opor ainda ao uso de seus dados pessoais para definição de perfil de
    //     marketing (profiling).
    //     <br></br>
    //     Direito de portabilidade dos dados: é o direito do usuário de receber os dados pessoais que lhe
    //     digam respeito e que tenha fornecido através do site, num formato estruturado, de uso corrente
    //     e de leitura automática, e o direito de transmitir esses dados a outros sites e serviços, mediante
    //     solicitação.
    //     <br></br>
    //     Direito de não ser submetido a decisões automatizadas: é o direito do usuário de não ficar
    //     sujeito a nenhuma decisão tomada exclusivamente com base no tratamento automatizado,
    //     incluindo a definição de perfis (profiling), que produza efeitos na sua esfera jurídica ou que o
    //     afete significativamente de forma similar.
    //     <br></br>
    //     O usuário poderá exercer os seus direitos por meio de comunicação escrita enviado a e-mail
    //     dpo@ciscatoadvocacia.com.br com o assunto “LGPD – SITE FAÇAJUS”, especificando:
    //     <br></br>
    //     - Nome completo, número do CPF (Cadastro de Pessoas Físicas, da Receita Federal do
    //     Brasil) e endereço de e-mail do usuário;
    //     <br></br>
    //     - Direito que deseja exercer;
    //     <br></br>
    //     - Data do pedido e assinatura do usuário; 
    //     <br></br>
    //     - Todo documento que possa demonstrar ou justificar o exercício de seu direito.
    //     <br></br>
    //     O pedido, também, poderá ser enviado por correio para o destinatário abaixo:
    //     CISCATO & ADVOGADOS ASSOCIADOS
    //     Rua Reinaldino Schaffenberg de Quadros, 1529, Cristo Rei,
    //     Curitiba/PR, CEP: 80050-435, A/C DPO
    //     O usuário será informado em caso de retificação ou eliminação, quando possível, dos seus dados
    //     pessoais.
    //     <br></br>
    //     3. O que é tratamento de dados pessoais?
    //     <br></br>
    //     Tratamento de dados pessoais é o que fazemos com os dados pessoais dos usuários, por
    //     exemplo, como vamos coletar, classificar, utilizar, acessar, processar, armazenar, eliminar,
    //     modificar, compartilhar, transferir, etc.
    //     <br></br>
    //     Ao utilizar os serviços do nosso site você está consentindo com a presente Política de
    //     Privacidade.
    //     <br></br>
    //     Na condição de titular dos dados pessoais coletados no nosso site, você possui o direito de obter
    //     da UseDigi a qualquer momento e mediante simples requisição (quando aplicável):
    //     <br></br>
    //     - Confirmação da existência de tratamento;
    //     <br></br>
    //     - Acesso aos dados;
    //     <br></br>
    //     - Correção de dados incompletos, inexatos ou desatualizados;
    //     <br></br>
    //     - Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados
    //     em desconformidade com o disposto na legislação;
    //     <br></br>
    //     - Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição
    //     expressa e observados os segredos comercial e industrial, de acordo com a
    //     regulamentação do órgão controlador;
    //     <br></br>
    //     - Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas
    //     hipóteses previstas no art. 16 da Lei 13709/18;
    //     <br></br>
    //     - Informação das entidades públicas e privadas com as quais o controlador realizou uso
    //     compartilhado de dados;
    //     <br></br>
    //     - Informação sobre a possibilidade de não fornecer consentimento e sobre as
    //     consequências da negativa; e
    //     <br></br>
    //     - Revogação do consentimento, nos termos do art. 8º da Lei 13709/18.
    //     <br></br>
    //     Ainda, informamos que o usuário (você) tem o direito de retirar seu consentimento a qualquer
    //     momento, não comprometendo a licitude do tratamento de seus dados pessoais antes da
    //     retirada. A retirada do consentimento poderá ser feita pelo email:
    //     dpo@ciscatoadvocacia.com.br ou por correio, cuja correspondência deverá ser destinada
    //     ao endereço abaixo:
    //     CISCATO & ADVOGADOS ASSOCIADOS
    //     Rua Reinaldino Schaffenberg de Quadros, 1529, Cristo Rei,
    //     Curitiba/PR, CEP: 80050-435, A/C DPO
    //     <br></br>
    //     O tratamento de dados pessoais sem o consentimento do usuário apenas será realizado em
    //     razão de legítimo interesse ou para as hipóteses previstas na Lei 13.709/2018, ou seja, dentre
    //     outras, as seguintes:
    //     <br></br>
    //     - Para o cumprimento de obrigação legal ou regulatório pelo controlador dos dados
    //     pessoais;
    //     <br></br>
    //     - Para a realização de estudos por órgãos de pesquisa, garantida, sempre que
    //     possível, a anonimização dos dados pessoais;
    //     <br></br>
    //     - Quando necessário para a execução de contrato ou de procedimentos preliminares
    //     relacionados a contrato do qual seja parte o usuário, a pedido do titular de dados;
    //     <br></br>
    //     - Para o exercício regular de direitos em processo judicial, administrativo ou arbitral,
    //     esse último nos termos da Lei n° 9.307, de 23 de setembro de 1996 (Lei de
    //     Arbitragem);
    //     <br></br>
    //     - Para a proteção da vida ou da incolumidade física do titular de dados ou de
    //     terceiros;
    //     <br></br>
    //     - Para a tutela da saúde, em procedimento realizado por profissionais da área da
    //     saúde ou por entidades sanitárias;
    //     <br></br>
    //     - Quando necessário para atender aos interesses legítimos do controlador ou de
    //     terceiros, exceto no caso de prevalecerem direitos e liberdades fundamentais do
    //     titular dos dados que exijam a proteção dos dados pessoais;
    //     <br></br>
    //     - Para a proteção do crédito, inclusive quanto ao disposto na legislação pertinente.
    //     <br></br>
    //     3.1. Qual a finalidade do tratamento dos dados pessoais?
    //     <br></br>
    //     Os dados pessoais do usuário coletados através de nosso site têm por finalidade facilitar, agilizar
    //     e cumprir os compromissos estabelecidos com o usuário e a fazer cumprir as solicitações
    //     realizadas.
    //             <br></br>
    //     Nós utilizamos os seus dados pessoais para poder entregar uma melhor experiência de
    //     navegação, de forma rápida e eficaz.
    //     <br></br>
    //     Utilizamos seus dados pessoais também para finalidade comercial, personalizar conteúdo
    //     oferecido ao usuário, bem como para dar subsídio ao site para a melhora da qualidade e
    //     funcionamento de seus serviços, de forma a identificar e disponibilizar (diretamente pela
    //     UseDigi ou empresas do nosso grupo) produtos e serviços da UseDigi e de empresas parceiras
    //     de forma mais assertiva e conforme os seus interesses. É por isso que queremos poder acessar
    //     os seus dados pessoais para ofertar produtos e serviços da forma mais personalizada possível,
    //     sempre dentro dos limites estabelecidos na Lei Geral de Proteção de Dados.
    //     <br></br>
    //     Também podemos tratar seus dados para entrar em contato com você quando necessário, para
    //     cumprir obrigações legais e regulatórias, exercer nossos direitos e deveres em algum processo
    //     judicial, administrativo ou arbitral, realizar a sua manutenção cadastral, garantir a sua segurança
    //     durante a navegação, além de outros possíveis usos que estarão sempre suportados por uma
    //     base legal.
    //     <br></br>
    //     Você tem o nosso compromisso de manter a transparência dos usos desses dados.
    //     <br></br>
    //     É importante que você saiba que a UseDigi atua sob supervisão de diversos órgãos reguladores,
    //     e precisa dos seus dados para cumprir obrigações legais e regulatórias. Além disto, adotamos
    //     procedimentos de prevenção à fraude, com o intuito de te proteger e de garantir que seus dados
    //     não sejam usados de forma indevida.
    //     <br></br>
    //     A coleta dos seus dados também é necessária para possibilitar a resolução de suas reclamações,
    //     dúvidas e solicitações. Porém, se você resolver nos deixar, vamos respeitar a sua decisão e
    //     faremos a exclusão de seus dados, exceto os que a lei nos manda ou permite guardar aqui com
    //     a gente.
    //     <br></br>
    //     Reforçamos que o tratamento de dados pessoais para finalidades não previstas nesta Política de
    //     Privacidade somente ocorrerá mediante comunicação prévia ao usuário, sendo que, em
    //     qualquer caso, os direitos e obrigações aqui previstos permanecerão aplicáveis.
    //     <br></br>
    //     4. Do encarregado de proteção de dados (data protection officer)
    //     <br></br>
    //     O encarregado de proteção de dados (data protection officer) é o profissional encarregado de
    //     informar, aconselhar e controlar o responsável pelo tratamento dos dados e o processador de
    //     dados subcontratado, bem como os trabalhadores que tratem os dados, a respeito das
    //     obrigações do site nos termos da Lei de Proteção de Dados Pessoais e de outras disposições de
    //     proteção de dados presentes na legislação nacional, em cooperação com a autoridade de
    //     controle competente.
    //     <br></br>
    //     O encarregado de proteção de dados da UseDigi é CISCATO & ADVOGADOS ASSOCIADOS, que
    //     poderá ser contactado pelo e-mail: dpo@ciscatoadvocacia.com.br.
    //     <br></br>
    //     5. Da Coleta de dados pessoais
    //     <br></br>
    //     É importante esclarecer que todos os servidores web rastreiam informações básicas acerca de
    //     seus visitantes. Tais informações incluem (mas não se limitam) a endereços IP, detalhes do
    //     navegador, registro de data e hora e páginas de referência. Nenhuma destas informações pode
    //     identificar pessoalmente visitantes específicos do nosso site. Estas informações são rastreadas
    //     com o propósito de administrar e manter o site.
    //     <br></br>
    //     Abaixo elencamos as atividades em que são coletados dados pessoais e para que são utilizados:
    //     <br></br>
    //     5.1. Tipos de Dados Coletados
    //     <br></br>
    //     5.1.1. Ícone “Entrar”
    //     <br></br>
    //     Ao clicar no ícone “Entrar”, disponibilizado no canto superior direito da página inicial de nosso
    //     site, você poderá escolher as opções “Cliente” ou “Advogado”, e será direcionado à página de
    //     acesso ao usuário para realizar o login ou se cadastrar em nossa plataforma.
    //     <br></br>
    //     Caso você já possua uma conta cadastrada, será requisitado apenas a confirmação do seu “CPF”
    //     e “Senha” para efetuar o login.
    //     <br></br>
    //     5.1.2. Ícone “Cadastrar”
    //     <br></br>
    //     O ícone “Cadastrar”, disponibilizado no canto superior direito da página inicial de nosso site,
    //     também possui as opções “Cliente” e “Advogado”.
    //     <br></br>
    //     Ao clicar na opção “Cliente”, você será redirecionado para a página de registro, na qual poderá
    //     criar sua conta como cliente, sendo necessária a coleta dos seguintes dados pessoais: “E-mail”,
    //     “Telefone” e “CPF”, além da elaboração de uma senha de acesso.
    //     <br></br>
    //     Ao clicar na opção “Advogado”, você será redirecionado para a página de registro, na qual
    //     poderá criar sua conta como advogado, sendo necessária a coleta dos seguintes dados pessoais:
    //     “Nome”, “E-mail”, “Telefone”, “CPF”, “Data de Nascimento” e “Estado”, além da elaboração de
    //     uma senha de acesso. Após o preenchimento destas informações, será encaminhado um link
    //     para o e-mail indicado para validação da conta.
    //     <br></br>
    //     Com a validação, o advogado deverá anexar as seguintes fotos: “Carteira da OAB frente”,
    //     “Carteira da OAB verso” e “Diploma”. Na sequência, deverão ser selecionadas as áreas de
    //     atuação do advogado e o plano da plataforma, assim como o preenchimento dos dados de
    //     pagamento, incluindo “Dados Bancários”, “Número do Cartão de Crédito”, “Nome”, “CPF” e
    //     “Celular” do pagador. Por fim, o cadastro será completado com a assinatura do contrato e do
    //     termo de consentimento para tratamento de dados.
    //     <br></br>
    //     5.1.3. Aba “Fale Conosco”
    //     <br></br>
    //     Ao selecionar a aba “Fale Conosco”, o usuário poderá preencher o formulário disponível nessa
    //     aba para entrar em contato com a UseDigi. Os dados coletados por meio deste formulário são:
    //     “Nome”, “Telefone” e “E-mail”.
    //     <br></br>
    //     Os dados acima serão tratados exclusivamente com a finalidade de entendermos quem nos
    //     procura, sua necessidade e para podermos retornar/responder sua solicitação da forma mais
    //     célere possível.
    //     <br></br>
    //     Além disso, utilizamos as informações coletadas para propósitos específicos que incluem o
    //     fornecimento de serviços, agilidade na comunicação, divulgação de eventos e parcerias feitas
    //     pela nossa empresa e envio de newsletter e e-mail marketing aos usuários cadastrados, em
    //     razão do fornecimento consentido dos dados.
    //     <br></br>
    //     5.1.4. Aba “Fórum”
    //     <br></br>
    //     Ao selecionar a aba “Fórum”, o usuário cliente poderá tirar suas dúvidas sobre seu caso com os
    //     advogados especialistas. Dessa forma, o usuário cliente deverá selecionar a área do seu caso e
    //     escrever sua mensagem, a qual será publicada de forma anônima.
    //     <br></br>
    //     A dúvida do usuário cliente será encaminhada aos usuários advogados que forem especialistas
    //     na área informada e as respostas serão publicadas no Fórum, constando o nome e avaliação de
    //     quem respondeu.
    //     <br></br>
    //     5.1.5. Aba “Segurança”
    //     <br></br>
    //     Ao selecionar a aba “Segurança”, você terá acesso às políticas de segurança da UseDigi e da
    //     plataforma Faça Jus.
    //     <br></br>
    //     5.2. Prazo de conservação de dados pessoais
    //     <br></br>
    //     Os dados pessoais do usuário serão conservados por um período não superior ao exigido para
    //     cumprir os objetivos em razão dos quais eles são processados e/ou pelo prazo definido em
    //     legislação, quando aplicável. Os dados pessoais dos usuários apenas poderão ser conservados
    //     após o término de seu tratamento nas seguintes hipóteses:
    //     <br></br>
    //     - Para o cumprimento de obrigação legal ou regulatório pelo controlador;
    //     <br></br>
    //     - Para estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos
    //     dados pessoais;
    //     <br></br>
    //     - Para uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que
    //     anonimizado os dados.
    //     <br></br>
    //     5.3. Seus dados pessoais podem ser compartilhados?
    //     <br></br>
    //     Sim, nós podemos compartilhar seus dados, mas somente com propósitos específicos
    //     respaldados na lei e, eventualmente, compartilharemos alguns de seus dados com órgãos
    //     públicos, autoridades governamentais, judiciais ou administrativas e prestadores de serviços.
    //     <br></br>
    //     Não se preocupe, nós adotamos políticas, procedimentos e mecanismos, tais como
    //     instrumentos contratuais e auditorias, para assegurar que quem receber os seus dados pessoais
    //     vai cuidar deles adequadamente e aplicar a mesma proteção que a UseDigi aplica.
    //     <br></br>
    //     A UseDigi não comercializa com ninguém, em nenhuma hipótese, as informações e dados
    //     pessoais a que tenha acesso. A UseDigi sabe, ainda, que seu perfil de utilização de serviços e
    //     recursos poderá nos auxiliar no oferecimento de um conteúdo personalizado. Contudo, da
    //     mesma forma que ocorre com todas as informações que possam lhe identificar, não iremos
    //     disponibilizá-las a terceiros sem a sua permissão.
    //     <br></br>
    //     Poderemos revelar, transferir e/ou compartilhar seus dados pessoais nos seguintes casos
    //     específicos:
    //     <br></br>
    //     I. Para os nossos Colaboradores: No curso normal dos negócios da empresa (e para os fins de
    //     tratamento), poderemos compartilhar seus dados pessoais com nossos Colaboradores,
    //     devidamente autorizados e contratados, para assegurar a consistência e a continuidade
    //     operacional de nossos serviços;
    //     <br></br>
    //     II. Para Prestadores de Serviços de Marketing: Para o oferecimento de anúncios relevantes ao
    //     seu perfil, a UseDigi poderá se valer da ferramenta de e-mail marketing, push, ligações e
    //     mensagens em geral que poderão ser enviadas por empresas de comunicação que atuem em
    //     nome da empresa e/ou pela própria empresa UseDigi; 
    //     <br></br>
    //     III. Outros: A UseDigi poderá, por fim, divulgar informações sobre o usuário quando exigido por
    //     lei ou por autoridades reguladoras ou, ainda, em casos excepcionais nos quais acreditemos que
    //     a divulgação dessas informações se mostre necessária para identificar, entrar em contato e/ou
    //     agir legalmente contra alguém que esteja causando danos ou interferindo (intencionalmente ou
    //     não) nos direitos de propriedade de nosso site e das marcas registradas pela UseDigi, nos
    //     direitos de outros usuários do site ou de qualquer pessoa que seja prejudicada por tais
    //     atividades.
    //     <br></br>
    //     6. Segurança no tratamento de dados pessoais do usuário
    //     <br></br>
    //     Proteger as suas informações faz parte do nosso compromisso. Atuamos sempre seguindo a
    //     legislação brasileira, todos os dados pessoais recebidos, transmitidos e/ou compartilhados
    //     através de nosso site são armazenados em servidores seguros.
    //     <br></br>
    //     A UseDigi se compromete em aplicar as medidas técnicas e organizativas aptas a proteger os
    //     dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração,
    //     comunicação ou difusão de tais dados.
    //     <br></br>
    //     Para a garantia da segurança, serão adotadas soluções que levem em consideração: as técnicas
    //     adequadas; os custos de adaptação; a natureza, o âmbito, o contexto e as finalidades do
    //     tratamento; e, os riscos para os direitos e liberdades do usuário.
    //     <br></br>
    //     No entanto, a UseDigi se exime de responsabilidade por culpa exclusiva de terceiros, como em
    //     caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele
    //     mesmo transfere seus dados a terceiro. A UseDigi se compromete, ainda, a comunicar o usuário
    //     em prazo adequado caso ocorra algum tipo de violação de segurança de seus dados pessoais
    //     que possa lhe causar um alto risco para seus direitos e liberdades pessoais.
    //     <br></br>
    //     A violação de dados pessoais é uma violação de segurança que provoque, de modo acidental ou
    //     ilícito, a destruição, a perda, a alteração, a divulgação ou o acesso não autorizado a dados
    //     pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento.
    //     <br></br>
    //     Por fim, a UseDigi se compromete em tratar os dados pessoais do usuário com
    //     confidencialidade, dentro dos limites legais.
    //     <br></br>
    //     7. Dados de navegação (cookies)
    //     <br></br>
    //     Cookies são pequenos arquivos de texto enviados pelo site ao computador do usuário e que nele
    //     ficam armazenados, com informações relacionadas à navegação em nosso site. Por meio dos
    //     cookies, pequenas quantidades de informação são armazenadas pelo navegador do usuário para
    //     que nosso servidor possa lê-las posteriormente. Podem ser armazenados, por exemplo, dados
    //     sobre o dispositivo utilizado pelo usuário, bem como seu local e horário de acesso ao nosso site.
    //     <br></br>
    //     Os cookies não permitem que qualquer arquivo ou informação sejam extraídos do disco rígido
    //     do usuário, não sendo possível, ainda, que, por meio deles, se tenha acesso as informações
    //     pessoais que não tenham partido do usuário ou da forma como utiliza os recursos do nosso site.
    //     <br></br>
    //     É importante ressaltar que nem todo cookie contém informações que permitem a identificação
    //     do usuário, sendo que determinados tipos de cookies podem ser empregados para que o site
    //     seja carregado corretamente, tenha melhor desempenho ou para que suas funcionalidades
    //     funcionem do modo esperado. As informações eventualmente armazenadas em cookies que
    //     permitam identificar um usuário são consideradas dados pessoais. Dessa forma, todas as regras
    //     previstas nesta Política de Privacidade também lhes são aplicáveis.
    //     <br></br>
    //     7.1. Cookies do site
    //     <br></br>
    //     Os cookies do site são aqueles enviados ao computador ou dispositivo do usuário e
    //     administrador exclusivamente pelo nosso site.
    //     <br></br>
    //     As informações coletadas por meio destes cookies são utilizadas para melhorar e personalizar a
    //     experiência do usuário, sendo que alguns cookies podem ser utilizados para lembrar as
    //     preferências e escolhas do usuário, bem como para o oferecimento de conteúdo personalizado.
    //     <br></br>
    //     7.2. Gestão dos cookies e configurações do navegador
    //     <br></br>
    //     O usuário poderá se opor ao registro de cookies, bastando que desative esta opção no seu
    //     próprio navegador ou aparelho móvel.
    //     <br></br>
    //     A desativação dos cookies, no entanto, pode afetar a disponibilidade de algumas ferramentas e
    //     funcionalidades do nosso site, comprometendo seu correto e esperado funcionamento. Outra
    //     consequência possível é remoção das preferências do usuário que eventualmente tiverem sido
    //     salvas, prejudicando sua experiência.
    //     <br></br>
    //     8. Das alterações.
    //     <br></br>
    //     A presente versão desta Política de Privacidade foi atualizada pela última vez em: 12.07.2023.
    //     <br></br>
    //     O editor se reserva o direito de modificar, a qualquer momento as presentes normas,
    //     especialmente para adaptá-las às evoluções de nosso site, seja disponibilização de novas
    //     funcionalidades, seja pela supressão ou modificação daquelas já existentes.
    //     <br></br>
    //     O usuário será explicitamente notificado em caso de alteração desta política.
    //     <br></br>
    //     Ao utilizar o serviço após eventuais modificações, o usuário demonstra sua concordância com
    //     as novas normas. Caso discorde de alguma modificação, deverá interromper, imediatamente, o
    //     acesso ao nosso site e apresentar a sua ressalva ao serviço de atendimento, se assim o desejar.
    //     <br></br>
    //     9. Do direito aplicável e do foro
    //     Para a solução das controvérsias decorrentes do presente instrumento, será aplicado
    //     integralmente a legislação brasileira.
    //     <br></br>
    //     Os eventuais litígios deverão ser apresentados no foro da Comarca de São Paulo-SP, onde se
    //     encontra a sede da UseDigi.
    //     <br></br>
    //     10. Onde você pode obter mais informações?
    //     <br></br>
    //     Em caso de dúvidas sobre a nossa Política de Privacidade, você poderá entrar em contato
    //     conosco por meio do e-mail: 
    //     <br></br>
    //     dpo@ciscatoadvocacia.com.br.
    //     <br></br>
    //     USEDIGI MEIOS DE PAGAMENTOS LTDA
    //     <br></br>
    //     CNPJ: 40.153.803/0001-09
    //     " 
    //     </p>

      
    //   );

    return (
      <div>

      <p>Este site é mantido e operado por Use Mais Multiserviços Ltda, proprietária do FAÇA JUS. A empresa coleta e utiliza alguns dados pessoais que pertencem aos usuários do site. Ao fazer isso, a Use Mais age na qualidade de controladora desses dados e está sujeita às disposições da Lei Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD).</p>
      <p>A empresa se responsabiliza pela proteção dos dados pessoais dos usuários e, portanto, disponibiliza esta política de privacidade, que contém informações importantes sobre:</p>
      <ul>
        <li>Quais dados são coletados e o que é feito com eles;</li>
        <li>O direito do usuário em relação aos seus dados pessoais; e</li>
        <li>Como entrar em contato conosco.</li>
      </ul>

      <h3>1. Dados pessoais coletados e os motivos da coleta</h3>
      <p>O site do Faça Jus coleta e utiliza alguns dados pessoais dos usuários, conforme detalhado nesta seção.</p>

      <h3>1.1. Dados pessoais fornecidos expressamente pelo usuário</h3>
      <p>São coletados os seguintes dados pessoais que o usuário fornece expressamente ao utilizar o site:</p>
      <ul>
        <li>CPF;</li>
        <li>Nome completo;</li>
        <li>E-mail;</li>
        <li>Telefone / Celular;</li>
        <li>Número da OAB;</li>
        <li>Estado;</li>
        <li>CEP;</li>
        <li>Foto e Foto de documento;</li>
        <li>Dados Bancários: Número do Cartão de Crédito, Dados Bancários.</li>
      </ul>
      <p>Os dados são coletados no momento que o usuário realiza o cadastro na plataforma.</p>
      <p>As informações fornecidas pelos usuários são coletadas com as seguintes finalidades:</p>
      <ul>
        <li>Realizar o cadastro e gestão das informações;</li>
        <li>Proporcionar a operacionalização e comercialização do produto Faça Jus.</li>
      </ul>

      <h3>1.2. Dados sensíveis</h3>
      <p>Não serão coletados dados sensíveis dos usuários, assim entendidos aqueles definidos nos arts.11 e seguintes da Lei de Proteção de Dados Pessoais. Assim, não haverá coleta de dados sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.</p>

      <h3>1.3. Coleta de dados não previstos expressamente</h3>
      <p>Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade poderão ser coletados, desde que sejam fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja permitida com fundamento em outra base legal prevista em lei.</p>
      <p>Em qualquer caso, a coleta de dados e as atividades de tratamento dela decorrentes serão informadas aos usuários do site.</p>

      <h3>2. Compartilhamento de dados pessoais com terceiros</h3>
      <p>A Use Mais, que mantém e opera o Faça Jus, somente compartilha dados pessoais dos usuários servidores públicos que desejam incluir os valores de plano e/ou consultas em desconto na margem consignável.</p>
      <p>Portanto, o usuário servidor público que contratar o Faça Jus, fica ciente que ao aceitar esta política está aceitando que a Use Mais compartilhe seus dados pessoais, para fins de averbação de descontos consignados, sendo estes dados destinados à consignatárias parcerias do Faça Jus, detentoras de rubricas e convênios com órgãos públicos.</p>
      <p>Para todos os demais usuários, a Use Mais não compartilha os dados pessoais de seus usuários com terceiros. No entanto, é possível que tal compartilhamento ocorra para cumprir alguma determinação legal ou regulatória, ou ainda, para atender a alguma ordem emitida por autoridade pública.</p>

      <h3>3. Por quanto tempo seus dados pessoais serão armazenados</h3>
      <p>Os dados pessoais coletados serão armazenados e utilizados pelos seguintes períodos:</p>
      <ul>
        <li>Os dados serão armazenados por todo o período em que o usuário é ativo na plataforma.</li>
      </ul>
      <p>Os períodos informados não são superiores ao estritamente necessário, atendendo às finalidades e às justificativas legais para o tratamento dos dados.</p>
      <p>Vale dizer que, se houver alguma justificativa legal ou regulatória, os dados poderão continuar armazenados ainda que a finalidade para a qual foram coletados ou tenham sido tratados tenha se exaurido.</p>
      <p>Uma vez finalizado o tratamento, observadas as disposições desta seção, os dados são apagados ou anonimizados.</p>

      <h3>4. Bases legais para o tratamento de dados pessoais</h3>
      <p>Cada operação de tratamento de dados pessoais precisa ter um fundamento jurídico, ou seja, uma base legal, que nada mais é que uma justificativa que a autorize, prevista na Lei Geral de Proteção de Dados Pessoais.</p>
      <p>Todas as atividades de tratamento de dados pessoais possuem uma base legal que as fundamenta, dentre as permitidas pela legislação. Mais informações sobre as bases legais que a Use Mais utiliza para operações de tratamento de dados pessoais específicas podem ser obtidas a partir dos canais de contato, informados ao final desta Política.</p>

      <h3>5. Direito do titular</h3>
      <p>O titular dos dados pessoais tem direito a obter do controlador, em relação aos dados do titular por ele tratados, a qualquer momento e mediante requisição:</p>
      <ul>
        <li>Confirmação da existência de tratamento;</li>
        <li>Acesso aos dados;</li>
        <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
        <li>Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei Geral de Proteção de Dados;</li>
        <li>Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</li>
        <li>Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da LGPD;</li>
        <li>Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</li>
        <li>Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
        <li>Revogação do consentimento, nos termos do § 5º do art. 8º da LGPD.</li>
      </ul>

      <h3>5.1. Como o titular pode exercer seus direitos</h3>
      <p>Para garantir que o usuário que pretende exercer seus direitos é, de fato, o titular dos dados pessoais objeto da requisição, a Use Mais poderá solicitar documentos ou outras informações que possam auxiliar em sua correta identificação, a fim de resguardar nossos direitos e os direitos de terceiros. Isto somente será feito, porém, se for absolutamente necessário, e o requerente receberá todas as informações relacionadas.</p>

      <h3>5.2. Do encarregado de proteção de dados (data protection officer)</h3>
      <p>O encarregado de proteção de dados (Data Protection Officer) é o profissional encarregado de informar, aconselhar e controlar o responsável pelo tratamento dos dados e o processador de dados subcontratado, bem como os trabalhadores que tratem os dados, a respeito das obrigações do site nos termos da Lei de Proteção de Dados Pessoais e de outras disposições de proteção de dados presentes na legislação nacional, em cooperação com a autoridade de controle competente.</p>
      <p>O encarregado de proteção de dados (Data Protection Officer) da Use Mais Multiserviços Ltda é ALLAN KOVALSCKI, que pode ser contatado pelo e-mail: dpo@complylgpd.com.br.</p>

      <h3>6. Medidas de segurança no tratamento de dados pessoais</h3>
      <p>A Use Mais emprega medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, extravio ou alteração desses dados. As medidas utilizadas levam em consideração a natureza dos dados, o contexto e a finalidade do tratamento, os riscos que uma eventual violação geraria para os direitos e liberdades do usuário, e os padrões atualmente empregados no mercado por empresas semelhantes à nossa.</p>
      <p>Entre as medidas de segurança adotadas, destaca-se as seguintes:</p>
      <ul>
        <li>Os dados dos usuários são armazenados em ambiente seguro;</li>
        <li>São limitados os acessos aos dados dos usuários, de modo que terceiros não autorizados não possam acessá-los;</li>
        <li>São registrados todos aqueles que têm, de alguma forma, contato com os dados.</li>
      </ul>
      <p>A Use Mais compromete-se a revisar suas medidas de segurança periodicamente, adequando-as sempre que necessário.</p>

      <h3>7. Cookies</h3>
      <p>Cookies são pequenos arquivos de texto enviados pelo site ao computador do usuário e que nele ficam armazenados, com informações relacionadas à navegação em nosso site.</p>
      <p>Por meio dos cookies, pequenas quantidades de informação são armazenadas pelo navegador do usuário para que nosso servidor possa lê-las posteriormente. Podem ser armazenados, por exemplo, dados sobre o dispositivo utilizado pelo usuário, bem como seu local e horário de acesso ao nosso site.</p>
      <p>Os cookies não permitem que qualquer arquivo ou informação sejam extraídos do disco rígido do usuário, não sendo possível, ainda, que, por meio deles, se tenha acesso às informações pessoais que não tenham partido do usuário ou da forma como utiliza os recursos do nosso site.</p>
      <p>É importante ressaltar que nem todo cookie contém informações que permitem a identificação do usuário, sendo que determinados tipos de cookies podem ser empregados simplesmente para que o nosso site seja carregado corretamente ou para que suas funcionalidades funcionem do modo esperado.</p>

      <h3>8. Alterações nesta Política</h3>
      <p>A presente versão desta política de privacidade foi atualizada pela última vez em 13 de agosto de 2024.</p>
      <p>A Use Mais reserva o direito de modificar, a qualquer momento, as presentes normas, especialmente para adaptá-las às eventuais alterações feitas no site, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.</p>
      <p>Sempre que houver uma modificação, os usuários serão notificados acerca da mudança.</p>
      <p>Ao utilizar o serviço após eventuais modificações, o usuário demonstra sua concordância com as novas normas. Caso discorde de alguma das modificações, deverá interromper, imediatamente, o acesso ao nosso site e apresentar a sua ressalva ao serviço de atendimento, se assim o desejar.</p>

      <h3>9. Como entrar em contato</h3>
      <p>Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que a Use Mais (que operacionaliza o Faça Jus) trata, entre em contato com o Encarregado de Proteção de Dados Pessoais, pelo e-mail mencionado abaixo:</p>
      <p>Email: dpo@complylgpd.com.br</p>

      </div>
    );
}

export default PoliticaDePrivacidadeText;



