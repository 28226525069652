import React, { useState } from "react";
import "./styles.css";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";


export const AdmSiteAdvModal = ({ show, onHide, base64 }) => {
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        {/* <Col className="d-flex align-items-center justify-content-center text-center">
          <Btn
            type={"secondary-sm"}
            isSubmit={false}
            text="Download"
            // onTouchable={handleShow}
          />
        </Col> */}
      </Modal.Header>
      <Modal.Body>
        <Container className="d-flex justify-content-center align-items-center">
          <img
            className="container-imagem-64"
            src={`data:image/jpg;base64,${base64}`}
            alt="Diploma"
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
};
