import React, { useState } from "react";
import "./styles.css";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import Btn from "../../atoms/Button";
import Paragraph from "../../atoms/Paragraph";
import { useNavigate } from "react-router-dom";
import { format , parseISO, zonedTimeToUtc  } from "date-fns-tz";

export const AgendaAdvModal = ({ show, onHide, data }) => {
  
  const navigate = useNavigate();
  
  const itemsPerPage = 5;
  const [startIndex, setStartIndex] = useState(0);
  
  const handlePrev = () => {
    if (startIndex - itemsPerPage >= 0) {
      setStartIndex(startIndex - itemsPerPage);
    }
  };

  const handleNext = () => {
    if (startIndex + itemsPerPage < Object.keys(data).length) {
      setStartIndex(startIndex + itemsPerPage);
    }
  };
  
  const visibleData = Object.keys(data)
  .slice(
    startIndex,
    startIndex + itemsPerPage
  )
    
  function sendToPreAgendamentoPage(advId) {
    navigate(`/Agendamento/${advId}`)
  }

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Col className="text-center">
          <Subtitle text="Horários Disponíveis" />
          <Subtitle text="Selecione a data e horário para realização da sua consulta" />
        </Col>
      </Modal.Header>
      <Modal.Body>
        <Container className="coluna-horarios d-lg-flex gap-2 text-center align-items-start justify-content-center">
          {visibleData.map((date, index) => {
            const itemsForDate = data[date].filter(item => item.disponibilidade);
            if (itemsForDate.length === 0) {             
              return null;
            }
          
            return (
              <Row lg={12} key={index} className="m-0">
                <Col className="bg-data py-1 px-4 rounded d-flex align-items-center justify-content-center">
                  <Paragraph text={date} />
                </Col>
                <Col className="">
                  {Array.isArray(data[date]) &&
                    data[date]
                    .map(
                      (item, timeIndex) =>
                        item.disponibilidade && (
                          <Col
                            key={timeIndex}
                            className="text-center horario-btn"
                            onClick={() => sendToPreAgendamentoPage(item.adv_id)}
                          >
                            <ParagraphSmall text={item.hora} />
                          </Col>
                        )
                    )}
                </Col>
              </Row>
            );
          })}
        </Container>
      </Modal.Body>
      <Modal.Footer className="d-flex-row  align-items-center justify-content-center">
        {startIndex - itemsPerPage >= 0 && (
          <Btn
            type={"secondary-sm"}
            text="< Voltar"
            isSumbit={false}
            onTouchable={handlePrev}
          />
        )}
        {startIndex + itemsPerPage < Object.keys(data).length && (
          <Btn
            type={"secondary-sm"}
            text="Avançar >"
            isSumbit={false}
            onTouchable={handleNext}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};
