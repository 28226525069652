import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../molecules/Header";
import Footer from "../../molecules/Footer";
import ImgBannerPerfil from "../../atoms/ImgBannerPerfil";
import Subtitle from "../../atoms/Subtitle";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import { Tabs, Tab } from "react-bootstrap";
import ChatCliente from "../../organisms/Chat";

import "./styles.css";
import CabecalhoPerfilCliente from "../../organisms/CabecalhoPerfilCliente";
import { PerguntarForumCli } from "../../organisms/PerguntarForumCli";

const nomeUsuario = sessionStorage.getItem("nome") || "";

export const ForumCli = () => {
  return (
    <body className="mt-5 pt-5">
      <Container fluid className="fluid-bg ">
        <Header />
      </Container>

      <Container fluid className="p-0 m-0 banner-container">
        <ImgBannerPerfil className="img-fluid " />
      </Container>

      <Container>
        <Row className="gap-5">
          <Col lg={2} className="avatar-container">
            <AvatarPerfil
              type="A"
              base64={sessionStorage.getItem("fotoPerfil")}
              width="13rem"
              height="13rem"
              padding="0.4rem"
            />
          </Col>

          <Col lg={8} className="py-2 mt-2 ms-2">
            <Subtitle text={"Olá, " + nomeUsuario} />
          </Col>
        </Row>
      </Container>

      <Container className="my-5">
        <PerguntarForumCli />
      </Container>

      <Container fluid className="fluid-bg ">
        <Footer />
      </Container>
    </body>
  );
};
