import React, { useState } from "react";
import "./styles.css";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import Btn from "../../atoms/Button";
import Paragraph from "../../atoms/Paragraph";
import { AdvogadoService } from "../../../services/AdmAdvogadoServices/AdvogadoService";
import { Toasts } from "../../utils/Toasts";

export const AdmSiteAdvRecusarModal = ({ show, onHide, id }) => {
  const api = new AdvogadoService();

  const [selectedOption, setSelectedOption] = useState("documentosPendentes");
  const [comentario, setComentario] = useState("");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const [showModal, setShowModal] = useState(true);

  const handleConfirm = () => {
    api
      .postRecusarAdovogado(id, selectedOption)
      .then((data) => {
        let optionsToast = {
          code: data.mensagem.codigo,
          message: data.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);

        setTimeout(() => {
          setShowModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 10);
        }, 5000);
      })
      .catch((error) => {
        let optionsToast = {
          code: error.mensagem.codigo,
          message: error.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);

        setTimeout(() => {
          setShowModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 10);
        }, 5000);
      });
  };

  const handleComentarioChange = (e) => {
    setComentario(e.target.value);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      {showModal && (
        // Renderize o modal aqui
        <Toasts
          code={optionsToast.code}
          message={optionsToast.message}
          visible={optionsToast.visible}
          setDefaultOptions={() => fecharToast()}
        />
      )}
      <Modal.Header closeButton>
        <Paragraph text="Escreva o motivo da reprovação ou os documentos que estão faltantes." />
      </Modal.Header>
      <Modal.Body>
        <Container className="d-flex flex-column">
          <Form.Control
            as="textarea"
            maxLength={1500}
            style={{ height: "14rem" }}
            type="text"
            name="recusar"
            value={comentario}
            onChange={handleComentarioChange}
            placeholder="Digite aqui."
          />
          <Row className="mt-4">
            <Col>
              <Col className="d-flex gap-5">
                <Form.Check
                  type="radio"
                  label="Documentos pendentes"
                  name="selectedOption"
                  value="Documentos pendentes"
                  checked={selectedOption === "Documentos pendentes"}
                  onChange={handleOptionChange}
                />
                <Form.Check
                  type="radio"
                  label="Outros"
                  name="selectedOption"
                  value="Outros"
                  checked={selectedOption === "Outros"}
                  onChange={handleOptionChange}
                />
              </Col>
            </Col>
            <Col>
              <Col className="d-flex justify-content-end">
                <Btn
                  type="secondary"
                  isSumbit={true}
                  text="Confirmar"
                  onClick={handleConfirm}
                />
              </Col>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
