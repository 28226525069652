import { Col, Container, Row } from "react-bootstrap";
import React from "react";

import "./styles.css";
import Paragraph from "../../atoms/Paragraph";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import Subtitle from "../../atoms/Subtitle";
import Btn from "../../atoms/Button";
import { useNavigate } from "react-router-dom";

const AvisoCadastroRecebimento = () => {
  const navigate = useNavigate();

  const handleNavigateToFinanceiroAdv = () => {
    const url = `/FinanceiroAdv`;
    navigate(url);
  };

  return (
    <Container>
      <Col className=" mb-5 d-flex  justify-content-center">
        <Col
          lg={6}
          className="text-center gap-3 d-flex flex-column justify-content-center"
        >
          <Subtitle text="Aviso" />
          <Col className="border border-warning rounded p-3">
            <Paragraph text="Para acessar a criação de agenda, primeiro você deve realizar o cadastro de uma conta de recebimento." />
          </Col>

          <Btn
            type={"secondary-sm"}
            isSubmit={false}
            text="Cadastrar recebimento"
            onTouchable={handleNavigateToFinanceiroAdv}
          />
        </Col>
      </Col>
    </Container>
  );
};

export default AvisoCadastroRecebimento;
