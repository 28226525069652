import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { InfoCircleFilled } from "@ant-design/icons";
import "./styles.css";

const HoverTooltip = ({ text, placement, className }) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip className={className}>
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </Tooltip>
      }
    >
      <span className={className}>
      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_6259_56)">
        <rect x="4" width="25" height="25" rx="12.5" fill="#FFC107" shape-rendering="crispEdges"/>
        <path d="M15.1656 6.78C14.8723 6.78 14.7256 6.63333 14.7256 6.34V4.24C14.7256 3.93333 14.8723 3.78 15.1656 3.78H17.8456C17.9923 3.78 18.099 3.82667 18.1656 3.92C18.2323 4 18.2656 4.10667 18.2656 4.24V6.34C18.2656 6.63333 18.1256 6.78 17.8456 6.78H15.1656ZM14.7656 19V8.24H18.2256V19H14.7656Z" fill="black"/>
        </g>
        <defs>
        <filter id="filter0_d_6259_56" x="0" y="0" width="33" height="33" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6259_56"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6259_56" result="shape"/>
        </filter>
        </defs>
      </svg>


      </span>
    </OverlayTrigger>
  );
};

export default HoverTooltip;
