import React, { useState } from "react";
import "./styles.css";
import { Col, Container, Modal, Row, Form } from "react-bootstrap";
import Btn from "../../atoms/Button";
import Subtitle from "../../atoms/Subtitle";
import { useNavigate } from "react-router-dom";
import ImgBanner from "../../atoms/ImgBanner";

export const SairAgendamentoModal = ({ show, onHide }) => {
  const navigate = useNavigate();

  const sairAgendamento = () => {
    navigate(-1);
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      className="tamanho-modal"
    >
      <Modal.Body>
        <Container className="text-center">
          <Row className="d-flex justify-content-center">
            <Col lg={6} className="mb-0 pb-0">
              <ImgBanner type="calendario" />
            </Col>
            <Col lg={9} className="mb-5">
              <Subtitle
                text={`Não tem certeza sobre a data? Agende sua consulta agora, 
                        você pode remarcar a qualquer momento!`}
              />
            </Col>
            <Col className="d-flex gap-3 justify-content-center">
              <Btn
                type={"terciary"}
                isSumbit={false}
                onTouchable={sairAgendamento}
                text="Sair"
              />
              <Btn
                type={"secondary"}
                isSumbit={false}
                onTouchable={onHide}
                text="Continuar agendamento"
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
