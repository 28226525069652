import { useState, useEffect } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { FinanceiroService } from "../../../services/ClienteServices/FinanceiroService";
import Paragraph from "../../atoms/Paragraph";
import { Col, Container, Row } from "react-bootstrap";
import { format } from "date-fns";
import Icons from "../../atoms/Icons";
import Subtitle from "../../atoms/Subtitle";
import { ptBR } from 'date-fns/locale';

const cacCliId = sessionStorage.getItem("id");
const fusoHorario = 'Etc/UTC';
const { parseISO } = require("date-fns");
const { utcToZonedTime } = require("date-fns-tz");

const ExtratoConsultaCli = () => {
  const [extratoData, setExtratoData] = useState([]);
  const [ordenacaoCrescente, setOrdenacaoCrescente] = useState(false);

  useEffect(() => {
    const fetchExtratoData = async () => {
      try {
        const api = new FinanceiroService();
        const response = await api.extratoConsultaCliente(cacCliId);

        if (response.status === true) {
          setExtratoData(response.data);
        } else {
          console.error("erro", response.message);
        }
      } catch (error) {
        console.error("Erro na chamada da API:", error);
      }
    };

    fetchExtratoData();
  }, []);

  const exportToCSV = () => {
    // Preparar os dados para o CSV
    const csvData = extratoData.map((item) => ({
      Data: new Date(item.adv_data).toLocaleDateString("pt-BR"),
      Valor: `R$ ${parseFloat(item.cac_valor).toFixed(2)}`,
      Advogado: item.cli_nome,
    }));

    // Criar o conteúdo do arquivo CSV
    const csvContent = [
      ["Data", "Valor", "Advogado"],
      ...csvData.map((item) => [item.Data, item.Valor, item.Advogado]),
    ]
      .map((row) => row.join(",")) // converter para uma string CSV
      .join("\n"); // unir as linhas com quebra de linha

    // Criar um elemento <a> temporário e simular o download
    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent)
    );
    link.setAttribute("download", "extrato.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Ordenar os dados por data de forma crescente ou decrescente
  const sortedExtratoData = [...extratoData].sort((a, b) => {
    const dateA = new Date(a.adv_data);
    const dateB = new Date(b.adv_data);

    return ordenacaoCrescente ? dateA - dateB : dateB - dateA;
  });

  // Alterar o estado de ordenação
  const handleToggleOrdenacao = () => {
    setOrdenacaoCrescente(!ordenacaoCrescente);
  };

  const formatDate = (dateString) => {
    return format(
      utcToZonedTime(parseISO(dateString), fusoHorario),
      'dd/MM/yyyy',
      { locale: ptBR }
    );
  };

  const renderTableRows = () => {
    if (sortedExtratoData.length > 0)
      return sortedExtratoData.map((item, index) => (
        <tr key={index} className="align-itens-center table-container">
          <td style={{ width: "" }}>
            <Paragraph text={formatDate(item.adv_data)} />
          </td>
          <td style={{ width: "" }}>
            <Paragraph
              text={`R$ ${parseFloat(item.cac_valor).toLocaleString("pt-BR")},00`}
            />
          </td>
          <td style={{ width: "" }}>
            <Paragraph
               text="Pago"
            />
          </td>
          <td style={{ width: "" }}>
            <Paragraph
              text={item.cli_nome}
            />
          </td>
        </tr>
      ));
  };    


  return (
    <Container>
      <Row className="d-flex column-gap-4 align-items-center justify-content-center  extrato-container">
        <Col>
          <Subtitle text="Extrato consultas" />
        </Col>
        <Col className="csv-export d-flex text-end">
          <Col lg={10} className="cursor icon-text" onClick={exportToCSV}>
            <Paragraph text="EXPORTAR DADOS" />
          </Col>
          <Col lg={2} className="icon-button cursor text-end align-items-right" onClick={exportToCSV}>
            <Icons type="exportar" />
          </Col>
        </Col>
      </Row>
      <Row>
        <Col className="table-responsive">
            <table className="table table-hover ">
              <thead className="">
                <tr class="table-head-bg">
                  <td class="table-head-bg table-rounded-start text-white">
                    Consultas
                  </td>
                  <td class="table-head-bg text-white">Valor</td>
                  <td class="table-head-bg text-white">Status</td>
                  <td class="table-head-bg table-rounded-end text-white">Advogado</td>
                </tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </table>
          </Col>
      </Row>    
    </Container>
  );
};

export default ExtratoConsultaCli;