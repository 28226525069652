import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './styles.css';
import Logo from '../../atoms/Logo';
import { Link } from "react-router-dom";
import { CadastroClienteForms } from "../../organisms/CadastroClienteForms";
import imagem from "../../../img/fotologincli.png"
import { Image } from "react-bootstrap";
import { RedefinirSenhaForms } from "../../organisms/RedefinirSenhaForms";


export const RedefinirSenhaTemp = ({token}) => {


    return (
        <Container fluid>             
            <Row className="row-login">  
            <Col lg={3}></Col> 
                <Col lg={6} className="text-center mt-4">                     
                    <Link to='/'>
                        <Logo type="imagem3"/>
                    </Link>    
                    <RedefinirSenhaForms 
                    token={token}
                    />
                </Col>
            <Col lg={3}></Col>
            </Row>
        </Container>        
    );
}