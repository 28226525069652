import React, { useEffect, useState } from "react";
import "./styles.css";
import Button from "react-bootstrap/Button";
import Subtitle from "../../atoms/Subtitle";
import * as formik from "formik";
import * as yup from "yup";
import { UserService } from "../../../services/UserServices/UserService";
import InputMask from "react-input-mask";
import functions from "../../utils/functions";
import Btn from "../../atoms/Button";
import { Link } from "react-router-dom";
import {
  Col,
  Collapse,
  Container,
  FloatingLabel,
  FormLabel,
  Row,
  InputGroup,
  Form,
} from "react-bootstrap";

import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import Nav from "react-bootstrap/Nav";
import { useSessionStorage } from "../../../storage/UseSessionStorage";
import { Toasts } from "../../utils/Toasts";

import { useNavigate } from "react-router-dom";

export const RedefinirSenhaForms = ({token}) => {
  
  const { Formik } = formik;
  let Api = new UserService();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [validated, setValidated] = useState(false);

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const schema1 = yup.object().shape({
    senha: yup.string()
       .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()])[a-zA-Z\d!@#$%^&*()]{8,}$/, 'A senha deve conter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial.')
       .required('A senha é obrigatória.'),
    confirma_senha: yup.string()
       .oneOf([yup.ref('senha')], 'As senhas devem ser iguais.')
       .required('A confirmação de senha é obrigatória.')
 });


  const handleSubmit = (values) => {
    console.log(values);
    setFormValues((prevFormValues) => ({ ...prevFormValues, ...values }));
    setStep((prevStep) => prevStep + 1);
    setValidated(true);
  };

  useEffect(() => {
    if (step === 2) {
      Api.postRedefinirSenha(formValues.senha, formValues.confirma_senha, token)
        .then((data) => {
          if (data.status === true) {
            let optionsToast = {
              code: data.mensagem.codigo,
              message: data.mensagem.mensagem,
              visible: true,
            };
            setOptionsToast(optionsToast);  
            setTimeout(() => {
              navigate("/");
            }, 3000);
          } else {
            let optionsToast = {
              code: data.mensagem.codigo,
              message: data.mensagem.mensagem,
              visible: true,
            };
            setStep((prevStep) => prevStep - 1);
            setOptionsToast(optionsToast);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [step]);
  

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <Formik
            key={step}
            validationSchema={schema1}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            initialValues={{
              senha: "",
              nova_senha: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="senha">
                  <FloatingLabel label={"Nova senha"}>
                    <Form.Control
                      className={`input-register-login-facj ${
                        touched.senha && errors.senha ? "is-invalid" : ""
                      }`}
                      type={type}
                      name="senha"
                      value={values.senha}
                      onChange={handleChange}
                      isValid={touched.senha && !errors.senha}
                      placeholder="Senha"
                    />
                    {touched.senha && errors.senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.senha}
                      </Form.Control.Feedback>
                    )}
                    <span className="icon-password-facj" onClick={handleToggle}>
                      <Icon icon={icon} size={25} />
                    </span>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="confirma_senha">
                  <FloatingLabel label={"Confirmar nova senha"}>
                    <Form.Control
                      className={`input-register-login-facj ${
                        touched.confirma_senha && errors.confirma_senha ? "is-invalid" : ""
                      }`}
                      type={type}
                      name="confirma_senha"
                      value={values.confirma_senha}
                      onChange={handleChange}
                      isValid={touched.senha && !errors.senha}
                      placeholder="Confirmar Senha"
                    />
                    {touched.confirma_senha && errors.confirma_senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.confirma_senha}
                      </Form.Control.Feedback>
                    )}
                    <span className="icon-password-facj" onClick={handleToggle}>
                      <Icon icon={icon} size={25} />
                    </span>
                  </FloatingLabel>
                </Form.Group>

                <div className="btn-login-facj">
                  <Btn type={"secondary"} text="Confirmar nova senha" isSumbit={true} />
                </div>
              </Form>
            )}
          </Formik>
        );
      default:
        return null;
    }
  };

  return (
    <Container className="container-login-facj">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Row>
        <Col>
          <Subtitle text={"Redefinir senha de acesso ao Faça Jus"} />
          {renderForm()}
        </Col>
      </Row>
    </Container>
  );
};
