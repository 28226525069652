import React from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import Btn from "../../atoms/Button";
import { useNavigate } from "react-router-dom";
import AdmGerenciaListaUsuarios from "../../molecules/AdmGerenciaListaUsuarios";
import AdmGerenciaListaPerfis from "../../molecules/AdmGerenciaListaPerfis";
import { usePermissaoAdm } from "../../../hooks/usePermissaoAdm";

const AdmGerencialPerfis = ({permissoes}) => {
  const navigate = useNavigate(); 
 
  const handleCadastrarClick = () => {
    navigate("/adm/gerencial/perfil/cadastrar");
  };
  return (
    <Container className="p-4">
     

      <Row >
        <Col className="d-flex align-items-center ">
          <Col className="">
            <Subtitle text="Perfis cadastrados" />
          </Col>
          <Col lg={2} className="d-flex justify-content-end">
            <Btn
              type={"secondary-sm"}
              isSubmit={false}
              text="+ Cadastrar"
              onTouchable={handleCadastrarClick} 
              disabled={!usePermissaoAdm('gerencial-perfis-cadastrar',permissoes)}
            />
          </Col>
        </Col>
      </Row>

      <Row className="">
        <Col>
          <AdmGerenciaListaPerfis permissoes={permissoes}/>
        </Col>
      </Row>
    </Container>
  );
};

export default AdmGerencialPerfis;
