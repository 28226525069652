import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../molecules/Header";

import "./styles.css";
import Footer from "../../molecules/Footer";

import CabecalhoCarrosselLpOne from "../../organisms/CabecalhoCarrosselLpOne";
import ConteudoCarrosselLpOne from "../../organisms/ConteudoCarrosselLpOne";
import SearchHome from "../../organisms/SearchHome";

export const CarrosselLpOne = () => {
  return (
    <body className="mt-5 pt-5">
      <Container fluid className="fluid-bg">
        <Header />
      </Container>

      <Container className="mt-4">
        <CabecalhoCarrosselLpOne />
      </Container>

      <Container className="mt-4">
        <ConteudoCarrosselLpOne />
      </Container>

      <Container className="my-5 pb-5">
          <SearchHome />
      </Container>


      <Container fluid className="fluid-bg ">
        <Footer />
      </Container>
    </body>
  );
};
