import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col } from "react-bootstrap";
import "./styles.css";
import NavitemSidebarAdm from "../../../atoms/NavitemSidebarAdm";
import AdmHeader from "../../../molecules/AdmHeader";
import { useParams } from "react-router-dom";
import { AdmSiteClienteDados } from "../../../organisms/AdmSiteClienteDados";
import { ClienteService } from "../../../../services/AdmClienteServices/ClienteService";

export const SiteClienteEditarTemp = () => {
    const [sideNavExpanded, setSideNavExpanded] = useState(true);

    const api = new ClienteService();

    const toggleSideNav = () => {
      setSideNavExpanded(!sideNavExpanded);
    };
    
    const { id } = useParams();

    return (
     <body className="m-0 p-0">
        <Container fluid className="fluid-bg">
            <AdmHeader />
        </Container>   

        <Container fluid className="d-flex  mt-5">
            <Col
            className={`menu-position ${sideNavExpanded ? "" : "menu-fechado"}`}
            >

            <NavitemSidebarAdm
                menuAberto={sideNavExpanded}
                toggleMenu={toggleSideNav}
            />
            </Col>

            <Col className=" w-75 shadow-lg">
                <AdmSiteClienteDados id={id}/>
            </Col>
        </Container>

        <Container></Container>
      </body>               
    )

}