import React, { useEffect, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import "./styles.css";

export function Toasts({ message, code, visible, setDefaultOptions}) {
  const [show, setShow] = useState(visible);

  useEffect(() => {
      setShow(visible)
      if(visible == true) {
        setTimeout(() => {
          handleClose()
        }, 3000);
      }
  }, [visible]);

  const handleClose = () => {
    setShow(false);
    setDefaultOptions();
  };

  let typeClass = "";
  let typeClass2 = "";
  let typeClass3 = "";

  switch (code) {
    case '101':
      typeClass = 'bg-sucesso';
      typeClass2 = 'texto-titulo-sucesso';
      typeClass3 = 'texto-mensagem-sucesso';
      break;

    case '102':
      typeClass = 'bg-alerta';
      typeClass2 = 'texto-titulo-alerta';
      typeClass3 = 'texto-mensagem-alerta';
      break;

    case '103':
      typeClass = 'bg-erro';
      typeClass2 = 'texto-titulo-erro';
      typeClass3 = 'texto-mensagem-erro';
      break;

    default:
      break;
  }

  
    return (
      <ToastContainer
        position='top-center'
        className='mt-5 custom-toast-container'
        style={{ position: 'fixed', zIndex: 9999 }}
      >
        <Toast className={typeClass} show={show} onClose={handleClose}>
          <Toast.Header className={typeClass2}>
            <strong>Aviso</strong>
          </Toast.Header>
          <Toast.Body className={typeClass3}>{message}</Toast.Body>
        </Toast>
      </ToastContainer>
    );
}
