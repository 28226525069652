import React from 'react';
import './styles.css';
import Title from '../../atoms/Title';
import Subtitle from '../../atoms/Subtitle';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Icons from '../../atoms/Icons';
import Paragraph from '../../atoms/Paragraph';
import Btn from '../../atoms/Button';
import { Link } from 'react-router-dom';
import BannerDisplay from '../../molecules/BannerDisplay';


const VantagensLpAdv = () => {
return (
  <Container className='my-5 pt-5'>
    <Row className='my-4 pt-5'>
      <Col lg={6}>
        <Col className="d-flex gap align-items-center ">
          <Col lg={2}>
            <Icons type="visualizacao" />
          </Col>
          <Col lg={10}>
            <Paragraph className="text-left" text="Seu perfil visualizado por diversos clientes." />
          </Col>        
        </Col>
      </Col>
      <Col lg={6}>
        <Col className="d-flex gap align-items-center ">
          <Col lg={2}>
            <Icons type="rede" />
          </Col>
          <Col lg={10}>
            <Paragraph className="text-left" text="Atenda diversos casos da área do direito." />
          </Col>        
        </Col>
      </Col>
    </Row>
    <Row className='my-4'>
      <Col lg={6}>
        <Col className="d-flex gap align-items-center ">
          <Col lg={2}>
            <Icons type="tela" />
          </Col>
          <Col lg={10}>
            <Paragraph className="text-left" text="Tenha agendamento on-line e faça tele consultas." />
          </Col>        
        </Col>
      </Col>
      <Col lg={6}>
        <Col className="d-flex gap align-items-center ">
          <Col lg={2}>
            <Icons type="beneficios" />
          </Col>
          <Col lg={10}>
            <Paragraph className="text-left" text="Escolha o seu plano e tenha recursos exclusivos." />
          </Col>        
        </Col>
      </Col>
    </Row>
  

    <Row >
      <Col className='d-flex my-5'>
        <Link to="/cadastro/adv">
          <Btn type={"secondary"} isSumbit="false" text="Comece agora grátis" />
        </Link>
      </Col>
    </Row>


    <Row>
       <BannerDisplay /> 
    </Row>
    
   
  </Container>
);
};

export default VantagensLpAdv;