import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { Modal, Container, Col, ModalFooter, FloatingLabel, Form, } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as yup from "yup";
import Subtitle from "../../atoms/Subtitle";
import Btn from "../../atoms/Button";
import { FinanceiroService } from "../../../services/AdvogadoServices/FinanceiroService";
import { Toasts } from "../../utils/Toasts";
import functions from "../../utils/functions";
import Paragraph from "../../atoms/Paragraph";

const AdicionarRecebimentoAdvModal = ({ onClose }) => {
  const api = new FinanceiroService();

  const schemaDadosBancarios = yup.object().shape({
    codigoBanco: yup.string().required("Código do banco é obrigatório"),
    agencia: yup.string().required("Agência é obrigatória"),
    contaCorrente: yup.string().required("Conta Corrente é obrigatória"),
    nome: yup.string().required("Nome é obrigatório"),
    cpfOrCnpj: yup.string().required("Selecione CPF ou CNPJ"),
  });

  const schemaChavePix = yup.object().shape({
    nomeChavePix: yup.string().required("Nome da Chave Pix é obrigatório"),
    chavePix: yup.string().required("Chave Pix é obrigatória"),
  });

  
  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState({
    code: "",
    message: "",
    visible: false,
  });

  const fecharToast = () => {
    setOptionsToast({
      code: "",
      message: "",
      visible: false,
    });
  };

  const [selectedOption, setSelectedOption] = useState("dadosBancarios");
  const [cpfOrCnpj, setCpfOrCnpj] = useState("cpf");
  const id = sessionStorage.getItem("id");
  const [codigoBanco, setCodigoBanco] = useState("");
  const [agencia, setAgencia] = useState("");
  const [nome, setNome] = useState("");
  const [contaCorrente, setContaCorrente] = useState("");
  const [chavePix, setChavePix] = useState("");

  const [isLoading, setIsLoading] = useState();

  const handleSubmit = async (values, selectedOption, id) => {
    try {
      setIsLoading(true);
      const requestData = {
        selectedOption,
        id,
        ...(selectedOption === "chavePix"
          ? {
            nomeChavePix: values.nomeChavePix,
            chavePix: values.chavePix,
          }
          : {
            codigoBanco: values.codigoBanco,
            agencia: values.agencia,
            contaCorrente: values.contaCorrente,
            nome: values.nome,
            cpfOrCnpj,
            cpf: values.cpf,
            cnpj: values.cnpj,
          }),
      };
      console.log(requestData);
      handleEnvia(requestData);
    } catch (error) {
      console.log(error);
    }
  };
  


  const handleEnvia = (requestData) => {
    api.cadastrarMeioRecebimento(requestData)
      .then((data) => {
        if (data.status === true) {
          setIsLoading(false);
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);
          setTimeout(() => {
            onClose();
            window.location.reload();
          }, 2500);
        } else {
          setIsLoading(false);
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);
        }
      });
  }

  return (
    <Modal show={true} onHide={onClose}>
      <Container>
        <Modal.Header closeButton>
          <Col className="text-center pt-1">
            <Subtitle text="Adicionar meio de recebimento" />
          </Col>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              selectedOption: "dadosBancarios",
              codigoBanco: "",
              agencia: "",
              contaCorrente: "",
              nome: "",
              cpfOrCnpj: "cpf",
              cpf: "",
              cnpj: "",
              nomeChavePix: "",
              chavePix: "",
              add_id: id,
            }}
            onSubmit={(values) => {
              const cpfSemMascara = functions.removeMascara(values.cpf);
              const cnpjSemMascara = functions.removeMascara(values.cnpj);

              const valuesSemMascara = {
                ...values,
                cpf: cpfSemMascara,
                cnpj: cnpjSemMascara,
              };
              handleSubmit(valuesSemMascara, selectedOption, id, cpfOrCnpj);
            }}
            validationSchema={
              selectedOption === "dadosBancarios"
                ? schemaDadosBancarios
                : schemaChavePix
            }
          >
            {({
              handleSubmit,
              handleChange,
              handleReset,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form
                onSubmit={handleSubmit}
                className="p-2 bg-radio rounded justify-content-center"
              >
                <Form.Group>
                  <Col className="d-flex justify-content-center ps-4">
                    <Col lg={6} className="">
                      <Form.Check
                        type="radio"
                        label="Dados bancários"
                        name="selectedOption"
                        value="dadosBancarios"
                        checked={selectedOption === "dadosBancarios"}
                        onChange={() => setSelectedOption("dadosBancarios")}
                      />
                    </Col>
                    {/* <Col lg={6} className="ps-5">
                      <Form.Check
                        type="radio"
                        label="Chave Pix"
                        name="selectedOption"
                        value="chavePix"
                        checked={selectedOption === "chavePix"}
                        onChange={() => setSelectedOption("chavePix")}
                      />
                    </Col> */}
                  </Col>
                </Form.Group>
                {selectedOption === "dadosBancarios" && (
                  <Form.Group>
                    <Col lg={12} className=" ">
                      <Form.Group className="d-flex row">
                        <Col lg={6} className="">
                          <FloatingLabel
                            label="Código do banco"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              name="codigoBanco"
                              className={`form-control ${touched.codigoBanco && errors.codigoBanco
                                ? "is-invalid"
                                : ""
                                }`}
                              placeholder="Banco"
                              value={values.codigoBanco}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                // Use uma expressão regular para permitir apenas números e até 15 caracteres
                                if (
                                  /^[0-9]*$/.test(inputValue) &&
                                  inputValue.length <= 8
                                ) {
                                  handleChange(e);
                                }
                              }}
                            />
                            {touched.codigoBanco && errors.codigoBanco && (
                              <Form.Control.Feedback type="invalid" tooltip>
                                {errors.codigoBanco}
                              </Form.Control.Feedback>
                            )}
                          </FloatingLabel>
                        </Col>
                        <Col lg={6} className="">
                          <FloatingLabel label="Agência" className="mb-3">
                            <Form.Control
                              type="text"
                              name="agencia"
                              className={`form-control ${touched.agencia && errors.agencia
                                ? "is-invalid"
                                : ""
                                }`}
                              placeholder="Agência"
                              value={values.agencia}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                // Use uma expressão regular para permitir apenas números e até 15 caracteres
                                if (
                                  /^[0-9]*$/.test(inputValue) &&
                                  inputValue.length <= 5
                                ) {
                                  handleChange(e);
                                }
                              }}
                            />
                            {touched.agencia && errors.agencia && (
                              <Form.Control.Feedback type="invalid" tooltip>
                                {errors.agencia}
                              </Form.Control.Feedback>
                            )}
                          </FloatingLabel>
                        </Col>
                        <Col lg={12}>
                          <FloatingLabel
                            label="Conta Corrente"
                            className="mb-3"
                          >
                            <Form.Control
                              type="text"
                              name="contaCorrente"
                              className={`form-control ${touched.contaCorrente && errors.contaCorrente
                                ? "is-invalid"
                                : ""
                                }`}
                              placeholder="Conta"
                              value={values.contaCorrente}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (
                                  /^[0-9]*$/.test(inputValue) &&
                                  inputValue.length <= 15
                                ) {
                                  handleChange(e);
                                }
                              }}
                            />
                            {touched.contaCorrente && errors.contaCorrente && (
                              <Form.Control.Feedback type="invalid" tooltip>
                                {errors.contaCorrente}
                              </Form.Control.Feedback>
                            )}
                          </FloatingLabel>
                        </Col>
                        <Col lg={12}>
                          <FloatingLabel label="Nome" className="mb-3">
                            <Form.Control
                              type="text"
                              name="nome"
                              className={`form-control ${touched.nome && errors.nome ? "is-invalid" : ""
                                }`}
                              placeholder="Nome"
                              value={values.nome}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (
                                  /^[a-zA-ZÀ-ÿ\s']*$/u.test(inputValue) &&
                                  inputValue.length <= 50
                                ) {
                                  handleChange(e);
                                }
                              }}
                            />
                            {touched.nome && errors.nome && (
                              <Form.Control.Feedback type="invalid" tooltip>
                                {errors.nome}
                              </Form.Control.Feedback>
                            )}
                          </FloatingLabel>
                        </Col>

                        <Col
                          lg={12}
                          className="m-0 d-lg-flex gap-5 align-items-center justify-content-center"
                        >
                          <Form.Check
                            type="radio"
                            label="CPF"
                            name="cpfOrCnpj"
                            value="cpf"
                            checked={cpfOrCnpj === "cpf"}
                            onChange={() => setCpfOrCnpj("cpf")}
                          />
                          <Form.Check
                            type="radio"
                            label="CNPJ"
                            name="cpfOrCnpj"
                            value="cnpj"
                            checked={cpfOrCnpj === "cnpj"}
                            onChange={() => setCpfOrCnpj("cnpj")}
                          />
                        </Col>
                        <Col>
                          <Form.Group controlId="cpf">
                            {cpfOrCnpj === "cpf" && (
                              <FloatingLabel label="CPF" className="mb-3">
                                <InputMask
                                  mask="999.999.999-99"
                                  type="text"
                                  className={`form-control ${touched.cpf && errors.cpf
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  name="cpf"
                                  value={values.cpf}
                                  onChange={handleChange}
                                />
                                {touched.cpf && errors.cpf && (
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.cpf}
                                  </Form.Control.Feedback>
                                )}
                              </FloatingLabel>
                            )}
                            {cpfOrCnpj === "cnpj" && (
                              <FloatingLabel label="CNPJ" className="mb-3">
                                <InputMask
                                  mask="99.999.999/9999-99"
                                  PJ
                                  type="text"
                                  className={`form-control ${touched.cnpj && errors.cnpj
                                    ? "is-invalid"
                                    : ""
                                    }`}
                                  name="cnpj"
                                  value={values.cnpj}
                                  placeholder="CNPJ"
                                  onChange={handleChange}
                                />
                                {touched.cnpj && errors.cnpj && (
                                  <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.cnpj}
                                  </Form.Control.Feedback>
                                )}
                              </FloatingLabel>
                            )}
                          </Form.Group>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Form.Group>
                )}
                {/* {selectedOption === "chavePix" && (
                  <Col lg={12} className=" ">
                    <Form.Group controlId="nomeChavePix" className="d-flex row">
                      <Col lg={12} className="">
                        <FloatingLabel label="Nome" className="mb-3">
                          <Form.Control
                            type="text"
                            name="nomeChavePix"
                            className={`form-control ${
                              touched.nomeChavePix && errors.nomeChavePix
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Nome"
                            value={values.nomeChavePix}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                /^[a-zA-ZÀ-ÿ\s']*$/u.test(inputValue) &&
                                inputValue.length <= 50
                              ) {
                                handleChange(e);
                              }
                            }}
                          />
                          {touched.nomeChavePix && errors.nomeChavePix && (
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.nomeChavePix}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>
                      <Col lg={12} className="">
                        <FloatingLabel label="Chave Pix" className="mb-3">
                          <Form.Control
                            type="text"
                            name="chavePix"
                            className={`form-control ${
                              touched.chavePix && errors.chavePix
                                ? "is-invalid"
                                : ""
                            }`}
                            placeholder="Chave Pix"
                            value={values.chavePix}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (inputValue.length <= 100) {
                                handleChange(e);
                              }
                            }}
                          />
                          {touched.chavePix && errors.chavePix && (
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.chavePix}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Col>
                    </Form.Group>
                  </Col>
                )} */}
                <Col className=" text-center">
                  <Paragraph text="Atenção! Certifique-se de que os dados informados estejam corretos." />
                </Col>
                <Col className="d-flex gap-2 justify-content-center">
                  <Btn type="terciary-sm" text="Voltar" onTouchable={onClose} />

                  <Btn
                    type="secondary-sm"
                    text="Confirmar"
                    isLoading={isLoading}
                    isSumbit={true}
                  />
                </Col>
                <Toasts
                  code={optionsToast.code}
                  message={optionsToast.message}
                  visible={optionsToast.visible}
                  setDefaultOptions={fecharToast}
                />
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Container>
    </Modal>
  );
};

export default AdicionarRecebimentoAdvModal;
