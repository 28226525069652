import React, { useState } from "react";
import "./styles.css";
import {
  Col,
  Container,
  Modal,
  Row,
  Form,
  FloatingLabel,
  Button,
  Spinner,
} from "react-bootstrap";
import Btn from "../../atoms/Button";
import Subtitle from "../../atoms/Subtitle";
import { Toasts } from "../../utils/Toasts";
import InputMask from "react-input-mask";
import * as yup from "yup";
import functions from "../../utils/functions";
import Logo from "../../atoms/Logo";
import { Field, Formik } from "formik";
import CountdownTimer from "../CountdownTimer ";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import { UserService } from "../../../services/UserServices/UserService";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";

export const RedefinirSenhaModal = ({ show, onHide }) => {
  const Api = new UserService();

  const [defaultCpf, setDefaultCpf] = useState("");
  const [idUsuario, setIdUsuario] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [step, setStep] = useState(1);

  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");
  const codigoCompleto = code1 + code2 + code3 + code4;

  const schema1 = yup.object().shape({
    senha: yup
      .string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()])[a-zA-Z\d!@#$%^&*()]{8,}$/,
        "A senha deve conter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial."
      )
      .required("A nova senha é obrigatória."),
    confirma_senha: yup
      .string()
      .oneOf([yup.ref("senha")], "As senhas devem ser iguais.")
      .required("A confirmação de senha é obrigatória."),
  });

  const schema2 = yup.object().shape({
    cpf: yup
      .string()
      .test("valid-cpf", "CPF inválido", function (value) {
        return functions.validarCPF(value);
      })
      .required("CPF é obrigatório"),
  });

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };
  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleSubmit = async (cpf, senha, confirma_senha) => {
    if (step === 1) {
      setIsLoading(true);
      if (!cpf) {
        let optionsToast = {
          code: "102",
          message: "CPF é obrigatório",
          visible: true,
        };
        setOptionsToast(optionsToast);
        setIsLoading(false);
        return;
      }
      Api.enviaCodigoRedefinirSenha(functions.removeMascara(cpf))
        .then((data) => {
          if (data.status) {
            setIdUsuario(data.data);
            setIsLoading(true);
            setStep((prevStep) => prevStep + 1);
          } else {
            let optionsToast = {
              code: data.mensagem.codigo,
              message: data.mensagem.mensagem,
              visible: true,
            };
            setOptionsToast(optionsToast);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (step === 2) {
      setIsLoading2(true);
      Api.postValidaCodigoVerificador(idUsuario, codigoCompleto)
        .then((data) => {
          if (data.status) {
            setIsLoading2(true);
            setStep((prevStep) => prevStep + 1);
          } else {
            setOptionsToast({
              code: data.mensagem.codigo,
              message: data.mensagem.mensagem,
              visible: true,
            });
            setIsLoading2(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (step === 3) {
      Api.alterarSenhaUsuario(
        functions.removeMascara(defaultCpf),
        senha,
        confirma_senha
      )
        .then((data) => {
          if (data.status === true) {
            let optionsToast = {
              code: data.mensagem.codigo,
              message: data.mensagem.mensagem,
              visible: true,
            };
            setOptionsToast(optionsToast);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            let optionsToast = {
              code: data.mensagem.codigo,
              message: data.mensagem.mensagem,
              visible: true,
            };
            setOptionsToast(optionsToast);
            return;
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const handleSair = () => {
    onHide(true);
  };

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <Formik
            key={step}
            validationSchema={schema2}
            onSubmit={(values) => {
              setDefaultCpf(values.cpf);
              handleSubmit(values.cpf);
            }}
            initialValues={{
              cpf: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="" controlId="cpf">
                  <FloatingLabel label={"CPF"}>
                    <InputMask
                      mask="999.999.999-99"
                      value={values.cpf}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.cpf && errors.cpf ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="cpf"
                          value={values.cpf}
                          onChange={handleChange}
                          isValid={touched.cpf && !errors.cpf}
                          placeholder="CPF"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.cpf && errors.cpf && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.cpf}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  <Col className="d-lg-flex mb-2 justify-content-between">
                    <Btn
                      type={"terciary"}
                      isSumbit={false}
                      text="Sair"
                      onTouchable={handleSair}
                    />
                    {isLoading ? (
                      <Button disabled className="botao-sec-disable">
                        <Spinner
                          size="sm"
                          className="align-itens-center"
                        ></Spinner>
                      </Button>
                    ) : (
                      <Button
                        type={"button"}
                        className={"botao-secundario"}
                        onClick={handleSubmit}
                      >
                        Próximo
                      </Button>
                    )}
                  </Col>
                </Form.Group>
              </Form>
            )}
          </Formik>
        );

      case 2:
        return (
          <Container className="">
            <Col className="d-flex justify-content-center">
              <Col className="d-flex justify-content-center">
                <ParagraphSmall
                  textAlign="center"
                  text="Por favor, digite abaixo o código de verificação 
              enviado ao seu celular via SMS e E-mail cadastrado."
                />
              </Col>
            </Col>

            <Row className="d-flex flex-column">
              <Col>
                <Formik
                  initialValues={{
                    code1: "",
                    code2: "",
                    code3: "",
                    code4: "",
                  }}
                  onSubmit={() => handleSubmit(codigoCompleto)}
                >
                  {({ values, handleChange }) => (
                    <Form className="" noValidate>
                      <Col className="d-flex gap-3 ">
                        <Field
                          key={1}
                          type="text"
                          name="code1"
                          value={values.code1}
                          onChange={(e) => {
                            handleChange(e);
                            setCode1(e.target.value);
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                        <Field
                          key={2}
                          type="text"
                          name="code2"
                          value={values.code2}
                          onChange={(e) => {
                            handleChange(e);
                            setCode2(e.target.value);
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                        <Field
                          key={3}
                          type="text"
                          name="code3"
                          value={values.code3}
                          onChange={(e) => {
                            handleChange(e);
                            setCode3(e.target.value);
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                        <Field
                          key={4}
                          type="text"
                          name="code4"
                          value={values.code4}
                          onChange={(e) => {
                            handleChange(e);
                            setCode4(e.target.value);
                          }}
                          className=" form-control shadow input-codigo"
                          maxLength="1"
                        />
                      </Col>
                    </Form>
                  )}
                </Formik>
              </Col>
              <Col className="d-flex justify-content-center">
                <Col className="d-flex flex-column gap-2 align-items-center justify-content-center">
                  <Col>
                    <CountdownTimer initialTime={180} />
                  </Col>
                </Col>
              </Col>
            </Row>

            <Row className="d-flex flex-column gap-3 mb-3">
              <Col className="d-lg-flex gap-2 justify-content-between">
                <Btn
                  type={"terciary"}
                  isSumbit={false}
                  text="Sair"
                  onTouchable={handleSair}
                />
                {isLoading2 ? (
                  <Button disabled className="botao-sec-disable">
                    <Spinner size="sm" className="align-itens-center"></Spinner>
                  </Button>
                ) : (
                  <Button
                    type={"button"}
                    className={"botao-secundario"}
                    onClick={handleSubmit}
                  >
                    Próximo
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        );

      case 3:
        return (
          <Formik
            key={step}
            validationSchema={schema1}
            onSubmit={(values) => {
              handleSubmit(values.cpf, values.senha, values.confirma_senha);
            }}
            initialValues={{
              senha: "",
              confirma_senha: "",
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <ParagraphSmall
                  textSize="15px"
                  textAlign='center'
                  text="Lembramos que a senha deve conter pelo menos 8 caracteres, uma letra maiúscula,
                uma letra minúscula, um caractere especial e um número."
                />
                <Form.Group controlId="senha">
                  <FloatingLabel label={"Nova senha"}>
                    <Form.Control
                      className={`input-register-login-facj ${
                        touched.senha && errors.senha ? "is-invalid" : ""
                      }`}
                      type={type}
                      name="senha"
                      value={values.senha}
                      onChange={handleChange}
                      isValid={touched.senha && !errors.senha}
                    />
                    {touched.senha && errors.senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.senha}
                      </Form.Control.Feedback>
                    )}
                    <span className="icon-password-facj" onClick={handleToggle}>
                      <Icon icon={icon} size={25} />
                    </span>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="confirma_senha">
                  <FloatingLabel label={"Confirmar nova senha"}>
                    <Form.Control
                      className={`input-register-login-facj ${
                        touched.confirma_senha && errors.confirma_senha
                          ? "is-invalid"
                          : ""
                      }`}
                      type={type}
                      name="confirma_senha"
                      value={values.confirma_senha}
                      onChange={handleChange}
                      isValid={touched.confirma_senha && !errors.confirma_senha}
                    />
                    {touched.confirma_senha && errors.confirma_senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.confirma_senha}
                      </Form.Control.Feedback>
                    )}
                    <span className="icon-password-facj" onClick={handleToggle}>
                      <Icon icon={icon} size={25} />
                    </span>
                  </FloatingLabel>
                </Form.Group>

                <Col className="d-lg-flex mb-2 justify-content-between">
                  <Btn
                    type={"terciary"}
                    isSumbit={false}
                    text="Sair"
                    onTouchable={handleSair}
                  />
               
                    <Btn type={"secondary"} text="Confirmar" isSumbit={true} />
                 
                </Col>
              </Form>
            )}
          </Formik>
        );
    }
  };

  return (
    <Modal size="md" show={show} backdrop="static" keyboard={false}>
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Modal.Header closeButton onClick={handleSair} />
      <Container className="text-center">
        <Row>
          <Col className="d-flex row gap-5 my-3">
            <Logo type="imagem3" />
            <Subtitle
              text={
                step === 1
                  ? "Digite seu CPF:"
                  : step === 2
                  ? "Código verificador"
                  : "Redefinição de Senha"
              }
            />
          </Col>
        </Row>
        <Row>
          <Col className="px-5">{renderForm(step)}</Col>
        </Row>
      </Container>
    </Modal>
  );
};
