import React from "react";
import { Col, Container, Modal } from "react-bootstrap";
import TermoDeUsoText from "../../atoms/TermoDeUsoText";
import Subtitle from "../../atoms/Subtitle";

const TermosDeUsoModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
          <Col className="text-center">
            <Subtitle text="Termos de Uso" />
          </Col>
        </Modal.Header>
      <Container>
      <Modal.Body className="mb-5 modal-scroll">
           <TermoDeUsoText />  
      </Modal.Body>
      </Container>
    </Modal>
  );
};

export default TermosDeUsoModal;
