import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Subtitle from "../../atoms/Subtitle";
import { Col, Container, Row } from "react-bootstrap";
import ImgBanner from "../../atoms/ImgBanner";
import ConsultaAgendadaCard from "../../molecules/ConsultaAgendadaCard";
import Paragraph from "../../atoms/Paragraph";
import { ConsultasService } from "../../../services/ClienteServices/ConsultasService";
import HistoricoConsultasCli from "../../organisms/HistoricoConsultasCli";
import { ptBR } from "date-fns/locale";
import { addHours } from "date-fns";
import ChatCliente from "../../organisms/Chat";
import "./styles.css";

const CliConsultasAgendadas = () => {
  const [consultas, setConsultas] = useState([]);
  const api = new ConsultasService();
  const cacCliId = sessionStorage.getItem("id");
  const dataAtual = new Date().toISOString().split("T")[0];

  const fusoHorario = "Etc/UTC";
  const { parseISO, format } = require("date-fns");
  const { utcToZonedTime } = require("date-fns-tz");

  useEffect(() => {
    async function fetchConsultas() {
      try {
        const response = await api.buscarConsultasAgendadasCli(cacCliId);
        setConsultas(response);
      } catch (error) {
        console.error(error);
      }
    }

    fetchConsultas();
  }, [cacCliId]);

  const formatDate = (dateString) => {
    return format(
      utcToZonedTime(parseISO(dateString), fusoHorario),
      "dd/MM/yyyy",
      { locale: ptBR }
    );
  };

  const proximasConsultas =
    consultas.length > 0
      ? consultas.filter((consulta) => consulta.Data >= dataAtual)
      : [];

  // const consultasAtrasadas = consultas.length > 0
  //   ? consultas.filter((consulta) => {
  //     const dataConsulta = new Date(consulta.Data);
  //     const cincoHorasAtras = addHours(new Date(), -5);
  //     return dataConsulta >= cincoHorasAtras ? consulta : null;
  //   })
  //   : consultas;
  // console.log("consultas aquiii ->>>", consultas)
  // console.log("consultas Atrasadas aqui ---->>", consultasAtrasadas)
  // // Filtra as consultas com data a partir de hoje
  // const consultasFuturas = consultas.length > 0
  // ? consultas.filter((consulta) => {
  //     const dataConsulta = new Date(consulta.Data);
  //     const hoje = new Date();
  //     return dataConsulta >= hoje ? consulta : null ;
  //   })
  // : consultas;

  return (
    <Container>
      <Row className="">
        <Col>
          <Subtitle text="Próximas consultas" />
        </Col>
      </Row>
      {proximasConsultas.length > 0 ? (
        <Row className="overflow-consultas">
          <Col lg={6} className="">
            {proximasConsultas.map((item) => {
              const horaAPI = item.Hora;

              const horaFormatada = `${horaAPI.substring(
                0,
                2
              )}:${horaAPI.substring(2)}`;

              return (
                <ConsultaAgendadaCard
                  key={item.Consulta_Id}
                  data={formatDate(item.Data)}
                  hora={horaFormatada}
                  nome={item.Nome_Add}
                  idConsulta={item.Consulta_Id}
                  idCac={item.Cac_Id}
                  showIcon={false}
                  id={item.Advogado_Cli_Id}
                  reagendar={true}
                  cancelarAdv={false}
                  cancelarCli={true}
                />
              );
            })}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <Paragraph text="Não há consultas agendadas" />
          </Col>
        </Row>
      )}
      <Col className="my-5">
        <Row>
          <HistoricoConsultasCli />
        </Row>
        <Container className="my-5">
          <Col className="ms-3">
            <Subtitle text="Chat" />
            <Paragraph
              text="Caso queria iniciar um bate papo com o seu advogado, clique aqui. Solicitamos que aguarde o contato de seu advogado, 
            o qual providenciará a liberação do seu acesso ao chat."
            />
          </Col>
          <ChatCliente />
        </Container>
      </Col>
      <Col>
        <Row className="mt-5">
          <Col>
            <Subtitle text="Produtos" />
          </Col>
        </Row>
        <Row className=" mb-5 pb-5">
          <Col lg={6} className="pb-2">
            <a
              href="https://assistamais.net.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImgBanner type="banner6" key="banner1" />
            </a>
          </Col>
          <Col lg={6} className="pb-2">
            <a
              href="https://www.usemaisstore.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImgBanner type="banner5" key="banner2" />
            </a>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default CliConsultasAgendadas;
