import React, { useEffect, useState } from "react";
import "./styles.css";
import DropdownBtn from "../../atoms/DropdownBtn";
import MultiDropdown from "../../atoms/MultiDropdown";
import Btn from "../../atoms/Button";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HomeService } from "../../../services/HomeServices/HomeService";
import { Toasts } from "../../utils/Toasts";
import functions from "../../utils/functions";

const api = new HomeService();

const SearchFieldsHome = () => {
  const [areaSelecionada, setAreaSelecionada] = useState(null);
  const [estadoSelecionado, setEstadoSelecionado] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [dados, setDados] = useState({
    optionsAreas: [],
    optionsEstados: [],
  });

  const defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  const fecharToast = () => {
    setOptionsToast(defaultOptionsToast);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await api.getAllAtivos();

        if (result.status === true) {
          const optionsAreaWithManualItem = [
            ...renderDropdownOptions(result.optionsArea),
            { id: 16, desc: "Outros", subOptions: null },
          ];
  
          setDados({
            optionsAreas: optionsAreaWithManualItem,
            optionsEstados: result.optionsEstados[0],
          });
        }
      } catch (error) {
        console.error("Erro ao obter dados:", error);
        setOptionsToast({
          code: "500",
          message: "Erro ao obter dados do servidor.",
          visible: true,
        });
      }
    };

    fetchData();
  }, []);

  const renderDropdownOptions = (areas) => {
    return areas.map((area) => ({
      id: area.id,
      desc: area.desc,
      subOptions: area.area_frases.map((frase) => ({
        id: frase.id,
        desc: frase.desc,
      })),
    }));
  };

  const navigate = useNavigate();

  const handleSearch = () => {
    if (!estadoSelecionado && !areaSelecionada) {
      setOptionsToast({
        code: "102",
        message: "Selecione uma das opções para realizar a busca.",
        visible: true,
      });
      return;
    }

    const estado = estadoSelecionado?.desc;
    const area = areaSelecionada?.id;

    api
      .buscarAdvogados(estado, area)
      .then((resultado) => {
        navigate("/ResultadoSearch", {
          state: { resultado },
        });
      })
      .catch((erro) => {
        console.error("Erro na busca:", erro);
        setOptionsToast({
          code: "500",
          message: "Erro ao realizar a busca.",
          visible: true,
        });
      });
  };

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  const filteredOptionsEstados = dados.optionsEstados.filter((option) => {
    const descricaoCompleta = functions
      .adicionarDescricaoEstado(option.desc)
      .replace(/-/g, "")
      .toLowerCase();
    const filtro = filterText.replace(/-/g, "").toLowerCase();
    return descricaoCompleta.includes(filtro);
  });

  return (
    <Container>
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={fecharToast}
      />
      <Row className="align-items-center gap-2">
        <Col lg={6}>
          <MultiDropdown
            text="Selecione a área"
            options={dados.optionsAreas}
            onPress={(data) => setAreaSelecionada(data)}
            selected={areaSelecionada}
            onClearFilter={() => {
              setFilterText("");
              setAreaSelecionada(null);
            }}
          />
        </Col>
        <Col lg={3}>
          <DropdownBtn
            type="primary1"
            text="Selecione o estado"
            options={filteredOptionsEstados}
            onPress={(data) => setEstadoSelecionado(data)}
            selected={estadoSelecionado}
            filterText={filterText}
            onFilterChange={handleFilterChange}
            onClearFilter={() => {
              setFilterText("");
              setEstadoSelecionado(null);
            }}
          />
        </Col>
        <Col lg={1}>
          <Btn
            type="primary"
            isSubmit={false}
            text="Buscar"
            onTouchable={handleSearch}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default SearchFieldsHome;
