import React from "react";
import "./styles.css";

import myImage from "../../../../src/img/Logo_horizontal_branca.png";
import myImage2 from "../../../../src/img/Logo_horizontal_azul.png";
import myImage3 from "../../../../src/img/Logo_horizontal_azul_amarela.png";




const Logo = ({ type }) => {
  let imagem = "";

  switch (type) {
    case "imagem1":
      imagem = myImage;
      break;
    case "imagem2":
      imagem = myImage2;
      break;
    case "imagem3":
      imagem = myImage3;
      break;
   
   
    default:
      imagem = myImage; // Defina um valor padrão caso o tipo não corresponda a nenhum caso.
      break;
  }

  return (
    <div className="container-fluid">
      <a className="navbar-brand" href="#">
        <img src={imagem} alt="Logo" />
      </a>
    </div>
  );
};

export default Logo;
