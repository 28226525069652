import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col, Row } from "react-bootstrap";
import "./styles.css";
import AdmHeader from "../../molecules/AdmHeader";
import NavitemSidebarAdm from "../../atoms/NavitemSidebarAdm";
import Subtitle from "../../atoms/Subtitle";
import ImgBanner from "../../atoms/ImgBanner";

export const PermissaoTemp = () => {
  const [sideNavExpanded, setSideNavExpanded] = useState(true);

  const toggleSideNav = () => {
    setSideNavExpanded(!sideNavExpanded);
  };

  return (
    <body className="m-0 p-0">
      <Container fluid className="fluid-bg">
        <AdmHeader />
      </Container>

      <Container fluid className="d-flex  row mt-5">
        <Col
          className={`menu-position ${sideNavExpanded ? "" : "menu-fechado"}`}
        >
          <NavitemSidebarAdm
            menuAberto={sideNavExpanded}
            toggleMenu={toggleSideNav}
          />
        </Col>

        <Col className="flex-grow-1 shadow-lg">
          <Row className="d-flex flex-column justify-content-center align-items-center">
            <Col lg={4} className="">
              <ImgBanner type="alerta" />
            </Col>
            <Col className="d-flex justify-content-center">
              <Subtitle text="Você não possui permissão de acesso." />
            </Col>
          </Row>
        </Col>
      </Container>

      <Container></Container>
    </body>
  );
};
