import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../molecules/Header';
import Footer from '../../molecules/Footer';
import ImgBannerPerfil from '../../atoms/ImgBannerPerfil';
import Subtitle from '../../atoms/Subtitle';
import { AvatarPerfil } from '../../atoms/AvatarPerfil';

import './styles.css';
import { NotificacaoCli } from '../../organisms/NotificacaoCli';
import ParagraphSmall from '../../atoms/ParagraphSmall';






const nomeUsuario = sessionStorage.getItem("nome") || "";

export const NotificacoesCli = () => {
  return (
    <body className='mt-5 pt-5'>

      <Container fluid className='p-0 m-0 '>
        <Header />
      </Container>
      
      <Container fluid className="p-0 m-0 banner-container">
        <ImgBannerPerfil className="img-fluid " />
      </Container>

      <Container>
        <Row className='gap-5'>
          <Col lg={2} className="avatar-container">
            <AvatarPerfil
              type="A"
              base64={sessionStorage.getItem("fotoPerfil")}
              width="13rem"
              height="13rem"
              padding="0.4rem"
            />
          </Col>

          <Col lg={8} className='py-2 mt-2 ms-2'>
            <Subtitle text={'Olá, ' + nomeUsuario} />
          </Col>   
        </Row>  
      </Container>

      <Container className='my-5 mt-3 '>
        <Row className="justify-content-center">
          <Col lg={9} className="">
            <Col className="ms-3">
              <Subtitle text="Notificações" />
              <ParagraphSmall text="A página de notificações mantém você conectado as ultimas novidades e informações importantes." />
            </Col>
            <Col>
            <NotificacaoCli/>
            </Col>
          </Col>
        </Row>
      </Container>      
      <Container fluid className="p-0 m-0 ">
        <Footer />
      </Container>
    
    </body>
  );
}