import api from "../Api";

export class HomeService {
  async getAll() {
    return await api
      .get("/home/get-infos-search", {
        headers: {
          // "Content-type": "application/json",
          // Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getAllAtivos() {
    return await api
      .get("/home/get-infos-search-ativos", {
        headers: {
          // "Content-type": "application/json",
          // Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async getById(id) {
    return await api
      .post(
        "/contratos-id",
        { cnt_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async postAtualizar(tokenGoogleo) {
    return await api
      .post(
        "/contratos-atualizar",
        {
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async postCadastrar(tokenGoogleo) {
    return await api
      .post(
        "/contratos-cadastrar",
        {
          tokenGoogleo: tokenGoogleo,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getDocsById(id) {
    return await api
      .post(
        "/documentos-listar",
        { cnt_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getContratoslogById(id) {
    return await api
      .post(
        "/contratoslog-listar",
        { cnt_id: id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async buscarAdvogados(add_estado, ada_id) {
    return await api.post(
        "/home/buscar-advogados",
        {
            add_estado: add_estado,
            ada_id: ada_id,
        },
        {
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        }
    ).then((res) => res.data);
}


  async  buscarAgendaAdv(adv_id) {
    try {
      const response = await api.post(
        "/home/buscar-agenda-adv",
        {
          "advogado_id": adv_id,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Erro na chamada à API:", error);
      throw error;
    }
  }

  async getInfosPreAgendamento(idAdv) {
    try {
      const response = await api.post(
        "/cliente/get-infos-pre-agendamento",
        {
          "idAdv": idAdv,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Erro na chamada à API:", error);
      throw error;
    }
    
  } 

  async carrosselAdvogados() {
    try {
      const response = await api.get("/home/carrossel-advogados");
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  async listarOpinioesAdv(cac_add_id) {
    try {
      const response = await api.post(
        "/home/listar-opinioes-adv",
        {
          "cac_add_id": cac_add_id,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Erro na chamada à API:", error);
      throw error;
    }    
  }; 

}