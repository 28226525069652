import React, { useState, useEffect } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { VerPerguntaForumCli } from "../../molecules/VerPerguntaForumCli";
import { Col, Container, Form, Row } from "react-bootstrap";
import Btn from "../../atoms/Button";
import { faleConoscoService } from "../../../services/FaleConoscoServices/FaleConoscoServices";
import Paragraph from "../../atoms/Paragraph";

const api = new faleConoscoService();

export const ResponderForumCli = () => {
  const [perguntas, setPerguntas] = useState([]);
  const [filtro, setFiltro] = useState(""); // Único estado para o filtro

  async function fetchPerguntas() {
    try {
      const response = await api.verPerguntaForum();
      console.log("Resposta da API:", response);

      if (Array.isArray(response.data)) {
        setPerguntas(response.data);
      } else {
        console.error("API response is not an array:", response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchPerguntas();
  }, []);

  const handleFiltroChange = (event) => {
    setFiltro(event.target.value);
  };

  const perguntasFiltradas = perguntas.filter(
    (pergunta) =>
      pergunta.mensagem.toLowerCase().includes(filtro.toLowerCase()) ||
      pergunta.dataHora.includes(filtro) // Considerando que a data tem o formato dd/mm/yyyy
  );

  return (
    <Container className="">
      <Col>
        <Paragraph text="Os advogados que contribuírem com respostas no nosso fórum serão
        recompensados com destaque na lista de busca do site. Isso significa que, quanto mais
        você ajudar a esclarecer dúvidas legais, mais visível você estará para os usuários
        que procuram assistência jurídica!"/>
      </Col>
      <Row>
        <Col className="mt-3">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Filtrar por palavra ou data (dd/mm/yyyy)"
              value={filtro}
              onChange={handleFiltroChange}
            />
          </Form.Group>
          <VerPerguntaForumCli perguntas={perguntasFiltradas} exibirBotoes={true} />
        </Col>
      </Row>
    </Container>
  );
};
