import React from "react";
import { useEffect, useState } from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import Paragraph from "../../atoms/Paragraph";
import { Form, FormControl } from "react-bootstrap";
import InputMask from "react-input-mask";
import Icons from "../../atoms/Icons";

import Spinner from "react-bootstrap/Spinner";
import { DashboardService } from "../../../services/AdmDashboardServices/DashboardService";

export const AdmDashboard = () => {
  const api = new DashboardService();

  const [ano, setAno] = useState();
  const [mes, setMes] = useState();

  const [consultasDashboard, setConsultasDashboard] = useState();
  const [consultasInativas, setConsultasInativas] = useState();
  const [consultasEstornadas, setConsultasEstornadas] = useState();
  const [solicitacoesEstorno, setSolicitacoesEstorno] = useState();

  const [repasseRealizadoAsaas, setRepasseRealizadoAsaas] = useState();
  const [faturamentoAsaas, setFaturamentoAsaas] = useState();
  const [faturamentoConsig, setFaturamentoConsig] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Verifica se o mês e o ano estão vazios, e se estiverem, define o mês e o ano atuais
    if (!mes || !ano) {
      const currentDate = new Date();
      setMes((currentDate.getMonth() + 1).toString().padStart(2, "0"));
      setAno(currentDate.getFullYear().toString());
    }

    fetchData();
  }, [mes, ano]);

  const fetchData = async () => {
    try {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 1800);

      const response = await api.postDashboard(mes, ano);

      if (response.status === true) {
        setConsultasDashboard(
          response.data.dados_consultas.qtd_consultas_ativas
        );
        setConsultasInativas(
          response.data.dados_consultas.qtd_consultas_inativas
        );
        setConsultasEstornadas(
          response.data.dados_estorno.qtd_reembolso_efetuado
        );
        setSolicitacoesEstorno(
          response.data.dados_estorno.qtd_reembolso_solicitado
        );
        setRepasseRealizadoAsaas(response.data.dados_repasse);
        setFaturamentoAsaas(response.data.dados_faturamentoAsaas);
        setFaturamentoConsig(response.data.dados_faturamentoConsig);
      } else {
        console.error("Erro na resposta da API:", response.message);
      }
    } catch (error) {
      console.error("Erro na chamada da API:", error);
    }
  };

  const exportToCSV = () => {
    const csvData = [
      {
        ReembolsoEfetuado: consultasEstornadas,
        SolicitacoesEstorno: solicitacoesEstorno,
        ConsultasInativas: consultasInativas,
        ConsultasAtivas: consultasDashboard,
        Repasse: "200",
        Faturamento: "3",
      },
    ];

    const csvContent = [
      [
        "Consultas Estornadas",
        "Solicitações de Estorno",
        "Consultas Inativas",
        "Consultas Ativas",
        "Repasse",
        "Faturamento",
      ],
      ...csvData.map((item) =>
        [
          item.ReembolsoEfetuado,
          item.SolicitacoesEstorno,
          item.ConsultasInativas,
          item.ConsultasAtivas,
          item.Repasse,
          item.Faturamento,
        ].join(",")
      ),
    ].join("\n");

    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent)
    );
    link.setAttribute("download", "extrato.csv");
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container>
      <Row className="">
        <Col
          lg={12}
          className="m-0 p-0 justify-content-start d-flex text-start"
        >
          <Breadcrumb className="p-0 m-0">
            <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row className="">
        <Col>
          <Subtitle text="Dashboard" />
        </Col>
      </Row>
      <Row className="d-flex justify-content-between">
        <Col lg={6} className="d-flex align-items-center gap-2">
          <Col lg={2} className="d-flex  align-items-center m-0 p-0">
            <InputMask
              className="form-control"
              mask="99"
              onChange={(e) => setMes(e.target.value)}
              placeholder="Mês"
            ></InputMask>
          </Col>
          <Col lg={4} className="d-flex  align-items-center m-0 p-0">
            <InputMask
              className="form-control"
              mask="9999"
              onChange={(e) => setAno(e.target.value)}
              placeholder="Ano"
            ></InputMask>
          </Col>
          <Col style={{ cursor: "pointer" }}>
            {loading ? (
              <Spinner style={{ width: '1.4rem', height: '1.4rem', }} animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>

             
            ) : (
              <Icons type="buscar" onClick={fetchData} />
            )}
          </Col>
        </Col>

        <Col lg={4} className="d-flex justify-content-end ">
          <Col className="d-flex text-end align-items-center">
            <Col lg={10} className="">
              <Paragraph text="EXPORTAR DADOS" />
            </Col>
            <Col lg={2} className="cursor" onClick={exportToCSV}>
              <Icons type="exportar" />
            </Col>
          </Col>
        </Col>
      </Row>

      <Row className="">
        <Col className="d-flex flex-column gap-1">
          <div className="text-center bg-warning rounded ">
            <Subtitle text="Estorno" />
          </div>
          <Col className="d-flex flex-column align-items-center my-2 shadow rounded border-start border-secondary border-3">
            <Col className="d-flex flex-column align-items-center justify-content-center  ">
              <ParagraphSmall text="Solicitados" />
              <Subtitle text={solicitacoesEstorno} />
            </Col>
          </Col>
          <Col className="d-flex flex-column align-items-center my-2 shadow rounded border-start border-secondary border-3">
            <Col className="d-flex flex-column align-items-center justify-content-center ">
              <ParagraphSmall text="Concluído" />
              <Subtitle text={consultasEstornadas} />
            </Col>
          </Col>
        </Col>

        <Col className="d-flex flex-column gap-1">
          <div className="text-center bg-warning rounded ">
            <Subtitle text="Consultas" />
          </div>
          <Col className="d-flex flex-column align-items-center my-2 shadow rounded border-start border-secondary border-3">
            <Col className="d-flex flex-column align-items-center justify-content-center ">
              <ParagraphSmall text="Agendadas" />
              <Subtitle text={consultasDashboard} />
            </Col>
          </Col>
          <Col className="d-flex flex-column align-items-center my-2 shadow rounded border-start border-secondary border-3">
            <Col className="d-flex flex-column align-items-center justify-content-center ">
              <ParagraphSmall text="Canceladas" />
              <Subtitle text={consultasInativas} />
            </Col>
          </Col>
        </Col>

        <Col className="d-flex flex-column gap-1">
          <div className="text-center bg-warning rounded ">
            <Subtitle text="Repasse" />
          </div>
          <Col className="d-flex flex-column align-items-center my-2 shadow rounded border-start border-secondary border-3">
            <Col className="d-flex flex-column align-items-center justify-content-center ">
              <ParagraphSmall text="Realizado (Consignado)" />
              <Subtitle text="R$ -" />
            </Col>
          </Col>
          <Col className="d-flex flex-column align-items-center my-2 shadow rounded border-start border-secondary border-3">
            <Col className="d-flex flex-column align-items-center justify-content-center ">
              <ParagraphSmall text="Realizado (Asaas)" />
              <Subtitle text={repasseRealizadoAsaas} />
            </Col>
          </Col>
        </Col>

        <Col className="d-flex flex-column gap-1">
          <div className="text-center bg-warning rounded ">
            <Subtitle text="Faturamento" />
          </div>
          {/* <Col className="d-flex flex-column align-items-center my-2 shadow rounded border-start border-secondary border-3">
            <Col className="d-flex flex-column align-items-center ">
              <ParagraphSmall text="Previsto (Consignado)" />
              <Subtitle text="R$ -" />
            </Col>
          </Col> */}
          <Col className="d-flex flex-column align-items-center my-2 shadow rounded border-start border-secondary border-3">
            <Col className="d-flex flex-column align-items-center ">
              <ParagraphSmall text="Recebido (Consignado)" />
              <Subtitle text={faturamentoConsig} />
            </Col>
          </Col>
          {/* <Col className="d-flex flex-column align-items-center my-2 shadow rounded border-start border-secondary border-3">
            <Col className="d-flex flex-column align-items-center ">
              <ParagraphSmall text="Previsto (Asaas)" />
              <Subtitle text="R$ -" />
            </Col>
          </Col> */}
          <Col className="d-flex flex-column align-items-center my-2 shadow rounded border-start border-secondary border-3">
            <Col className="d-flex flex-column align-items-center ">
              <ParagraphSmall text="Recebido (Asaas)" />
              <Subtitle text={faturamentoAsaas} />
            </Col>
          </Col>
        </Col>
      </Row>

      
    </Container>
  );
};

export default AdmDashboard;
