import functions from "../../components/utils/functions";
import api from "../Api";

export class CadastroService {

  async getAll() {
    return await api.get("/", {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) => res.data);
  }

  async getById(id) {
    return await api.post("/contratos-id", { cnt_id: id },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }).then((res) => res.data);
  }

  async postAtualizar(tokenGoogleo) {
    return await api
      .post("/contratos-atualizar", {
        tokenGoogleo: tokenGoogleo,
      }, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async postCadastrar(
    cpf,
    nome,
    email,
    telefone,
    senha,
    estado,
    cidade,
  ) {
    return await api.post("web/usuario/cadastrar-advogado", {
      "cpf": cpf,
      "nome": nome,
      "email": email,
      "celular": telefone,
      "senha": senha,
      "uf": estado,
      "cidade": cidade,
    }, {
      headers: {
        "Content-type": "application/json",
        // Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((res) => res.data);
  }

  async postPagarPlano(
    id,
    cpf,
    nome,
    email,
    telefone,
    cep,
    endereco,
    enderecoComplemento,
    enderecoNumero,
    enderecoBairro,
    enderecoCidade,
    numeroCartao,
    nomeCartao,
    dataExpiracao,
    cvvCartao
  ) {
    return await api.post("/advogado/realizar-pagamento", {
      "id": id,
      "cpf": cpf,
      "nome": nome,
      "email": email,
      "telefone": telefone,
      "cep": cep,
      "endereco": endereco,
      "complementoEndereco": enderecoComplemento,
      "enderecoNumero": enderecoNumero,
      "bairroEndereco": enderecoBairro,
      "cidadeEndereco": enderecoCidade,
      "numeroCartao": numeroCartao,
      "nomeCartao": nomeCartao,
      "dataExpiracao": dataExpiracao,
      "cvv": cvvCartao

    }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((res) => res.data)
      .catch((error) => {
        if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
          functions.modalToken();
        }
      });
  }

  async getDocsById(id) {
    return await api.post("/documentos-listar", { cnt_id: id }, {
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) => res.data);
  }

  async postEnviaDocumentos(id, fotoPerfil, fotoOab, oabNumero) {
    let formData = new FormData();
    formData.append('id', id);
    formData.append('perfil_foto', fotoPerfil);
    formData.append('documento_foto', fotoOab);
    // formData.append('diploma', diploma);
    formData.append('oab_numero', oabNumero);

    return await api.post("/advogado/recebe-documentacao", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((res) => res.data)
      .catch((error) => {
        if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
          functions.modalToken();
        }
      });
  }

  async postAreasAtuacao(id, areasAdv, valorConsulta, ambitoNacional) {
    return await api.post("/advogado/areas-atuacao", { id, areasAdv, valorConsulta, ambitoNacional },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }).then((res) => res.data)
      .catch((error) => {
        if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
          functions.modalToken();
        }
      });
  }

  async postValidaCodigoVerificador(id, codigo) {
    return await api.post("/advogado/valida-codigo-verificador", { id, codigo },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }).then((res) => res.data)
      .catch((error) => {
        if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
          functions.modalToken();
        }
      });
  }


  async postAceitaBoasVindas(cli_id) {
    return await api.post("/advogado/aceita-boas-vindas", { cli_id },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }).then((res) => res.data)
      .catch((error) => {
        if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
          functions.modalToken();
        }
      });
  }

  async postReenviaCodigoValidacao(cli_id) {
    return await api.post("/advogado/reenvia-codigo-verificador", { cli_id },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }).then((res) => res.data)
      .catch((error) => {
        if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
          functions.modalToken();
        }
      });
  }

  async getEstados() {
    return await api.get("/web/usuario/listar-estados", {
      headers: {
        "Content-type": "application/json",
        // Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    }).then((res) => res.data);
  }

  async listaCidadesEstado(nomeEstado) {
    return await api.post("/web/usuario/listar-cidade-estado", { nomeEstado },
      {
        headers: {
          "Content-type": "application/json",
          // Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      }).then((res) => res.data)
  }



};
