import React, { useEffect, useMemo, useState } from "react";
import "./styles.css";
import Button from "react-bootstrap/Button";
import Subtitle from "../../atoms/Subtitle";
import * as formik from "formik";
import * as yup from "yup";
import { LoginService } from "../../../services/ClienteServices/LoginService";
import { VideoConferenciaService } from "../../../services/VideoConferenciaServices/VideoConferenciaService";
import InputMask from "react-input-mask";
import functions from "../../utils/functions";
import Btn from "../../atoms/Button";
import { Link } from "react-router-dom";
import {
  Col,
  Collapse,
  Container,
  FloatingLabel,
  FormLabel,
  Row,
  InputGroup,
  Form,
  Modal,
} from "react-bootstrap";

import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import Nav from "react-bootstrap/Nav";
import { useSessionStorage } from "../../../storage/UseSessionStorage";
import { Toasts } from "../../utils/Toasts";

import { useNavigate } from "react-router-dom";
import Logo from "../../atoms/Logo";
import { UserService } from "../../../services/UserServices/UserService";

export const 

LoginClienteForms = ({ avaliacao }) => {
  const { Formik } = formik;
  let Api = new LoginService();
  let ApiEmail = new UserService();
  const api2 = useMemo(() => new VideoConferenciaService(), []);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [validated, setValidated] = useState(false);
  const [showFormFogotPassword, setShowFormForgotPassword] = useState(false);

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);
  

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const schema1 = yup.object().shape({
    cpf: yup
      .string()
      .test("valid-cpf", "CPF inválido", function (value) {
        return functions.validarCPF(value);
      })
      .required("CPF é obrigatório"),
    senha: yup.string("Senha incorreta").required("Senha é obrigatório"),
  });

  const handleSubmit = (values) => {
    setFormValues((prevFormValues) => ({ ...prevFormValues, ...values }));
    setStep((prevStep) => prevStep + 1);
    setValidated(true);
  };

  const openFormFogotPassword = () => {
    setShowFormForgotPassword(true);
  };

  const fecharModal = () => {
    setShowFormForgotPassword(false);
  };

  const handleSubmitEmail = async (values) => {
    ApiEmail.postEnviarEmail(values.email)
      .then((response) => {
        if (response.status) {
          let optionsToast = {
            code: response.mensagem.codigo,
            message: response.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);
        } else {
          let optionsToast = {
            code: response.mensagem.codigo,
            message: response.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const schema2 = yup.object().shape({
    email: yup
      .string()
      .required("Email é obrigatório")
      .email("Formato de e-mail inválido"),
  });

  useEffect(() => {
    if (step === 2) {
      Api.postLogin(formValues.cpf, formValues.senha)      
        .then((data) => {
          if (data.status === true) {
            sessionStorage.setItem("acessos", "");
            sessionStorage.setItem("nome", data.data.name);
            sessionStorage.setItem("email", data.data.email);
            sessionStorage.setItem("token", data.data.tokenId.dados);
            sessionStorage.setItem("tipo", data.data.tipo);
            sessionStorage.setItem("id", data.data.id);
            sessionStorage.setItem("emailValidado", data.data.emailValidado);
            sessionStorage.setItem("etapa_1", data.data.etapa_1);
            sessionStorage.setItem("etapa_2", data.data.etapa_2);
            sessionStorage.setItem("etapa_3", data.data.etapa_3);
            sessionStorage.setItem("etapa_4", data.data.etapa_4);
            sessionStorage.setItem("etapa_5", data.data.etapa_5);
            sessionStorage.setItem("logado", true);
            sessionStorage.setItem("fotoPerfil", data.data.fotoPerfil);
            api2.getUsers().then((data) =>{
              if (data.data){
                let usersComet = JSON.stringify(data.data);
                sessionStorage.setItem("usersComet", usersComet)
              } else {
                console.log("erro ao carregar usuario do cometchat")
              }
            }).catch((error) => {
              console.error("Error ao carregar os dados", error);
            });

            api2.getGroup().then((data) =>{
              if (data.data){
                let groupsComet = JSON.stringify(data.data);
                sessionStorage.setItem("groupsComet", groupsComet)
              } else {
                console.log("erro ao carregar Grupo do cometchat")
              }
            }).catch((error) => {
              console.error("Error ao carregar os dados", error);
            });
            
            if (avaliacao === 'avaliacao') {
              navigate("/ConsultasCli");
            } else {
              navigate("/");
            }


          } else {
            let optionsToast = {
              code: data.mensagem.codigo,
              message: data.mensagem.mensagem,
              visible: true,
            };
            setStep((prevStep) => prevStep - 1);
            console.log(data);
            setOptionsToast(optionsToast);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [step]);


  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <Formik
            key={step}
            validationSchema={schema1}
            onSubmit={(values) => {
              // Remover mascara antes de enviar info ao back
              const { cpf, ...rest } = values;
              const cpfSemMascara = functions.removeMascara(cpf);
              const valuesSemMascara = {
                ...rest,
                cpf: cpfSemMascara,
              };
              handleSubmit(valuesSemMascara);
            }}
            initialValues={{
              cpf: "",
              senha: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleReset,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group controlId="cpg">
                  <FloatingLabel label={"CPF"}>
                    <InputMask
                      mask="999.999.999-99"
                      value={values.cpf}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {(inputProps) => (
                        <Form.Control
                          className={`input-register-login-facj ${
                            touched.cpf && errors.cpf ? "is-invalid" : ""
                          }`}
                          type="text"
                          name="cpf"
                          value={values.cpf}
                          onChange={handleChange}
                          isValid={touched.cpf && !errors.cpf}
                          placeholder="CPF"
                          {...inputProps}
                        />
                      )}
                    </InputMask>
                    {touched.cpf && errors.cpf && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.cpf}
                      </Form.Control.Feedback>
                    )}
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.cpf}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group controlId="senha">
                  <FloatingLabel label={"Senha"}>
                    <Form.Control
                      className={`input-register-login-facj ${
                        touched.senha && errors.senha ? "is-invalid" : ""
                      }`}
                      type={type}
                      name="senha"
                      value={values.senha}
                      onChange={handleChange}
                      isValid={touched.senha && !errors.senha}
                      placeholder="Senha"
                    />
                    {touched.senha && errors.senha && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.senha}
                      </Form.Control.Feedback>
                    )}
                    <span className="icon-password-facj" onClick={handleToggle}>
                      <Icon icon={icon} size={25} />
                    </span>
                  </FloatingLabel>
                </Form.Group>
                <Nav.Link onClick={() => openFormFogotPassword()}>
                  Esqueceu sua senha?
                </Nav.Link>

                <Col className="btn-login-facj">
                  <Btn type={"secondary"} text="Entrar" isSumbit={true} />
                </Col>
              </Form>
            )}
          </Formik>
        );
      default:
        return null;
    }
  };

  return (
    <Container className="container-login-facj">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Row>
        <Modal
          size="md"
          show={showFormFogotPassword}
          backdrop="static"
          keyboard={false}
          // className="tamanho-modal"
        >
          <Modal.Header
            closeButton
            onClick={() => fecharModal()}
          ></Modal.Header>
          <Container className=" text-center">
            <Row>
              <Col className="d-flex row gap-5 my-3">
                <Logo type="imagem3" />

                <Subtitle text="Digite o e-mail cadastrado:" />
              </Col>
            </Row>
            <Row>
              <Col className="px-5">
                <Formik
                  key={"email"}
                  validationSchema={schema2}
                  onSubmit={(values) => {
                    handleSubmitEmail(values);
                  }}
                  initialValues={{
                    email: "",
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Form.Group controlId="email">
                        <FloatingLabel label={"Email"}>
                          <Form.Control
                            className={`input-register-login-facj ${
                              touched.email && errors.email ? "is-invalid" : ""
                            }`}
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                            placeholder="E-mail"
                          />
                          {touched.email && errors.email && (
                            <Form.Control.Feedback type="invalid" tooltip>
                              {errors.email}
                            </Form.Control.Feedback>
                          )}
                        </FloatingLabel>
                      </Form.Group>
                      <div className="btn-login-facj">
                        <Btn type={"secondary"} isSumbit={true} text="Enviar" />
                      </div>
                      <div className="layout-footer"></div>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </Modal>
        <Col>
          <Subtitle text={"Login"} />
          {renderForm()}
        </Col>
      </Row>
    </Container>
  );
};
