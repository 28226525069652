import api from "../Api";

export class ClienteService {
  async getAll() {
    return await api
      .get("/", {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
      })
      .then((res) => res.data);
  }

  async postListar(
    id,
    nome,
    data,
    telefone,
    status,
    planoAtivo,
    planoNome,
    dataContratacao,
    dataCancelamento
  ) {
    return await api
      .post(
        "/adm/site/cliente/listar",
        {
          id: id,
          nome: nome,
          data: data,
          telefone: telefone,
          status: status,
          planoAtivo: planoAtivo,
          planoNome: planoNome,
          dataContratacao: dataContratacao,
          dataCancelamento: dataCancelamento,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async getClientePorId(
    id,
    nome,
    cpf,
    telefone,
    email,
    data,
    status,
    planoAtivo,
    planoNome,
    dataContratacao,
    dataCancelamento
  ) {
    try {
      const { id: userId } = id;
      const response = await api.get(`/adm/site/cliente/dados/${userId}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        data: {
          nome,
          cpf,
          telefone,
          email,
          data,
          status,
          planoAtivo,
          planoNome,
          dataContratacao,
          dataCancelamento,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getPlanos() {
    try {
      const response = await api.get(`adm/site/lista-planos`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        },
        data: {},
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async postValidarCodigoCliente(cli_id) {
    return await api
      .post(
        "adm/site/cliente/dados/validar-codigo",
        { cli_id: cli_id },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async ativarPlano(cli_id, pla_id, data) {
    return await api
      .post(
        "adm/site/cliente/ativar-plano",
        {
          cli_id,
          pla_id,
          data,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }

  async desativarPlano(cli_id) {
    return await api
      .post(
        "adm/site/cliente/desativar-plano",
        {
          cli_id,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
}
