import React, { useEffect, useState } from "react";

import "./styles.css";
import Subtitle from "../../atoms/Subtitle";
import Image from "react-bootstrap/Image";
import myImage from "../../../../src/img/banner-video.png";
import AdvSelectArea from "../../molecules/AdvSelectArea";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Icons from "../../atoms/Icons";

import { HomeService } from "../../../services/HomeServices/HomeService";
import Paragraph from "../../atoms/Paragraph";

const HowItWorks = () => {

  return (
    <Container>
      <Container>
        <Subtitle text="Como o Faça Jus funciona?" />
        <Col className="d-flex  justify-content-center video-container ">
          <iframe           
            className="mt-4"
            src="https://www.youtube.com/embed/caAnkSQBusg?si=rXk3wdlUhgxfOo6M"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen={true}
          ></iframe>
        </Col>
      </Container>

      <Container className="text-start d-flex align-items-center my-5 py-5">
        <Row>
          <Col lg={4}>
            <Col className="d-flex gap-1 align-items-center">
              <Col lg={2}>
                <Icons type="procurar" />
              </Col>
              <Col className="pt-2">
                <Subtitle text="Encontre um advogado" />
              </Col>
            </Col>
            <Col>
              <Paragraph text="Busque por especialistas para atender o seu caso." />
            </Col>
          </Col>

          <Col lg={4}>
            <Col className="d-flex gap-1 align-items-center">
              <Col lg={2}>
                <Icons type="calendario" />
              </Col>
              <Col className="pt-2">
                <Subtitle text="Agende sua teleconsulta" />
              </Col>
            </Col>
            <Col>
              <Paragraph text="Escolha o especialista, dia e horário que melhor te atende, em seguida envie seu caso para a consulta." />
            </Col>
          </Col>

          <Col lg={4}>
            <Col className="d-flex gap-1 align-items-center">
              <Col lg={2}>
                <Icons type="duvidas" />
              </Col>
              <Col className="pt-2">
                <Subtitle text="Tire dúvidas sobre o seu caso" />
              </Col>
            </Col>
            <Col>
              <Paragraph text="Envie suas perguntas sobre o seu caso, especialistas vão te orientar sobre o assunto." />
            </Col>
          </Col>
        </Row>
      </Container>
      <Container className="text-start d-flex align-items-center my-5 pb-5">
        <Row>
          <Col lg={4}>
            <Col className=" d-flex gap-2 align-items-center">
              <Col lg={2}>
                <Icons type="seguro" />
              </Col>
              <Col  className="pt-2">
                <Subtitle text="Ambiente seguro" />
              </Col>
            </Col>
            <Col>
              <Paragraph text="O Faça Jus proporciona um ambiente digital seguro e confiável para as tele consultas." />
            </Col>
          </Col>
          <Col lg={4}>
            <Col className="d-flex gap-2  align-items-center">
              <Col lg={2}>
                <Icons type="certificado" />
              </Col>
              <Col className="pt-2">
                <Subtitle text="Profissionais qualificados" />
              </Col>
            </Col>
            <Col>
              <Paragraph text="Realizamos uma triagem no cadastro de profissionais, garantindo os melhores especialistas para atender o seu caso." />
            </Col>
          </Col>
          <Col lg={4}>
            <Col className="d-flex gap-2 align-items-center">
              <Col lg={2}>
                <Icons type="consignado" />
              </Col>
              <Col className="pt-2">
                <Subtitle text="Pague com seu consignado" />
              </Col>
            </Col>
            <Col>
              <Paragraph text="Possibilidade para servidores, pague sua consulta descontada da sua margem." />
            </Col>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default HowItWorks;
