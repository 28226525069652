import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ParagraphSmall from "../../atoms/ParagraphSmall/index.js";
import Paragraph from "../../atoms/Paragraph/index.js";
import { Col, Container, Modal, Row } from "react-bootstrap";
import Icons from "../../atoms/Icons/index.js";
import { useState } from "react";
import Btn from "../../atoms/Button/index.js";
import { AgendaService } from "../../../services/AdvogadoServices/AgendaService.js";
import { Toasts } from "../../utils/Toasts/index.js";
import ExcluirHorarioModal from "../ExcluirHorarioModal/index.js";

const api = new AgendaService();

const HorarioDisponivelCard = ({ data, hora, id }) => {

  const [showModal, setShowModal] = useState(false);
  const [optionsToast, setOptionsToast] = useState({
    code: "",
    message: "",
    visible: false,
  });

  const handleExcluirClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

 
  

  return (
    <Container className="d-flex justify-items-center gap-2">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() =>
          setOptionsToast({ code: "", message: "", visible: false })
        }
      />

      <Col
        lg={12}
        className="border-start border-secondary border-2 shadow ps-3 p-1 bg-light rounded d-flex align-items-center gap-2"
      >
        <Col lg={6} className="d-flex align-items-center flex-row gap-3">
          <Paragraph text={data} className="fw-bold" />
          <Col
            lg={4}
            className="border border-primary pt-1 px-2 rounded d-flex justify-content-center "
          >
            <Paragraph text={`${hora}`} className="fw-bold" />
          </Col>
        </Col>
        <Col lg={6}  className="pe-1 gap-2 d-flex flex-row align-items-center">
          <Col lg={2} className="cursor" onClick={handleExcluirClick}>
            <Icons type="excluir" />
          </Col>
          <ParagraphSmall text="Excluir horário" />
        </Col>
      </Col>

      <ExcluirHorarioModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        id={id}  
      />

    </Container>
  );
};

export default HorarioDisponivelCard;
