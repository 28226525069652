import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col } from "react-bootstrap";
import Header from "../../../molecules/Header";
import NavitemSidebarAdm from "../../../atoms/NavitemSidebarAdm";
import AdmDashboard from "../../../organisms/AdmDashboard ";
import "./styles.css";
import AdmHeader from "../../../molecules/AdmHeader";
import AdmGraficoFaturamento from "../../../molecules/AdmGraficoFaturamento";
import Subtitle from "../../../atoms/Subtitle";

export const DashboardTemp = () => {
  const [sideNavExpanded, setSideNavExpanded] = useState(true);

  const toggleSideNav = () => {
    setSideNavExpanded(!sideNavExpanded);
  };

  // useEffect(() => {
  //   sessionStorage.setItem('acessosAdm', ['gerencial-perfis-acessar,gerencial-perfis-visualizar,gerencial-perfis-cadastrar,gerencial-perfis-editar','gerencial-usuarios-acessar,gerencial-usuarios-visualizar,gerencial-usuarios-cadastrar,gerencial-usuarios-editar'])
  // }, []);

  return (
    <body className="m-0 p-0">
      <Container fluid className="fluid-bg">
        <AdmHeader />
      </Container>

      <Container fluid className="d-flex  row mt-5">
        <Col
          className={`menu-position ${sideNavExpanded ? "" : "menu-fechado"}`}
        >
          <NavitemSidebarAdm
            menuAberto={sideNavExpanded}
            toggleMenu={toggleSideNav}
          />
        </Col>
        <Col className="d-flex flex-column gap-2">
          <Col className="flex-grow-1 shadow-lg">
            <AdmDashboard />
          </Col>
          <Col className="flex-grow-1 p-2 shadow-lg">
            <AdmGraficoFaturamento />
          </Col>
        </Col>
      </Container>

      <Container></Container>
    </body>
  );
};
