import functions from "../../components/utils/functions";
import api from "../Api";

export class RelatorioParticularService {
  async listar(dataInicial, dataFinal) {
    try {
      // Convertendo datas para o formato 'YYYY-MM-DD'
      const [diaInicial, mesInicial, anoInicial] = dataInicial
        .split("-")
        .map(Number);
      const [diaFinal, mesFinal, anoFinal] = dataFinal.split("-").map(Number);

      // Ajustando para o formato 'YYYY-MM-DD'
      const formattedDataInicial = new Date(
        anoInicial,
        mesInicial - 1,
        diaInicial
      )
        .toISOString()
        .split("T")[0];
      const formattedDataFinal = new Date(anoFinal, mesFinal - 1, diaFinal)
        .toISOString()
        .split("T")[0];

      const response = await api.post(
        "/adm/financeiro/relatorio-particular",
        {
          dataInicial: formattedDataInicial,
          dataFinal: formattedDataFinal,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.msg === "Token Expirado3."
      ) {
        functions.modalToken();
      } else {
        throw error;
      }
    }
}

async listarCancelamento(dataInicial, dataFinal) {
    try {
        // Convertendo datas para o formato 'YYYY-MM-DD'
        const [diaInicial, mesInicial, anoInicial] = dataInicial.split('-').map(Number);
        const [diaFinal, mesFinal, anoFinal] = dataFinal.split('-').map(Number);
        
        // Ajustando para o formato 'YYYY-MM-DD'
        const formattedDataInicial = new Date(anoInicial, mesInicial - 1, diaInicial).toISOString().split('T')[0];
        const formattedDataFinal = new Date(anoFinal, mesFinal - 1, diaFinal).toISOString().split('T')[0];

        const response = await api.post("/adm/financeiro/relatorio-cancelamento-particular", {
            "dataInicial": formattedDataInicial,
            "dataFinal": formattedDataFinal
        }, {
            headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        });

        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 403 && error.response.data.msg === 'Token Expirado3.') {
          functions.modalToken();

        } else {
            throw error;
        }
    }
}
  

  async statusParcelaParticular(id_parcela) {
    return await api
      .post(
        "/adm/financeiro/status-parcela-particular",
        {
          id_parcelas: id_parcela
        },
        {
          headers: {
            "Content-type": "application/json",
            // Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => res.data);
  }
}
