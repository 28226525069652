import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Icons from "../../atoms/Icons";

import "./styles.css";
import Paragraph from "../../atoms/Paragraph";
import Subtitle from "../../atoms/Subtitle";

export default function ConteudoCarrosselLpOne() {
  return (
    <Container className="mb-5">
      <Row className="my-0 py-0">
        <Col className="d-flex justify-content-center ">
          <Col lg={10} className="bg-warning p-3 rounded shadow">
            <Paragraph
              text="Esse tipo de benefício oferece várias vantagens,
            facilitando o acesso à orientação jurídica de qualidade de maneira 
            conveniente e acessível."
            />
          </Col>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-center my-0 py-0">
          <Col lg={10} className="index d-lg-flex ">
            <Col className="d-lg-flex align-items-end pt-0 mt-0">
              <Col className="index">
                <Icons type="one" />
              </Col>
              <Col className="d-none d-sm-flex align-item-end linha-vertical-warning shadow ">
                
              </Col>
            </Col>
            <Col lg={10} sm={12} className="d-flex mt-5 ">
              <Col className="">
                <Subtitle text="Acessibilidade:" />
                <Paragraph
                  text="O principal benefício desse sistema é tornar 
               os serviços jurídicos mais acessíveis para os servidores.
                Muitas vezes, as pessoas evitam buscar aconselhamento 
                jurídico devido aos altos custos envolvidos. Com o pagamento 
                descontado em folha, os funcionários podem obter assistência 
                legal sem precisar pagar uma grande quantia de uma só vez."
                />
              </Col>
            </Col>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-center my-0 py-0">
          <Col lg={10} className="index d-lg-flex ">
            <Col className="d-lg-flex align-items-end pt-0 mt-0">
              <Col className="index">
                <Icons type="two" />
              </Col>
              <Col className="d-none d-sm-flex align-item-end linha-vertical-warning shadow ">
                
              </Col>
            </Col>
            <Col lg={10} sm={12} className="d-flex mt-5 ">
              <Col className="">
                <Subtitle text="Conveniência:" />
                <Paragraph
                  text="Esse método de pagamento também 
               oferece conveniência. Os pagamentos são feitos de 
               forma automática a partir do salário do servidor,
                eliminando a necessidade de organizar pagamentos separados 
                ou lidar com transações financeiras complexas."
                />
              </Col>
            </Col>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-center my-0 py-0">
          <Col lg={10} className="index d-lg-flex ">
            <Col className="d-lg-flex align-items-end pt-0 mt-0">
              <Col className="index ">
                <Icons type="three" />
              </Col>
              <Col className="d-none d-sm-flex align-item-end linha-vertical-warning shadow ">
                
              </Col>
            </Col>
            <Col lg={10} sm={12} className="d-flex mt-5 pt-4">
              <Col className="">
                <Subtitle text="Privacidade e confidencialidade:" />
                <Paragraph
                  text="As consultas jurídicas podem envolver informações sensíveis. 
               No Faça Jus você pode discutir seus problemas legais de maneira confidencial 
               com seus advogados, preservando sua privacidade."
                />
              </Col>
            </Col>
          </Col>
        </Col>
      </Row>

      <Row className="my-0 py-0">
        <Col className="d-flex justify-content-center my-5">
        <Col lg={10}  >
          <Paragraph
            text="Em resumo, o pagamento descontado em 
            folha para consultas jurídicas é uma opção que visa
            tornar os serviços jurídicos mais acessíveis, convenientes e 
            financeiramente gerenciáveis para os funcionários. Busque agora 
            especialistas que atendam o seu caso."
            />
        </Col>
            </Col>
      </Row>
    </Container>
  );
}
