import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Subtitle from "../../atoms/Subtitle";
import { Col, Container, Modal, Row } from "react-bootstrap";
import ImgBanner from "../../atoms/ImgBanner";
import ConsultaAgendadaCard from "../../molecules/ConsultaAgendadaCard";
import Calendario from "../../molecules/Calendario";
import functions from "../../utils/functions";
import { ConsultaService } from "../../../services/AdvogadoServices/ConsultasService";
import Paragraph from "../../atoms/Paragraph";
import { Spinner } from "react-bootstrap";

import { ptBR } from "date-fns/locale";
import HorarioDisponivelCard from "../../molecules/HorarioDisponivelCard";
import Btn from "../../atoms/Button";
import { Toasts } from "../../utils/Toasts";
import ExcluirAgendaModal from "../../molecules/ExcluirAgendaModal";

const AdvConsultasAgendadas = () => {
  const api = new ConsultaService();
  const id = sessionStorage.getItem("id");
  const [loading, setLoading] = useState(false);
  const fusoHorario = "Etc/UTC";
  const { parseISO, format } = require("date-fns");
  const { utcToZonedTime } = require("date-fns-tz");

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [horariosLivres, setHorariosLivres] = useState([]);
  const [consultasAgendadas, setConsultasAgendadas] = useState([]);
  const [listaConsultas, setListaConsultas] = useState([]);
  const now = new Date();
  const dateAtual = format(now, "yyyy-MM-dd");

  const [showModal, setShowModal] = useState(false);
  const [showExcluirAgendaModal, setShowExcluirAgendaModal] = useState(false);

  const [optionsToast, setOptionsToast] = useState({
    code: "",
    message: "",
    visible: false,
  });

  const searchDate = (date) => {
    setLoading(true);
    setSelectedDate(date);
    let data = functions.formatarDataParaString(date);
    api
      .getConsultas(id, data)
      .then((data) => {
        setLoading(false);
        if (data.status === true) {
          setHorariosLivres(data.data.horariosLivres || []);
          setConsultasAgendadas(data.data.consultasAgendadas || []);
        } else {
          setHorariosLivres([]);
          setConsultasAgendadas([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleVerTodasConsultas = () => {
    api
      .listarConsultas(id)
      .then((data) => {
        if (data.status === true) {
          setConsultasAgendadas(data.data);
          setHorariosLivres([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    handleVerTodasConsultas();
  }, []);

  const formatDate = (dateString) => {
    return format(
      utcToZonedTime(parseISO(dateString), fusoHorario),
      "dd/MM/yyyy",
      { locale: ptBR }
    );
  };

  const handleExcluirAgenda = () => {
    setShowExcluirAgendaModal(true);
  };

  const handleCloseModal = () => {
    setShowExcluirAgendaModal(false);
  };

  return (
    <Container>
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() =>
          setOptionsToast({ code: "", message: "", visible: false })
        }
      />
      <Col>
        <Row>
          <Col>
            <Subtitle text="Minha Agenda" />
          </Col>
        </Row>
        <Row className="gap-5">
          <Col
            lg={6}
            className="d-flex flex-column align-items-end justify-content-top gap-3"
          >
            <Calendario selectedDate={(date) => searchDate(date)} />
            <Col className="d-flex  gap-5">
              <Btn
                type="danger-sm"
                text="Excluir Agenda disponível"
                onTouchable={handleExcluirAgenda}
              />
              <Btn
                type="secondary-sm"
                text="Ver todas as consultas"
                onTouchable={() => {
                  handleVerTodasConsultas();
                  setSelectedDate(new Date());
                }}
              />
            </Col>
          </Col>

          <Col
            lg={5}
            className="d-flex flex-column justify-content-top"
            style={{ height: "32rem", overflowY: "auto" }}
          >
            {loading ? (
              // Loading spinner
              <Row className="mt-5 pt-5 d-flex align-items-center">
                <Col lg={1}>
                  <Spinner animation="border" role="status"></Spinner>
                </Col>
                <Paragraph text="Buscando agenda, aguarde." />
              </Row>
            ) : (
              <>
                {consultasAgendadas.length > 0 && (
                  // Display appointments
                  <>
                    <Paragraph text="Pŕoximas consultas:" />
                    {consultasAgendadas.map((consulta, index) => (
                        <ConsultaAgendadaCard
                          key={index}
                          data={formatDate(consulta.data)}
                          hora={consulta.hora}
                          nome={consulta.nome}
                          idConsulta={consulta.idDaConsulta}
                          idCac={consulta.idCac}
                          showIcon={false}
                          idAgenda={consulta.idAgenda}
                          reagendar={false}
                          cancelarAdv={true}
                          cancelarCli={false}
                          id={consulta.cliId}
                        />
                    ))}
                  </>
                )}
                {horariosLivres.length > 0 && (
                  // Display available time
                  <>
                    <Paragraph text="Horários disponíveis:" />
                    {horariosLivres.map((horario, index) => (
                      <HorarioDisponivelCard
                        key={index}
                        data={formatDate(horario.data)}
                        hora={horario.hora}
                        id={horario.idAgenda}
                        showIcon={false}
                      />
                    ))}
                  </>
                )}
                {listaConsultas.length > 0 &&
                  consultasAgendadas.length === 0 &&
                  horariosLivres.length === 0 && (
                    <>
                      {listaConsultas.map((consulta, index) => (
                        <div key={index}>
                          {consulta.data >= dateAtual ? (
                            <ConsultaAgendadaCard
                              data={formatDate(consulta.data)}
                              hora={consulta.hora}
                              nome={consulta.nome}
                              idConsulta={consulta.idDaConsulta}
                              showIcon={false}
                              id={consulta.cliId}
                              reagendar={false}
                              cancelarAdv={true}
                              cancelarCli={false}
                            />
                          ) : null}
                        </div>
                      ))}
                    </>
                  )}

                {consultasAgendadas.length === 0 &&
                  horariosLivres.length === 0 &&
                  listaConsultas.length === 0 && (
                    <Col className="d-flex align-items-center mt-5 pt-5">
                      <Paragraph text="Não há agenda para este dia." />
                    </Col>
                  )}
              </>
            )}
          </Col>
        </Row>
      </Col>
      <ExcluirAgendaModal
        showModal={showExcluirAgendaModal}
        handleCloseModal={handleCloseModal}
        id={id}
      />
      <Col>
        <Row className="mt-5">
          <Col>
            <Subtitle text="Produtos" />
          </Col>
        </Row>
        <Row className=" mb-5 pb-5">
          <Col lg={6} className="pb-2">
            <Link
              to="https://assistamais.net.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImgBanner type="banner6" key="banner1" />
            </Link>
          </Col>
          <Col lg={6} className="pb-2">
            <Link
              to="https://www.usemaisstore.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImgBanner type="banner5" key="banner2" />
            </Link>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default AdvConsultasAgendadas;
