import functions from "../../components/utils/functions";
import api from "../Api";

export class RelatorioConsignadoService {
  async listar(ano, mes) {
    try {
      const response = await api.post(
        "/adm/financeiro/relatorio-consignado",
        {
          ano: ano,
          mes: mes,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.msg === "Token Expirado3."
      ) {
        functions.modalToken();
      } else {
        throw error;
      }
    }
  }

  async listarCancelados(ano, mes) {
    try {
      const response = await api.post(
        "/adm/financeiro/relatorio-consignado-cancelados",
        {
          ano: ano,
          mes: mes,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.msg === "Token Expirado3."
      ) {
        functions.modalToken();
      } else {
        throw error;
      }
    }
  }

  async statusConsignado(id_terceiros, idUsuario) {
    try {
      const response = await api.post(
        "/adm/financeiro/status-parcelar-consignado",
        {
          id_terceiros: id_terceiros,
          idUsuario: idUsuario,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.msg === "Token Expirado3."
      ) {
        functions.modalToken();
      } else {
        throw error;
      }
    }
  }

  async statusConsignadoCancelamento(id_reembolso, idUsuario) {
    try {
      const response = await api.post(
        "/adm/financeiro/status-parcela-consignado-cancelamento",
        {
          id_reembolso: id_reembolso,
          idUsuario: idUsuario,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        }
      );

      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.msg === "Token Expirado3."
      ) {
        functions.modalToken();
      } else {
        throw error;
      }
    }
  }
}
