import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../molecules/Header";
import Footer from "../../molecules/Footer";
import ImgBannerPerfil from "../../atoms/ImgBannerPerfil";
import Subtitle from "../../atoms/Subtitle";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import { Tabs, Tab } from "react-bootstrap";

import AdvConsultasAgendadas from "../../organisms/AdvConsultasAgendadas";
import "./styles.css";
import { AdvMinhaAgenda } from "../../organisms/AdvMinhaAgenda";
import { HistoricoConsultasAdv } from "../../organisms/HistoricoConsultasAdv";
import { Rating } from "react-simple-star-rating";
import { PerfilService } from "../../../services/AdvogadoServices/PerfilService";
import { AvaliacaoService } from "../../../services/AdvogadoServices/AvaliacaoService";
import { FinanceiroService } from "../../../services/AdvogadoServices/FinanceiroService";
import AvisoCadastroRecebimento from "../../molecules/AvisoCadastroRecebimento";

export const ConsultasAdv = () => {
  const apiMediaDeNotas = new AvaliacaoService
  const api = new FinanceiroService();

  const [estrelas, setEstrelas] = useState();

  const [meioRecebimento, setMeioRecebimento] = useState(false);
  const id = sessionStorage.getItem("id");
  const nomeUsuario = sessionStorage.getItem("nome") || "";
  
  useEffect(() => {
    apiMediaDeNotas.listarMediaEstrelas(id)
    .then((data) => {
      setEstrelas(data.data);
    })
    .catch((error) => {
      console.log(error);
    });
  }, [])


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.verificarMeioRecebimento(id);
        setMeioRecebimento(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <body className="mt-5 pt-5">
      <Container fluid className="fluid-bg ">
        <Header />
      </Container>

      <Container fluid className="p-0 m-0 banner-container">
        <ImgBannerPerfil className="img-fluid " />
      </Container>

      <Container>
        <Row className="gap-5">
          <Col lg={2} className="avatar-container">
            <AvatarPerfil
              type="A"
              base64={sessionStorage.getItem("fotoPerfil")}
              width="13rem"
              height="13rem"
              padding="0.4rem"
            />
          </Col>

          <Col lg={8} className="py-2 mt-2 ms-2">
            <Subtitle text={"Olá, " + nomeUsuario} />
            <Rating size={25} readonly={true} initialValue={estrelas}></Rating>
          </Col>
        </Row>
      </Container>

      <Container className="my-5">
        <Tabs
          defaultActiveKey="minhas-consultas"
          id="justify-tab-example"
          className="custom-tabs gap-2 mb-5 ms-5 me-5 ps-5 pe-5"
          justify
        >
              <Tab eventKey="minhas-consultas" title="Minha Agenda">
            <AdvConsultasAgendadas />
          </Tab>
          <Tab eventKey="minha-agenda" title="Criar Agenda">
            {meioRecebimento ? (
              <AdvMinhaAgenda />
            ) : (
              <AvisoCadastroRecebimento />
            )}
          </Tab>
       
          <Tab eventKey="historico-consultas" title="Histórico de consultas">
            <HistoricoConsultasAdv />
          </Tab>
        </Tabs>
      </Container>

      <Container fluid className="fluid-bg ">
        <Footer />
      </Container>
    </body>
  );
};
