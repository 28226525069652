import React from "react";
import "./styles.css";

import myImage1 from "../../../../src/img/Icone_calendario.png";
import myImage2 from "../../../../src/img/Icone_certificado.png";
import myImage3 from "../../../../src/img/Icone_consignado.png";
import myImage4 from "../../../../src/img/Icone_duvidas.png";
import myImage5 from "../../../../src/img/Icone_procurar.png";
import myImage6 from "../../../../src/img/Icone_seguro.png";

import myImage7 from "../../../../src/img/Icone_relogio_azul.png";
import myImage8 from "../../../../src/img/Icone_check_azul.png";
import myImage9 from "../../../../src/img/Icone_perfil_azul.png";
import myImage10 from "../../../../src/img/check-amarelo.png";
import myImage11 from "../../../../src/img/icone-circulos.png";
import myImage12 from "../../../../src/img/Icone_exportar.png";
import myImage13 from "../../../../src/img/Icone_ordenar_up.png";
import myImage14 from "../../../../src/img/Icone_ordenar_down.png";
import myImage15 from "../../../../src/img/Icone_editar.png";
import myImage16 from "../../../../src/img/Icone_excluir.png";

import myImage17 from "../../../../src/img/Icon_chat.png";
import myImage18 from "../../../../src/img/Icon_video.png";

import PagaParaMim from "../../../../src/img/Logo_pagaparamim.png";

import amex from "../../../../src/img/Cartao_amex.png";
import aura from "../../../../src/img/Cartao_aura.png";
import dinners from "../../../../src/img/Cartao_dinners.png";
import discover from "../../../../src/img/Cartao_discover.png";
import elo from "../../../../src/img/Cartao_elo.png";
import hipercard from "../../../../src/img/Cartao_hipercard.png";
import master from "../../../../src/img/Cartao_master.png";
import visa from "../../../../src/img/Cartao_visa.png";

import banco from "../../../../src/img//icone_banco.png";
import pix from "../../../../src/img/icone-pix.png";

import one from "../../../../src/img/one.png";
import two from "../../../../src/img/two.png";
import three from "../../../../src/img/three.png";

import beneficios from "../../../../src/img/icone-beneficios.png";
import rede from "../../../../src/img/icone-rede.png";
import tela from "../../../../src/img/icone-tela.png";
import visualizacao from "../../../../src/img/icone-visualizacao.png";

import menu from "../../../../src/img/icone-abrir-menu.png";
import dashboard from "../../../../src/img/icone-dashboard.png";
import gerencial from "../../../../src/img/icone-gerencial.png";
import perfil from "../../../../src/img/icone-perfil.png";
import usuarios from "../../../../src/img/icone-usuarios.png";
import financeiro from "../../../../src/img/icone-financiero.png";
import forum from "../../../../src/img/icone-forum.png";
import atendimento from "../../../../src/img/icone-atendimento.png";
import site from "../../../../src/img/icone-site.png";
import advogados from "../../../../src/img/icone-advogados.png";
import clientes from "../../../../src/img/icone-clientes.png";
import reagendar from "../../../../src/img/icone_reagendar.png";
import notAgenda from "../../../../src/img/icone-not-agenda.png";
import notChat from "../../../../src/img/icone-not-chat.png";
import notLembrete from "../../../../src/img/icon_lembrete.png";
import buscar from "../../../../src/img/icon_buscar.png";
import whatsapp from "../../../../src/img/whatsapp.png";

import notificacao from "../../../../src/img/icone-notificacao.png";
import cancelar from "../../../../src/img/icone_cancelar.png";
const Icons = ({ type, ...props }) => {
  let imagem = "";

  switch (type) {
    case "calendario":
      imagem = myImage1;
      break;
    case "certificado":
      imagem = myImage2;
      break;
    case "consignado":
      imagem = myImage3;
      break;
    case "duvidas":
      imagem = myImage4;
      break;
    case "procurar":
      imagem = myImage5;
      break;
    case "seguro":
      imagem = myImage6;
      break;
    case "relogio-azul":
      imagem = myImage7;
      break;
    case "check-azul":
      imagem = myImage8;
      break;
    case "perfil-azul":
      imagem = myImage9;
      break;
    case "check-amarelo":
      imagem = myImage10;
      break;
    case "circulos":
      imagem = myImage11;
      break;
    case "exportar":
      imagem = myImage12;
      break;
    case "ordenar-up":
      imagem = myImage13;
      break;
    case "ordenar-down":
      imagem = myImage14;
      break;
    case "editar":
      imagem = myImage15;
      break;
    case "excluir":
      imagem = myImage16;
      break;
    case "chat":
      imagem = myImage17;
      break;
    case "video":
      imagem = myImage18;
      break;

    case "pagaparamim":
      imagem = PagaParaMim;
      break;

    case "c-amex":
      imagem = amex;
      break;
    case "c-aura":
      imagem = aura;
      break;
    case "c-dinners":
      imagem = dinners;
      break;
    case "c-discover":
      imagem = discover;
      break;
    case "c-elo":
      imagem = elo;
      break;
    case "c-hipercard":
      imagem = hipercard;
      break;
    case "c-master":
      imagem = master;
      break;
    case "c-visa":
      imagem = visa;
      break;
    case "banco":
      imagem = banco;
      break;
    case "pix":
      imagem = pix;
      break;

    case "one":
      imagem = one;
      break;
    case "two":
      imagem = two;
      break;
    case "three":
      imagem = three;
      break;

    case "beneficios":
      imagem = beneficios;
      break;
    case "rede":
      imagem = rede;
      break;
    case "tela":
      imagem = tela;
      break;
    case "visualizacao":
      imagem = visualizacao;
      break;
    case "reagendar":
      imagem = reagendar;
      break;

    /*-----icones ADM-----*/
    case "abrir-menu":
      imagem = menu;
      break;
    case "dashboard":
      imagem = dashboard;
      break;
    case "gerencial":
      imagem = gerencial;
      break;
    case "perfil":
      imagem = perfil;
      break;
    case "usuarios":
      imagem = usuarios;
      break;
    case "financeiro":
      imagem = financeiro;
      break;
    case "forum":
      imagem = forum;
      break;
    case "atendimento":
      imagem = atendimento;
      break;
    case "site":
      imagem = site;
      break;
    case "advogados":
      imagem = advogados;
      break;
    case "clientes":
      imagem = clientes;
      break;

    case "notificacao":
      imagem = notificacao;
      break;

    case "notAgenda":
      imagem = notAgenda;
      break;

    case "notChat":
      imagem = notChat;
      break;

    case "notLembrete":
      imagem = notLembrete;
      break;

    case "cancelar":
      imagem = cancelar;
      break;

      case "buscar":
        imagem = buscar;
        break;

        case "whatsapp":
          imagem = whatsapp;
          break;
  }

  return (
    <div className="container-fluid m-0 p-0" {...props}>
      <img src={imagem} alt="Icone" />
    </div>
  );
};

export default Icons;
