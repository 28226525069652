import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Col } from "react-bootstrap";
import "./styles.css";
import Header from "../../../molecules/Header";
import NavitemSidebarAdm from "../../../atoms/NavitemSidebarAdm";
import AdmHeader from "../../../molecules/AdmHeader";
import AdmGerencialUsuarios from "../../../organisms/AdmGerencialUsuarios";

export const GerencialUsuariosTemp = ({permissoes}) => {
  const [sideNavExpanded, setSideNavExpanded] = useState(true);

  const toggleSideNav = () => {
    setSideNavExpanded(!sideNavExpanded);
  };

  return (
    <body className="m-0 p-0">
      <Container fluid className="fluid-bg">
      <AdmHeader />

      </Container>

      <Container fluid className="d-flex  mt-5">
        <Col className={`menu-position ${sideNavExpanded ? "" : "menu-fechado"}`}>
          <NavitemSidebarAdm menuAberto={sideNavExpanded} toggleMenu={toggleSideNav} />
        </Col>
        
        <Col  className=" w-75 shadow-lg">
        <AdmGerencialUsuarios
          permissoes={permissoes}
        />
      </Col>
      </Container>

      <Container></Container>
    </body>
  );
};
