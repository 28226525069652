import React, { useState, useEffect, useCallback } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Col, Container, Row } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import CardMeioRecebimentoAdv from "../../molecules/CardMeioRecebimentoAdv";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import Icons from "../../atoms/Icons";
import AdicionarRecebimentoAdvModal from "../../organisms/AdicionarRecebimentoAdvModal";
import { FinanceiroService } from "../../../services/AdvogadoServices/FinanceiroService";
import ExtratoConsultaAdv from "../../molecules/ExtratoConsultaAdv";
import { PerfilService } from "../../../services/AdvogadoServices/PerfilService";
import { AdvDadosConsulta } from "../../organisms/AdvDadosConsulta";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Paragraph from "../../atoms/Paragraph";
import Btn from "../../atoms/Button";

export const MeioRecebimentoAdv = () => {

  const api = new FinanceiroService();
  const api2 = new PerfilService();

  const id = sessionStorage.getItem("id");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dadosApi2, setDadosApi2] = useState(null);

  const [controlRender, setControlRender] = useState(false);
  const [showCorrespondente, setShowCorrespondente] = useState(true);
  const [meioRecebimento, setMeioRecebimento] = useState(false);
  const [dados, setDados] = useState([]);

  const [tokenRecaptcha, setTokenRecaptcha] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.verificarMeioRecebimento(id);
        setMeioRecebimento(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (dadosApi2 != null) {
      setControlRender(true);
    }
  }, [dadosApi2]);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    setTokenRecaptcha(await executeRecaptcha("submit"));
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  useEffect(() => {
    handleReCaptchaVerify();
    api2
      .postPerfilAdv(id, tokenRecaptcha)
      .then((data) => {
        setDadosApi2(data);
        setShowCorrespondente(data.data.dadosAdvogado.correspondente);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    async function fetchDados() {
      try {
        const response = await api.exibirMeioRecebimento(id);
        setDados(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchDados();
  }, []);

  return (
    <Container>
      <Row>
        <Col lg={12}>
          <Subtitle text="Conta de Recebimento" />

          {!meioRecebimento ? (
            <Col
              lg={4}
              className="border rounded align-items-center d-flex  p-2"
            >
              <Col className="">
                <Paragraph text="Cadastre sua conta de recebimento:" />
              </Col>
              {isModalOpen && (
                <AdicionarRecebimentoAdvModal onClose={closeModal} />
              )}
              <Col lg={1} className="cursor" onClick={openModal}>
                <Icons type="editar" />
              </Col>
            </Col>
          ) : (
            <Col
              lg={4}
              className=" align-items-center d-flex  p-2"
            >
              <Btn
                type="secondary-sm"
                text="Alterar conta de recebimento"
                onTouchable={openModal}
              />
              {isModalOpen && (
                <AdicionarRecebimentoAdvModal onClose={closeModal} />
              )}
            </Col>
          )}


          <Col lg={8} sm={6} className="d-flex gap-3 flex-column mt-2">
            <Col>
              {meioRecebimento != false && (
                <Col style={{ overflowX: "hidden" }} className="shadow rounded mb-4">
                  <CardMeioRecebimentoAdv type="banco" />
                </Col>
              )}
              <Col className="ps-3 pe-3">
                <ParagraphSmall
                  text="Gostaríamos de lembrá-lo(a) sobre a importância de estar ciente das
                taxas que serão retidas nas transações realizadas em nossa plataforma. Para obter informações
                detalhadas sobre essas taxas e como elas são aplicadas, recomendamos  que você consulte
                o Termo de Uso disponível em nosso site."
                />
              </Col>
            </Col>
          </Col>
        </Col>
      </Row>
      <Row lg={12} className="mt-3">
        <Col className="ms-2">
          <Subtitle text="Valor da Consulta" />
        </Col>
        {controlRender ? <AdvDadosConsulta data={dadosApi2} /> : <></>}
      </Row>
      {/* <Row className="d-flex align-items-center mt-2 mb-5 ">
        {showCorrespondente ? (
          <Container className="mt-5">
            <Subtitle text="Correspondente:" />
            {controlRender ? (
              <AdvDadosCorrespondente data={dadosApi2} />
            ) : (
              <></>
            )}
          </Container>
        ) : (
          <></>
        )}
      </Row> */}
      <Row>
        <Col className="">
          <ExtratoConsultaAdv />
        </Col>
      </Row>
    </Container>
  );
};
