import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.css";
import Subtitle from "../../atoms/Subtitle";
import Paragraph from "../../atoms/Paragraph";
import { Rating } from "react-simple-star-rating";
import { AvatarPerfil } from "../../atoms/AvatarPerfil";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import Btn from "../../atoms/Button";
import { AvaliacaoService } from "../../../services/AdvogadoServices/AvaliacaoService";
import { Form } from "react-bootstrap";
import { Toasts } from "../../utils/Toasts";

export const ClassificacaoAdv = ({ userData }) => {
  const api = new AvaliacaoService();
  const avaliacoes = userData.avaliacoes || [];

  const timestamp = userData.dataAvaliacao;
  const data = new Date(timestamp);

  const dia = String(data.getDate()).padStart(2, "0");
  const mes = String(data.getMonth() + 1).padStart(2, "0");
  const ano = data.getFullYear();

  const dataFormatada = `${dia}/${mes}/${ano}`;

  const [comentario, setComentario] = useState("");

  const nomeAdvogado = sessionStorage.getItem("nome");
  const nomeCliente = userData.nomeCliente;
  const mensagem = comentario;
  const emailCliente = userData.emailCLiente;

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const [solicitacaoExclusao, setSolicitacaoExclusao] = useState();

  const solicitarExclusao = async () => {
    setSolicitacaoExclusao(true);
    try {
      const response = await api.solicitarExclusaoAvaliacao(
        userData.consultaId,
        solicitacaoExclusao
      );
      if (response.status === true) {
        let optionsToast = {
          code: response.mensagem.codigo,
          message: response.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);
      }
    } catch (error) {
      let optionsToast = {
        code: "102",
        message: "Não foi possível enviar sua solicitação, tente novamente.",
        visible: true,
      };
      setOptionsToast(optionsToast);
    }
  };

  const enviaEmail = () => {
    if (!mensagem) {
      let optionsToast = {
        code: "102",
        message: "Você precisa preencher o formulário para enviar.",
        visible: true,
      };
      setOptionsToast(optionsToast);
    }

    api
      .enviaEmailFeedback(nomeAdvogado, nomeCliente, mensagem, emailCliente)
      .then((data) => {
        if (data.status === true) {
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);
         
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else {
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);       
         
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container className="shadow p-4 rounded">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Row lg={12} className="d-flex  align-items-center ">
        <Col className="d-flex ">
          <Col lg={2} className="">
            {userData.anonimo ? (
              <AvatarPerfil
                type="A"
                width="6rem"
                height="6rem"
                padding="0.4rem"
              />
            ) : (
              <AvatarPerfil
                type="A"
                base64={userData.fotoCliente}
                width="6rem"
                height="6rem"
                padding="0.4rem"
              />
            )}
            {!userData.anonimo && (
              <ParagraphSmall text={userData.nomeCliente} />
            )}
          </Col>
          <Col className="d-flex align-items-center py-2 mt-2 ms-2 pb-4">
            <Col
              lg={1}
              className="bg-aval-comentario pt-2 m-2 px-4 rounded d-flex align-items-center justify-content-center"
            >
              <Subtitle text={userData.mediaAvaliacao} />
            </Col>
            <Col>
              <Rating
                size={32}
                readonly={true}
                initialValue={userData.mediaAvaliacao}
                allowFraction={true}
              ></Rating>
              <Subtitle text="Avaliação Geral Consulta" />
            </Col>
          </Col>
        </Col>
        <Col lg={3} className="text-end ">
          <Btn
            type="terciary-sm"
            text="Solicitar exclusão"
            onTouchable={solicitarExclusao}
          />
        </Col>
      </Row>
      <Row className="d-flex flex-column ms-5 ps-5">
        {avaliacoes.map((avaliacao, index) => (
          <Col key={index} className="d-flex gap-2">
            <Rating
              readonly={true}
              size={25}
              allowFraction={true}
              initialValue={avaliacao.notaAvaliacao}
            />
            <Col className="justify-content-start">
              <Paragraph text={avaliacao.nomeAvaliacao} />
            </Col>
          </Col>
        ))}
      </Row>
      <Row lg={12} className="d-flex flex-column ">
  {userData.comentario !== null && (
    <>
      <Col lg={12} className="d-flex ">
        <Col>
          <Subtitle text="Comentários" />
        </Col>
        <Col className="d-flex justify-content-end">
          <ParagraphSmall text={dataFormatada} />
        </Col>
      </Col>
      <Col className="rounded p-2 bg-aval-comentario py-2 px-3">
        <Paragraph text={userData.comentario} />
      </Col>
    </>
  )}
</Row>

      <Row className=" mt-3">
        <Subtitle text="Feedback" />
      </Row>
      <Row className="d-flex flex-column">
        <Col className="text-center">
          <Form.Control
            as="textarea"
            maxLength={500}
            style={{ height: "6rem" }}
            type="text"
            name="comentario"
            value={comentario}
            onChange={(e) => setComentario(e.target.value)}
            placeholder="Neste campo você pode fornecer comentários às suas avaliações e oferecer sugestões/feedbacks."
          />
          <Col className="text-end">
            <Btn
              type="secondary-sm"
              text="Enviar feedback"
              onTouchable={enviaEmail}
            />
          </Col>
        </Col>
      </Row>
    </Container>
  );
};
