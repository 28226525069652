import React, { useEffect, useState } from "react";
import "./styles.css";
import { useNavigate, useParams } from "react-router";
import { HomeTemp } from "../../components/templates/HomeTemp";

import { HomeAdvTemp } from "../../components/templates/HomeAdvTemp";
import { HomeCliTemp } from "../../components/templates/HomeCliTemp";

import { NotFound } from "../NotFound";
import { UserService } from "../../services/UserServices/UserService";
import { Toasts } from "../../components/utils/Toasts";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../components/atoms/Logo";
import { Link } from "react-router-dom";
import Subtitle from "../../components/atoms/Subtitle";
import Btn from "../../components/atoms/Button";
import ImgBanner from "../../components/atoms/ImgBanner";

export const Validacao = () => {
  const { chave } = useParams(); // Acessa o ID da URL
  const [dados, setDados] = useState();
  const navigate = useNavigate();

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);
  const [emailValidado, setEmailValidado] = useState(false);

  useEffect((props) => {
    const API = new UserService();
    API.validarEmail(chave).then((data) => {
      if (data.status) {
        setEmailValidado(true);
      } else {
        setEmailValidado(false);
      }
    });
  }, []);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  return (
    //  renderTemp()
    <Container fluid>
      <Row className="row-login d-flex justify-content-center align-items-center">
        <Col lg={8} className="text-center mt-4">
          <Col>
            <Link to="/">
              <Logo type="imagem3" />
            </Link>
          </Col>
        
      
          <Col className=" d-flex flex-column justify-content-center">
          <Row className="d-flex flex-column justify-content-center align-items-center">
            <Col lg={4} className="">
              <ImgBanner type="confirmacao" />
            </Col>         
          </Row>
           
            <Subtitle text={""} />
            <Subtitle text={""} />
            {emailValidado ? (
              <Subtitle text={"Seu email foi validado"} />
            ) : (
              <Subtitle text={"Erro ao validar e-mail"} />
            )}

            <Col className="d-flex justify-content-center mt-3">
              <Btn
                type="secondary"
                text="Acessar FaçaJus"
                onTouchable={() => navigate(`/Login/Cli`)}
              />
            </Col>
          </Col>

          {/* <Link to='/'>
          <Subtitle text={"Página inicial"} />

                    </Link>     */}
        </Col>
      </Row>

      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
    </Container>
  );
};
