import React from "react";
import { Form } from "react-bootstrap";
import "./styles.css";

export const removeAccents = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

const BarraPesquisaNome = ({ value, onChange }) => {
  const handleChange = (event) => {
    const rawValue = event.target.value;
    const filteredValue = removeAccents(rawValue.toLowerCase());
    onChange({ rawValue, filteredValue });
  };

  return (
    <Form.Control
      type="text"
      placeholder="Pesquisar Nome"
      value={value.rawValue || ""}
      onChange={handleChange}
    />
  );
};

export default BarraPesquisaNome;
