import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./styles.css";
import ImgBanner from "../../atoms/ImgBanner";
import Title from "../../atoms/Title";
import Paragraph from "../../atoms/Paragraph";

export default function CabecalhoCarrosselLpTwo() {
  return (
    <Container className="mb-5">
      <Row>
        <Col className="d-flex flex-column align-items-center justify-content-center">
          <Col className="shadow">
          <ImgBanner type="bannerLpOne" key="banner1" />
          </Col>
          <Col lg={10} >
            <Col className="d-flex justify-content-center text-center mt-5">
              <Title text="Encontre o especialista para o seu caso" />
            </Col>
            <Col className="mt-2 ">
              <Paragraph text="O Faça Jus oferece uma maneira eficaz de busca de advogados 
              para assistência legal para o seu caso. 
              Aqui estão os passos que você deve seguir para encontrar um profissional:" />
            </Col>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}
