import React from "react";
import Logo from "../../atoms/Logo";
import NavItem from "../../atoms/Navitem";
import Navbar from "react-bootstrap/Navbar";
import NavItemAdvogado from "../../atoms/NavitemAdvogado";
import NavItemCliente from "../../atoms/NavItemCliente";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";


function Header() {
  const isUserLoggedIn = sessionStorage.getItem("logado") === "true";
  const userType = sessionStorage.getItem("tipo");

  return (
    <header
      className="bg-warning px-5 "
      style={{ position: "fixed", top: 0, width: "100%", zIndex: 999 }}
    >
      <Container>
        <Row>
          <Navbar
            className="d-flex  align-items-center p-0 m-0"
            
          >
            <Col className="d-flex align-items-center m-0 p-0 justify-content-between">
              <Col lg={2} className="d-flex justify-content-start align-items-center m-0 p-0">
                <Navbar.Brand href="#home" className="">
                  <Link to="/">
                  <Logo type="imagem2" />
                  </Link>
                </Navbar.Brand>
              </Col>

              <Col lg={10} className="d-flex justify-content-end  m-0 p-0">
                {isUserLoggedIn ? (
                  userType === "A" ? (
                    <NavItemAdvogado />
                  ) : userType === "C" ? (
                    <NavItemCliente />
                  ) : (
                    <NavItem />
                  )
                ) : (
                  <NavItem />
                )}
              </Col>
            </Col>
          </Navbar>
        </Row>
      </Container>
    </header>
  );
}

export default Header;
