import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { UIKitSettingsBuilder } from "@cometchat/uikit-shared";
// import { CometChatUIKit } from '@cometchat/chat-uikit-react';
// import { VideoConferenciaService } from './services/VideoConferenciaServices/VideoConferenciaService';
// import { setApiResponse } from './services/ApiData.js';
// const api2 =  new VideoConferenciaService();

(async () => {
  // const response = await api2.getLinks();
  // setApiResponse(response);
  //   const UIKitSettings = new UIKitSettingsBuilder()
  //   .setAppId(response.app_id)
  //   .setRegion(response.region)
  //   .setAuthKey(response.authKey)
  //   .subscribePresenceForFriends()
  //   .build();
  // const response = await api2.getLinks();
  // setApiResponse(response);
  //   const UIKitSettings = new UIKitSettingsBuilder()
  //   .setAppId(response.app_id)
  //   .setRegion(response.region)
  //   .setAuthKey(response.authKey)
  //   .subscribePresenceForFriends()
  //   .build();
  
  try{
    // await CometChatUIKit.init(UIKitSettings);
    // CometChatUIKit.Localize.init("pt");
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render( 
      <React.StrictMode>
        <App />
      </React.StrictMode>);

  } catch(error) {
    console.log("Initialization failed with error:", error);
  }
})();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
