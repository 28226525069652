import React, { useState } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Container, Navbar, Nav, Button, Col } from "react-bootstrap";
import Icons from "../../atoms/Icons";
import Paragraph from "../../atoms/Paragraph";
import { Link } from "react-router-dom";

function SubMenuGerencial({ gerencialAberto, toggleGerencial }) {
  return (
    <Nav className={`flex-column ${gerencialAberto ? "" : "d-none"}`}>
      <Nav.Link
        className="menu-subitem px-4 clickable-icon item-menu w-100"
        onClick={toggleGerencial}
      >
        <Link
          to="/adm/gerencial/perfil"
          style={{ textDecoration: "none", color: "inherit" }}
          className="  d-flex align-items-center  w-100"
        >
          <Icons type="perfil" />
          <Col className="d-flex text-start">
            <Col className="text-center">
              <Paragraph text="Perfis" />
            </Col>
          </Col>
        </Link>
      </Nav.Link>

      <Nav.Link
        className="menu-subitem px-4 clickable-icon item-menu   w-100"
        onClick={toggleGerencial}
      >
        <Link
          to="/adm/gerencial/usuarios"
          style={{ textDecoration: "none", color: "inherit" }}
          className="  d-flex align-items-center  w-100"
        >
          <Icons type="usuarios" />
          <Col className="d-flex text-start">
            <Col className="text-center">
              <Paragraph text="Usuários" />
            </Col>
          </Col>
        </Link>
      </Nav.Link>
    </Nav>
  );
}

function SubMenuFinanceiro({ financeiroAberto, toggleFinanceiro }) {
  return (
    <Nav className={`flex-column ${financeiroAberto ? "" : "d-none"}`}>
      <Nav.Link
        className="menu-subitem px-4 clickable-icon item-menu w-100"
        onClick={toggleFinanceiro}
      >
        <Link
          to="/adm/financeiro/consignado"
          style={{ textDecoration: "none", color: "inherit" }}
          className="  d-flex align-items-center  w-100"
        >
          <Icons type="financeiro" />
          <Col className="d-flex text-start">
            <Col className="text-center">
              <Paragraph text="Consignado" />
            </Col>
          </Col>
        </Link>
      </Nav.Link>

      <Nav.Link
        className="menu-subitem px-4 clickable-icon item-menu   w-100"
        onClick={toggleFinanceiro}
      >
        <Link
          to="/adm/financeiro/particular"
          style={{ textDecoration: "none", color: "inherit" }}
          className="  d-flex align-items-center  w-100"
        >
          <Icons type="financeiro" />
          <Col className="d-flex text-start">
            <Col className="text-center">
              <Paragraph text="Particular" />
            </Col>
          </Col>
        </Link>
      </Nav.Link>
    </Nav>
  );
}

function SubMenuSite({ siteAberto, toggleSite }) {
  return (
    <Nav className={`flex-column ${siteAberto ? "" : "d-none"}`}>
      <Nav.Link
        className="menu-subitem px-4 clickable-icon item-menu d-flex align-items-center w-100"
        // onClick={toggleSite}
      >
        <Link
          to="/adm/site/clientes"
          style={{ textDecoration: "none", color: "inherit" }}
          className="  d-flex align-items-center  w-100"
        >
          <Icons type="clientes" />
          <Col className="d-flex text-start">
            <Col className="text-center">
              <Paragraph text="Clientes" />
            </Col>
          </Col>
        </Link>
      </Nav.Link>
      <Nav.Link
        className="menu-subitem px-4 clickable-icon item-menu d-flex align-items-center w-100"
        // onClick={toggleSite}
      >
        <Link
          to="/adm/site/advogados"
          style={{ textDecoration: "none", color: "inherit" }}
          className="  d-flex align-items-center  w-100"
        >
          <Icons type="advogados" />
          <Col className="d-flex text-start">
            <Col className="text-center">
              <Paragraph text="Advogados" />
            </Col>
          </Col>
        </Link>
      </Nav.Link>
    </Nav>
  );
}

function Navitem({ menuAberto, toggleMenu }) {
  const [gerencialAberto, setGerencialAberto] = useState(false);
  const [siteAberto, setSiteAberto] = useState(false);
  const [financeiroAberto, setFinanceiroAberto] = useState(false);

  const toggleGerencial = () => {
    setGerencialAberto(!gerencialAberto);
  };

  const toggleSite = () => {
    setSiteAberto(!siteAberto);
  };

  const toggleFinanceiro = () => {
    setFinanceiroAberto(!financeiroAberto)
  };

  return (
    <Nav
      className={`d-flex row px-2  shadow ${menuAberto ? "" : "menu-fechado"}`}
    >
      <Nav.Item className="p-0 m-0">
        <Link
          to="/adm/dashboard"
          style={{ textDecoration: "none", color: "inherit" }}
          className="px-4 clickable-icon item-menu d-flex align-items-center justify-content-start"
        >
          <Icons type="dashboard" />
          <Col
            className={`d-flex text-start ${menuAberto ? "" : "texto-oculto"}`}
          >
            <Col className="text-center">
              <Paragraph text="Dashboard" />
            </Col>
          </Col>
        </Link>
      </Nav.Item>

      <Nav.Item className="p-0 m-0">
        <Nav.Link
          className="px-4 clickable-icon item-menu d-flex align-items-center justify-content-start"
          onClick={toggleGerencial}
        >
          <Icons type="gerencial" />
          <Col
            className={`d-flex text-start ${menuAberto ? "" : "texto-oculto"}`}
          >
            <Col className="text-center">
              <Paragraph text="Gerencial" />
            </Col>
          </Col>
        </Nav.Link>

        <SubMenuGerencial
          gerencialAberto={gerencialAberto}
          toggleGerencial={toggleGerencial}
        />
      </Nav.Item>

      <Nav.Item className="p-0 m-0 ">
        <Nav.Link
          className="px-4 clickable-icon item-menu d-flex align-items-center justify-content-start"
          onClick={toggleFinanceiro}
        >
          <Icons type="financeiro" />
          <Col
            className={`d-flex text-start ${menuAberto ? "" : "texto-oculto"}`}
          >
            <Col className="text-center">
              <Paragraph text="Financeiro" />
            </Col>
          </Col>
        </Nav.Link>
        <SubMenuFinanceiro financeiroAberto={financeiroAberto} toggleFinanceiro={toggleFinanceiro} />
      </Nav.Item>

      <Nav.Item className="p-0 m-0 ">
        <Nav.Link className="px-4 clickable-icon item-menu d-flex align-items-center justify-content-start">
          <Icons type="forum" />
          <Col
            className={`d-flex text-start ${menuAberto ? "" : "texto-oculto"}`}
          >
            <Col className="text-center">
              <Paragraph text="Fórum" />
            </Col>
          </Col>
        </Nav.Link>
      </Nav.Item>

      <Nav.Item className="p-0 m-0 ">
        <Nav.Link className="px-4 clickable-icon item-menu d-flex align-items-center justify-content-start">
          <Icons type="atendimento" />
          <Col
            className={`d-flex text-start ${menuAberto ? "" : "texto-oculto"}`}
          >
            <Col className="text-center">
              <Paragraph text="Atendimento" />
            </Col>
          </Col>
        </Nav.Link>
      </Nav.Item>

      <Nav.Item className="p-0 m-0">
        <Nav.Link
          className="px-4 clickable-icon item-menu d-flex align-items-center justify-content-start"
          onClick={toggleSite}
        >
          <Icons type="site" />
          <Col
            className={`d-flex text-start ${menuAberto ? "" : "texto-oculto"}`}
          >
            <Col className="text-center">
              <Paragraph text="Plataforma" />
            </Col>
          </Col>
        </Nav.Link>

        <SubMenuSite siteAberto={siteAberto} toggleSite={toggleSite} />
      </Nav.Item>
    </Nav>
  );
}

export default function NavitemSidebarAdm({ menuAberto, toggleMenu }) {
  return (
    <Container className="menu-aberto">
      <Col className="ps-5 m-0 ">
        <Button variant="none" onClick={toggleMenu} className=" ">
          <Col className="icone-menu-container">
            <Icons type="abrir-menu" />
          </Col>
        </Button>
      </Col>
      <Navbar>
        <Container className="d-flex">
          <Navbar.Collapse id="navbar">
            <Navitem menuAberto={menuAberto} toggleMenu={toggleMenu} />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
}
