import React, { useState, useEffect, useRef } from "react";
import { Col } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import { CadastroService } from "../../../services/ClienteServices/CadastroService";
import { Toasts } from "../../utils/Toasts";


const CountdownTimer = ({ initialTime, cliId = null }) => {
  let api = new CadastroService();

  const cli_id = sessionStorage.getItem("id") ? sessionStorage.getItem("id") : cliId;
  const [timeRemaining, setTimeRemaining] = useState(initialTime);
  const isNegative = useRef(initialTime < 0); // Track initial negativity for styling

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);
  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          // Code to handle expiration (optional)
          return prevTime; // Maintain negative value for display
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [initialTime]);

  const formatTime = (time) => {
    const absTime = Math.abs(time); // Handle negative values gracefully
    const minutes = Math.floor(absTime / 60);
    const seconds = absTime % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  const handleReenviaCodigo = () => {
    api
      .postReenviaCodigoValidacao(cli_id)
      .then((data) => {
        if (data) {
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);
        } else {
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);
        }
      })
      .catch((error) => {
        console.error("Erro ao reenviar código de validação:", error);
      });
  };

  return (
    <Col bg={"warning"} className="px-4 py-2 shadow-sm border rounded">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      {timeRemaining === 0 ? (
        <Col className="d-flex flex-column justify-content-center  ">
          <Badge bg={"secondary"} className="px-3 py-2 shadow">
            <ParagraphSmall
              textColor="white"
              textAlign="center"
              textSize="14px"
              text="<strong>CÓDIGO EXPIRADO</strong>"
            />
          </Badge>
          <Col className=" d-flex flex-row  align-items-center pt-2">
            <ParagraphSmall
              // textColor="white"
              textAlign="center"
              textSize="13px"
              text="<strong>Não recebeu o código? Para reenviar,</strong>"
            />
            <Col
              onClick={handleReenviaCodigo}
              className="ps-2 pe-2 cursor underline-link"
            >
              <ParagraphSmall textSize="13px" text="clique aqui." />
            </Col>
          </Col>
        </Col>
      ) : (
        <Col className="d-flex align-items-center gap-3">
          <ParagraphSmall
            textAlign="center"
            textSize="14px"
            text="Tempo de validade do código:"
          />
          <Badge bg={"secondary"} className="px-3 py-2 shadow">
            <Col>
              <ParagraphSmall
                textColor="white"
                textAlign="center"
                textSize="13px"
                text={formatTime(timeRemaining)}
              />
            </Col>
          </Badge>
        </Col>
      )}
    </Col>
  );
};

export default CountdownTimer;
