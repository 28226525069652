import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Col, Container, Row, Form, Modal } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import Btn from "../../atoms/Button";
import InputMask from "react-input-mask";
import "./styles.css";

import { useFormik } from "formik";
import * as yup from "yup";
import functions from "../../utils/functions";
import { FloatingLabel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


import { Toasts } from "../../utils/Toasts";
import { UsuarioService } from "../../../services/AdmUsuarioServices/UsuarioService";
import Paragraph from "../../atoms/Paragraph";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import DropdownBtn from "../../atoms/DropdownBtn";
import { PerfilService } from "../../../services/AdmUsuarioServices/PerfilService";

import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";


export const AdmGerencialUsuariosDados = ({ id }) => {

  const api = new UsuarioService();
  const Api2 = new PerfilService();

  const navigate = useNavigate();


  const [showModal, setShowModal] = useState(false);

    const [defaultCpf, setDefaultCpf] = useState("");
  const [defaultNome, setDefaultNome] = useState("");
  const [defaultEmail, setDefaultEmail] = useState("");
  const [defaultTelefone, setDefaultTelefone] = useState("");
  const [idDesc, setIdDesc] = useState([]);
  const [userData, setUserData] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const [type2, setType2] = useState("password");
  const [icon2, setIcon2] = useState(eyeOff);

  const handleToggle2 = () => {
    if (type2 === "password") {
      setIcon2(eye);
      setType2("text");
    } else {
      setIcon2(eyeOff);
      setType2("password");
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDeleteAdvogado = () => {
    setShowModal(false);
  };

  const handleItemClick = (selectedItem) => {
    setSelectedItem(selectedItem);
  };

  const initialValues = {
    nome: "",
    email: "",
    telefone: "",
    cpf: "",
    senha: "",
    confirma_senha: "",
  };

  const schema = yup.object().shape({
    nome: yup
      .string()
      .required("O nome é obrigatório")
      .matches(/^[a-zA-ZÀ-ÿ\s]*$/, "Por favor, digite apenas letras e acentos")
      .max(80, "Máximo de caracteres excedido")
      .min(3, "Mínimo de caracteres não detectado"),
    email: yup
      .string()
      .required("Email é obrigatório")
      .email("Formato de e-mail inválido."),
    telefone: yup
      .string()
      .test(
        "telefone",
        "O Telefone deve conter exatamente 11 dígitos",
        (value) => {
          if (value) {
            return /^\(\d{2}\) \d{5}-\d{4}$/.test(value);
          }
          return false;
        }
      ),
    cpf: yup
      .string()
      .test("valid-cpf", "CPF inválido", function (value) {
        return functions.validarCPF(value);
      })
      .required("CPF é obrigatório"),
    senha: yup
      .string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()])[a-zA-Z\d!@#$%^&*()]{8,}$/,
        "A senha deve conter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial."
      )
      .required("A senha é obrigatória."),
    confirma_senha: yup
      .string()
      .oneOf([yup.ref("senha")], "As senhas devem ser iguais.")
      .required("A confirmação de senha é obrigatória."),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      api.postAlteraDadosUsuarios(
        id,
        values.cpf,
        values.nome,
        functions.removeMascara(values.telefone),
        values.email,
        values.senha,
        values.confirma_senha,
        selectedItem.id
      ).then((data) => {
        if (data.status === true) {
          let optionsToast = {
            code: data.mensagem.codigo,
            message: data.mensagem.mensagem,
            visible: true,
          };
          setOptionsToast(optionsToast);
          setTimeout(() => {
            navigate("/adm/gerencial/usuarios");
          }, 3100); // Atraso
        } else {
          return false;
        }
      })
        .catch((error) => {
          console.log(error);
        });
    },
  });


  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await api.getUsuarioPorId({ id });
        const userData = {
          ...response.data,
          telefone: functions.aplicarMascara(response.data.telefone),
        };
        formik.setValues(userData);
      } catch (error) {
        console.error("Erro ao buscar dados do usuário:", error);
      }
    }
    fetchUserData();
  }, [id]);


  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await Api2.getAll();
        setUserData(response);
        if (response && response.data) {
          const idDescArray = response.data.map((item) => ({
            id: item.per_id,
            desc: item.per_nome,
          }));
          setIdDesc(idDescArray);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchUserData();
  }, []);


  const handleNavigateBack = () => {
    navigate(-1);
  };


  return (
    <Container className="px-4">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />
      <Row className="pe-3">
        <Col>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="nome" className="d-lg-flex gap-3">
              <Col lg={6} md={6} sm={12}>
                <FloatingLabel controlId="nome" label="Nome">
                  <Form.Control
                    type="text"
                    name="nome"
                    value={formik.values.nome}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.nome && formik.errors.nome}
                    placeholder="Nome"
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.nome}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>

              <Col lg={6} md={6} sm={12}>
                <FloatingLabel controlId="email" label="Email">
                  <Form.Control
                    type="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.email && formik.errors.email}
                    placeholder="E-mail"
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Form.Group>

            <Form.Group controlId="telefone" className="d-lg-flex gap-3">
              <Col lg={6} md={6} sm={12}>
                <FloatingLabel controlId="telefone" label="Telefone">
                  <InputMask
                    mask="(99) 99999-9999"
                    value={formik.values.telefone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {(inputProps) => (
                      <Form.Control
                        type="text"
                        name="telefone"
                        value={formik.values.telefone}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.touched.telefone && formik.errors.telefone
                        }
                        placeholder="Telefone"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.telefone}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>

              <Col lg={6} md={6} sm={12}>
                <FloatingLabel controlId="cpf" label="CPF">
                  <InputMask
                    mask="999.999.999-99"
                    value={formik.values.cpf}
                  >
                    {(inputProps) => (
                      <Form.Control
                        type="text"
                        name="cpf"

                        placeholder="CPF"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.cpf}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Col>
            </Form.Group>

            <Form.Group className="d-lg-flex gap-3" controlId="senha">
              <Col lg={6} md={6} sm={12}>
                <FloatingLabel controlId="senha" label="Nova senha">
                  <Form.Control
                    type={type}
                    name="senha"
                    value={formik.values.senha}
                    onChange={formik.handleChange}
                    isInvalid={formik.touched.senha && formik.errors.senha}
                    placeholder="Senha"
                  />

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.senha}
                  </Form.Control.Feedback>
                  <span className="icon-password-facj" onClick={handleToggle}>
                    <Icon icon={icon} size={25} />
                  </span>
                </FloatingLabel>
              </Col>

              <Col lg={6} md={6} sm={12}>
                <FloatingLabel
                  controlId="confirma_senha"
                  label="Confirme sua senha"
                >
                  <Form.Control
                    type={type2}
                    name="confirma_senha"
                    value={formik.values.confirma_senha}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.confirma_senha &&
                      formik.errors.confirma_senha
                    }
                    placeholder="Confirme sua senha"
                  />

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.confirma_senha}
                  </Form.Control.Feedback>
                  <span className="icon-password-facj" onClick={handleToggle2}>
                    <Icon icon={icon2} size={25} />
                  </span>
                </FloatingLabel>
              </Col>
            </Form.Group>

            <Form.Group className="d-lg-flex  ">
              <Col lg={6} className="me-3">
                {" "}
              </Col>
              <Col lg={6} className="mb-2 render-index">
                <DropdownBtn
                  text="Escolha o Perfil"
                  type={"primary"}
                  options={idDesc}
                  onPress={(selectedItem) => handleItemClick(selectedItem)}
                />
              </Col>
            </Form.Group>

            <Col className="d-flex gap-3">
              <Btn
                type="terciary-sm"
                text="Voltar"
                onTouchable={handleNavigateBack}
              />

              {/* <Btn
                type="primary-sm"
                text="Bloquear Usuário"
                isSubmit={false}
                onTouchable={handleOpenModal}
              /> */}
              <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>Confirmação de Bloqueio</Modal.Header>
                <Modal.Body>
                  <Subtitle text="Atenção" />
                  <Paragraph text="Deseja bloquear o Usuário?" />
                  <ParagraphSmall text="Os dados serão mantidos, mas o Login será suspenso. " />
                </Modal.Body>

                <Modal.Footer>
                  <Btn
                    type="primary-sm"
                    onTouchable={() => setShowModal(false)}
                    text="Não!"
                  />
                  <Btn
                    type="secondary-sm"
                    onTouchable={() => handleDeleteAdvogado()}
                    text="Sim, bloquear!"
                  />
                </Modal.Footer>
              </Modal>

              <Btn
                type="secondary-sm"
                text="Salvar alterações"
                isSumbit={formik.handleSubmit}
              />
            </Col>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
