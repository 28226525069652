import React from "react";
import "./styles.css";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";

const Btn = ({ onTouchable, type, isSumbit, disabled, ...props }) => {
  let typeClass = "";

  switch (type) {
    case "primary":
      typeClass = "botao-primario ";
      break;
    case "secondary":
      typeClass = "botao-secundario";
      break;
    case "terciary":
      typeClass = "botao-terciario";
      break;
    case "primary-sm":
      typeClass = "botao-primario-sm";
      break;
    case "secondary-sm":
      typeClass = "botao-secundario-sm";
      break;
    case "terciary-sm":
      typeClass = "botao-terciario-sm";
      break;
    case "primary-X":
      typeClass = "botao-primario-X";
      break;
    case "primary-numero":
      typeClass = "botao-primario-numero";
      break;
    case "secondary-numero":
      typeClass = "botao-secundario-numero";
      break;
    case "terciary-numero":
      typeClass = "botao-terciario-numero";
      break;
    case "secondary-load":
      typeClass = "botao-secundario-load";
      break;
    case "danger-sm":
      typeClass = "botao-danger-sm";
      break;
    case "danger-sm-load":
      typeClass = "botao-danger-sm-load";
      break;
  }

  return isSumbit ? (
    <Button type="submit" className={typeClass} disabled={disabled} {...props}>
      {props.text}
      {props.isLoading ? (
        <Spinner size="sm" className="align-itens-center"></Spinner>
      ) : (
        <></>
      )}
    </Button>
  ) : (
    <Button className={typeClass} disabled={disabled} onClick={() => onTouchable()}>
      {props.text}
      {props.isLoading ? (
        <Spinner size="sm" className="align-itens-center"></Spinner>
      ) : (
        <></>
      )}
    </Button>
  );
};

export default Btn;
