import React, { useState, useEffect, useRef, useCallback } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import SubDropdown from "../SubDropdown";
import { CloseOutlined } from "@ant-design/icons";

const MultiDropdown = ({
  text,
  options,
  onPress,
  defaultValue,
  onClearFilter,
}) => {
  const [selectedItem, setSelectedItem] = useState(defaultValue);
  const [selectedSubItem, setSelectedSubItem] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filteredSubOptions, setFilteredSubOptions] = useState([]);
  const [showSubDropdown, setShowSubDropdown] = useState(false);
  const [subDropdownPosition, setSubDropdownPosition] = useState({
    top: 0,
    left: 0,
  });
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setShowSubDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const newFilteredSubOptions = [];
    const newFilteredOptions = options.filter((option) => {
      const optionDesc = option.desc ? option.desc.toLowerCase() : "";
      const filterTextLower = filterText.toLowerCase();
      const isOptionMatch = optionDesc.includes(filterTextLower);
      const isSubOptionMatch = option.subOptions?.some((subOption) => {
        const subOptionDesc = subOption.desc
          ? subOption.desc.toLowerCase()
          : "";
        if (subOptionDesc.includes(filterTextLower)) {
          newFilteredSubOptions.push({
            ...subOption,
            parent: option,
          });
          return true;
        }
        return false;
      });
      return isOptionMatch || isSubOptionMatch;
    });

    setFilteredOptions(newFilteredOptions);
    setFilteredSubOptions(newFilteredSubOptions);
  }, [filterText, options]);

  const handleItemClick = useCallback(
    (option, event) => {
      const rect = event.currentTarget.getBoundingClientRect();
      setSelectedItem(option);
      setSelectedSubItem(null);
      setFilteredSubOptions(option.subOptions || []);
      setSubDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX + rect.width,
      });
      setShowSubDropdown(true);
      setIsOpen(false);
      onPress(option);
    },
    [onPress]
  );

  const handleFilterChange = useCallback((e) => {
    setFilterText(e.target.value);
  }, []);

  const handleClearFilter = useCallback(() => {
    setFilterText("");
    setSelectedItem(null);
    setSelectedSubItem(null);
    setFilteredOptions(options);
    setIsOpen(false);
    onClearFilter();
  }, [options, onClearFilter]);

  const handleSubItemClick = useCallback(
    (selectedItem, selectedSubItem) => {
      setSelectedSubItem(selectedSubItem);
      setShowSubDropdown(false);
      setIsOpen(false);
      onPress(selectedItem, selectedSubItem);
    },
    [onPress]
  );

  const handleMouseEnter = (option, event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setSelectedItem(option);
    setFilteredSubOptions(option.subOptions || []);
    setSubDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX + rect.width,
    });
    setShowSubDropdown(true);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      if (!dropdownRef.current.contains(document.activeElement)) {
        setShowSubDropdown(false);
      }
    }, 300);
  };

  return (
    <div id="area-drop" className="dropdown" ref={dropdownRef}>
      <button
        id="area-primario-tg"
        className="drop-primario dropdown-toggle"
        type="button"
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedItem
          ? `${selectedItem.desc} - ${
              selectedSubItem ? selectedSubItem.desc : ""
            }`
          : text}
      </button>

      <ul
        id="area-drop-list"
        className={`dropdown-menu custom-dropdown-menu ${isOpen ? "show" : ""}`}
        onMouseLeave={handleMouseLeave}
      >
        <li className="align-search-input">
          <input
            type="text"
            className="form-control"
            placeholder="Pesquisar..."
            value={filterText}
            onChange={handleFilterChange}
          />
          <CloseOutlined
            onClick={handleClearFilter}
            className="align-search-input anticon-close"
          />
        </li>
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option, index) => (
            <li
              key={index}
              className={`dropdown-item ${
                selectedItem && selectedItem.desc === option.desc
                  ? "active"
                  : ""
              }`}
              onClick={(e) => handleItemClick(option, e)}
              onMouseEnter={(e) => handleMouseEnter(option, e)}
            >
              {option.desc}
            </li>
          ))
        ) : (
          <li className="dropdown-item">Nenhuma opção</li>
        )}
      </ul>
      {showSubDropdown && filteredSubOptions.length > 0 && (
        <SubDropdown
          options={filteredSubOptions}
          selectedItem={selectedItem}
          selectedSubItem={selectedSubItem}
          onPress={handleSubItemClick}
          position={subDropdownPosition}
        />
      )}
    </div>
  );
};

export default MultiDropdown;
