import React from 'react';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const Paragraph = (props) => {
  const { text, color, fontSize } = props;
  const textColor = '#505050' || 'black'; 
  const white = '#fff' || 'white';
  return (
 

<p className='paragraph' style={{ color: props.textColor || 'black', fontSize: props.fontSize || '' }}>
{props.text}
</p>
  );
}

export default Paragraph;

