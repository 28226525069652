import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './styles.css';
import Logo from '../../atoms/Logo';
import { Link } from "react-router-dom";
import { LoginClienteForms } from "../../organisms/LoginClienteForms";
import imagem from "../../../img/fotologincli.png"
import { Image } from "react-bootstrap";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const LoginCliente = ({ avaliacao }) => {


    return (
        <Container fluid className="">             
            <Row className="">   
                <Col className="p-0 m-0 ">
                    <Image className="imagem-div-login" src={imagem} />
                </Col>

                <Col lg={6} className="text-center mt-4">                        
                    <Link to='/'>
                        <Logo type="imagem3"/>
                    </Link>    
                    <LoginClienteForms avaliacao={avaliacao} />
                </Col>
            </Row>
        </Container>         
    );
}