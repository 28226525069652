import React from "react";
import './styles.css';
import { useNavigate, useParams } from "react-router";
import { HomeTemp } from "../../components/templates/HomeTemp";

import { HomeAdvTemp } from "../../components/templates/HomeAdvTemp";
import { HomeCliTemp } from "../../components/templates/HomeCliTemp";
import { RedefinirSenhaTemp } from "../../components/templates/RedefinirSenhaTemp";
import { NotFound } from "../NotFound";



export const RedefinirSenha = () => {
  
  const navigate = useNavigate();
  const params = useParams();
  const {token} = params;


    return (
         <RedefinirSenhaTemp 
          token={token}
         />
    );

}