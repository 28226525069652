import React from "react";
import "./styles.css";
import Logo from "../../atoms/Logo";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Paragraph from "../../atoms/Paragraph";
import { Nav, Navbar } from "react-bootstrap";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import Icons from "../../atoms/Icons";

function Footer() {
  const handleClick = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=554199934379&text=Ol%C3%A1,%20vi%20seu%20contato%20no%20site%20e%20gostaria%20de%20saber%20mais."
    );
  };

  return (
    <Container fluid className="footer-style m-0 p-0">
      <Container>
        <Row>
          <Col className="py-2 d-flex flex-column flex-lg-row align-items-center ">
            <Col lg={2} className="">
              <Logo type="imagem1" />
            </Col>
            <Col lg={10} className="d-flex justify-content-between flex-column flex-lg-row">
              <Col
                lg={5}
                sm={12}
                className="d-flex flex-lg-row flex-sm-column align-items-start mb-4 mb-sm-0"
              >
                <a href="https://api.whatsapp.com/send?phone=554199934379&text=Ol%C3%A1,%20vi%20seu%20contato%20no%20site%20e%20gostaria%20de%20saber%20mais.">
                <Col className="d-flex align-items-center gap-4 cursor ">
                  <Col lg={1}>
                    <Icons type="whatsapp" />
                  </Col>
                  <Col lg={11} sm={12} className="d-flex flex-column">
                    <Paragraph text="+55 (41) 9993-4379 " textColor="white" />
                    <ParagraphSmall
                      text="Atendimento das 9h às 18h de segunda à sexta."
                      textColor="white"
                      textSize="13px"
                    />
                  </Col>
                </Col>
              </a>
              </Col>

              <Col
                lg={6}
                sm={12}
                className="d-flex justify-content-end align-items-center"
              >
                <Navbar className="flex-column flex-lg-row">
                  <Nav className="d-flex flex-column flex-lg-row">
                    <Nav.Link
                      href="/PerguntasFrequentes"
                      className="text-footer"
                    >
                      Perguntas Frequentes
                    </Nav.Link>
                    <Nav.Link
                      href="/PoliticaDePrivacidade"
                      className="text-footer"
                    >
                      Política de Privacidade
                    </Nav.Link>
                    <Nav.Link href="/TermosDeUso" className="text-footer">
                      Termos de Uso
                    </Nav.Link>
                  </Nav>
                </Navbar>
              </Col>
            </Col>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Footer;
