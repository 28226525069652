import React from 'react';
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Subtitle= (props) => {
  return (
   
      <h3>{props.text}</h3>
     
    );
}

export default Subtitle;



