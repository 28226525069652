import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";



import "./styles.css";
import ImgBanner from "../../atoms/ImgBanner";


export const FotoEmailTemp = () => {
  return (
    <body className="mt-5 pt-5">
                 <ImgBanner type="header" key="" />
                 <ImgBanner type="footer" key="" />
                 <ImgBanner type="headerforum" key="" />
                 <ImgBanner type="headerconsulta" key="" />
                 <ImgBanner type="headersenha" key="" />
                 <ImgBanner type="email_1" key="" />
                 <ImgBanner type="email_2" key="" />

    </body>
  );
};
