import api from "../Api";

export class DashboardService {
    async postDashboard(
        mes,
        ano
    ) {
        return await api.post("/adm/dashboard/financeiro/listar", {
            "mes": mes,
            "ano": ano
        }, {
            headers: {
                "Content-type": "application/json",
                // Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        })
            .then((res) => res.data);
    }

    async grafico(periodo){
        return await api.post("/adm/dashboard/grafico", {
            "meses": periodo,
        }, {
            headers: {
                "Content-type": "application/json",
                // Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
        })
            .then((res) => res.data);
    }
};
