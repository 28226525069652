import React from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import Subtitle from "../../atoms/Subtitle";
import Btn from "../../atoms/Button";
import AdmSiteListaCliente from "../../molecules/AdmSiteListaCliente";


const AdmSiteClientes = () => {
  return (
    <Container>
      <Row className="">
        <Col lg={2} className="m-0 p-0  text-start">
          <Breadcrumb className="">
            <Breadcrumb.Item>Gerencial</Breadcrumb.Item>
            <Breadcrumb.Item href="#" active>
              Clientes
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex align-items-center ">
          <Col className="">
            <Subtitle text="Clientes Cadastrados" />
          </Col>
        </Col>
      </Row>

      <Row className="">
                <Col>
                <AdmSiteListaCliente/>
                </Col>
            </Row>
    </Container>
  );
};

export default AdmSiteClientes;
