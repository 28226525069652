import React, { useState, useEffect } from "react";
import { Container, Row, Col, Badge, Form } from "react-bootstrap";
import { AdvogadoService } from "../../../services/AdmAdvogadoServices/AdvogadoService";
import Subtitle from "../../atoms/Subtitle";
import Paragraph from "../../atoms/Paragraph";
import ParagraphSmall from "../../atoms/ParagraphSmall";
import "./styles.css";
import Icons from "../../atoms/Icons";
import Btn from "../../atoms/Button";
import { useNavigate } from "react-router-dom";
import { AdmSiteAdvModal } from "../../molecules/AdmSiteAdvModal";

import { ptBR } from "date-fns/locale";
import { Toasts } from "../../utils/Toasts";
import { AdmSiteAdvRecusarModal } from "../../molecules/AdmSiteAdvRecusarModal";
import { Icon } from 'react-icons-kit';
import {ic_sync_alt} from 'react-icons-kit/md/ic_sync_alt';

export const AdmGerencialAdvogadoDados = ({ id }) => {

  const navigate = useNavigate();
  const api = new AdvogadoService();

  const [userData, setUserData] = useState(null);

  const [diploma, setDiploma] = useState("");
  const [oabFrente, setOabFrente] = useState("");
  const [oabVerso, setOabVerso] = useState("");

  const [showDiplomaModal, setShowDiplomaModal] = useState(false);
  const [showOabFrenteModal, setShowOabFrenteModal] = useState(false);
  const [showOabVersoModal, setShowOabVersoModal] = useState(false);
  const [showRecusaModal, setShowRecusaModal] = useState(false);

  const fusoHorario = 'Etc/UTC';
  const { parseISO, format } = require("date-fns");
  const { utcToZonedTime } = require("date-fns-tz");

  let defaultOptionsToast = {
    code: "",
    message: "",
    visible: false,
  };

  const [optionsToast, setOptionsToast] = useState(defaultOptionsToast);

  function fecharToast() {
    let defaultOptionsToast = {
      code: "",
      message: "",
      visible: false,
    };
    setOptionsToast(defaultOptionsToast);
  }

  useEffect(() => {
    async function fetchUserData() {
      try {
        const response = await api.getAdvogadoPorId({ id });
        console.log(response);
        setUserData(response);
      } catch (error) {
        console.log("Erro ao buscar dados da API:", error);
      }
    }
    fetchUserData();
  }, [id]);

  if (!userData) {
    return <p>Carregando dados do usuário...</p>;
  }
  const handleValidarAdvogado = () => {
    api
      .postValidarAdovogado(id)
      .then((data) => {
        let optionsToast = {
          code: data.mensagem.codigo,
          message: data.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        let optionsToast = {
          code: error.mensagem.codigo,
          message: error.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);
      });
  };

  const handleValidarCodigo = () => {
    api
      .postValidarCodigoAdovogado(id)
      .then((data) => {
        let optionsToast = {
          code: data.mensagem.codigo,
          message: data.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        let optionsToast = {
          code: error.mensagem.codigo,
          message: error.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);
      });
  }

  const toogleDisponibilidade = () => {
    api
      .toogleDispAdovogado(id)
      .then((data) => {
        let optionsToast = {
          code: data.mensagem.codigo,
          message: data.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        let optionsToast = {
          code: error.mensagem.codigo,
          message: error.mensagem.mensagem,
          visible: true,
        };
        setOptionsToast(optionsToast);
      });
  }

  const handleExportDiploma = async () => {
    try {
      const doc = userData.data.diploma;
      const diplomaResponse = await api.postDocumentosAdvogado(doc);
      setDiploma(diplomaResponse);
      setShowDiplomaModal(true);
    } catch (error) {
      console.error("Erro ao exportar diploma:", error);
    }
  };
  const handleExportOabFrente = async () => {
    try {
      const doc = userData.data.oab_frente;
      const oabFrenteResponse = await api.postDocumentosAdvogado(doc);
      setOabFrente(oabFrenteResponse);
      setShowOabFrenteModal(true);
    } catch (error) {
      console.error("Erro ao exportar oabFrente:", error);
    }
  };

  const handleExportOabVerso = async () => {
    try {
      const doc = userData.data.oab_verso;
      const oabVersoResponse = await api.postDocumentosAdvogado(doc);
      setOabVerso(oabVersoResponse);
      setShowOabVersoModal(true);
    } catch (error) {
      console.error("Erro ao exportar oabVerso:", error);
    }
  };

  const handleShowRecusaModal = () => {
    setShowRecusaModal(true);
  };

  const formatDate = (dateString) => {
    return format(
      utcToZonedTime(parseISO(dateString), fusoHorario),
      'dd/MM/yyyy',
      { locale: ptBR }
    );
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <Container className="d-flex flex-column px-4">
      <Toasts
        code={optionsToast.code}
        message={optionsToast.message}
        visible={optionsToast.visible}
        setDefaultOptions={() => fecharToast()}
      />

      <Row className="pe-3 ">
        <Col>
          <Subtitle text="Dados do advogado:" />
        </Col>
      </Row>
      <Row>
        <Col className="d-flex gap-2">
          <Col lg={6}>
            <ParagraphSmall text="Nome:" />
            <Col className="bg-form shadow-sm rounded py-2 px-3">
              <Paragraph text={userData.data.nome} />
            </Col>
          </Col>
          <Col>
            <ParagraphSmall text="CPF:" />
            <Col className="bg-form shadow-sm rounded py-2 px-3">
              <Paragraph text={userData.data.cpf} />
            </Col>
          </Col>
          <Col>
            <ParagraphSmall text="Data nascimento:" />
            <Col className="bg-form shadow-sm rounded py-2 px-3">
              <Paragraph text="--" />
            </Col>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex gap-2">
          <Col lg={6}>
            <ParagraphSmall text="E-mail:" />
            <Col className="bg-form shadow-sm rounded py-2 px-3">
              <Paragraph text={userData.data.email} />
            </Col>
          </Col>
          <Col>
            <ParagraphSmall text="Telefone:" />
            <Col className="bg-form shadow-sm rounded py-2 px-3">
              <Paragraph text={userData.data.celular} />
            </Col>
          </Col>

          <Col>
            <ParagraphSmall text="Data cadastro:" />
            <Col className="bg-form shadow-sm rounded py-2 px-3">
              <Paragraph text={formatDate(userData.data.dataCadastro)} />
            </Col>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col className="border border-1 bg-border-line mt-3 mx-3 "></Col>
      </Row>

      <Row>
        <Col>
          <Col className="d-flex align-items-center gap-1 ps-2">
            <ParagraphSmall text="Carteira OAB - Frente:" />
            <Col className="tamanho icon ps-2">
              <Col lg={1} className="cursor">
                <Icons type="exportar" onClick={handleExportOabFrente} />
              </Col>
              {showOabFrenteModal && (
                <AdmSiteAdvModal
                  show={showOabFrenteModal}
                  onHide={() => setShowOabFrenteModal(false)}
                  base64={oabFrente.data.conteudo_base64}
                />
              )}
            </Col>
          </Col>
        </Col>

        <Col>
          <Col className="d-flex align-items-center gap-1 ps-2">
            <ParagraphSmall text="Carteira OAB - Verso:" />

            <Col className="tamanho icon ps-2">
              <Col lg={1} className="cursor">
                <Icons type="exportar" onClick={handleExportOabVerso} />
              </Col>
              {showOabVersoModal && (
                <AdmSiteAdvModal
                  show={showOabVersoModal}
                  onHide={() => setShowOabVersoModal(false)}
                  base64={oabVerso.data.conteudo_base64}
                />
              )}
            </Col>
          </Col>
        </Col>

        <Col className="">
          <Col className="d-flex align-items-center gap-1 ps-2">
            <ParagraphSmall text="Diploma:" />

            <Col className="tamanho icon ps-2">
              <Col lg={1} className="cursor">
                <Icons type="exportar" onClick={handleExportDiploma} />
              </Col>
              {showDiplomaModal && (
                <AdmSiteAdvModal
                  show={showDiplomaModal}
                  onHide={() => setShowDiplomaModal(false)}
                  base64={diploma.data.conteudo_base64}
                />
              )}
            </Col>
          </Col>
        </Col>
      </Row>

      <Row className="d-flex align-items-end">
        <Col lg={4}>
          <Col>
            <ParagraphSmall text="Número OAB:" />
          </Col>
          <Col className="d-flex me-2">
            <Col className=" bg-form shadow-sm rounded py-1 px-2">
              <Paragraph text={userData.data.oab} />
            </Col>
          </Col>
        </Col>

        <Col lg={4} className="d-flex align-items-end">
          <Col className="d-flex  align-items-center  gap-1 ps-2">
            <ParagraphSmall text="Correspondente jurídico:" />
            <Col className=" bg-form shadow-sm rounded py-1 px-2">
              <Paragraph text={userData.data.correspondente ? "Sim" : "Não"} />
            </Col>

            <Col className="ps-3">
              {/* <Form.Check
                type="switch"
                id="custom-switch"
                label="ACEITO"
                // checked={aceitoTermos}
                // onChange={(e) => setAceitoTermos(e.target.checked)}
              /> */}
            </Col>
          </Col>
        </Col>

        <Col className="d-flex align-items-end">
          <Col className="">
            <Col className="d-flex align-items-center">
              <ParagraphSmall text="Status:" />

              <Col className="ps-2 d-flex align-items-end">
                <Badge pill bg="primary" className="m-1 px-4 py-2">
                  {userData.data.status}
                </Badge>
              </Col>
            </Col>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col>
          <Col className="d-flex align-items-center gap-1 ps-2">
            <ParagraphSmall text="Áreas de atuação:" />
            <Col className="mx-2">
              {userData.data.area.map((area, index) => (
                <Badge pill bg="secondary" className="m-1 p-2" key={index}>
                  {area}
                </Badge>
              ))}
            </Col>
          </Col>
        </Col>
        <Col>
          <Col className="d-flex align-items-center gap-1 ps-2">
            <ParagraphSmall text="Preço da Consulta:" />
            <Col className="mx-2">
                <Badge pill bg="secondary" className="m-1 p-2">
                  R$ {Number(userData.data.valorConsulta).toLocaleString('pt-br', {style: 'decimal', minimumFractionDigits: 2})}
                </Badge>
            </Col>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col>
          <Col className="d-flex align-items-center gap-1 ps-2">
            <ParagraphSmall text="Disponível para plano:" />
            <Col lg={2} className="ps-2 d-flex align-items-end" >
                <Badge pill bg="primary" className={(userData.data.planoDisponivel ? 'badge-disp-green': 'badge-disp-red') + ' m-1 px-4 py-2' }>
                  {userData.data.planoDisponivel ? 'Disponível': 'Não Disponível'}
                </Badge>
                <Icon 
                    icon={ic_sync_alt}
                    size={32}
                    alt={"Ligar Vídeo"}
                    onClick={toogleDisponibilidade}
                    style={{
                        padding: 0,
                        color: "#888",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        cursor:  "pointer"
                    }}
                />
              </Col>
          </Col>
        </Col>
      </Row>

      <Row>
        <Col className="border border-1 bg-border-line mb-3 mx-3 "></Col>
      </Row>

      <Row>
        <Col lg={9} className="d-flex gap-3 mb-5">
          <Btn
            type="terciary-sm"
            text="Voltar"
            onTouchable={handleNavigateBack}
          />
        </Col>

        {userData.data.ativo ? (
          <Col className="d-flex gap-3 mb-5">
            <Btn
              type="secondary-sm"
              text="Bloquear"
              onTouchable={handleValidarAdvogado}
            />
          </Col>
        ) : (
          <Col className="d-flex gap-3 mb-5">
            <Btn
              type="primary-sm"
              text="Recusar"
              onTouchable={() => {
                handleShowRecusaModal();
              }}
            />
            <AdmSiteAdvRecusarModal
              show={showRecusaModal}
              onHide={() => setShowRecusaModal(false)}
              id={id}
            />

            {userData.data.status === "Cadastro Incompleto" ? (
              " "
            ) : userData.data.status === "Código pendente" ? (
              <Btn
                type="secondary-sm"
                text={"Validar Codigo"}
                onTouchable={handleValidarCodigo}
              />
            ) : (
              <Btn
                type="secondary-sm"
                text={"Validar"}
                onTouchable={handleValidarAdvogado}
              />
            )}
          </Col>
        )}
      </Row>
    </Container>
  );
};
