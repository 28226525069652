import React from "react";
import "./styles.css";
import { useParams } from "react-router";
import { CadastroAdvogadoForms } from "../../organisms/CadastroAdvogadoForms";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Logo from "../../atoms/Logo";
import { Link } from "react-router-dom";
import imagem from "../../../img/banner-login.png";
import { Image } from "react-bootstrap";

export const CadastroAdvogado = () => {
  const params = useParams();
  const { idTypeUser } = params;

  return (
    <Container fluid>
      <Row className="row-login">
        <Col className=" m-0 p-0 ">
          <Image className="imagem-div-login-3" src={imagem} />
        </Col>

        <Col lg={6} className="text-center ">
          <Col>
            <Link to="/">
              <Logo type="imagem3" />
            </Link>
            <CadastroAdvogadoForms />
          </Col>
        </Col>
      </Row>
    </Container>
  );
};
